<template>
    <div class="animated fadeIn faster">
        <div class="titlebar-con">
            <ar-titlebar :has_access_accounting="access.accounting" :campaign="campaign_data.name"
                :img="campaign_data.img" :from_count="((current_page - 1) * page_size) + 1" :to_count="listing_to_count"
                :max_count="filteredListings.length" :subject="subject_action_bar" :case_name="case_name"
                class="animated fadeIn faster" :options="options" @click="() => { is_select_all = true; selectAll(); }"
                @uns-click="() => { is_select_all = false; selectAll(); }"
                @accounting-details-function="showAccountingDetails()"
                :selected_count="selected_listings.length ? selected_listings.length : 0"
                :selected_max_count="page_size" :selected="selected" :selected_subject="$route.params.case_id != '0' && typeof $route.params.case_id !== 'undefined' ? 'defendant(s)'
            : $route.params.status == 'Cases' && $route.params.case_id == '0'
                ? 'case(s)'
                : 'data'" :action_text="select_all_text" is_listings>
                <!-- <ar-button v-if="$route.params.case_id != '0' && typeof $route.params.case_id !== 'undefined'" :icon="'fas fa-chevron-left'" :class="'a-btn-text'" @click="backToCases()">Back to Cases</ar-button> -->
                <!-- <ar-button v-else :icon="'fas fa-chevron-left'" :class="'a-btn-text'" @click="backToCampaigns()">Back to Campaign</ar-button> -->
                <template v-slot:search-form>
                    <div class="d-flex justify-content-between align-items-center"
                        v-if="$route.params.status != 'Cases' || ($route.params.status == 'Cases' && ($route.params.case_id != '0' && typeof $route.params.case_id != 'undefined'))">
                        <div class="d-flex justify-content-between align-items-center">
                            <!-- <div
                            >
                                <a
                                    v-if="$route.params.status != 'Cases'"
                                    href="javascript:void(0);"
                                    class="btn d-inline-block btn-primary mr-2"
                                    style="color:#fff; padding: 5px 6px; background:#3E88DA; border:0; font-size: 13.5px"
                                    data-toggle="modal"
                                    data-target="#available-accounts-modal"
                                    v-tooltip   ="`Show Available Payment Accounts`"
                                >
                                    <i class="fas fa-money-bill-wave"></i>
                                </a>
                            </div> -->
                            <div>
                                <a
                                    v-if="access_level != 6"
                                    href="javascript:void(0);"
                                    class="btn d-inline-block btn-primary mr-2"
                                    style="color:#fff; padding: 5px 6px; background:#3E88DA; border:0; font-size: 13.5px"
                                    data-toggle="modal"
                                    data-target="#item-class-type-aggregator"
                                    @click="getItemClass_ItemType()"
                                >
                                    <img src="/static/svg/item-aggregator.svg" alt="Item Aggregator">
                                </a>
                            </div>
                            <ar-select id="search-by-filter" class="mr-2 mb-0" a_style="width: 180px;"
                                :options="$route.params.status != 'Cases' ? search_options : case_search_options"
                                v-model="search_by">
                                {{ $route.params.status != 'Cases' ? $t('Search by Seller Name') : 'Search by Defendants Name' }}
                            </ar-select>
                        </div>

                        <div class="a-input-group" @click="clearGlobalSearch()">
                            <!--<ar-textfield 
                                placeholder ="Search here.." 
                                type        ="text"
                                a_class     ="mr-1 mb-0"
                                :icon        ="search_input.toString().trim() != ''? 'fas fa-times' : 'fas fa-search'"
                                v-model.trim ="search_input"
                                @keyup.enter.native="initializeSearch" 
                                @click       ="clearKeywords"
                            /> -->
                            <ar-textfield :placeholder="hasUsed_globalSearch ? 'Global Search..' : $t('Search here')"
                                type="text" a_class="mr-1 mb-0"
                                :icon="hasUsed_globalSearch ? 'fas fa-times text-danger' : search_input.toString().trim() != '' ? 'fas fa-times' : 'fas fa-search'"
                                v-model.trim="search_input" @input="current_page = 1" @click="clearKeywords"
                                @keyup.enter.native="$route.params.status == 'Cases' ? '' : globalSearch(search_input)"
                                v-tooltip="'Press Enter to Global Search'" />
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="$featureAccess.isAllowed('cm-button-case-files-explorer')" class="cm-button-case-files-explorer d-flex justify-content-end align-items-center">
                            <router-link :to="{ name: 'lawfirmcasefilesexplorer' }" @click="goToCaseFilesExplorer()" class="d-inline-block mr-2">
                                <!-- <img src="/static/svg/case-files-explorer/Case Files.svg" alt="Something Folder"> -->
                                <ar-button outlined class="text-uppercase" style="padding: 3px 10px; letter-spacing: 1.07px; font: bold 12px / 16px Roboto;">
                                    Case Files Explorer
                                    <i class="fas fa-arrow-right ml-1" style="transform: rotate(-45deg);" />
                                </ar-button>
                            </router-link>
                        </div>
                    </div>
                </template>
                <template>
                    <div class="qa-selected">
                        <a
                            v-if               ="isInCasesPage && !isFeatureRestricted('cm-modal-download-case-files')"
                            href               ="javascript: void(0);"
                            data-toggle        ="modal"
                            data-target        ="#case-download-case-files-modal"
                            class              ="mr-3 action-item cm-download-case-files"
                            title              ="Download Case File(s) Excel"
                        >
                            <img src="/static/svg/Action_viewfiles.svg" style="-webkit-transform: rotate(180deg); transform: rotate(180deg);" alt="download case files">
                        </a>
                        <!-- <template v-if="showExportFrozenAccounts"> -->
                        <!-- v-if="show_actions.includes('frozen')" -->
                        <!-- v-restrict ="'cm-download-frozen-accounts-summary'" -->
                        <a
                            v-if               ="isInCasesPage && !isFeatureRestricted('cm-download-frozen-accounts-summary')"
                            href               ="javascript: void(0);"
                            data-toggle        ="modal"
                            @click             ="showExportFrozenModal()"
                            class              ="mr-3 action-item cm-download-frozen-accounts-summary"
                            title              ="Export Frozen Accounts"
                        >
                            <img src="/static/svg/Action_Frozen.svg" alt="frozen">
                        </a>
                        <!-- </template> -->
                        <!-- v-if   ="show_actions.includes('vendorCase')" -->
                        <!-- v-restrict ="'cm-button-export-vendor-data'" -->
                        <a
                            v-if               ="isInCasesPage && !isFeatureRestricted('cm-button-export-vendor-data')"
                            href               ="javascript: void(0);"
                            @click             ="initializeExport('vendorCase')"
                            class              ="mr-3 action-item cm-button-export-vendor-data"
                            title              ="Export Vendor Data"
                        >
                            <img src="/static/svg/Action_Vendor.svg" alt="export">
                        </a>
                        <a
                            v-if               ="isInCasesPage && !isFeatureRestricted('cm-button-export-vendor-data')"
                            href               ="javascript: voide(0);"
                            @click             ="initializeExport('vendorCaseHistory')"
                            class              ="mr-3 action-item cm-button-export-vendor-data"
                            title              ="Export Vendor Data (Seller Status History)"
                        >
                            <img src="/static/svg/Action_VendorHistory.svg" alt="export">
                        </a>
                        <!-- <strong class="mr-3 action-item" v-if="this.$route.params.status == 'Cases' && this.$route.params.case_id == '0' && access.defaults"> | </strong>  -->
                        <!-- v-if="show_actions.includes('export')" -->
                        <!-- v-restrict ="'cm-link-export-options'" -->
                        <a
                            v-if="!isFeatureRestricted('cm-link-export-options')"
                            href               ="javascript: void(0);"
                            @click             ="showExportDialog()"
                            class              ="mr-3 action-item cm-link-export-options"
                            v-tooltip          ="`Show Export Options`"
                        >
                            <img src="/static/svg/export/Action_export.svg" alt="export">
                        </a>

                        <!-- v-if="show_actions.includes('settlementAgreement') && [7].includes(access_level)" -->
                        <!-- v-restrict ="'cm-button-generate-settlement-agreement'" -->
                        <a
                            v-if               ="isInDefendantsPage && !isFeatureRestricted('cm-button-generate-settlement-agreement')"
                            href               ="javascript: void(0);"
                            class              ="mr-3 action-item cm-button-generate-settlement-agreement"
                            v-tooltip          ="`Generate Settlement Agreement`"
                            data-toggle        ="modal"
                            @click             ="showSettlementAgreementModal()"
                        >
                            <img src="/static/svg/Action_SA.svg" alt="sa">
                        </a>

                        <!-- v-if="show_actions.includes('groupSeller') && (!access.readonly || [7].includes(access_level)) && access_level == 7" -->
                        <!-- v-restrict ="'cm-link-group-sellers'" -->
                        <a
                            v-if               ="isInDefendantsPage && !isFeatureRestricted('cm-link-group-sellers')"
                            href               ="javascript: void(0);"
                            class              ="mr-3 action-item cm-link-group-sellers"
                            v-tooltip          ="`Group Seller`"
                            data-toggle        ="modal"
                            data-target        ="#group-seller-modal"
                            @click             ="resetGroupnameData()"
                        >
                            <img src="/static/svg/accounting/Group_Name.svg" alt="group seller" style="height:23px;">
                        </a>
                        <!-- <a href="javascript:void(0);" v-if="show_actions.includes('excel') && (!access.readonly || [7].includes(access_level))" @click="initializeExport('excel')" class="mr-3 action-item" title="Export to Excel">
                            <img src="/static/svg/Action_Excel.svg" alt="export">
                        </a>
                        <a href="javascript:void(0);" v-if="show_actions.includes('word') && (!access.readonly || [7].includes(access_level))" @click="initializeExport('word')" class="mr-3 action-item" title="Export to Word">
                            <img src="/static/svg/Action_Word.svg" alt="export">
                        </a> -->
                        <!-- <a href="javascript:void(0);" v-if="show_actions.includes('schedulea')" @click="initializeExport('schedulea')" class="mr-3 action-item" title="Export Schedule A">
                            <img src="/static/svg/Action_ScheduleA.svg" alt="export">
                        </a> -->
                        <!-- <a href="javascript:void(0);" v-if="show_actions.includes('vendor')" data-toggle="modal" data-target="#vendor-extract-modal" class="mr-3 action-item" title="Export Vendor Data">
                            <img src="/static/svg/Action_Vendor.svg" alt="export">
                        </a> -->
                        <!-- v-if="show_actions.includes('moveData')" -->
                        <!-- v-restrict ="'cm-link-move-data'" -->
                        <a
                            v-if               ="isInDefendantsPage && !isFeatureRestricted('cm-link-move-data')"
                            href               ="javascript: void(0);"
                            @click             ="showMoveModal()"
                            class              ="mr-3 action-item cm-link-move-data"
                            v-tooltip          ="`Move Data`"
                        >
                            <img src="/static/svg/Action_MoveData.svg" alt="export">
                        </a>
                        <!-- <strong class="mr-3 action-item" v-if="this.$route.params.status != 'Cases' && this.$route.params.case_id == '0'"> | </strong>  -->
                        <!-- v-if="show_actions.includes('reassign') && $route.params.status === 'Rejected'" -->
                        <!-- v-restrict ="'cm-button-reassign'" -->
                        <a
                            v-if               ="isInRejectedTab || isInForInternalReviewTab && !isFeatureRestricted('cm-button-reassign')"
                            href               ="javascript:void(0);"
                            data-toggle        ="modal"
                            data-target        ="#reassign-modal"
                            @click             ="resetPredefinedReasonState(); selected_reasons = []"
                            class              ="mr-3 action-item cm-button-reassign"
                            v-tooltip          ="`Reassign`"
                        >
                            <img src="/static/svg/Action_Reassign.svg" alt="reassign">
                        </a>

                        <!-- v-if="show_actions.includes('group_listings') && settlement_selected_defendants.find(l => l.group_name != null) == null" -->
                        <!-- v-restrict ="'cm-link-group-listings'" -->
                        <a
                            v-if               ="settlement_selected_defendants.find(l => l.group_name != null) == null && !isFeatureRestricted('cm-link-group-listings')"
                            href               ="javascript:void(0);"
                            data-toggle        ="modal"
                            data-target        ="#group-listings-modal"
                            @click             ="selected_reasons = []; already_worked_lids = ''"
                            class              ="mr-3 action-item cm-link-group-listings"
                            v-tooltip          ="`Group Data`"
                        >
                            <img src="/static/svg/Action_Group.svg" alt="group listings">
                        </a>

                        <template v-if="canStatusChange && $route.params.status !== 'Cases'">
                            <!-- v-if="show_actions.includes('wip')" -->
                            <!-- v-restrict ="'cm-button-mark-as-wip'" -->
                            <div class="dropdown d-inline-block mr-3" v-if="$route.params.status === 'Auto Qualified' && !isFeatureRestricted('cm-button-mark-as-wip')">
                                <a
                                    id="acceptedSubStatusDropdown"
                                    href               ="javascript:void(0);"
                                    :data-toggle        ="wipSubStatuses.length ? 'dropdown' : 'modal'"
                                    :data-target        ="!wipSubStatuses.length ? '#set-wip-modal' : null"
                                    @click             ="resetPredefinedReasonState(); selected_reasons = []"
                                    class              ="action-item cm-button-mark-as-wip"
                                    v-tooltip          ="`Mark as Work in Progress`"
                                >
                                    <img src="/static/svg/Action_WIP.svg" alt="wip">
                                </a>
                                <div v-if="wipSubStatuses.length" class="dropdown-menu dropdown-menu-right" aria-labelledby="acceptedSubStatusDropdown">
                                    <label class="ml-3 font-weight-medium" style="font-size: 14px;">Select a Sub-status</label>
                                    <hr class="m-0 mb-2" />
                                    <div class="bulk-status-options-con">
                                        <a
                                            v-for="(option, index) in wipSubStatuses"
                                            :key="index" 
                                            class="dropdown-item"
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            data-target="#set-wip-modal"
                                            @click="subStatusesStore.currentSelectedSubStatus = option.id"
                                        >
                                            {{ option.label }}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <!-- v-if="show_actions.includes('monitoring')" -->
                            <!-- v-restrict ="'cm-status-change-monitoring'" -->
                            <div class="dropdown d-inline-block mr-3" v-if="!isInMonitoringTab && !isFeatureRestricted('cm-status-change-monitoring')">
                                <a
                                    id="monitoringSubStatusDropdown"
                                    href               ="javascript: void(0);"
                                    :data-toggle        ="monitoringSubStatuses.length ? 'dropdown' : 'modal'"
                                    :data-target        ="!monitoringSubStatuses.length ? '#set-monitoring-modal' : null"
                                    @click             ="resetPredefinedReasonState(); getPredefinedReasons(31)"
                                    class              ="action-item cm-status-change-monitoring"
                                    v-tooltip          ="$t(`Mark as Monitoring (Temporary On Hold)`)"
                                >
                                    <img src="/static/svg/Action_Monitor.svg" alt="formonitoring">
                                </a>
                                <div v-if="monitoringSubStatuses.length" class="dropdown-menu dropdown-menu-right" aria-labelledby="monitoringSubStatusDropdown">
                                    <label class="ml-3 font-weight-medium" style="font-size: 14px;">Select a Sub-status</label>
                                    <hr class="m-0 mb-2" />
                                    <div class="bulk-status-options-con">
                                        <a
                                            v-for="(option, index) in monitoringSubStatuses"
                                            :key="index" 
                                            class="dropdown-item"
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            data-target="#set-monitoring-modal"
                                            @click="subStatusesStore.currentSelectedSubStatus = option.id"
                                        >
                                            {{ option.label }}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <!-- v-if="show_actions.includes('forreview')" -->
                            <!-- v-restrict ="'cm-status-change-for-review'" -->
                            <div class="dropdown d-inline-block mr-3" v-if="!isInForReviewTab && !isFeatureRestricted('cm-status-change-for-review')">
                                <a
                                    id="forReviewSubStatusDropdown"
                                    href               ="javascript:void(0);"
                                    :data-toggle        ="forreviewSubStatuses.length ? 'dropdown' : 'modal'"
                                    :data-target        ="!forreviewSubStatuses.length ? `#set-${isInFiledTab ? 'filed-to-' : ''}forreview-modal` : null"
                                    @click             ="resetPredefinedReasonState(); getPredefinedReasons(3); selected_reasons = []; already_worked_lids = ''"
                                    class              ="action-item cm-status-change-for-review"
                                    v-tooltip          ="`Mark as For Review`"
                                >
                                    <img src="/static/svg/Action_ForReview.svg" alt="forreview">
                                </a>
                                <div v-if="forreviewSubStatuses.length" class="dropdown-menu dropdown-menu-right" aria-labelledby="forReviewSubStatusDropdown">
                                    <label class="ml-3 font-weight-medium" style="font-size: 14px;">Select a Sub-status</label>
                                    <hr class="m-0 mb-2" />
                                    <div class="bulk-status-options-con">
                                        <a
                                            v-for="(option, index) in forreviewSubStatuses"
                                            :key="index" 
                                            class="dropdown-item"
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            :data-target="`#set-${isInFiledTab ? 'filed-to-' : ''}forreview-modal`"
                                            @click="subStatusesStore.currentSelectedSubStatus = option.id"
                                        >
                                            {{ option.label }}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <!-- <a 
                                href        ="javascript:void(0);" 
                                data-toggle ="modal" 
                                data-target ="#set-accepted-modal" 
                                @click      ="getPredefinedReasons(32)"
                                v-if        ="show_actions.includes('accepted')" 
                                class       ="mr-3 action-item" 
                                v-tooltip   ="`Mark as Accepted (Counterfeit - Approved for Filing)`"
                            >
                                <img src="/static/svg/Action_Accept.svg" alt="accept">
                            </a> -->
                            <!-- <a 
                                href        ="javascript:void(0);" 
                                @click      ="checkListings"
                                v-if        ="show_actions.includes('accepted')" 
                                class       ="mr-3 action-item"
                                v-tooltip   ="`Mark as Accepted (Counterfeit - Approved for Filing)`"
                            >
                                <img src="/static/svg/Action_Accept.svg" alt="accept">
                            </a> -->

                            <!-- v-if="show_actions.includes('accepted')" -->
                            <!-- v-restrict ="'cm-status-change-accepted'" -->
                            <div class="dropdown d-inline-block mr-3" v-if="!isInAcceptedTab && !isFeatureRestricted('cm-status-change-accepted') && !(isClient && isInHasPotentialTab)">
                                <a
                                    id="acceptedSubStatusDropdown"
                                    href               ="javascript:void(0);"
                                    :data-toggle        ="acceptedSubStatuses.length ? 'dropdown' : 'modal'"
                                    :data-target        ="!acceptedSubStatuses.length ? `#set-${isInFiledTab ? 'filed-to-' : ''}accepted-modal` : null"
                                    @click             ="resetPredefinedReasonState(); getPredefinedReasons(32); selected_reasons = []"
                                    class              ="action-item cm-status-change-accepted"
                                    v-tooltip          ="$t(`Mark as Accepted (Counterfeit - Approved for Filing)`)"
                                >
                                    <img src="/static/svg/Action_Accept.svg" alt="accept">
                                </a>
                                <div v-if="acceptedSubStatuses.length" class="dropdown-menu dropdown-menu-right" aria-labelledby="acceptedSubStatusDropdown">
                                    <label class="ml-3 font-weight-medium" style="font-size: 14px;">Select a Sub-status</label>
                                    <hr class="m-0 mb-2" />
                                    <div class="bulk-status-options-con">
                                        <a
                                            v-for="(option, index) in acceptedSubStatuses"
                                            :key="index" 
                                            class="dropdown-item"
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            :data-target="`#set-${isInFiledTab ? 'filed-to-' : ''}accepted-modal`"
                                            @click="subStatusesStore.currentSelectedSubStatus = option.id"
                                        >
                                            {{ option.label }}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <!-- v-if="show_actions.includes('rejected')" -->
                            <!-- v-restrict ="'cm-status-change-rejected'" -->
                            <div class="dropdown d-inline-block mr-3" v-if="!isInRejectedTab && !isFeatureRestricted('cm-status-change-rejected')">
                                <a
                                    id="rejectedSubStatusDropdown"
                                    href               ="javascript: void(0);"
                                    :data-toggle        ="rejectedSubStatuses.length ? 'dropdown' : 'modal'"
                                    :data-target        ="!rejectedSubStatuses.length ? '#set-rejected-modal' : null"
                                    @click             ="resetPredefinedReasonState(); getPredefinedReasons(33)"
                                    class              ="action-item cm-status-change-rejected"
                                    v-tooltip          ="$t(`Mark as Rejected (Legit - Disapproved for Filing)`)"
                                >
                                    <img src="/static/svg/Action_Reject.svg" alt="reject">
                                </a>
                                <div v-if="rejectedSubStatuses.length" class="dropdown-menu dropdown-menu-right" aria-labelledby="rejectedSubStatusDropdown">
                                    <label class="ml-3 font-weight-medium" style="font-size: 14px;">Select a Sub-status</label>
                                    <hr class="m-0 mb-2" />
                                    <div class="bulk-status-options-con">
                                        <a
                                            v-for="(option, index) in rejectedSubStatuses"
                                            :key="index" 
                                            class="dropdown-item"
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            data-target="#set-rejected-modal"
                                            @click="subStatusesStore.currentSelectedSubStatus = option.id"
                                        >
                                            {{ option.label }}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <!-- v-if="show_actions.includes('submitted')" -->
                            <!-- v-restrict ="'cm-status-change-submitted'" -->
                            <!-- @click="resetPredefinedReasonState(); checkListings();" -->
                            <div class="dropdown d-inline-block mr-3" v-if="!isInSubmittedTab && !isFeatureRestricted('cm-status-change-submitted')">
                                <a
                                    id="submittedSubStatusDropdown"
                                    href               ="javascript: void(0);"
                                    :data-toggle        ="submittedSubStatuses.length ? 'dropdown' : 'modal'"
                                    :data-target        ="!submittedSubStatuses.length ? `#set-${isInFiledTab ? 'filed-to-' : ''}submitted-modal` : null"
                                    @click             ="resetPredefinedReasonState(); getPredefinedReasons(35);"
                                    class              ="action-item cm-status-change-submitted"
                                    v-tooltip          ="`Mark as For Submission`"
                                >
                                    <img src="/static/svg/Action_Submitted.svg" alt="submitted">
                                </a>
                                <div v-if="submittedSubStatuses.length" class="dropdown-menu dropdown-menu-right" aria-labelledby="submittedSubStatusDropdown">
                                    <label class="ml-3 font-weight-medium" style="font-size: 14px;">Select a Sub-status</label>
                                    <hr class="m-0 mb-2" />
                                    <div class="bulk-status-options-con">
                                        <a
                                            v-for="(option, index) in submittedSubStatuses"
                                            :key="index" 
                                            class="dropdown-item"
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            :data-target="`#set-${isInFiledTab ? 'filed-to-' : ''}submitted-modal`"
                                            @click="subStatusesStore.currentSelectedSubStatus = option.id"
                                        >
                                            {{ option.label }}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <!-- v-if="show_actions.includes('for-internal-review')" -->
                            <!-- v-restrict ="'cm-status-change-for-internal-review'" -->
                            <div class="dropdown d-inline-block mr-3" v-if="!isInForInternalReviewTab && !isFeatureRestricted('cm-status-change-for-internal-review')">
                                <a
                                    id="forInternalReviewSubStatusDropdown"
                                    href               ="javascript: void(0);"
                                    :data-toggle        ="firSubStatuses.length ? 'dropdown' : 'modal'"
                                    :data-target        ="!firSubStatuses.length ? '#set-fir-modal' : null"
                                    @click             ="resetPredefinedReasonState(); getPredefinedReasons(36)"
                                    class              ="action-item cm-status-change-for-internal-review"
                                    v-tooltip          ="`Mark as For Internal Review`"
                                >
                                    <img src="/static/svg/Action_InternalReview.svg" alt="for-internal-review">
                                </a>
                                <div v-if="firSubStatuses.length" class="dropdown-menu dropdown-menu-right" aria-labelledby="forInternalReviewSubStatusDropdown">
                                    <label class="ml-3 font-weight-medium" style="font-size: 14px;">Select a Sub-status</label>
                                    <hr class="m-0 mb-2" />
                                    <div class="bulk-status-options-con">
                                        <a
                                            v-for="(option, index) in firSubStatuses"
                                            :key="index" 
                                            class="dropdown-item"
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            data-target="#set-fir-modal"
                                            @click="subStatusesStore.currentSelectedSubStatus = option.id"
                                        >
                                            {{ option.label }}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <!-- v-if="show_actions.includes('initial-review-completed')" -->
                            <!-- v-restrict ="'cm-status-change-initial-review-completed'" -->
                            <div class="dropdown d-inline-block mr-3" v-if="!isInInitialReviewCompletedTab && !isFeatureRestricted('cm-status-change-initial-review-completed')">
                                <a
                                    id="internalReviewCompletedSubStatusDropdown"
                                    href               ="javascript:void(0);"
                                    :data-toggle        ="ircSubStatuses.length ? 'dropdown' : 'modal'"
                                    :data-target        ="!ircSubStatuses.length ? `#set-${isInFiledTab ? 'filed-to-' : ''}irc-modal` : null"
                                    @click             ="resetPredefinedReasonState(); getPredefinedReasons(37); selected_reasons = []"
                                    class              ="action-item cm-status-change-initial-review-completed"
                                    v-tooltip          ="`Mark as Initial Review Completed`"
                                >
                                    <img src="/static/svg/Action_complete.svg" alt="initial-review-completed">
                                </a>
                                <div v-if="ircSubStatuses.length" class="dropdown-menu dropdown-menu-right" aria-labelledby="internalReviewCompletedSubStatusDropdown">
                                    <label class="ml-3 font-weight-medium" style="font-size: 14px;">Select a Sub-status</label>
                                    <hr class="m-0 mb-2" />
                                    <div class="bulk-status-options-con">
                                        <a
                                            v-for="(option, index) in ircSubStatuses"
                                            :key="index" 
                                            class="dropdown-item"
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            :data-target="`#set-${isInFiledTab ? 'filed-to-' : ''}irc-modal`"
                                            @click="subStatusesStore.currentSelectedSubStatus = option.id"
                                        >
                                            {{ option.label }}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <!-- v-if="show_actions.includes('haspo')" -->
                            <!-- v-restrict ="'cm-status-change-has-potential'" -->
                            <div class="dropdown d-inline-block mr-3" v-if="!isInHasPotentialTab && !isFeatureRestricted('cm-status-change-has-potential')">
                                <a
                                    id="hasPoCompletedSubStatusDropdown"
                                    href               ="javascript: void(0);"
                                    class              ="action-item cm-status-change-has-potential"
                                    :data-toggle        ="hasPotentialSubStatuses.length ? 'dropdown' : 'modal'"
                                    :data-target        ="!hasPotentialSubStatuses.length ? '#set-haspo-modal' : null"
                                    @click             ="resetPredefinedReasonState(); getPredefinedReasons(98)"
                                    title              ="Mark as Has Potential"
                                >
                                    <img src="/static/svg/Action_HasPo.svg" alt="haspo">
                                </a>
                                <div v-if="hasPotentialSubStatuses.length" class="dropdown-menu dropdown-menu-right" aria-labelledby="hasPoCompletedSubStatusDropdown">
                                    <label class="ml-3 font-weight-medium" style="font-size: 14px;">Select a Sub-status</label>
                                    <hr class="m-0 mb-2" />
                                    <div class="bulk-status-options-con">
                                        <a
                                            v-for="(option, index) in hasPotentialSubStatuses"
                                            :key="index" 
                                            class="dropdown-item"
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            data-target="#set-haspo-modal"
                                            @click="subStatusesStore.currentSelectedSubStatus = option.id"
                                        >
                                            {{ option.label }}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <!-- v-if="show_actions.includes('recycled')" -->
                            <!-- v-restrict ="'cm-status-change-recycled'" -->
                            <div class="dropdown d-inline-block mr-3" v-if="!isInRecycledTab && !isFeatureRestricted('cm-status-change-recycled')">
                                <a
                                    id="recycledCompletedSubStatusDropdown"
                                    href="javascript:void(0);"
                                    :data-toggle="recycledSubStatuses.length ? 'dropdown' : 'modal'"
                                    :data-target="!recycledSubStatuses.length ? '#set-recycled-modal' : null"
                                    @click="resetPredefinedReasonState(); getPredefinedReasons(38)"
                                    class="action-item cm-status-change-recycled"
                                    v-tooltip="`Mark as Recycled`"
                                >
                                    <img src="/static/svg/Action_recycle.svg" alt="accept">
                                </a>
                                <div v-if="recycledSubStatuses.length" class="dropdown-menu dropdown-menu-right" aria-labelledby="recycledCompletedSubStatusDropdown">
                                    <label class="ml-3 font-weight-medium" style="font-size: 14px;">Select a Sub-status</label>
                                    <hr class="m-0 mb-2" />
                                    <div class="bulk-status-options-con">
                                        <a
                                            v-for="(option, index) in recycledSubStatuses"
                                            :key="index" 
                                            class="dropdown-item"
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            data-target="#set-recycled-modal"
                                            @click="subStatusesStore.currentSelectedSubStatus = option.id"
                                        >
                                            {{ option.label }}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div class="dropdown d-inline-block mr-3" v-if="transferToAnotherSubStatusOptions.length">
                                <a
                                    id="transferToAnotherSubStatusDropdown"
                                    href="javascript:void(0);"
                                    data-toggle="dropdown"
                                    class="action-item"
                                    v-tooltip="`Transfer to another sub status`"
                                >
                                    <img src="/static/svg/Action_Archived.svg" alt="transfer to another sub status" style="height: 23px; width: 23px;">
                                </a>
                                <div v-if="transferToAnotherSubStatusOptions.length" class="dropdown-menu dropdown-menu-right" aria-labelledby="transferToAnotherSubStatusDropdown">
                                    <label class="ml-3 font-weight-medium" style="font-size: 14px;">Select a Sub-status</label>
                                    <hr class="m-0 mb-2" />
                                    <div class="bulk-status-options-con">
                                        <a
                                            v-for="(option, index) in transferToAnotherSubStatusOptions"
                                            :key="index" 
                                            class="dropdown-item"
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            data-target="#transfer-to-another-substatus-modal"
                                            @click="showTransferSubStatusModal(option)"
                                        >
                                            {{ option.label }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <!-- Add to Case Modal trigger -->
                        <!-- v-if="show_actions.includes('add-to-case')" -->
                        <!-- v-restrict ="'cm-link-add-to-case'" -->
                        <a
                            v-if               ="isInSubmittedTab && !isFeatureRestricted('cm-link-add-to-case')"
                            href               ="javascript: void(0);"
                            class              ="mr-3 action-item cm-link-add-to-case"
                            v-tooltip          ="`Add to Case`"
                            @click             ="showAddToCaseModal"
                        >
                            <img src="/static/svg/Action_Existingcase.svg" alt="add to case">
                        </a>

                        <!-- v-if="show_actions.includes('filecase') && access_level == 7" -->
                        <!-- v-restrict ="'cm-link-create-case'" -->
                        <a
                            v-if               ="isInSubmittedTab && !isFeatureRestricted('cm-link-create-case')"
                            href               ="javascript: void(0);"
                            class              ="mr-4 action-item cm-button-create-case"
                            v-tooltip          ="`File a case`"
                            @click             ="newCase"
                        >
                            <img src="/static/svg/Action_FileCase.svg" alt="filecase">
                        </a>

                        <!-- v-if="show_actions.includes('removecase')" -->
                        <!-- v-restrict ="'cm-button-remove-cases'" -->
                        <a
                            v-if               ="isInCasesPage && !isFeatureRestricted('cm-button-remove-cases')"
                            href               ="javascript:void(0);"
                            class              ="mr-4 action-item cm-button-remove-cases"
                            data-toggle        ="modal"
                            data-target        ="#remove-case-modal"
                            v-tooltip          ="`Remove case(s)`"
                        >
                            <img src="/static/svg/Action_Remove.svg" alt="removecase">
                        </a>
                        <!-- <strong class="mr-3" v-if="this.$route.params.status == 'Cases' && this.$route.params.case_id != '0'"> | </strong> 
                        <a href="javascript:void(0);" v-if="show_actions.includes('removedefendants')" class="mr-4 action-item" data-toggle="modal" data-target="#remove-defendant-modal" title="Remove defendant(s)">
                            <img src="/static/svg/Action_Remove.svg" alt="removedefendants">
                        </a> -->
                    </div>
                </template>
            </ar-titlebar>

            <!-- Start of additional filters -->
            <template v-if="selected_listings.length == 0">
                <template v-if="$route.params.case_id != '0'">
                    <div class="case-timeline-menu position-relative d-flex align-items-center pl-5">
                        <h4 class="mt-1 mb-0 mr-3">Case Timeline</h4>
                        <div class="d-flex align-items-center defendants-case-statuses pl-4">
                            <a :target="file?.link ? '_blank' : null" v-for="(file, index) in caseFileStore.caseFiles" 
                                :key="`${case_name}--case-file--${index}`" :href="file?.link ? file.link : 'javascript:void(0)'" class="position-relative" 
                                style="margin-left: -20px;"
                                :style="`z-index: ${5 - index}`">
                                <span class="case-status text-uppercase info-status mb-0 case-file" 
                                    :class="[{'available': file?.link, 'current': file?.link && currentCaseStatus == file?.label }]">
                                    <img v-if="file?.link" src="/static/svg/agreement-files/PDF.svg" class="mr-1" /> {{ file?.label }}
                                </span>
                            </a>
                        </div>
                    </div>
                </template>

                <div class="position-relative d-flex justify-content-between align-items-center border-top pl-4"
                    style="border-width: 2px !important; border-color: #ccc;">
                    <div>
                        <ar-button v-if="$route.params.case_id != '0'" @click="backToCases"
                            class="position-relative font-weight-bold pl-3 a-btn-text animated bounceInLeft faster"
                            style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;">
                            <img class="position-relative mr-1" style="top: -2px;"
                                src="/static/svg/defendants/Back_Cases.svg" alt="backCase"> {{ $t('Back to Cases') }}
                        </ar-button>
                        <div class="dropdown d-inline-block pl-2"
                            :class="{ 'pl-3 ml-2 has-bullet-separator': $route.params.case_id != '0' }">
                            <a href="javascript:void(0);" id="sortDropdown" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <ar-button class="position-relative font-weight-bold pl-3 a-btn-text" :disabled="customSorting"
                                    style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;">
                                    <img class="position-relative mr-1" style="top: -2px;"
                                        src="/static/svg/defendants/Sort.svg" alt="backCase"> {{ $t(sort_by_display) }}
                                </ar-button>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="sortDropdown">
                                <span class="sort-order-label"> Sort data by </span>
                                <a v-for="option in  sort_by_option" :key="option.value" class="dropdown-item"
                                    href="javascript:void(0);" @click="sort_by = option.value">
                                    <!-- [6].includes(access_level) ? sort_by_option_client : -->
                                    {{ $t(option.label) }}
                                </a>
                            </div>
                        </div>
                        <div class="dropdown d-inline-block pl-3 ml-2 has-bullet-separator">
                            <a href="javascript:void(0);" id="orderDropdown" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <ar-button class="position-relative font-weight-bold pl-3 a-btn-text" :disabled="customSorting"
                                    style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;">
                                    <img class="position-relative mr-1" style="top: -2px;"
                                        src="/static/svg/defendants/Order.svg" alt="backCase"> {{ $t($parent.ucFirst(order_by+'ending')) }}
                                </ar-button>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="orderDropdown">
                                <span class="sort-order-label"> Order Data by </span>
                                <a v-for="option in order_by_option" :key="option.value" class="dropdown-item"
                                    href="javascript:void(0);" @click="order_by = option.value">
                                    {{ $t(option.label) }}
                                </a>
                            </div>
                        </div>
                        <div class="d-inline-block pl-3 ml-2 has-bullet-separator" v-if="access_level !== 6">
                            <a href="javascript:void(0);">
                                <ar-button 
                                    @click="customPrioritySort = !customPrioritySort"
                                    class="animated fadeIn fast position-relative font-weight-bold pl-3 a-btn-text" 
                                    v-tooltip="customPrioritySort ? 'Customized Data Prioritization is on. Click to disable' : 'Platform data prioritization is turned off. Click to enable.'"
                                    style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;"
                                    :style="customPrioritySort ? 'background: #2D59AE; color: #FFFFFF' : ''">
                                    <template v-if="customPrioritySort">
                                        <img 
                                            v-for="(p, index) in platform_prioritization.data" 
                                            :key="`prio-platform-${index}`" 
                                            class="position-relative mr-1" 
                                            style="top: -1px; height: 16px; width: 16px"
                                            :src="$parent.getImage('platforms', p.name)"/>
                                    </template>
                                    <img v-else class="position-relative mr-1" style="top: -1px; height: 16px; width: 16px" :src="`${$parent.getImage('platforms', `Walmart`)}`">

                                    <!-- <img class="position-relative mr-1" style="top: -1px; height: 16px; width: 16px" :src="`${$parent.getImage('platforms', `Walmart`)}`">
                                    <img class="position-relative mr-1" style="top: -1px; height: 16px; width: 16px" :src="`${$parent.getImage('platforms', `Amazon`)}`">
                                    <img class="position-relative mr-1" style="top: -1px; height: 16px; width: 16px" :src="`${$parent.getImage('platforms', `Ebay`)}`">
                                    <img class="position-relative mr-1" style="top: -1px; height: 16px; width: 16px" :src="`${$parent.getImage('platforms', `Temu`)}`">
                                    <img class="position-relative mr-1" style="top: -1px; height: 16px; width: 16px" src="/static/img/payment-types/payment-fruugo.jpg">
                                    <img class="position-relative mr-1" style="top: -1px; height: 16px; width: 16px" src="/static/img/payment-types/payment-other.png">
                                    <img class="position-relative mr-1" style="top: -1px; height: 16px; width: 16px" src="/static/img/payment-types/payment-no-account.png"> -->
                                    Data Priority {{ ['Off', 'On'][+customPrioritySort] }}
                                </ar-button>
                            </a>
                        </div>
                        <template v-if="$featureAccess.isAllowed('cm-button-manage-groups')">
                            <div class="d-inline-block pl-3 ml-2 has-bullet-separator" v-if="$route.params.status !== 'Cases'">
                                <ar-button class="btn a-btn btn-primary a-btn-outline" style="border: none; outline: none; font-size: 12px; text-align:center; 
                                    padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;"
                                    @click="openManageGroups()"
                                >
                                    <img src="/static/svg/groups.svg" alt="Manage Groups" class="position-relative mr-1" style="top: -2px;">
                                    Manage Groups
                                </ar-button>
                            </div>
                        </template>
                        <!-- <div class="dropdown d-inline-block pl-3 ml-2 has-bullet-separator" v-if="$route.params.status == 'Cases' && $route.params.case_id == '0'">
                            <a
                                href="javascript:void(0);"
                                id="orderDropdown"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <ar-button
                                    class="position-relative font-weight-bold pl-3 a-btn-text"
                                    style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;">
                                    <img class="position-relative mr-1" style="top: -2px;" src="/static/svg/defendants/Sort.svg" alt="backCase"> Show - {{ case_status_options[$route.query.case_status || 0] }}
                                </ar-button>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="orderDropdown">
                                <a
                                    v-for="(option, index) in case_status_options"
                                    :key="index" 
                                    class="dropdown-item"
                                    href="javascript:void(0);"
                                    @click="$router.push({query: {case_status: index}})"
                                >
                                    <span v-if="option === 'All'">{{ option }}</span>
                                    <span v-else>{{ option }} [{{ listings.filter(l => l.case_status === index).length }}]</span>
                                </a>
                            </div>
                        </div> -->
                        <div class="dropdown d-inline-block pl-3 ml-2 has-bullet-separator"
                            v-if="$route.params.case_id != '0'">
                            <a href="javascript:void(0);" id="groupFilterDropdown" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <ar-button class="position-relative font-weight-medium pl-3 a-btn-text"
                                    style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;"
                                    :disabled="!group_names.length">
                                    <img src="/static/svg/notifications/Sort_Researcher.svg" class="position-relative"
                                        style="margin-right: 2px; top: -2px" alt="icon">
                                    {{ groupFilterLabel }}
                                </ar-button>
                            </a>
                            <div class="dropdown-menu p-3" aria-labelledby="groupFilterDropdown" style="width: 404px;">
                                <ar-select label="Group Name" :options="group_names" type="search" :val="filter_group"
                                    v-model="filter_group">
                                    Select Group
                                </ar-select>
                            </div>
                        </div>
                    </div>
                    <ar-button v-if="$route.params.status != 'Cases'" class="position-relative rounded mt-2 pl-3"
                        :icon="`fas ${expanded ? 'fa-angle-up' : 'fa-angle-down'}`" icon_right outlined
                        style="font-size: 12px; padding-top: 3px; padding-bottom: 3px;" @click="expanded = !expanded, getAdditionalFilters_data(), getNewItemTypes()"
                        :disabled="hasUsed_globalSearch">
                        {{ expanded ? 'Less filters' : $t('More filters') }} <span v-if="applied_filters_count"
                            class="badge badge-primary rounded-circle" style="padding: 4px 4px 4px 6px">{{
                                    applied_filters_count
                            }}</span>
                    </ar-button>
                    <span v-else class="d-inline-block mt-2 p-3"></span>
                </div>
                <div class="additional-filters-con" style="margin-top: 10px;" :class="{ 'expanded': expanded }">
                    <div class="additional-filters-con-con">
                        <!-- <div class="row" v-if="access_level !== 6"> -->
                        <div class="row cm-filter-listing-ids" v-if="!isFeatureRestricted('cm-filter-listing-ids')">
                            <ar-textfield a_type="chip" a_class="mt-2 mb-1 w-100"
                                :placeholder="filtered_listing_id.length > 0 ? 'Input here and Press Enter (Press Alt+X to remove all)' : 'Input Listing IDs here and Press Enter'"
                                id="input-rids" v-model="filtered_listing_id_input"
                                @keyup.enter.native="appendListingID" @keydown.backspace.native="removeLastListingID"
                                @keypress.native="isNumber" @paste="cleanInput">
                                <ar-chip close v-for="(listing_id, index) in filtered_listing_id" :key="index"
                                    @remove-chip="removeListingID(listing_id)">{{ listing_id }}</ar-chip>
                            </ar-textfield>
                            <div style="text-align:right; width: 100%; font-size:13px">Count: {{
                                    filtered_listing_id.length
                            }}</div>
                        </div>
                        <!-- <div class="row" v-if="access_level !== 6"> -->
                        <div class="row cm-filter-email" v-if="!isFeatureRestricted('cm-filter-email')">
                            <!-- Newly added filter feature EMAIL - 7/22/22 -->
                            <div class="col-md-6">
                                <ar-textfield
                                    :label="`Email (\`---\` to show ${listing_defendant_label} with no email set)`"
                                    a_type="text" v-model.trim="filter_email" placeholder="Input keywords here" />
                            </div>
                            <div class="col-md-6">
                                <div class="email_info_container">
                                    <i class="fas fa-info-circle d-block text-lg mr-2" />
                                    <p>This is a generic email search. It will search in multiple email-related data
                                        such as: <strong><u>Account Email</u></strong> and <strong><u>Seller
                                                Email</u></strong>.</p>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row" v-if="access_level !== 6"> -->
                        <div class="row cm-filter-item-location-and-date" v-if="!isFeatureRestricted('cm-filter-item-location-and-date')">
                            <div class="col-md-6">
                                <!-- <ar-textfield
                                    :label="`Item Location (\`---\` to show ${listing_defendant_label} with no item location set)`"
                                    a_type="text" v-model.trim="filter_item_location"
                                    placeholder="Input keywords here" /> -->

                                <ar-select 
                                    searchFieldId ="item_location"
                                    v-model="filters__.item_location.selected" 
                                    label="Item Location"
                                    :placeholder="getDataFor_AdditionalFilters ? 'Loading data...' : filters__.item_location.options.length == 0 ? 'No Item Locations Available' : 'Search Item Location'"
                                    type="search"
                                    :options="filters__.item_location.options"
                                    :disabled="filters__.item_location.options.length == 0 || getDataFor_AdditionalFilters"
                                >
                                    <!-- Select Item Location -->
                                </ar-select>
                            </div>
                            <div class="col-md-3">
                                <ar-textfield label="Date added (from)" a_type="date" a_class="remove-date-spinner"
                                    a_style="padding-right: 3px;" v-model="min_date_added" :max="today"
                                    @blur="validateFilterDates" />
                            </div>
                            <div class="col-md-3">
                                <ar-textfield label="Date added (to)" a_type="date" a_class="remove-date-spinner"
                                    a_style="padding-right: 3px;" :disabled="min_date_added == ''"
                                    v-model="max_date_added" :min="min_date_added" @blur="validateFilterDates" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <ar-select
                                    label="Seller Location"
                                    :options="seller_locations"
                                    :val="filter_seller_location"
                                    v-model="filter_seller_location"
                                    :disabled="seller_locations.length == 0 || getDataFor_AdditionalFilters"
                                >
                                    <!-- Select Seller Location -->
                                    {{getDataFor_AdditionalFilters ? 'Loading data...' : seller_locations.length == 0 ? 'No Seller Locations Available' : 'Select Seller Location'}}
                                </ar-select>
                            </div>
                            <!-- <div v-if="access_level == 6" class="col-md-6"> -->
                            <!-- <div class="col-md-6 cm-filter-client-access-alias" v-restrict="'cm-filter-client-access-alias'">
                                <ar-textfield label="Alias" a_type="text" v-model.trim="filter_alias"
                                    placeholder="Input Alias here" />
                            </div> -->
                            <!-- <template v-if="access_level !== 6"> -->
                                <div class="col-md-3 cm-filter-date-qualified-range" v-if="!isFeatureRestricted('cm-filter-date-qualified-range')">
                                    <ar-textfield label="Date qualified (from)" a_type="date"
                                        a_class="remove-date-spinner" a_style="padding-right: 3px;"
                                        v-model="min_date_qualified" :max="today" @blur="validateFilterDates" />
                                </div>
                                <div class="col-md-3 cm-filter-date-qualified-range" v-if="!isFeatureRestricted('cm-filter-date-qualified-range')">
                                    <ar-textfield label="Date qualified (to)" a_type="date"
                                        a_class="remove-date-spinner" a_style="padding-right: 3px;"
                                        :disabled="min_date_qualified == ''" v-model="max_date_qualified"
                                        :min="min_date_qualified" @blur="validateFilterDates" />
                                </div>
                            <!-- </template> -->
                        </div>
                        <div class="row">
                            <!-- :class="show_actions.includes('group_listings') ? 'col-md-4' : 'col-md-6'" -->
                            <!-- <div class="col-md-6" v-if="access_level !== 6"> -->
                            <div class="col-md-6 cm-filter-account-name" v-if="!isFeatureRestricted('cm-filter-account-name')">
                                <ar-textfield label="Account Name / Email" a_type="text"
                                    v-model.trim="filter_account_name_email" placeholder="Input keywords here" />
                            </div>
                            <!-- Newly added filter feature ALIAS - 7/21/22 -->
                            <!-- <div class="col-md-3" v-if="access_level !== 6"> -->
                            <div class="col-md-3 cm-filter-alias" v-if="!isFeatureRestricted('cm-filter-alias')">
                                <ar-textfield label="Alias" a_type="text" v-model.trim="filter_alias"
                                    placeholder="Input Alias here" />
                            </div>
                            <!-- <div class="col-md-3" v-if="show_actions.includes('group_listings')"> -->
                            <div class="col-md-3 cm-filter-group-name" v-if="!isFeatureRestricted('cm-filter-group-name')">
                                <ar-select
                                    label="Group Name"
                                    :options="groups"
                                    :val="filter_group"
                                    v-model="filter_group"
                                    :disabled="groups.length == 0 || getDataFor_AdditionalFilters"
                                >
                                    <!-- Select Group -->
                                    {{getDataFor_AdditionalFilters ? 'Loading data...' : groups.length == 0 ? 'No Group/s Found' : 'Select Group' }}
                                </ar-select>
                            </div>
                        </div>

                        <!-- <div class="row mb-2" v-if="access_level !== 6"> -->
                        <div class="row mb-2">
                            <div class="col-md-6 cm-filter-account-number" v-if="!isFeatureRestricted('cm-filter-account-number')">
                                <ar-textfield label="Account Number" a_type="text" v-model.trim="filter_account_number"
                                    placeholder="Input keywords here" />
                            </div>
                            <div class="col-md-6">
                                <ar-select
                                    label="Claim Type"
                                    :options="filtered_claim_types_dropdown_new"
                                    :val="filter_claim_type"
                                    v-model="filter_claim_type"
                                    :disabled="claim_types_dropdown.length == 0 || getDataFor_AdditionalFilters"
                                >
                                    <!-- Select Claim Type -->
                                    {{claim_types_dropdown.length == 0 || getDataFor_AdditionalFilters ? 'Loading data...' : 'Select Claim Type'}}
                                </ar-select>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <!-- <div v-if="access_level !== 6" :class="`col-md-${['Submitted', 'Accepted'].includes($route.params.status) ? 4 : 6}`"> -->
                            <div class="cm-filter-item-classes" v-if="!isFeatureRestricted('cm-filter-item-classes')" :class="`col-md-${['Submitted', 'Accepted'].includes($route.params.status) ? 4 : 6}`">
                                <div style="margin-bottom: 1rem">
                                    <label class="filter-label">Item Classes</label>
                                    <multiselect 
                                        v-model="filter_item_class"
                                        :options="filtered_item_class"
                                        :placeholder="getDataFor_AdditionalFilters ? 'Loading data...' : filtered_item_class.length == 0 ? 'No Item Class Available' : 'Select here' "
                                        track-by="label" label="label"
                                        :multiple="true"
                                        :close-on-select="false"
                                        :clear-on-select="false"
                                        :disabled="filtered_item_class.length == 0 || getDataFor_AdditionalFilters"
                                    >
                                    </multiselect>
                                </div>
                            </div>
                            <div :class="`col-md-${['Submitted', 'Accepted'].includes($route.params.status) ? 4 : 6}`">
                                <div style="margin-bottom: 1rem">
                                    <label class="filter-label">Item Types</label>
                                    <multiselect 
                                        label="label" track-by="label"
                                        v-model="filter_item_type"
                                        :options="item_type_options_display"
                                        :placeholder="getDataFor_AdditionalFilters ? 'Loading data...' : item_type_options_display.length == 0 ? 'No Item Types Available' : 'Select here' "
                                        :multiple="true"
                                        :close-on-select="false"
                                        :clear-on-select="false"
                                        :disabled="item_type_options_display.length == 0 || getDataFor_AdditionalFilters"
                                    >
                                    </multiselect>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="['Submitted', 'Accepted'].includes($route.params.status)">
                                <div style="margin-bottom: 1rem">
                                    <label class="filter-label">Submissions Pack Name</label>
                                    <multiselect label="label" track-by="label"
                                        v-model="filter_submissions_pack.selected"
                                        :options="filter_submissions_pack.options"
                                        :placeholder="getDataFor_AdditionalFilters ? 'Loading data...' : filter_submissions_pack.options.length == 0 ? 'No Submission Pack Available' : 'Select here' "
                                        :disabled="filter_submissions_pack.options.length == 0 || getDataFor_AdditionalFilters"
                                    >
                                    </multiselect>
                                </div>
                            </div>

                            <!-- v-if="access_level !== 6" -->
                            <ar-textfield v-if="!isFeatureRestricted('cm-filter-date-captured')"  label="Date Captured" class="col-md-6" a_type="date"
                                v-model="filterDateCaptured" :max="today" />
                            
                            <div v-if="access_level === 6" class="col-md-6">
                                <ar-select label="Claim Type"
                                    :options="filtered_claim_types_dropdown"
                                    :val="filter_claim_type"
                                    v-model="filter_claim_type"
                                    :disabled="filtered_claim_types_dropdown.length == 0 || getDataFor_AdditionalFilters"
                                >
                                    {{ filtered_claim_types_dropdown.length == 0 || getDataFor_AdditionalFilters ? 'Loading Claim Type...' : 'Select Claim Type' }}
                                </ar-select>
                            </div>
                        </div>

                        <!-- <section class="mb-4 fieldset-alt" v-if="access_level !== 6"> -->
                        <section class="mb-4 fieldset-alt cm-filter-payment-methods" v-if="!isFeatureRestricted('cm-filter-payment-methods')">
                            <label class="mt-2 legend-alt"
                                style="color: #383A3D; margin-left: 5px; font-weight: 500; font-size: 13px;">Payment
                                Methods</label>
                            <div class="row">
                                <div class="col-md-4 pl-3" v-for="(method, index) in payment_methods" :key="index">
                                    <div class="custom-control custom-checkbox mb-2">
                                        <input class="custom-control-input" type="checkbox" name="method"
                                            :id="`payment-method--${method.value}`" v-model="selected_payment_methods"
                                            :value="method.label">
                                        <label :for="`payment-method--${method.value}`"
                                            class="custom-control-label font-weight-normal">{{ method.label }}</label>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <div class="mb-4 fieldset-alt" v-if="filter_predefined_reasons.data.length">
                            <label class="mt-2 d-inline-block align-middle legend-alt"
                                style="color: #383A3D; margin-left: 5px; font-weight: 500; font-size: 13px;">Status
                                Change Reasons</label>
                            <div class="row pl-2 pb-2 pr-2">
                                <div class="col-md-6 pl-2" v-for="reason in filter_predefined_reasons.data"
                                    :key="`predefined-reason--${reason.id}`">
                                    <div class="custom-control custom-checkbox mb-2" v-if="!reason.sub_reasons.length">
                                        <input class="custom-control-input" type="checkbox" name="predefined--reasons"
                                            :id="`predefined-reason--${reason.id}`"
                                            v-model="filter_predefined_reasons.selected.main" :value="reason.id">
                                        <label :for="`predefined-reason--${reason.id}`"
                                            class="custom-control-label font-weight-normal">{{ reason.reason }} [{{
                                                    reason.count
                                            }}]</label>
                                    </div>
                                    <div class="mb-2" style="cursor: pointer" v-else>
                                        <div class="filter--sub-reasons-trigger"
                                            @click="reason.toggled = !reason.toggled">
                                            <i class="fas pt-1 pb-2 pl-1 pr-2 mr-3"
                                                :class="{ 'fa-chevron-right': !reason.toggled, 'fa-chevron-down': reason.toggled }"></i>
                                            <label class="font-weight-normal m-0" style="cursor: pointer">{{
                                                    reason.reason
                                            }} [{{ reason.count }}]</label>
                                        </div>
                                        <div class="filter--sub-reasons-con ml-4"
                                            :class="{ 'toggled': reason.toggled }">
                                            <div class="custom-control custom-checkbox mb-2"
                                                v-for="sub in reason.sub_reasons"
                                                :key="`predefined-sub-reason--${sub.id}`">
                                                <input class="custom-control-input" type="checkbox"
                                                    name="predefined--sub-reasons"
                                                    :id="`predefined-sub-reason--${sub.id}`"
                                                    v-model="filter_predefined_reasons.selected.sub" :value="sub.id">
                                                <label :for="`predefined-sub-reason--${sub.id}`"
                                                    class="custom-control-label font-weight-normal">{{ sub.reason }} [{{
                                                            sub.count
                                                    }}]</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="fieldset-alt" v-if="access_level !== 6"> -->
                        <div class="fieldset-alt cm-filter-infringement-indicators" v-if="!isFeatureRestricted('cm-filter-infringement-indicators')">
                            <div class="mb-3">
                                <label class="mt-2 d-inline-block align-middle legend-alt"
                                    style="color: #383A3D; margin-left: 5px; font-weight: 500; font-size: 13px;">Infringement
                                    Indicators</label>
                                <div class="btn-group btn-group-toggle ml-3 align-middle legend-alt"
                                    data-toggle="buttons">
                                    <label class="btn btn-outline-secondary font-weight-normal"
                                        :class="{ 'active': infringement_option == '1' }" style="font-size: 13px;"
                                        @click="updateInfringementOption('1')">
                                        <input type="radio" name="infringement-options" id="infringment-with" value="1">
                                        With
                                    </label>
                                    <label class="btn btn-outline-secondary font-weight-normal"
                                        :class="{ 'active': infringement_option == '0' }" style="font-size: 13px;"
                                        @click="updateInfringementOption('0')">
                                        <input type="radio" name="infringement-options" id="infringment-without"
                                            value="0"> Without
                                    </label>
                                </div>
                            </div>
                            <div class="row pl-2 pb-2 pr-2">
                                <div class="col-md-6 pl-2" v-for="reason in reasons" :key="`reason--${reason.value}`">
                                    <div class="custom-control custom-checkbox mb-2">
                                        <input class="custom-control-input" type="checkbox" name="reasons"
                                            :id="`reason--${reason.value}`" v-model="selected_infringement_reasons"
                                            :value="reason.value">
                                        <label :for="`reason--${reason.value}`"
                                            class="custom-control-label font-weight-normal">{{ reason.label }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="float-right mr-3 mt-3">
                        <ar-button id="reset-more-filter-btn" a_class="btn-invalid mr-2" @click="resetMoreFilters">Reset
                        </ar-button>
                        <ar-button id="apply-more-filter-btn" @click="applyMoreFilters">
                            Apply
                        </ar-button>
                    </div>
                </div>
            </template>
        </div>

        <portal>
            <ArMessageDialog>
                <div class="row justify-content-center success-confirmation text-center">
                    <img src="/static/svg/WR_Success.svg" class="animated zoomIn">
                    <h5 class="w-100 mt-3 animated fadeIn slower" style="margin-top: 146px !important;">{{ clrSuccessMessage }}</h5>
                    <p class="text-muted">Getting the next one.. Please wait..</p>
                </div>
            </ArMessageDialog>
        </portal>

        <ar-add-to-case :listings="selected_listings" :prepare-data="prepare_add_to_case" />

        <ar-submission-pack-generator :campaign-data="campaign_data" :spg="spg"
            :listings="listings.filter(listing => selected_listings.includes(listing.id))"
            @added="filter_submissions_pack.options.push({ label: $event.package_name, value: $event.id })" />

        <ar-dialog id="exports-modal" :has_footer="false" :has_button="false">
            <div class="exports-con">
                <div class="mb-2">
                    <img :src="campaign_data.img" class="d-inline-block align-middle exports-campaign-img">
                    <h5 class="d-inline-block ml-2 align-middle">
                        {{ campaign_data.name }}
                        <span class="mt-1 d-block font-weight-normal">Selected {{ selected_listings.length ?
                                selected_listings.length : 0
                        }} data </span>
                    </h5>
                </div>
                <p class="exports-sub mb-4">Select below your export options</p>
                <div class="exports-list">
                    <span
                        v-for              ="(option, index) in exportOptions"
                        :key               ="index"
                        :class             ="`cm-link-${option.label.toLowerCase().replace(/\s/g, '-')}`"
                        v-restrict ="`cm-link-${option.label.toLowerCase().replace(/\s/g, '-')}`"
                    >
                        <!-- v-show="option.condition" -->
                        <ar-list
                            type          ="small"
                            a_class       ="exports-item"
                            :campaign     ="option.label"
                            :campaign_img ="option.icon"
                            @click        ="showExportDialog(option.id)"
                        />
                    </span>
                </div>
            </div>
        </ar-dialog>

        <ar-dialog id="remove-group-name" heading="Remove Seller Group" heading_style="color: #2D599E;"
            sub_heading="Confirm if you want to remove.">
            <div class="row d-inline-block mt-4">
                <ar-button class="mr-2" data-dismiss="modal" outlined>Don't Include</ar-button>
                <ar-button data-dismiss="modal" @click="initializeExport('vendor', true)">Include</ar-button>
            </div>
        </ar-dialog>

        <ar-dialog id="confirm-rdupdate-modal" :heading="this.confirm_update.header" heading_style="color: #eb942b;"
            :sub_heading="this.confirm_update.sub">
            <template v-slot:buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" outlined @click="confirm(false)">
                    Cancel
                </ar-button>
                <ar-button @click="confirm(true)">
                    Continue
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog id="settlement-agreement-modal"
            v-if="this.$route.params.status == 'Cases' && this.$route.params.case_id != '0' && [7].includes(access_level)"
            large :has_button="false" :has_footer="false">
            <div class="row text-left justify-content-between">
                <div class="col-md-6 pr-4 border-right">
                    <div class="fadeIn animated">
                        <div class="mb-2">
                            <h5 class="d-inline-block ml-2 align-middle">Settlement Agreement Generator<span
                                    class="d-block font-weight-normal mt-1"
                                    :class="{ 'color-royal-blue font-weight-medium': templates.action == 'generate', 'text-danger': templates.generate.error }">
                                    {{ templates.action == 'generate'
                                            ? templates.generate.error
                                                ? templates.generate.error_message
                                                : templates.generate.done
                                                    ? 'Generation has been completed.'
                                                    : 'Now generating, Please wait...'
                                            : `Click 'Generate Now' button to start SA generation.`
                                    }}</span></h5>
                        </div>
                        <div class="a-modal-seller-info-item current mt-4 mb-3"
                            :class="templates.generate.error ? 'sa-error' : ''">
                            <img src="/static/svg/doc.svg" alt="icon">
                            <h5>General SA Template.docx<span>Settlement Agreement Template</span></h5>
                            <div class="status-icon">
                                <template v-if="templates.generate.error">
                                    <img src="/static/svg/Error_SA.svg" alt="icon">
                                </template>
                                <template v-else-if="templates.generate.done">
                                    <img src="/static/svg/UnselectedTemplate.svg" alt="icon">
                                </template>
                                <template v-else-if="templates.generate.done != null">
                                    <div class="lds-ring">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="a-modal-seller-info-item generate-details" v-if="templates.generate.file_name">
                            <img src="/static/svg/Download_File.svg" alt="icon">
                            <h5 class="flow">{{ templates.generate.file_name }}<span>File Name</span></h5>
                        </div>
                        <div class="a-modal-seller-info-item generate-details">
                            <img src="/static/svg/Campaign_Icon.svg" alt="icon">
                            <h5 class="flow">{{ campaign_data.name }}<span>Campaign</span></h5>
                        </div>
                        <div class="a-modal-seller-info-item generate-details">
                            <img src="/static/svg/Items_Icon.svg" alt="icon">
                            <h5 class="flow">{{ case_name }}<span>Case Number</span></h5>
                        </div>
                        <div class="button-con mt-3 float-right mb-4" v-if="templates.generate.error">
                            <ar-button class="mr-2" outlined @click="changeSettlementAction('template')">
                                Cancel
                            </ar-button>
                            <ar-button @click="generateSettlementAgreement()">
                                Retry
                            </ar-button>
                        </div>
                        <div class="button-con mt-3 float-right mb-4" v-else-if="templates.generate.done">
                            <ar-button @click="downloadSettlementAgreement()">
                                Download Now
                            </ar-button>
                        </div>
                    </div>
                </div>
                <div class="pl-4 col-md-6 w-100">
                    <h5 class="d-inline-block ml-2 text-truncate align-middle">{{ 
                            templates.generate.sa_setter.status 
                            ? 'Update Group Settlement Amount' 
                            : templates.generate.rd_setter.status 
                                ? 'Update Group Received Default Amount' 
                                : 'Selected Defendants' }}<span
                            class="d-block font-weight-normal mt-1">Defendant Information</span></h5>
                    <hr class="mt-2" />
                    <template v-if="templates.generate.sa_setter.status">
                        <label class="d-block mt-2"
                            style="font-size:12px; font-weight: 500; text-align:left; margin-bottom: 6px;">Agreed
                            Settlement Amount</label>
                        <div class="input-group mb-2">
                            <input type="text" v-model="templates.generate.sa_setter.value" @keypress="isNumber"
                                class="form-control text-right" placeholder="Input here">
                        </div>
                    </template>
                    <template v-else-if="templates.generate.rd_setter.status">
                        <label class="d-block mt-2"
                            style="font-size:12px; font-weight: 500; text-align:left; margin-bottom: 6px;">Received
                            Default Amount</label>
                        <div class="input-group mb-2">
                            <input type="text" v-model="templates.generate.rd_setter.value" @keypress="isNumber"
                                class="form-control text-right" placeholder="Input here">
                        </div>
                    </template>
                    <div class="settlement-defendants-con right-con"
                        :class="{ 'sa-setter': templates.generate.sa_setter.status || templates.generate.rd_setter.status }">
                        <template v-if="templates.generate.sa_setter.status || templates.generate.rd_setter.status">
                            <div class="selected-settlement-defendants animated fadeIn"
                                v-for="(defendant, index) in templatesGenerateData" :key="index">
                                <h6 class="ml-2">{{ defendant.name }}</h6>
                                <table class="table table-borderless">
                                    <tbody>
                                        <tr>
                                            <td class="header-label text-truncate"><img
                                                    src="/static/svg/defendants/Details_Account.svg" alt="icon"
                                                    class="mr-2">Listing ID<span>:</span></td>
                                            <td>
                                                <div class="d-flex justify-content-between">
                                                    <b class="text-truncate">
                                                        {{ defendant.listing_id }}
                                                    </b>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="header-label text-truncate"><img
                                                    src="/static/svg/defendants/Details_Account.svg" alt="icon"
                                                    class="mr-2">Account Name<span>:</span></td>
                                            <td>
                                                <div class="d-flex justify-content-between">
                                                    <b class="text-truncate">
                                                        {{ defendant.name_email }}
                                                    </b>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="header-label text-truncate"><img
                                                    src="/static/svg/defendants/Details_Account.svg" alt="icon"
                                                    class="mr-2">Account Type<span>:</span></td>
                                            <td>
                                                <div class="d-flex justify-content-between">
                                                    <b class="text-truncate">
                                                        {{ defendant.Platform }}
                                                    </b>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="header-label text-truncate"><img
                                                    src="/static/svg/defendants/Details_Account.svg" alt="icon"
                                                    class="mr-2">Account Number<span>:</span></td>
                                            <td>
                                                <div class="d-flex justify-content-between">
                                                    <b class="text-truncate">
                                                        {{ defendant.number }}
                                                    </b>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="templates.generate.sa_setter.status">
                                            <td class="header-label text-truncate"><img
                                                    src="/static/svg/Case_Settled.svg" alt="icon" class="mr-2">Agreed
                                                Settlement<span>:</span></td>
                                            <td>
                                                <div class="d-flex justify-content-between">
                                                    <b class="text-truncate">
                                                        {{ defendant.amount_settled }}
                                                    </b>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-else>
                                            <td class="header-label text-truncate"><img
                                                    src="/static/svg/Case_Settled.svg" alt="icon" class="mr-2">Received
                                                Default<span>:</span></td>
                                            <td>
                                                <div class="d-flex justify-content-between">
                                                    <b class="text-truncate">
                                                        {{ defendant.amount_received_default }}
                                                    </b>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </template>
                        <template v-else>
                            <div class="selected-settlement-defendants animated fadeIn"
                                v-for="(defendant, index) in settlement_selected_defendants" :key="index">
                                <h6 class="ml-2">{{ defendant.seller != null ? defendant.seller[0] ?
                                        defendant.seller[0].name : '' : ''
                                }}</h6>
                                <label class="ml-2">DOE NUMBER {{ defendant.case_listing != null ?
                                        defendant.case_listing[0] ? (defendant.case_listing[0].doe_number || 0).toString() :
                                            '' : ''
                                }}<span> | {{ case_name }}</span></label>
                                <table class="table table-borderless">
                                    <tbody>
                                        <tr v-for="(info, iindex) in buildPaymentAccount(defendant.seller_account_id, defendant.case_seller_accounts)"
                                            :key="iindex">
                                            <td class="header-label text-truncate" v-if="info.title"><img
                                                    v-if="info.image" :src="info.image" alt="icon" class="mr-2">{{
                                                            info.title
                                                    }}<span v-if="info.value">:</span></td>
                                            <td>
                                                <div class="d-flex justify-content-between">
                                                    <b class="text-truncate"
                                                        v-if="active_settlement_edit.index != index || info.title != 'Agreed Settlement'"
                                                        :class="{ 'd-block': info.title == 'Agreed Settlement' }">
                                                        {{ info.value }}
                                                    </b>
                                                    <input
                                                        v-else-if="active_settlement_edit.index == index && info.title == 'Agreed Settlement'"
                                                        v-model="active_settlement_edit.value" @keypress="isNumber"
                                                        class="sa-custom-input" style="width: 113px;" type="number" />
                                                    <div class="sa-action d-block"
                                                        v-if="info.title == 'Agreed Settlement'">
                                                        <i class="fas fa-edit fa-sm mt-1"
                                                            v-if="active_settlement_edit.index != index"
                                                            @click="setActiveSettlementEdit(index, info.value)"
                                                            style="color: #515365; cursor: pointer;" title="Edit"></i>
                                                        <span v-else>
                                                            <i class="fas fa-check fa-sm mt-1 mr-2"
                                                                @click="updateSelectedDefendantSettlementAgreement(index)"
                                                                style="color: #515365; cursor: pointer;"
                                                                title="Save"></i>
                                                            <i class="fas fa-times fa-sm mt-1"
                                                                @click="resetActiveSettlementEditIndex()"
                                                                style="color: #515365; cursor: pointer;"
                                                                title="Cancel"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="defendant.case_seller_accounts.filter(account => account.seller_account_id == defendant.seller_account_id).length > 0">
                                            <td class="header-label text-truncate"><img
                                                    src="/static/svg/Case_Settled.svg" alt="icon" class="mr-2">Pay
                                                Through:</td>
                                            <td>
                                                <select class="sa-custom-input"
                                                    v-model="listings.filter(listing => listing.id == defendant.id)[0].pay_through"
                                                    style="width: 141px;">
                                                    <option value="1" selected>Direct Payment</option>
                                                    <option value="2">Authorize Platform</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="defendant.pay_through == '2' && getDefendantPaymentMethods(defendant).length == 1">
                                            <td class="header-label text-truncate"><img
                                                    src="/static/svg/defendants/Details_Account.svg" alt="icon"
                                                    class="mr-2">Payment Platform:</td>
                                            <td>
                                                <b class="text-truncate d-block">
                                                    {{ getDefendantPaymentMethods(defendant)[0].label }}
                                                </b>
                                            </td>
                                        </tr>
                                        <tr
                                            v-else-if="defendant.pay_through == '2' && getDefendantPaymentMethods(defendant).length > 1">
                                            <td class="header-label text-truncate"><img
                                                    src="/static/svg/defendants/Details_Account.svg" alt="icon"
                                                    class="mr-2">Payment Platform:</td>
                                            <td>
                                                <select class="sa-custom-input" style="width: 141px;"
                                                    v-model="listings.filter(listing => listing.id == defendant.id)[0].payment_platform">
                                                    <option
                                                        v-for="(method, iindex) in getDefendantPaymentMethods(defendant)"
                                                        :value="method.value" :key="iindex">{{ method.label }}</option>
                                                </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </template>
                    </div>
                    <div class="button-con mt-3 float-right mb-4">
                        <ar-button class="mr-2" data-dismiss="modal" outlined
                            :disabled="templates.generate.done === false || templates.generate.error == true">
                            Cancel
                        </ar-button>
                        <ar-button
                            :disabled="templates.generate.sa_setter.status ? templates.generate.sa_setter.value == '0' || templates.generate.sa_setter.value == '' : templates.generate.rd_setter.status ? templates.generate.rd_setter.value == '0' || templates.generate.rd_setter.value == '' : templates.generate.done != null || templates.generate.error == true || !checkPayThrough() || !allow_settlement_generate"
                            @click="templates.generate.sa_setter.status ? updateGroupSettlement() : templates.generate.rd_setter.status ? updateGroupReceivedDefault() : generateSettlementAgreement()">
                            {{ templates.generate.sa_setter.status || templates.generate.rd_setter.status ? 'Save' :
                                    'Generate Now'
                            }}
                        </ar-button>
                    </div>
                </div>
            </div>
        </ar-dialog>

        <ar-dialog id="new-case-modal" large>
            <div class="welcome-con">
                <h5>
                    Now Setting up
                    <span>Are you sure you want to create a new case? <i>You can still cancel this action.</i></span>
                </h5>
                <ar-button class="mr-3" @click="newCase()" data-dismiss="modal">I want to create</ar-button>
                <ar-button outlined data-dismiss="modal">Cancel</ar-button>

                <img src="/static/svg/Create_Case.svg" alt="Culling Hammer">
            </div>
        </ar-dialog>

        <ar-dialog id="remove-case-modal" :heading="`Remove Case${selected_listings.length > 1 ? 's' : ''}`"
            heading_style="color: #DC3C40;"
            :sub_heading="canDelete ? `You are about to remove ${selected_listings.length} case${selected_listings.length > 1 ? 's' : ''}. Click 'Proceed' to continue deletion.` : `${selected_listings.length > 1 ? 'Some' : 'The'} selected case has defendants in it and can not be deleted. Only empty cases can be deleted.`">
            <template v-slot:buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" :outlined="canDelete">
                    Cancel
                </ar-button>
                <ar-button @click="removeCase()" v-if="canDelete">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog id="accounting-details-modal" large>
            <div class="text-left pl-4 pr-4">
                <h2>
                    {{ (case_accounting_details || {}).case_number || "Loading.." }}
                    <span v-if="case_accounting_details.case_status" class="text-sm" style="color:#53606DB3">
                        {{ case_status_options[case_accounting_details.case_status - 1].label }}
                    </span>
                </h2>
                <p>{{ (case_accounting_details || {}).details || "Loading.." }}</p>
                <hr class="header-border">
                <h4 class="mt-3">Primary Details</h4>
                <div class="row pl-3 pr-3">
                    <div class="col-md-6">
                        <h5><span>Date Filed</span> {{ ((case_accounting_details || {}).primary_details ||
                                {}).date_filed || "Loading.."
                        }}</h5>
                    </div>
                    <div class="col-md-6">
                        <h5><span>Jurisdiction</span> {{ ((case_accounting_details || {}).primary_details ||
                                {}).jurisdiction || "Loading.."
                        }}</h5>
                    </div>
                    <div class="col-md-6">
                        <h5><span>TRO Issue Date</span> {{ ((case_accounting_details || {}).primary_details ||
                                {}).tro_issue_date || "Loading.."
                        }}</h5>
                    </div>
                    <div class="col-md-6">
                        <h5><span>Judge</span> {{ ((case_accounting_details || {}).primary_details || {}).judge ||
                                "Loading.."
                        }}</h5>
                    </div>
                    <div class="col-md-6">
                        <h5><span>PIO Issue Date</span> {{ ((case_accounting_details || {}).primary_details ||
                                {}).pio_issue_date || "Loading.."
                        }}</h5>
                    </div>
                    <div class="col-md-6">
                        <h5><span>Total Defendants</span> {{ ((case_accounting_details || {}).primary_details ||
                                {}).total_defendants || "Loading.."
                        }}</h5>
                    </div>
                    <div class="col-md-6">
                        <h5><span>Lawfirm Assigned</span> {{ ((case_accounting_details || {}).primary_details ||
                                {}).lawfirm_name || "Loading.."
                        }}</h5>
                    </div>
                </div>
                <template v-if="access.amountsDefaults">
                <!-- <template v-if="showDefaultedFigures || showNonDefaultedFigures"> -->
                    <hr class="header-border">
                    <h4 class="mt-3">Accounting Reports</h4>
                    <div class="row pl-3 pr-3">
                        <template v-if="access.amounts">
                        <!-- <template v-if="showNonDefaultedFigures"> -->
                            <div class="col-md-6">
                                <h5><span>Total Frozen Amount</span> {{ ((case_accounting_details ||
                                        {}).accounting_reports || {}).total_frozen_amount || "Loading.."
                                }}</h5>
                            </div>
                            <div class="col-md-6">
                                <h5><span>Total Agreed Settlement</span> {{ ((case_accounting_details ||
                                        {}).accounting_reports || {}).total_agreed_settlement || "Loading.."
                                }}</h5>
                            </div>
                            <div class="col-md-6">
                                <h5><span>Expected Settlement Amount</span> {{ ((case_accounting_details ||
                                        {}).accounting_reports || {}).expected_settlement_amount || "Loading.."
                                }}</h5>
                            </div>
                        </template>
                        <template v-if="access.defaults">
                        <!-- <template v-if="showDefaultedFigures"> -->
                            <div class="col-md-6">
                                <h5><span>Predicted Total Default Amount</span> {{ ((case_accounting_details ||
                                        {}).accounting_reports || {}).predicted_total_default_amount || "Loading.."
                                }}</h5>
                            </div>
                            <div class="col-md-6">
                                <h5><span>Total Received Default Amount</span> {{ ((case_accounting_details ||
                                        {}).accounting_reports || {}).total_received_default_amount || "Loading.."
                                }}</h5>
                            </div>
                        </template>
                        <template v-if="access.amounts">
                        <!-- <template v-if="showNonDefaultedFigures"> -->
                            <div class="col-md-6">
                                <h5><span>Total Received Settlement Amount</span> {{ ((case_accounting_details ||
                                        {}).accounting_reports || {}).total_received_settlement_amount || "Loading.."
                                }}
                                </h5>
                            </div>
                            <div class="col-md-6">
                                <h5><span>Total Defendants Settled</span> {{ ((case_accounting_details ||
                                        {}).accounting_reports || {}).total_defendants_settled || "Loading.."
                                }}</h5>
                            </div>
                        </template>
                        <template v-if="access.defaults">
                        <!-- <template v-if="showDefaultedFigures"> -->
                            <div class="col-md-6">
                                <h5><span>Total Defendants Defaulted</span> {{ ((case_accounting_details ||
                                        {}).accounting_reports || {}).total_defendants_defaulted || "Loading.."
                                }}</h5>
                            </div>
                        </template>
                    </div>
                    <div class="row pl-3 pr-3">
                        <div class="col-md-12">
                            <h5><span>Comments</span> {{ (case_accounting_details || {}).comments || "Loading.." }}</h5>
                        </div>
                    </div>
                </template>
            </div>
        </ar-dialog>

        <ar-dialog id="remove-defendant-modal" :heading="`Remove Defendant${selected_listings.length > 1 ? 's' : ''}`"
            heading_style="color: #DC3C40;"
            :sub_heading="`You are about to remove ${selected_listings.length} defendant${selected_listings.length > 1 ? 's' : ''}. Click 'Proceed' to continue deletion.`">
            <template v-slot:buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" outlined>
                    Cancel
                </ar-button>
                <ar-button @click="removeDefendants()">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog id="uploaded-files-modal" large status_dialog :has_footer="false">
            <div class="d-flex justify-content-between">
                <h2 class="mb-3 a-dialog-header" style="color: #515365; text-align: left; margin-top: 30px;">View Uploaded files</h2>
                <!-- v-if="access_level !== 6" -->
                <ar-select v-restrict ="'cm-filter-uploaded-files-type'" label="Filter by upload type" :options="caseFileUploads"
                    v-model="filter_upload_type" :val="filter_upload_type" style="width: 180px;"
                    a_class="text-left float-right cm-filter-uploaded-files-type">
                    All
                </ar-select>
            </div>
            <div-row class="uploaded-files-con">
                <template v-if="filtered_uploaded_files.length && case_files_loaded">
                    <column md="6" v-for="(file, index) in filtered_uploaded_files" :key="index">
                        <div class="a-modal-seller-info-item case-file-item border fadeIn"
                            style="border-color: #ccc !important; margin-bottom: 10px;">
                            <img style="width:28px; top:50%; left:12px"
                                onerror="this.src='/static/svg/file_default.svg'"
                                :src="`/static/svg/${getExtensionName(file)}.svg`" alt="icon">
                            <h5 style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                                <rt style="font-style:none" :title="file.file_name">{{ file.file_name }}</rt>
                                <span style="margin-top:2px; text-transform: capitalize;" :title="file.type">Date Granted: {{
                                        ["0000-00-00", null].includes(file.date_granted) ? "Not Set" : file.date_granted
                                }}</span>
                            </h5>
                            <div class="case-item-actions animated fadeIn">
                                <!-- v-if="access_level == 6" -->
                                <a v-restrict="'cm-button-download-uploaded-files'" class="cm-button-download-uploaded-files" :href="getCaseFileLink(file.case_id, file.file_name)"
                                    title="Download file" target="_blank">
                                    <img style="width:28px; top:50%; right: 46px;" src="/static/svg/Upload_Download.svg"
                                        alt="icon">
                                </a>
                               <!--  <a v-else :href="getCaseFileLink(file.case_id, file.file_name)" title="Download file"
                                    target="_blank">
                                    <img style="width:28px; top:50%; right:46px;" src="/static/svg/Upload_Download.svg"
                                        alt="icon">
                                </a> -->
                                <!-- v-if="access_level !== 6" -->
                                <a v-restrict="`cm-button-delete-uploaded-files`" class="cm-button-delete-uploaded-files" href="javascript:void(0);" title="Delete file"
                                    @click="showPromptRemoveFile(true, file.case_id, file.file_name)">
                                    <img style="width:28px; top:50%; right:12px;" src="/static/svg/Upload_Remove.svg"
                                        alt="icon">
                                </a>
                            </div>
                        </div>
                    </column>
                </template>
                <template v-else-if="filtered_uploaded_files.length == 0 && case_files_loaded">
                    <div class="position-relative text-center w-100 pt-2">
                        <img style="width: 250px; max-width: 100%;" src="/static/svg/NoFiles_Photo.svg" alt="No file">
                    </div>
                </template>
                <template v-else>
                    <div class="text-center w-100">
                        <div v-for="(spin, index) in [1, 2, 3]" :key="index" class="spinner-grow text-dark"
                            role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span class="d-block mt-3">Getting uploaded files..</span>
                    </div>
                </template>
            </div-row>
            <template v-slot:buttons>
                <!-- <template v-if="!access.readonly || [7].includes(access_level)"> -->
                    <ar-button a_class="mr-2" data-dismiss="modal" outlined>
                        Cancel
                    </ar-button>
                    <!-- v-if="access_level !== 6" -->
                    <ar-button icon="far fa-file-alt" :outlined="true"
                        @click="showUploadCaseFileModal(true)" :disabled="!case_files_loaded" class="cm-button-upload-case-files" v-restrict="'cm-button-upload-case-files'">
                        Upload Case Files
                    </ar-button>
                </template>
                <!-- <template v-else>
                    <ar-button a_class="mr-2" data-dismiss="modal" outlined>
                        Close
                    </ar-button>
                </template> -->
            <!-- </template> -->
        </ar-dialog>
        <ar-dialog id="sa-files-modal" large status_dialog :has_footer="false">
            <div class="d-flex justify-content-between">
                <h2 class="mb-3 a-dialog-header" style="color: #515365; text-align: left; margin-top: 30px;">
                    Settlement Agreement Files
                </h2>
                <!-- <ar-select label="Filter by file type" :options="fileUploadsSA" v-model="filter_sa_type"
                    :val="filter_sa_type" style="width: 180px;" a_class="text-left float-right">
                    All
                </ar-select> -->
            </div>
            <div class="d-flex justify-content-between" style="margin-bottom: 10px; margin-top: -10px;">
                <span style="font-size: 14px; font-weight: 500">
                    <span style="color: #444444">
                        Showing {{ saUploads_computed.length }} out of {{ saUploads_computed.length }} <!-- {{ settlementPaymentCount ?? 0 }} -->
                        <span style="color:#2D599E; font-weight: bolder"> settlements </span>
                    </span>
                </span>
            </div>
            <div-row class="uploaded-files-con">
                <template v-if="saUploads.length !== 0 && loadingSAUploads == false">
                    <column md="6" v-for="(file, index) in saUploads_computed" :key="index">
                        <div class="a-modal-seller-info-item case-file-item border fadeIn"
                            style="border-color: #ccc !important; margin-bottom: 10px;">
                            <img style="width:28px; top:50%; left:12px"
                                onerror="this.src='/static/svg/file_default.svg'"
                                :src="`/static/svg/${getExtensionName(file)}.svg`" alt="icon">
                            <h5 style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                                <rt style="font-style:none" :title="file.file_name">{{ file.file_name }}</rt>
                                <span style="margin-top:2px; text-transform: capitalize;" :title="file.type">{{
                                        file.type
                                }}</span>
                            </h5>
                            <div class="case-item-actions animated fadeIn">
                                <!-- v-if="access_level !== 6" -->
                                <a v-if="!isFeatureRestricted('cm-button-download-sa-file')"  class="mr-2 cm-button-download-sa-file" href="javascript:void(0);" title="Download file" 
                                    @click="selectedAgreementFile = file.id; downloadSAFile()">
                                    <!-- <img style="width:28px; top:50%; right:46px;" src="/static/svg/Upload_Download.svg"
                                        alt="icon"> -->
                                    <img :class="{'r-12': isFeatureRestricted('cm-button-delete-sa-file')}" style="width:28px; right: 45px; top:50%;"  src="/static/svg/Upload_Download.svg" alt="icon">
                                </a>
                                <!-- <a href="javascript:void(0);" title="Download file" v-else
                                    @click="selectedAgreementFile = file.id; downloadSAFile()">
                                    <img style="width:28px; top:50%; right:16px;" src="/static/svg/Upload_Download.svg"
                                        alt="icon">
                                </a> -->
                                <!-- v-if="access_level !== 6" -->
                                <a v-if="!isFeatureRestricted('cm-button-delete-sa-file')" class="cm-button-delete-sa-file" href="javascript:void(0);" title="Delete file" 
                                    @click="selectedAgreementFile = file.id; removeSAConfirmation()">
                                    <img style="width:28px; top:50%; right:12px;" src="/static/svg/Upload_Remove.svg"
                                        alt="icon">
                                </a>
                            </div>
                        </div>
                    </column>
                </template>
                <template v-else-if="saUploads.length == 0 && loadingSAUploads == false">
                    <div class="position-relative text-center w-100 pt-2">
                        <img style="width: 250px; max-width: 100%;" src="/static/svg/NoFiles_Photo.svg" alt="No file">
                    </div>
                </template>
                <template v-else-if="loadingSAUploads == true">
                    <div class="text-center w-100">
                        <div v-for="(spin, index) in [1, 2, 3]" :key="index" class="spinner-grow text-dark"
                            role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span class="d-block mt-3">Getting settlement agreement files..</span>
                    </div>
                </template>
            </div-row>
            <template v-slot:buttons>
                <template>
                    <ar-button :disabled="isDisabled()" a_class="mr-2" @click="downloadArchivedSAFiles()">
                        <span v-if="saUploads_computed.length && isDownloading == false"> Download All Files </span>
                        <span v-else-if="saUploads_computed.length && isDownloading == true"> Generating Zip File </span>
                        <span v-else> Download All Files </span>
                    </ar-button>

                    <ar-button a_class="mr-2" data-dismiss="modal" outlined>
                        Close
                    </ar-button>
                </template>
            </template>
        </ar-dialog>

        <ar-dialog id="upload-case-file-modal" heading="Upload Case Files"
            heading_style="color: #2D599E; margin-bottom: 5px !important;"
            sub_heading="Click on the droppable area to browse for a file to be uploaded."
            sub_heading_style="font-size: 12px;" custom_width="620px" custom_maxwidth="100%">
            <div class="upload-sections-con mt-4">
                <div class="position-relative d-inline-flex flex-wrap mb-2 ml-1 mr-1" style="width: 48%" v-for="(section, index) in caseFileUploads" :key="index">
                    <div class="upload-case--box w-100 mb-2"
                        :class="{ 'cantUpload': (typeof caseFileUploads[index].file.name != 'undefined' || caseFileUploads[index].link), 'toBeUploaded': typeof caseFileUploads[index].file.name != 'undefined' }">
                        <i v-if="(typeof caseFileUploads[index].file.name != 'undefined')"
                            class="fas fa-times-circle remove-upload--file-button text-muted"
                            @click="removeUploadCaseFile(index, section.label)"
                            v-tooltip="'Click to remove this file and upload another'"></i>
                        <template v-else-if="caseFileUploads[index].link">
                            <a href="javascript:void(0);" :id="`caseFileDeleteConfirmationDropdown${index}`"
                                @click="initializeRemoveCaseFileButton" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <i class="fas fa-times-circle remove-upload--file-button text-muted"
                                    v-tooltip="'Click to remove this file and upload another'"></i>
                            </a>
                            <div class="dropdown-menu caseFileDeleteConfirmationDropdown p-3"
                                :aria-labelledby="`caseFileDeleteConfirmationDropdown${index}`" style="width: 270px;">
                                <p class="text-sm">You are about to delete a file of this case. Click 'Continue' to proceed
                                    with the action.</p>
                                <div class="float-right">
                                    <ar-button a_class="mr-2" :class="`cancelCaseFileDelete delete-file-btn--${index}`"
                                        :outlined="true">
                                        Cancel
                                    </ar-button>
                                    <ar-button
                                        @click="deleteCaseFile(current_show_file_case_id, section.label, index, false)">
                                        Continue
                                    </ar-button>
                                </div>
                            </div>
                        </template>
                        <input v-if="!caseFileUploads[index].file && !caseFileUploads[index].link" type="file"
                            class="upload--section" :name="`case--upload-section-${section.label}`"
                            :id="`case--upload-section-${section.label.replace(/\s/g, '')}`" accept="application/pdf"
                            @dragenter="caseUploadDragEnter(index)" @dragleave="caseUploadDragLeave(index, section.label)"
                            @drop="caseUploadDragLeave(index, section.label)"
                            @change="addCaseFileToBeUploaded($event, index, section.label)">
                        <div class="icon" v-tooltip="viewToBeUploadedTooltip(index)"
                            @click="openToBeUploadedCaseFile(index)">
                            <img :src="`/static/svg/case-upload/${!caseFileUploads[index].file && !caseFileUploads[index].link ? 'UploadFile' : 'PDF'}.svg`"
                                class="mb-2" alt="upload-icon">
                            <label class="d-block">{{ section.label }}</label>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center w-100" v-if="!isDateGrantedRestricted">
                        <ar-textfield
                            :disabled="(!caseFileUploads[index].file && !caseFileUploads[index].link)"
                            :state="((caseFileUploads[index].file || caseFileUploads[index].link) && !caseFileUploads[index].date_granted) || caseFileUploads[index].error ? 'invalid' : ''"
                            :label="`${section.label} - Date Granted`" class="ml-1 mr-1 w-100 text-left" a_type="date" a_style="padding-right: 3px;"
                            v-model="caseFileUploads[index].date_granted" :max="today" />

                        <a v-if="caseFileUploads.every(c => typeof c.file.name == 'undefined') 
                            && caseFileUploads[index].link && caseFileUploads[index].date_granted != caseFileUploads_copy[index].date_granted" 
                            href="javascript:void(0)" class="d-inline-block ml-1 mt-3" title="Update"
                            @click="updateCurrentFileDateGranted(caseFileUploads[index].file_uploads_id, caseFileUploads[index].date_granted, index)">
                            <img src="/static/svg/Active.svg" />
                        </a>
                    </div>
                </div>
            </div>
            <template v-slot:buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" :outlined="true" @click="showUploadCaseFileModal(false)">
                    Back
                </ar-button>
                <ar-button @click="uploadCaseFile" :disabled="!canUploadCaseFile || !file_case_uploaded || hasFileButNoDateGranted">
                    Upload{{ caseFileUploads.filter(c => typeof c.file.name != "undefined").length ? " " +
                            caseFileUploads.filter(c => typeof c.file.name != "undefined").length + " new file(s)" : ''
                    }}
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog id="remove-file-modal" :heading="`Remove File`" heading_style="color: #DC3C40;"
            :sub_heading="`You are about to remove a file for this case. Click 'Proceed' to continue deletion.`">
            <template v-slot:buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" @click="showPromptRemoveFile(false)" outlined>
                    Cancel
                </ar-button>
                <ar-button @click="deleteCaseFile(delete_file_data.id, delete_file_data.name)">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>

        <!-- :custom_width ="!frozen_accounts.filter(account=> account['error'] != '' && account['error'] != null).length || frozen_success_update ? '800px' : '100%'"
        :large        ="!frozen_accounts.filter(account=> account['error'] != '' && account['error'] != null).length || frozen_success_update"
        :xx-large     ="!!frozen_accounts.filter(account=> account['error'] != '' && account['error'] != null).length && !frozen_success_update" -->

        <!-- ar-dialog
            id            ="import-frozen-modal"
            @close        ="downloadFrozenLog()"
            :has_button   ="false"
            :has_footer   ="false"
            :custom_width ="!frozen_accounts_with_errors.length || frozen_success_update ? '800px' : '100%'"
            :large        ="!frozen_accounts_with_errors.length || frozen_success_update"
            :xx-large     ="!!frozen_accounts_with_errors.length && !frozen_success_update"
            status_dialog
        >
            <div :class="!frozen_accounts.length || frozen_success_update ? 'add-new-con' : 'add-new-con-without-p'">
                <div class="mb-3" :class="{'d-flex justify-content-between': isColumnSelection}">
                    <h5>Import Frozen Accounts</h5>
                    <div v-if="isColumnSelection && frozen_payment_type === 'Ebay'" class="custom-control custom-checkbox mb-2">
                        <input v-model="includeEbayAccountName" type="checkbox" class="custom-control-input" name="include-ebay-sellername" id="include-ebay-sellername">
                        <label for="include-ebay-sellername" class="custom-control-label font-weight-normal">Include Account Name</label>
                    </div>
                </div>
                <template v-if="!frozen_accounts.length">
                    <ar-select
                        id       ="frozen-platform"
                        class    ="mb-3"
                        :options ="frozen_payment_types"
                        label    ="Platform"
                        v-model  ="frozen_payment_type"
                    >
                        Select Platform
                    </ar-select>
                    <label
                        class="d-block mt-2"
                        style="font-size:12px; font-weight: 500; text-align:left; margin-bottom: 6px;"
                    >
                        Upload Source File
                    </label>
                    <ar-textfield
                        id      ="frozen-upload-field"
                        a_type  ="file"
                        a_class ="text-left d-inline-block text-truncate"
                        :label  ="file_name || 'Choose File'"
                        @change ="viewSourceFile"
                        root-style ="width:305px;"
                    />
                    <ar-button
                        class="animated fadeIn mr-2"
                        @click="importFrozenAccount()"
                        :disabled="is_frozen_loading"
                    >
                        {{is_frozen_loading ? "Uploading..." : "Upload"}}
                    </ar-button>
                    <ar-button
                        class="animated fadeIn" data-dismiss="modal" :outlined="true" :disabled="is_frozen_loading">Cancel</ar-button>
                    <img class="photo-modal" src="/static/svg/defendants/Modal_Photo.svg" alt="Woman carrying folder">
                </template>
                !-- <template v-else-if="frozen_accounts.filter(account=> account['error'] != '' && account['error'] != null).length && !frozen_success_update"> --
                <template v-else-if="frozen_accounts.filter(({error}) => !isEmpty(error)).length && !frozen_success_update">
                    <div class="animated fadeIn">
                        <div class="scrollable-table mb-2">
                            <table class="table header-fixed animated fadeIn" role="table">
                                <thead>
                                    <tr>
                                        <th title="Doe" style="width:3%">
                                            Doe
                                        </th>
                                        <template v-for="(head, index) in Object.keys(frozen_accounts[0])">
                                            <th
                                                v-if   ="!non_displayable_keys.includes(head)"
                                                class  ='ellipsify text-capitalize'
                                                :title ="head === 'platform_account_id' ? 'Ebay ID' : setImportFrozenColumnName(head)"
                                                :style ="{ width : table_th_tr_width}"
                                                :key   ="index"
                                            >
                                                !-- {{ head === 'platform_account_id' ? 'Ebay ID' : setImportFrozenColumnName(ucFirst(head).replaceAll('_', ' ')) }} --
                                                {{ head !== 'platform_account_id' ? setImportFrozenColumnName(head) : 'Ebay ID' }}
                                            </th>
                                        </template>
                                        !-- v-if   ="Object.keys(frozen_accounts[0]).includes('amount_frozen')" --
                                        <th
                                            v-if   ="isKeyInObject(frozen_accounts[0], 'amount_frozen')"
                                            title  ="Frozen USD"
                                            :style ="{ width : table_th_tr_width}"
                                            class  ="ellipsify"
                                        >
                                            Frozen USD
                                        </th>
                                        !-- v-if   ="Object.keys(frozen_accounts[0]).includes('amount_frozen')"  --
                                        !-- <th
                                            v-if   ="isKeyInObject(frozen_accounts[0], 'amount_frozen')"
                                            title  ="Current Frozen USD"
                                            :style ="{ width : table_th_tr_width}"
                                            class  ="ellipsify"
                                        >
                                            Current Frozen USD
                                        </th> --
                                        <th
                                            title="Error"
                                            :style="{ width: table_th_tr_width }"
                                        >
                                            Error
                                        </th>
                                        <th
                                            title   ="Action"
                                            rowspan ="2"
                                            class   ="ellipsify"
                                            :style  ="{ width : table_th_tr_width}"
                                        >
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(account, index) in frozen_accounts" :key="index"> 
                                        <td style="width: 3%">
                                            {{account.doe_number || 0}}
                                        </td>
                                        <td v-for="(acc,key) in account" :key   ="key"
                                            class  ="text-break"
                                            :style ="{ width : table_th_tr_width }"
                                            v-show ="!non_displayable_keys.includes(key) && !isEmpty(account.error)"
                                        >
                                            {{key === 'number' ? asterisk(acc) : acc}}
                                        </td>
                                        <td
                                            v-show="!isEmpty(account.error)"
                                            :style="{ width : table_th_tr_width }"
                                            class="text-break"
                                        >
                                            {{ account.amount_frozen }}
                                        </td>
                                        !-- <td
                                            v-show="!isEmpty(account.error)"
                                            :style="{ width : table_th_tr_width }"
                                            class="text-center text-break"
                                        >
                                            {{ account.prev_value }}
                                        </td> --
                                        <td
                                            class="text-break"
                                            :style="{ width : table_th_tr_width }"
                                        >
                                            {{account.error}}
                                        </td>
                                        <td :style="{ width : table_th_tr_width }"  v-show="!isEmpty(account.error)">
                                            <ar-select
                                                select-class ="frozen-issues"
                                                :id          ="'frozen-issues-'+index"
                                                v-model      ="frozen_accounts[index].action"
                                                :disabled    ="non_updatable_errors.includes(account.error) || update_all_frozen_accounts"
                                                :options     ="frozen_update_actions"
                                                first_element_as_default
                                            >
                                                Not Set
                                            </ar-select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <template v-if="has_updatable_frozen_account">
                            <p class="note-import-text">Update all account(s) with issues</p>
                            <div  class="mt-2 mb-4 custom-control custom-checkbox a-checkbox">
                                <input
                                    class     ="custom-control-input"
                                    type      ="checkbox"
                                    id        ="new-entry"
                                    v-model   ="update_all_frozen_accounts"
                                    name      ="name"
                                    :disabled ="is_frozen_loading"
                                >
                                <label for="new-entry" class="custom-control-label">Check if you want to update all accounts.</label>
                            </div>
                        </template>
                        <ar-button class="mr-2" @click="updateImportedAccount(frozen_accounts)" :disabled="is_frozen_loading">{{is_frozen_loading ? 'Processing...' : 'Continue'}}</ar-button>
                        <ar-button :outlined="true" data-dismiss="modal" :disabled="is_frozen_loading">Cancel</ar-button>
                        <a href="javascript:;" @click.prevent="downloadFrozenLog()" target="_blank" class="float-right animated fadeIn">Download Log File</a>
                    </div>
                </template>
                <template v-else-if="frozen_success_update">
                    <p><span class="fas fa-check-circle text-success"></span> Successfully updated frozen accounts. All actions are logged in a file. You may click button below to download.</p>
                    <ar-button class="animated fadeIn mr-2" @click="downloadFrozenLog()">Download</ar-button>
                    <img class="photo-modal" src="/static/svg/defendants/Modal_Photo.svg" alt="Woman carrying folder">
                </template>
                <template v-else>
                    <template v-if="!is_proceed_to_related_accounts">
                        <div class="animated fadeIn">
                           
                            <div  v-for="(column, index) in frozen_columns" :key="index" class="form-group row">
                                <div class="col-md-6">
                                    <h5 v-if="index == 0">Source Columns</h5>
                                    <label class="col-form-label">{{column}}</label>
                                </div>
                                <div class="col-md-6">
                                    <h5 v-if="index == 0">Associate Columns</h5>
                                    <ar-select
                                        :key       ="index"
                                        :id        ="`frozen-column-${index}`"
                                        v-model    ="frozen_account_columns[column]"
                                        :options   ="frozen_db_columns"
                                        :selecteds ="Object.values(frozen_account_columns)"
                                    >
                                        Select Column
                                    </ar-select>
                                </div>
                            </div>
                        </div>
                        <p v-if="frozen_required_message != ''" class="text-center text-red text-bold animated fadeIn">
                            <i class="fas fa-exclamation-triangle"></i> {{frozen_required_message}}
                        </p>
                        <ar-button class="mr-2" @click="validateImportedAccount()" :disabled="is_frozen_loading">{{is_frozen_loading ? "Processing..." : "Continue"}}</ar-button>
                        <ar-button :outlined="true" data-dismiss="modal" :disabled="is_frozen_loading">Cancel</ar-button>
                    </template>
                    <template v-else>
                        <div class="animated fadeIn">
                            <div v-for="(column, index) in related_frozen_columns" :key="index" class="form-group row">
                                <div class="col-md-6">
                                    <h5 v-if="index == 0">Source Columns</h5>
                                    <label class="col-form-label">{{column}}</label>
                                </div>
                                <div class="col-md-6">
                                    <h5 v-if="index == 0">Associate Columns</h5>
                                    <ar-select
                                        :key     ="index"
                                        :id      ="`related-frozen-column-${index}`"
                                        v-model  ="related_frozen_account_columns[column]"
                                        :options ="frozen_db_columns"
                                        :selecteds ="Object.values(related_frozen_account_columns)"
                                    >
                                        Select Column
                                    </ar-select>
                                </div>
                            </div>
                        </div>
                        <p v-if="frozen_required_message != ''" class="text-center text-red text-bold animated fadeIn">
                            <i class="fas fa-exclamation-triangle"></i> {{frozen_required_message}}
                        </p>
                        <ar-button  class="mr-2" @click="checkRelatedFrozenAccounts()" :disabled="is_frozen_loading">{{is_frozen_loading ? "Processing..." : "Continue"}}</ar-button>
                        <ar-button :outlined="true" data-dismiss="modal" :disabled="is_frozen_loading">Cancel</ar-button>
                        <a href="javascript:;" class="float-right animated fadeIn">Note: This selection is for Related Accounts</a>
                    </template>
                </template>
            </div>
        </ar-dialog -->

        <ar-import-wizard />

        <ar-frozen-account-compiler :case_id="$route.params.case_id" :modal="true" />

        <ar-release-amount-import :reset-data="reset_release" :importPlatforms="import_platforms" />

        <ar-dialog id="update-doe-modal" heading="Update Doe" heading_style="color: #2D599E;"
            sub_heading="You can generate a source file if you don't have any." data-backdrop="static">
            <label class="d-block mt-5"
                style="font-size:12px; font-weight: 500; text-align:left; margin-bottom: 6px;">Upload Source
                File</label>
            <ar-textfield id="doe-upload-field" a_type="file" a_class="text-left" :label="file_name || 'Choose File'"
                @change="viewSourceFile"></ar-textfield>
            <ar-button a_class="mt-5 float-left" :outlined="true" icon="far fa-file-alt" @click="generateDoeExcel"
                :disabled="!doe_generated">
                {{ doe_generated ? "Generate File" : "Generating.." }}
            </ar-button>
            <template v-slot:buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" :outlined="true" @click="removeSetUploadFile">
                    Cancel
                </ar-button>
                <ar-button :disabled="file_name == '' || is_uploading_doe" @click="uploadDoeExcel">
                    Upload
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog id="review-doe-changes-modal" heading="Review your changes" heading_style="color: #2D599E"
            :sub_heading="((doe_updates_meta || {}).error || false) ? '' : `Please review the changes you have made. Click 'Proceed' to apply this changes.`"
            large>
            <div-row>
                <template v-if="((doe_updates_meta || {}).error || false)">
                    <label class="text-danger" style="font-weight:400">Errors were found, you cannot proceed until you
                        resolved the errors. Please review the changes you have made.</label>
                    <div class="text-left border-left border-danger mb-4 pl-4" style="border-width: 5px !important;">
                        <div-row>
                            <label class="text-danger">The errors found can be the following.</label>
                            <column md="12" class="mb-1 text-danger" v-for="(perror, key, index) in possible_errors"
                                :key="index">
                                {{ perror }}
                            </column>
                        </div-row>
                    </div>
                </template>
                <div class="error-doe-con">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Doe Number</th>
                                <th scope="col">Listing ID</th>
                                <th scope="col">Seller Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, key, index) in doe_updates" :key="index">
                                <td>{{ item.doe_number }}</td>
                                <td>{{ item.listing_id }}</td>
                                <td>{{ item.seller_name }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div-row>
            <template v-slot:buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" :outlined="true" @click="removeSetUploadFile">
                    Cancel
                </ar-button>
                <ar-button v-if="!((doe_updates_meta || {}).error || false)" :disabled="is_uploading_doe"
                    @click="applyDoeChanges">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog 
            id="transfer-to-another-substatus-modal" 
            heading="Transfer to Another Sub Status"
            :sub_heading="`You are about to tag ${selected_listings.length} data to substatus: <b>${subStatusesStore.currentSelectedSubStatusLabel}</b>. Confirm by clicking on the 'Proceed' button.`"
            custom_minwidth="1100px">
            <ArLoaderinator :loading="is_loading" style="min-height: 100px;">
                <div 
                    class="float-left text-left w-100 overflow-auto" 
                    style="min-height: 100px; max-height: 500px"
                >
                    <ar-reason-selection
                        key                ="batch-transfer-to-another-sub-status-reason"
                        v-bind             ="{ reasons: predefinedReasons }"
                        is-batch-modal
                    />
                </div>
            </ArLoaderinator>
            <template v-slot:buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" :outlined="true">
                    Cancel
                </ar-button>
                <ar-button @click="updateListingSubStatus" :disabled="is_loading || status_changing">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog id="vendor-extract-modal" heading="Extracting Vendor Data" heading_style="color: #2D599E;"
            sub_heading="Select your preference and then click 'Continue'.">
            <div v-show="selected_listings.length <= 150" class="custom-control custom-switch text-left ml-3 mt-1 mb-1">
                <input type="checkbox" class="custom-control-input" id="vendorDataEvidenceSwitch"
                    v-model="vendor_data.evidences">
                <label class="custom-control-label font-weight-normal" for="vendorDataEvidenceSwitch">Include evidences
                    in the vendor data export</label>
            </div>
            <div class="custom-control custom-switch text-left ml-3">
                <input type="checkbox" class="custom-control-input" id="vendorDataReasonSwitch"
                    v-model="vendor_data.reasons">
                <label class="custom-control-label font-weight-normal" for="vendorDataReasonSwitch">Include reasons in
                    the vendor data sheet</label>
            </div>
            <template #buttons>
                <!-- <ar-button class="mr-2" data-dismiss="modal" @click="initializeExport('vendor', false)" outlined>Don't Include</ar-button>
                <ar-button data-dismiss="modal" @click="initializeExport('vendor', true)" >Include</ar-button> -->
                <ar-button a_class="mr-2" data-dismiss="modal" outlined>
                    Cancel
                </ar-button>
                <ar-button @click="initializeExport('vendor', vendor_data.evidences, vendor_data.reasons)">
                    Continue
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog id="word-export-modal" heading="Export data to Word" heading_style="color: #2D599E;"
            sub_heading="Select your preference and then click 'Continue'.">
            <div class="custom-control custom-switch text-left ml-3 mt-1 mb-1">
                <input type="checkbox" class="custom-control-input" id="exportToWordSwitch"
                    v-model="word_export.exclude">
                <label class="custom-control-label font-weight-normal" for="exportToWordSwitch"><b>Show Seller Name & Product Image only</b></label>
            </div>
            <template #buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" outlined>
                    Cancel
                </ar-button>
                <ar-button @click="initializeExport('word')">
                    Continue
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog id="excel-export-modal" heading="Export data to Excel" heading_style="color: #2D599E;"
            sub_heading="Select your preference and then click 'Continue'.">
            <div class="custom-control custom-switch text-left ml-3 mt-1 mb-1">
                <input type="checkbox" class="custom-control-input" id="exportToExcelSwitch"
                    v-model="areMainAccountsOnly">
                <label class="custom-control-label font-weight-normal" for="exportToExcelSwitch"><b>Show Main Accounts only</b></label>
            </div>
            <template #buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" outlined>
                    Cancel
                </ar-button>
                <ar-button @click="initializeExport('excel')">
                    Continue
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog id="export-frozen-modal" heading="Frozen amount download option" heading_style="color: #2D599E;"
            sub_heading="Set option for the said download task.">
            <ar-select label="Select payment type" :options="frozen_options" a_class="text-left" v-model="frozen_type">
                {{ importExportLoading.caseExportFrozen ? 'Getting platforms.. please wait..' : 'All' }}
            </ar-select>
            <ar-select :options="order_by_option_frozen" label="Select order" a_class="text-left"
                v-model="frozen_order">
                Ascending
            </ar-select>
            <!-- v-if="access_level == 7" -->
            <ar-select v-restrict ="'cm-dropdown-export-frozen-accounts-generate-with-default-amounts'" :options="has_default_option_frozen"
                label="Generate with default amounts?" a_class="text-left cm-dropdown-export-frozen-accounts-generate-with-default-amounts" v-model="has_default_frozen">
                Yes
            </ar-select>
            <template v-slot:buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" :outlined="true">
                    Cancel
                </ar-button>
                <ar-button @click="initializeExport('frozen')" :disabled="importExportLoading.caseExportFrozen">
                    Download
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog id="whitelist-info-modal" heading="Whitelist Seller" heading_style="color: #DC3C40"
            sub_heading="You are trying to change status a whitelisted seller." :has_footer="false" :has_button="false">
            <div class="d-flex flex-column align-items-start text-left">
                <p class="text-muted text-bold">Name: <span>{{ detected_whitelist.name }}</span></p>
                <p class="text-muted text-bold d-inline-block text-truncate" style="max-width: 100%"
                    :title="detected_whitelist.url">URL: <span>{{ detected_whitelist.url }}</span></p>
                <p class="text-muted text-bold">Site ID: <span>{{ detected_whitelist.site_id }}</span></p>
                <p class="text-muted text-bold">Platform: <span>{{ detected_whitelist.platforms }}</span></p>
                <p class="text-muted text-bold">Campaign: <span>{{ detected_whitelist.campaigns }}</span></p>
                <ar-button class="mt-2 align-self-end" data-dismiss="modal" outlined>
                    Close
                </ar-button>
            </div>
        </ar-dialog>

        <ar-dialog id="duplicate-seller-modal" heading="Failed to Change Status" sub_heading="Duplicate Seller Found"
            heading_style="color: #DC3C40" :has_footer="false" :has_button="false" large>
            <div class="d-flex text-muted">
                <p class="col">Your Data</p>
                <p class="col">Duplicate ID</p>
                <p class="col">Seller</p>
                <p class="col">Status</p>
            </div>
            <div v-for="duplicate in duplicate_seller" :key="duplicate.id" class="d-flex">
                <p class="col">{{ duplicate.ref }}</p>
                <p class="col">
                    <a :href="`/lawfirm/listings/campaign/${campaign_id}/${$parent.getQFlag(duplicate.qflag_id)}/0?lid=${duplicate.id}`"
                        target="_blank" rel="noopener">
                        {{ duplicate.id }}
                    </a>
                </p>
                <p class="d-inline-block text-truncate col" style="width: 120px" :title="duplicate.name">{{
                        duplicate.name
                }}</p>
                <p class="col">{{ $parent.getQFlag(duplicate.qflag_id) }}</p>
            </div>

            <ar-button data-dismiss="modal" class="mt-3 float-right" outlined>
                Close
            </ar-button>
        </ar-dialog>

        <ar-dialog id="duplicate-listing-modal" heading="Duplicate Data found"
            sub_heading="Data below will not be included" heading_style="color: #DC3C40" :has_footer="false"
            :has_button="false" large>
            <div class="overflow-auto" style="max-height: 400px;">
                <div id="duplicate-listing-accordion" class="accordion">
                    <div v-for="(listing, index) in duplicate_listings" class="card mb-0" :key="listing.id">
                        <div :id="`heading-${listing.id}`" class="card-header collapsed" data-toggle="collapse"
                            :data-target="`#collapse-${listing.id}`" :aria-expanded="!index"
                            :aria-controls="`#collapse-${listing.id}`">
                            <h6 class="text-left mb-0">
                                {{ listing.id }}
                                <span class="ml-1 text-primary">( {{ listing.duplicates.length }} )</span>
                            </h6>
                        </div>
                        <div :id="`collapse-${listing.id}`" class="slow p-0 collapse" :class="{ 'show': !index }"
                            :aria-labelledby="`heading-${listing.id}`" data-parent="#duplicate-listing-accordion">
                            <div class="card-body p-0">
                                <div class="row">
                                    <div v-for="duplicate in listing.duplicates" class="col-md-4" :key="duplicate.id">
                                        <div class="a-modal-seller-info-item p-0 mt-2 ml-2">
                                            <h5 class="text-left">
                                                <span v-if="duplicate.case_number !== null" class="text-truncate"
                                                    :title="duplicate.case_number">Case No. {{ duplicate.case_number
                                                    }}</span>
                                                <span class="text-truncate" :title="duplicate.name">{{ duplicate.name
                                                }}</span>
                                                <span class="text-primary cursor-pointer" :title="duplicate.id">{{
                                                        duplicate.id
                                                }}</span>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="d-flex justify-content-between text-bold">
                <p class="w-25 text-left">Your Listing</p>
                <p class="w-25 text-left">Duplicate Listing</p>
                <p class="w-25 text-left">Seller Name</p>
                <p class="w-25 text-left">Duplicate Status</p>
            </div>
            <div v-for="duplicate in duplicate_seller" :key="duplicate.id" class="d-flex justify-content-between text-left">
                <p class="w-25">{{ duplicate.ref }}</p>
                <p class="w-25">{{ duplicate.id }}</p>
                <p class="w-25">{{ duplicate.name }}</p>
                <p class="w-25">{{ $parent.getQFlag(duplicate.qflag_id) }}</p>
            </div>-->
            <ar-button ref="proceedAnyway" data-dismiss="modal" class="mt-3 float-right"
                @click="duplicateListingModalProceedAnyway" outlined>
                Continue
            </ar-button>
            <ar-button ref="onContinue" data-dismiss="modal" class="mt-3 mr-3 float-right"
                @click="duplicateListingModalOnContinue" outlined>
                Remove Duplicates & Continue
            </ar-button>
        </ar-dialog>

        <ar-move-listing
            v-if="this.$route.params.status == 'Cases' && this.$route.params.case_id != '0' && [7].includes(access_level)"
            :case_id="$route.params.case_id" :campaign_id="campaign_id" :listings="settlement_selected_defendants"
            :multipleStatusChange="multipleStatusChange" :checkPotentialWhitelists="checkPotentialWhitelists"
            :checkListings="checkListings" :setPredefinedReasons="setPredefinedReasons" @alter="removeSelectedListing"
            @acknowledged="updateAcknowledged" @already-worked-lids="updateWorkedLids"
            @status-changing="updateStatusChanging" />

        <ar-group-listing v-if="show_actions.includes('group_listings')" :campaign_id="campaign_id"
            :campaign_logo="campaign_data.img" :listings="settlement_selected_defendants"
            :qflag_id="$parent.getQFlagId(this.$route.params.status)"
            @assign="is_select_all = false; selectAll()" @remove="selected_function" @change="mapGroup" />

        <ar-dialog id="case-export-frozen-modal" :has_button="false" :has_footer="false" custom_width="100%" large
            status_dialog>
            <div class="add-new-con text-left">
                <h5>Frozen Amount Export</h5>
                <template>
                    <ar-select label="Account Type" :options="frozen_options" v-model="frozen_type">
                        All
                    </ar-select>
                    <ar-select :options="order_by_option_frozen" label="Select order" a_class="text-left"
                        v-model="frozen_order">
                        Ascending
                    </ar-select>
                    <!-- v-if="access_level == 7" -->
                    <ar-select v-restrict ="'cm-dropdown-frozen-amount-export-modal-generate-with-default-amounts'" :options="has_default_option_frozen"
                        label="Generate with default amounts?" a_class="text-left cm-dropdown-frozen-amount-export-modal-generate-with-default-amounts" v-model="has_default_frozen">
                        Yes
                    </ar-select>
                    <ar-select :options="frozenAmountExportFormats" v-model="frozenAmountExportFormat" label="Format">
                        Default Format
                    </ar-select>
                    <div class="animated fadeIn">
                        <label class="mt-5 mb-0 d-block" style="color: #2D599E; font-size: 16px;">Generate Spreadsheet
                            form</label>
                        <p style="font-size: 16px;">Choose options for the export you want to download. If you're done
                            with this process, please proceed with clicking the generate file button.</p>
                        <ar-button class="mr-2 btn-primary" outlined :disabled="is_frozen_export_generating"
                            @click="exportFrozenAccounts()">
                            <img src="/static/svg/defendants/Button_GenerateFile.svg" class="position-relative mr-1"
                                style="top: -2px;" alt="generate"> {{ is_frozen_export_generating ? 'Generating...' :
                                        'Generate File'
                                }}
                        </ar-button>
                        <ar-button outlined data-dismiss="modal" style="color: #202229; border-color: #202229;">Cancel
                        </ar-button>
                    </div>

                    <img class="photo-modal" src="/static/svg/defendants/Modal_Photo.svg"
                        alt="Woman standing beside bars bars but in blue">
                </template>
            </div>
        </ar-dialog>

        <ar-dialog id="reassign-modal" heading="Reassign data" heading_style="color: #2D599E;"
            sub_heading="Select from the list of Researchers below to reassign products(s).">
            <div class="row text-left">
                <template v-if="show_reassign_users">
                    <div class="col-md-12">
                        <ar-select type="search" placeholder="Select Users" label="Users"
                            v-model="selected_reassign_user" :options="reassign_users">Select Researcher</ar-select>
                    </div>
                </template>
            </div>
            <template v-slot:buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" :outlined="true">
                    Cancel
                </ar-button>
                <ar-button @click="multiReassign()">
                    Reassign
                </ar-button>
            </template>
        </ar-dialog>

        <!-- <ar-dialog
            id="set-rejected-modal"
            heading="Mark as Rejected"
            :heading_style="`color: #DC3C40; ${ user.id != 814 ? 'text-align:left;' : '' }`"
            :sub_heading="`You are about to reject ${selected_listings.length} ${selected_listings.length > 1 ? 'listings' : 'listing'}. ${ user.id != 814 ? 'Please leave a message why you decided to tag these items as rejected. You can select these preset reasons below.' : '' } Confirm by clicking on the 'Proceed' button.`"
            :sub_heading_style="`${ user.id != 814 ? 'text-align:left;' : '' }`"
            :large="user.id != 814"
            :status_dialog="user.id != 814"
        >
            <template v-if="user.id != 814">
                <div-row class="text-left mb-2">
                    <div class="col-md-12" v-for="(reason,index) in rejected_reasons" :key="index">
                        <div class="custom-control custom-checkbox mb-1">
                            <input class="custom-control-input" type="checkbox" name="rejected-reasons" v-model="selected_reasons" :id="reason.scc_id" :value="reason.reason">
                            <label :for="reason.scc_id" class="custom-control-label font-weight-normal" style="font-size: 15px">{{reason.reason}}</label>
                        </div>
                    </div>
                </div-row>
                <ar-textfield
                    a_type="textarea"
                    a_class="text-left"
                    a_style="height: 100px; resize: none"
                    label="Comment"
                    placeholder="Comment here"
                    v-model="batch_comment"
                    v-if="showCommentsTextbox(rejected_reasons)"
                />
            </template>
            <template v-slot:buttons>
                <ar-button
                    a_class="mr-2"
                    data-dismiss="modal"
                    :outlined="true"
                    @click="batch_comment = ''">
                    Cancel
                </ar-button>
                <ar-button @click="multiMarkAs('rejected', 'set-rejected-modal')" :disabled="status_changing">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog> -->

        <ar-dialog id="set-rejected-modal" :heading="$t('Mark as Rejected')" heading_style="color: #DC3C40;" custom_minwidth="1100px">
            <!-- <div v-if="!this.already_worked_lids.length && user.id != 814" class="float-left text-left w-100 overflow-auto" style="min-height: 50px; max-height: 350px"> -->
            <div v-if="!this.already_worked_lids.length" class="float-left text-left w-100 overflow-auto"
                style="min-height: 50px; max-height: 350px">
                <p class="text-muted"> {{ $t(`A selection of predefined reasons are available below. Confirm by clicking on the 'Proceed' button.`) }}</p>

                <!-- :reasons="predefinedReasons" -->
                <ar-reason-selection
                    :reasons ="translatedPredefinedReasons"
                    key      ="lawfirm-listing-rejected-batch-change-status-reasons"
                    is-batch-modal
                />
            </div>

            <div v-else-if="this.already_worked_lids.length" class="text-danger text-bold animated fadeIn text-left"
                style="font-size: 14px">
                <p>The seller(s) of the data already has a working item in this campaign. Click Listing ID to
                    check.</p>
                <div class="already-worked-con">
                    <a v-for="(l, i) in already_worked_lids" class="text-danger d-block mb-2"
                        :href="`/lawfirm/listings/campaign/${campaign_id}/${qflag_status}/0?lid=${l.lid}`"
                        target="_blank" :key="i">
                        <u>{{ l.origin_lid }} | {{ l.seller_name }} : {{ l.lid }} | {{ $parent.getQFlag(l.qflag) }}</u>
                    </a>
                </div>
            </div>

            <p v-else class="text-muted">You are about to reject {{ selected_listings.length }}
                data{{ selected_listings.length > 1 ? 's' : '' }}. Confirm by clicking on the 'Proceed' button.</p>

            <template v-slot:buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" :outlined="true" @click="batch_comment = ''"
                    :disabled="status_changing">
                    Cancel
                </ar-button>

                <ar-button @click="checkListings2(33)"
                    :disabled="status_changing || !!already_worked_lids.length">
                    {{ status_changing ? 'Processing...' : 'Proceed' }}
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog id="set-monitoring-modal" :heading="$t('Mark as Monitoring')" heading_style="color: #ec846b;" custom_minwidth="1100px">
            <!-- <p
                v-if="!this.already_worked_lids.length"
                class="text-muted"
            >
                You are about to accept {{this.selected_listings.length}} listing{{(this.selected_listings.length > 1 && 's') || ''}} for filing. Confirm by clicking on the 'Proceed' button.
            </p> -->
            <div v-if="!this.already_worked_lids.length" class="float-left text-left w-100 overflow-auto"
                style="min-height: 50px; max-height: 350px">
                <p class="text-muted">{{ $t("A selection of predefined reasons are available below. Confirm by clicking on the 'Proceed' button.") }}</p>

                <!-- :reasons="predefinedReasons" -->
                <ar-reason-selection
                    :reasons ="translatedPredefinedReasons"
                    key      ="lawfirm-listing-monitoring-batch-change-status-reasons"
                    is-batch-modal
                />
            </div>

            <div v-else class="text-danger text-bold animated fadeIn text-left" style="font-size: 14px">
                <p>The seller(s) of the data already has a working item in this campaign. Click Listing ID to
                    check.</p>
                <div class="already-worked-con">
                    <a v-for="(l, i) in already_worked_lids" class="text-danger d-block mb-2"
                        :href="`/lawfirm/listings/campaign/${campaign_id}/${qflag_status}/0?lid=${l.lid}`"
                        target="_blank" :key="i">
                        <u>{{ l.origin_lid }} | {{ l.seller_name }} : {{ l.lid }} | {{ $parent.getQFlag(l.qflag) }}</u>
                    </a>
                </div>
            </div>

            <template v-slot:buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" :outlined="true" @click="batch_comment = ''">
                    Cancel
                </ar-button>
                <ar-button @click="checkListings2(31)"
                    :disabled="status_changing || !!already_worked_lids.length">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog id="set-forreview-modal" heading="Mark as For Review" heading_style="color: #29aba2fd">
            <p v-if="!this.already_worked_lids.length" class="text-muted">
                You are about to tag {{ this.selected_listings.length }} listing{{ (this.selected_listings.length > 1
                        &&
                        's') || ''
                }} as for review. Confirm by clicking on the 'Proceed' button.
            </p>

            <div v-else class="text-danger text-bold animated fadeIn text-left" style="font-size: 14px">
                <p>The seller(s) of the data already has a working item in this campaign. Click Listing ID to
                    check.</p>
                <div class="already-worked-con">
                    <a v-for="(l, i) in already_worked_lids" class="text-danger d-block mb-2"
                        :href="`/lawfirm/listings/campaign/${campaign_id}/${qflag_status}/0?lid=${l.lid}`"
                        target="_blank" :key="i">
                        <u>{{ l.origin_lid }} | {{ l.seller_name }} : {{ l.lid }} | {{ $parent.getQFlag(l.qflag) }}</u>
                    </a>
                </div>
            </div>

            <template v-slot:buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" :outlined="true" @click="batch_comment = ''">
                    Cancel
                </ar-button>
                <ar-button @click="checkListings2(3)"
                    :disabled="status_changing || !!already_worked_lids.length">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog id="set-filed-to-forreview-modal" :heading="$t('Mark as For Review')" heading_style="color: #29aba2fd;" custom_minwidth="1100px">
            <!-- <div v-if="!this.already_worked_lids.length && user.id != 814" class="float-left text-left w-100 overflow-auto" style="min-height: 50px; max-height: 350px"> -->
            <div v-if="!this.already_worked_lids.length" class="float-left text-left w-100 overflow-auto"
                style="min-height: 50px; max-height: 350px">
                <p class="text-muted"> {{ $t(`A selection of predefined reasons are available below. Confirm by clicking on the 'Proceed' button.`) }}</p>

                <!-- :reasons="predefinedReasons" -->
                <ar-reason-selection
                    :reasons ="translatedPredefinedReasons"
                    key      ="lawfirm-listing-forreview-batch-change-status-reasons"
                    is-batch-modal
                />
            </div>

            <div v-else-if="this.already_worked_lids.length" class="text-danger text-bold animated fadeIn text-left"
                style="font-size: 14px">
                <p>The seller(s) of the data already has a working item in this campaign. Click Listing ID to
                    check.</p>
                <div class="already-worked-con">
                    <a v-for="(l, i) in already_worked_lids" class="text-danger d-block mb-2"
                        :href="`/lawfirm/listings/campaign/${campaign_id}/${qflag_status}/0?lid=${l.lid}`"
                        target="_blank" :key="i">
                        <u>{{ l.origin_lid }} | {{ l.seller_name }} : {{ l.lid }} | {{ $parent.getQFlag(l.qflag) }}</u>
                    </a>
                </div>
            </div>

            <template v-slot:buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" :outlined="true" @click="batch_comment = ''"
                    :disabled="status_changing">
                    Cancel
                </ar-button>

                <ar-button @click="checkListings2(3)"
                    :disabled="status_changing || !!already_worked_lids.length">
                    {{ status_changing ? 'Processing...' : 'Proceed' }}
                </ar-button>
            </template>
        </ar-dialog>

        <!-- Modal for showing detected whitelisted and potential whitelisted sellers
        before tagging -->
        <!--<ar-accepted-modal :listings="listingsToBeAccepted" />-->
        <ar-whitelisted-modal
            id="statusChangeModal"
            :listings="whitelistedListings"
            :campaign="campaign_data"
            acknowledgementFeature="status change"
            @proceed="proceedMultipleStatusChange"
        >
            <template v-slot:header>{{ targetChangeStatus !== null ? `Mark as ${ucWords(getQFlag(targetChangeStatus))}` : '' }}</template>
        </ar-whitelisted-modal>
        <ar-whitelisted-modal
            id="listingsToBeAcceptedModal"
            :listings="listingsToBeAccepted"
            :campaign="campaign_data"
            acknowledgementFeature="accept data"
            @proceed="proceedToAcceptListings"
        >
            <template v-slot:header>Mark as Submitted</template>
        </ar-whitelisted-modal>

        <!-- :sub_heading="`You are about to tag ${selected_listings.length} ${selected_listings.length > 1 ? 'listings' : 'listing'} for accepted. Confirm by clicking on the 'Proceed' button.`" -->
        <ar-dialog id="set-accepted-modal" :heading="$t('Mark as Accepted')" heading_style="color: #4eaf52"
            :sub_heading="willBeAcceptedAutoSorted ? '' : markAsAcceptedModalSubheading" :has_footer="false" :has_buttons="false"
        >
            <p v-if="willBeAcceptedAutoSorted" class="text-muted">{{ markAsAcceptedModalSubheading }}</p>
            <div class="accepted-auto-sort mt-4 mb-4" v-if="willBeAcceptedAutoSorted">
                <a class="notice-img">
                    <img onerror="this.src='/static/svg/Notice_SVG.svg'" src="/static/svg/Notice_SVG.svg" alt="woman-smiling-with-notice">
                </a>
                <h6>Notice</h6>
                <p>These listings to be accepted in this tab/status may be auto-sorted to <b v-tooltip="{ content: acceptedAutoSortConditions }" class="d-inline-block text-primary animated pulse infinite slow" style="cursor: pointer;"><u>Recycled</u></b>.</p>
            </div>
            <div class="float-right mb-3" :class="{'mt-3': !willBeAcceptedAutoSorted}">
                <ar-button a_class="mr-2" data-dismiss="modal" :outlined="true" @click="batch_comment = ''">
                    Cancel
                </ar-button>
                <ar-button @click="checkListings2(32)" :disabled="status_changing">
                    {{ willBeAcceptedAutoSorted ? 'I understand and proceed' : 'Proceed' }}
                </ar-button>
            </div>
        </ar-dialog>

        <ar-dialog
            id            ="set-filed-to-accepted-modal"
            :heading       ="$t('Mark as Accepted')"
            heading_style ="color:#4eaf52"
            :has_footer="false"
            :has_buttons="false"
            xx-large
        >
            <div v-if="!this.already_worked_lids.length" class="float-left text-left w-100 overflow-auto mb-4" style="min-height: 50px; max-height: 350px">
                <p class="text-muted">A selection of predefined reasons are available below.</p>

                <ar-reason-selection 
                    :reasons ="predefinedReasons" 
                    key      ="lawfirm-listing-accepted-batch-change-status-reasons"
                    is-batch-modal
                />
            </div>
            <div
                v-else
                class ="text-danger text-bold animated fadeIn text-left"
                style ="font-size: 14px"
            >
                <p >The seller(s) of the listing(s) already has a working item in this campaign. Click Listing ID to check.</p>
                <div class="already-worked-con">
                    <a
                        v-for  ="(l, i) in already_worked_lids"
                        class  ="text-danger d-block mb-2"
                        :href  ="`/lawfirm/listings/campaign/${campaign_id}/${qflag_status}/0?lid=${l.lid}`"
                        target ="_blank"
                        :key   ="i"
                    >
                        <u>{{l.origin_lid}} | {{l.seller_name}} : {{l.lid}} | {{$parent.getQFlag(l.qflag)}}</u>
                    </a>
                </div>
            </div>

            <div class="row mt-2 float-right">
                <ar-button a_class="mr-2" data-dismiss="modal" :outlined="true" @click="batch_comment = ''">
                    Cancel
                </ar-button>
                <ar-button @click="checkListings2(32)" :disabled="status_changing || !!already_worked_lids.length">
                    {{ status_changing ? 'Processing...' : 'Proceed' }}
                </ar-button>
            </div>
        </ar-dialog>

        <ar-dialog id="set-submitted-modal" heading="Mark as Submitted" heading_style="color: #4eaf52"
            :sub_heading="`You are about to tag ${selected_listings.length} data for submission. Confirm by clicking on the 'Proceed' button.`">
            <template v-slot:buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" :outlined="true" @click="batch_comment = ''">
                    Cancel
                </ar-button>
                <ar-button @click="multiMarkAs('submitted', 'set-submitted-modal')" :disabled="status_changing">
                    {{ status_changing ? 'Processing...' : 'Proceed' }}
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog id="set-filed-to-submitted-modal" :heading="$t('Mark as Submitted')" heading_style="color: #4caf50;" custom_minwidth="1100px">
            <!-- <div v-if="!this.already_worked_lids.length && user.id != 814" class="float-left text-left w-100 overflow-auto" style="min-height: 50px; max-height: 350px"> -->
            <div v-if="!this.already_worked_lids.length" class="float-left text-left w-100 overflow-auto"
                style="min-height: 50px; max-height: 350px">
                <p class="text-muted"> {{ $t(`A selection of predefined reasons are available below. Confirm by clicking on the 'Proceed' button.`) }}</p>

                <!-- :reasons="predefinedReasons" -->
                <ar-reason-selection
                    :reasons ="translatedPredefinedReasons"
                    key      ="lawfirm-listing-submitted-batch-change-status-reasons"
                    is-batch-modal
                />
            </div>

            <div v-else-if="this.already_worked_lids.length" class="text-danger text-bold animated fadeIn text-left"
                style="font-size: 14px">
                <p>The seller(s) of the data already has a working item in this campaign. Click Listing ID to
                    check.</p>
                <div class="already-worked-con">
                    <a v-for="(l, i) in already_worked_lids" class="text-danger d-block mb-2"
                        :href="`/lawfirm/listings/campaign/${campaign_id}/${qflag_status}/0?lid=${l.lid}`"
                        target="_blank" :key="i">
                        <u>{{ l.origin_lid }} | {{ l.seller_name }} : {{ l.lid }} | {{ $parent.getQFlag(l.qflag) }}</u>
                    </a>
                </div>
            </div>

            <template v-slot:buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" :outlined="true" @click="batch_comment = ''"
                    :disabled="status_changing">
                    Cancel
                </ar-button>

                <ar-button @click="checkListings2(35)"
                    :disabled="status_changing || !!already_worked_lids.length">
                    {{ status_changing ? 'Processing...' : 'Proceed' }}
                </ar-button>
            </template>
        </ar-dialog>

        <!-- ar-dialog id="set-recycled-modal" heading="Mark as Recycled" heading_style="color: #6fb4f9"
            :sub_heading="`You are about to tag ${selected_listings.length} ${selected_listings.length > 1 ? 'listings' : 'listing'} as recycled. Confirm by clicking on the 'Proceed' button.`">
            <ar-reason-selection :reasons="predefinedReasons"
                    key="lawfirm-listing-recycled-batch-change-status-reasons" />
            <template v-slot:buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" :outlined="true" @click="batch_comment = ''">
                    Cancel
                </ar-button>
                <ar-button @click="multiMarkAs('recycled', 'set-recycled-modal')" :disabled="status_changing">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog -->

        <ar-dialog id="set-recycled-modal" heading="Mark as Recycled" heading_style="color: #6fb4f9;" custom_minwidth="1100px">
            <div v-if="!this.already_worked_lids.length" class="float-left text-left w-100 overflow-auto"
                style="min-height: 50px; max-height: 350px">
                <p class="text-muted">A selection of predefined reasons are available below. Confirm by clicking on the
                    'Proceed' button.</p>

                <ar-reason-selection
                    :reasons = "predefinedReasons"
                    key      = "lawfirm-listing-recycled-batch-change-status-reasons"
                    is-batch-modal
                />
            </div>

            <div v-else-if="this.already_worked_lids.length" class="text-danger text-bold animated fadeIn text-left"
                style="font-size: 14px">
                <p>The seller(s) of the data already has a working item in this campaign. Click Listing ID to
                    check.</p>
                <div class="already-worked-con">
                    <a v-for="(l, i) in already_worked_lids" class="text-danger d-block mb-2"
                        :href="`/lawfirm/listings/campaign/${campaign_id}/${qflag_status}/0?lid=${l.lid}`"
                        target="_blank" :key="i">
                        <u>{{ l.origin_lid }} | {{ l.seller_name }} : {{ l.lid }} | {{ $parent.getQFlag(l.qflag) }}</u>
                    </a>
                </div>
            </div>

            <p v-else class="text-muted">You are about to reject {{ selected_listings.length }}
                data{{ selected_listings.length > 1 ? 's' : '' }}. Confirm by clicking on the 'Proceed' button.</p>

            <template v-slot:buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" :outlined="true" @click="batch_comment = ''"
                    :disabled="status_changing">
                    Cancel
                </ar-button>

                <ar-button @click="checkListings2(38)"
                    :disabled="status_changing || !!already_worked_lids.length">
                    {{ status_changing ? 'Processing...' : 'Proceed' }}
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog id="set-fir-modal" heading="Mark as For Internal Review" heading_style="color: #8b9dc3" custom_minwidth="1100px">
            <div v-if="!this.already_worked_lids.length" class="float-left text-left w-100 overflow-auto"
                style="min-height: 50px; max-height: 350px">
                <p class="text-muted">A selection of predefined reasons are available below. Confirm by clicking on the
                    'Proceed' button.</p>

                <ar-reason-selection
                    :reasons ="predefinedReasons"
                    key      ="lawfirm-listing-fir-batch-change-status-reasons"
                    is-batch-modal
                />
            </div>

            <div v-else class="text-danger text-bold animated fadeIn text-left" style="font-size: 14px">
                <p>The seller(s) of the data already has a working item in this campaign. Click Listing ID to
                    check.</p>
                <div class="already-worked-con">
                    <a v-for=" (l, i) in already_worked_lids" class="text-danger d-block mb-2"
                        :href="`/lawfirm/listings/campaign/${campaign_id}/${qflag_status}/0?lid=${l.lid}`"
                        target="_blank" :key="i">
                        <u>{{ l.origin_lid }} | {{ l.seller_name }} : {{ l.lid }} | {{ $parent.getQFlag(l.qflag) }}</u>
                    </a>
                </div>
            </div>

            <template v-slot:buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" :outlined="true" @click="batch_comment = ''">
                    Cancel
                </ar-button>
                <ar-button @click="checkListings2(36)"
                    :disabled="status_changing || !!already_worked_lids.length">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog id="set-haspo-modal" heading="Mark as Has Potential" heading_style="color: #8b9dc3" custom_minwidth="1100px">
            <div v-if="!this.already_worked_lids.length" class="float-left text-left w-100 overflow-auto"
                style="min-height: 50px; max-height: 350px">
                <p class="text-muted">A selection of predefined reasons are available below. Confirm by clicking on the
                    'Proceed' button.</p>

                <ar-reason-selection
                    :reasons ="predefinedReasons"
                    key      ="lawfirm-listing-haspo-batch-change-status-reasons"
                    is-batch-modal
                />
            </div>

            <div v-else class="text-danger text-bold animated fadeIn text-left" style="font-size: 14px">
                <p>The seller(s) of the data already has a working item in this campaign. Click Listing ID to
                    check.</p>
                <div class="already-worked-con">
                    <a v-for="(l, i) in already_worked_lids" class="text-danger d-block mb-2"
                        :href="`/lawfirm/listings/campaign/${campaign_id}/${qflag_status}/0?lid=${l.lid}`"
                        target="_blank" :key="i">
                        <u>{{ l.origin_lid }} | {{ l.seller_name }} : {{ l.lid }} | {{ $parent.getQFlag(l.qflag) }}</u>
                    </a>
                </div>
            </div>

            <template v-slot:buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" :outlined="true" @click="batch_comment = ''">
                    Cancel
                </ar-button>
                <ar-button @click="checkListings2(98)"
                    :disabled="status_changing || !!already_worked_lids.length">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>

        <!-- <ar-dialog
            id="set-fir-modal"
            heading="Mark as For Internal Review"
            heading_style="color: #8b9dc3"
            :sub_heading="`You are about to tag ${selected_listings.length} ${selected_listings.length > 1 ? 'listings' : 'listing'} as for internal review. Confirm by clicking on the 'Proceed' button.`"
        >
            <template v-slot:buttons>
                <ar-button
                    a_class="mr-2"
                    data-dismiss="modal"
                    :outlined="true"
                    @click="batch_comment = ''">
                    Cancel
                </ar-button>
                <ar-button @click="multiMarkAs('for-internal-review')" :disabled="status_changing">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog> -->

        <ar-dialog id="set-irc-modal" heading="Mark as Initial Review Completed" heading_style="color: #3d5b98"
            :sub_heading="`You are about to tag ${selected_listings.length} data as initial review completed. Confirm by clicking on the 'Proceed' button.`">
            <template v-slot:buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" :outlined="true" @click="batch_comment = ''">
                    Cancel
                </ar-button>
                <ar-button @click="checkListings2(37)"
                    :disabled="status_changing">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog id="set-filed-to-irc-modal" heading="Mark as Initial Review Completed" heading_style="color: #3d5b98" custom_minwidth="1100px">
            <div v-if="!this.already_worked_lids.length" class="float-left text-left w-100 overflow-auto"
                style="min-height: 50px; max-height: 350px">
                <p class="text-muted">A selection of predefined reasons are available below. Confirm by clicking on the
                    'Proceed' button.</p>

                <ar-reason-selection
                    :reasons ="predefinedReasons"
                    key      ="lawfirm-listing-irc-batch-change-status-reasons"
                    is-batch-modal
                />
            </div>

            <div v-else class="text-danger text-bold animated fadeIn text-left" style="font-size: 14px">
                <p>The seller(s) of the data already has a working item in this campaign. Click Listing ID to
                    check.</p>
                <div class="already-worked-con">
                    <a v-for=" (l, i) in already_worked_lids" class="text-danger d-block mb-2"
                        :href="`/lawfirm/listings/campaign/${campaign_id}/${qflag_status}/0?lid=${l.lid}`"
                        target="_blank" :key="i">
                        <u>{{ l.origin_lid }} | {{ l.seller_name }} : {{ l.lid }} | {{ $parent.getQFlag(l.qflag) }}</u>
                    </a>
                </div>
            </div>

            <template v-slot:buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" :outlined="true" @click="batch_comment = ''">
                    Cancel
                </ar-button>
                <ar-button @click="checkListings2(37)"
                    :disabled="status_changing || !!already_worked_lids.length">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog id="set-wip-modal" heading="Mark as Work in Progress" heading_style="color: #2D599E;"
            :sub_heading="`You are about to tag ${selected_listings.length} data for work in progress. Confirm by clicking on the 'Proceed' button.`">
            <template v-slot:buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" :outlined="true" @click="batch_comment = ''">
                    Cancel
                </ar-button>
                <ar-button @click="checkListings2(4)" :disabled="status_changing">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog id="available-accounts-modal" large>
            <div class="row d-flex justify-content-between">
                <div>
                    <h5>
                        Available {{ hasUsed_globalSearch ? 'Global Search' : this.$route.params.status }} Payment Accounts
                    </h5>
                </div>
                <div>
                    <div class="form-group a-select reassign-select-label">
                        <select v-model="selected_payment_method" class="form-control" style="font-size: 14px;">
                            <option value="0">All Payment Type</option>
                            <!-- <option :value="method.value" v-for="(method,index) in payment_methods" :key="index">{{method.label}}</option> -->
                            <option :value=" (type || '').toString().toLowerCase().replace(/\s/g, '')"
                                v-for=" (accounts, type) in [6].includes(access_level) ? filtered_case_seller_accounts : case_seller_accounts"
                                :key="type">{{ type }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <!-- <br/>
            <div-row>
                <column md="4">
                    <div class="form-group a-select reassign-select-label">
                        <select v-model="selected_payment_method" class="form-control" style="font-size: 14px;">
                            <option value="0">All Payment Type</option>
                            <option :value="method.value" v-for="(method,index) in payment_methods" :key="index">{{method.label}}</option>
                        </select>
                    </div>
                </column>
            </div-row>
            <br> -->
            <!-- <div style="height:300px;overflow-y:auto;overflow-x:hidden;"> -->
            <div class="accordion" id="paymentAccountsAccordion" v-if="show_available_payment_accounts">
                <!-- originally: v-for="(accounts,type) in case_seller_accounts" -->
                <div class="card mb-0" style="margin-bottom: .5rem !important"
                    v-for="(accounts, type) in [6].includes(access_level) ? filtered_case_seller_accounts :filteredCaseSellerAccount"
                    :key="type" :id=" (type || '').toString().toLowerCase().replace(/\s/g, '')">
                    <div class="card-header" style="cursor:pointer"
                        :id="`heading${(type || '').toString().toLowerCase().replace(/\s/g, '')}`"
                        data-toggle="collapse"
                        :data-target="`#collapse${(type || '').toString().toLowerCase().replace(/\s/g, '')}`"
                        aria-expanded="true"
                        :aria-controls="`#collapse${(type || '').toString().toLowerCase().replace(/\s/g, '')}`">
                        <h6 class="text-left mb-0">
                            <!-- {{type}} -->
                            {{ type }}
                            <span class="doe-no ml-1"
                                style="background-color: #007bff;color: white;padding: 2px 8px;border-radius: 30px;font-size: 13px;">{{
                                        [6].includes(access_level) ? filtered_case_seller_accounts[type].length :
                                           filteredCaseSellerAccount[type].length
                                }}</span>
                        </h6>
                    </div>

                    <div :id="`collapse${(type || '').toString().toLowerCase().replace(/\s/g, '')}`"
                        class="collapse slow p-0"
                        :aria-labelledby="`heading${(type || '').toString().toLowerCase().replace(/\s/g, '')}`"
                        data-parent="#paymentAccountsAccordion">
                        <div class="card-body p-0" style="height:250px; overflow:auto; overflow-x:hidden;">
                            <div-row>
                                <column md="6" v-for="(account, index) in accounts" :key="index">
                                    <div class="a-modal-seller-info-item">
                                        <img style="width:23px; top:53%; left:18px"
                                            src="/static/svg/SellerInfo_ContactPerson.svg" alt="icon">
                                        <h5>
                                            <span class="text-primary text-truncate"
                                                style="font-weight: 500; margin-bottom: 2px;"
                                                :title="account.seller_name || 'No seller name'">{{ account.seller_name
                                                        || "No seller name"
                                                }}</span>
                                            <rt style="font-style:none"
                                                :title="account.name_email || 'No account info'">{{ account.name_email
                                                        ||
                                                        "No account info"
                                                }}</rt>
                                            <span style="margin-top:2px"
                                                :title="account.number || 'No account number'">{{ account.number || "No account number" }}</span>
                                        </h5>
                                    </div>
                                </column>
                            </div-row>
                        </div>
                    </div>
                </div>
            </div>
            <!-- </div> -->
        </ar-dialog>

        <ar-dialog
            id="frozen-amount-breakdown-modal"
            :has_footer="false"
            :has_button="false"
            large
        >
            <div class="row d-flex justify-content-between">
                <h5>
                    Frozen Amount Data Breakdown
                </h5>
            </div>
            <div-row>
                <template v-if="currentFrozenBreakdown.loading">
                    <div class="text-center mt-3 w-100">
                        <div v-for="(spin, index) in [1, 2, 3]" :key="index" class="spinner-grow text-dark"
                            role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span class="d-block mt-3">Getting frozen amount data breakdown..</span>
                    </div>
                </template>
                <template v-else-if="currentFrozenBreakdown.data.length">
                    <column md="6" v-for="(datum, index) in currentFrozenBreakdown.data" :key="index">
                        <div class="a-modal-seller-info-item">
                            <img style="width:23px; top:53%; left:18px" src="/static/svg/Case_Frozen.svg" alt="icon">
                            <h5>
                                <span class="text-primary text-truncate" style="font-weight: 500; margin-bottom: 2px;"
                                    title="Platform">{{ datum.type }}</span>
                                <span style="margin-top:2px" title="Frozen Data">{{ datum.total_frozen !== null ? '$ ' +
                                        ($parent.formatMoney(datum.total_frozen) || 0).toString() : 'Pending'
                                }}</span>
                            </h5>
                        </div>
                    </column>
                </template>
                <template v-else>
                    <h6 class="text-center w-100 text-muted mt-5">No Data to Show</h6>
                </template>
            </div-row>
            <hr style="border: 1px dashed #141517ad;">
            <div
                class="row mb-4 pl-5 text-left"
                style="font-size: 15px !important; font-weight: bold"
            >
                <div class="col-md-6">Total</div>
                <div class="col-md-6" style="color: #007bff !important">
                    {{ $parent.formatMoney(currentFrozenBreakdown.data.reduce((total, currentFrozen) => total + (+currentFrozen.total_frozen), 0)) }}
                </div>
            </div>
        </ar-dialog>

        <ar-dialog id="platform-data-breakdown-modal" large>
            <div class="row d-flex justify-content-between">
                <div>
                    <h5>
                        Platform Data Breakdown
                    </h5>
                </div>
                <div>
                    <div class="form-group a-select reassign-select-label" style="display: flex; column-gap: 10px; align-items: center">
                        <!-- <div class="platform-more-filter" @click="platformBreakdownMoreFilter()">
                            <img src="/static/svg/Action_disable.svg" title="Customize Platform Data Breakdown">
                        </div> -->
                        <div>
                            <select v-model="selected_platform_data_breakdown" class="form-control"
                                style="font-size: 14px;">
                                <option value="0">All Platforms</option>
                                <!-- <option :value="method.value" v-for="(method,index) in payment_methods" :key="index">{{method.label}}</option> -->
                                <option :value="removeSpaceAndSpecChars(platform)"
                                    v-for="(data, platform) in slicedPlatformData" :key="platform">{{ platform }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="accordion" style="max-height: 500px; overflow-y: auto" id="platformDataBreakdownAccordion" v-if="show_platform_data_breakdown">
                <div class="card mb-0" style="margin-bottom: .5rem !important"
                    v-for="(data, platform) in slicedPlatformData" :key="platform"
                    :id="removeSpaceAndSpecChars(platform)">
                    <div class="card-header" style="cursor:pointer" :id="`heading-${removeSpaceAndSpecChars(platform)}`"
                        data-toggle="collapse" :data-target="`#collapse-${removeSpaceAndSpecChars(platform)}`"
                        aria-expanded="true" :aria-controls="`#collapse-${removeSpaceAndSpecChars(platform)}`">
                        <h6 class="text-left mb-0">
                            {{ platform }}
                            <span class="doe-no ml-1"
                                style="background-color: #007bff;color: white;padding: 2px 8px;border-radius: 30px;font-size: 13px;">{{
                                        slicedPlatformData[platform].length
                                }}</span>
                        </h6>
                    </div>

                    <div :id="`collapse-${removeSpaceAndSpecChars(platform)}`" class="collapse slow p-0"
                        :aria-labelledby="`heading-${removeSpaceAndSpecChars(platform)}`"
                        data-parent="#platformDataBreakdownAccordion">
                        <div class="card-body p-0" style="height:250px; overflow:auto; overflow-x:hidden;">
                            <div-row>
                                <column md="6" v-for="(datum, index) in data" :key="index">
                                    <div class="a-modal-seller-info-item">
                                        <img style="width:23px; top:53%; left:18px"
                                            src="/static/svg/SellerInfo_ContactPerson.svg" alt="icon">
                                        <h5>
                                            <span class="text-primary text-truncate"
                                                style="font-weight: 500; margin-bottom: 2px;"
                                                :title="datum.seller_name">{{ datum.seller_name }}</span>
                                            <span style="margin-top:2px" title="Listing id">{{ datum.listing_id
                                            }}</span>
                                        </h5>
                                    </div>
                                </column>
                            </div-row>
                        </div>
                    </div>
                </div>
            </div>
        </ar-dialog>

        <ar-dialog
            id="platform-data-breakdown-more-filter"
            :has_button="false"
            :has_footer="false"
            small
            data-backdrop="static"
        >
            <div style="display: flex; flex-direction: column; row-gap: 20px; height: 600px;">
                <div style="display: flex; flex-direction: column; row-gap: 5px; text-align: left; padding-bottom: 10px; border-bottom: 1px solid #DEDEDE;">
                    <span style="font: normal normal bold 20px/24px Roboto;"> Display Platform </span>
                    <span style="font: normal normal normal 14px/17px Roboto;"> Edit number of Platforms to display </span>
                </div>
                <div class="d-flex flex-column text-left" style="height: 450px; overflow-y: auto">
                    <div v-for="(data, platform) in platform_data_breakdown" :key="platform">
                        <ar-textfield a_type="number" :label="platform" v-model="platformDisplayCounts[platform]" />
                    </div>
                </div>
                <div class="d-flex justify-content-end" style="column-gap: 10px">
                    <ar-button @click="cancelPlatformMoreFilter()" outlined>
                        Cancel
                    </ar-button>
                    <ar-button @click="savePlatformDisplayCounts(); showPlatformDataBreakdownAgain()">
                        Save
                    </ar-button>
                </div>
            </div>
        </ar-dialog>

        <ar-dialog id="edit-seller-modal" heading="Update Account Details" heading_style="text-align: left;" large
            v-if="!access.readonly || [7].includes(access_level)">
            <div-row class="mt-4 edit-seller-account-con">
                <column md="12">
                    <ar-textfield placeholder="Not Set" v-model="account_name" a_class="text-left"
                        label="Account Name or Email" />
                </column>
                <column md="6">
                    <ar-textfield placeholder="Not Set" a_class="text-left" label="Account Number"
                        v-model="fit_number" />
                </column>

                <column md="6">
                    <ar-select label="Seller Flag" :options="seller_flags" v-model.number="seller_flag"
                        :val="seller_flag" a_class="text-left">
                        Select Here
                    </ar-select>
                </column>

                <column md="6">
                    <ar-textfield placeholder="Not Set" a_class="text-left" label="Notes" v-model.trim="notes" />
                </column>

                <!-- <column md="6">
                    <ar-textfield
                        placeholder  ="Input here"
                        a_class      ="text-left"
                        label        ="Location"
                        v-model.trim ="location"
                    />
                </column> -->

                <template v-if="access.amounts">
                <!-- <template v-if="showNonDefaultedFigures"> -->
                    <column md="6">
                        <label class="d-block mt-2"
                            style="font-size:12px; font-weight: 500; text-align:left; margin-bottom: 6px;">Frozen USD
                            Amount</label>
                        <div class="input-group mb-3">
                            <input type="text" v-model="amount_frozen" @keypress="isNumber"
                                class="form-control text-right" placeholder="Not Set">
                        </div>
                    </column>

                    <column md="6">
                        <label class="d-block mt-2"
                            style="font-size:12px; font-weight: 500; text-align:left; margin-bottom: 6px;">Updated
                            Frozen USD Amount</label>
                        <div class="input-group mb-3">
                            <input type="text" v-model="amount_updated_frozen" @keypress="isNumber"
                                class="form-control text-right" placeholder="Not Set">
                        </div>
                    </column>

                    <column md="6">
                        <label class="d-block mt-2"
                            style="font-size:12px; font-weight: 500; text-align:left; margin-bottom: 6px;">Frozen CNY
                            Amount</label>
                        <div class="input-group mb-3">
                            <input type="text" v-model="amount_frozen_cny" @keypress="isNumber"
                                class="form-control text-right" placeholder="Not Set">
                        </div>
                    </column>

                    <column md="6">
                        <label class="d-block mt-2"
                            style="font-size:12px; font-weight: 500; text-align:left; margin-bottom: 6px;">Updated
                            Frozen CNY Amount</label>
                        <div class="input-group mb-3">
                            <input type="text" v-model="amount_updated_frozen_cny" @keypress="isNumber"
                                class="form-control text-right" placeholder="Not Set">
                        </div>
                    </column>

                    <column md="6">
                        <label class="d-block mt-2"
                            style="font-size:12px; font-weight: 500; text-align:left; margin-bottom: 6px;">Agreed
                            Settlement Amount</label>
                        <div class="input-group mb-3">
                            <input type="text" v-model="amount_settled" @keypress="isNumber"
                                class="form-control text-right" placeholder="Not Set">
                        </div>
                    </column>

                    <column md="6">
                        <label class="d-block mt-2"
                            style="font-size:12px; font-weight: 500; text-align:left; margin-bottom: 6px;">Received
                            Settlement Amount</label>
                        <div class="input-group mb-3">
                            <input type="text" v-model="received_amount_settled" @keypress="isNumber"
                                class="form-control text-right" placeholder="Not Set">
                        </div>
                    </column>
                </template>

                <template v-if="access.defaults">
                <!-- <template v-if="showDefaultedFigures"> -->
                    <column md="6">
                        <label class="d-block mt-2"
                            style="font-size:12px; font-weight: 500; text-align:left; margin-bottom: 6px;">Received
                            Default Amount</label>
                        <div class="input-group mb-3">
                            <input type="text" v-model="default_amount_received" @keypress="isNumber"
                                class="form-control text-right" placeholder="Not Set">
                        </div>
                    </column>

                    <column md="6">
                        <label class="d-block mt-2"
                            style="font-size:12px; font-weight: 500; text-align:left; margin-bottom: 6px;">Received CTDA
                            Default Amount</label>
                        <div class="input-group mb-3">
                            <input type="text" v-model="default_ctda_amount_received" @keypress="isNumber"
                                class="form-control text-right" placeholder="Not Set">
                        </div>
                    </column>
                </template>
            </div-row>
            <template v-slot:buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" :outlined="true">
                    Cancel
                </ar-button>
                <ar-button @click="editSellerAccount(index, account_index, 'save')">
                    Update Now
                </ar-button>
            </template>
        </ar-dialog>

        <listing-modal :content="listing" :mark_as="show_actions" :mark_as_function="markAs"
            :edit_details_function="editCaseListing" :in_case="$route.params.status == 'Cases'" :loading="modalLoading"
            :reasons="{ rejected: rejected_reasons }" :status_changing="status_changing"
            :valid-listing="already_worked_lids[0] || {}" :qflag-status="qflag_status"
            @remove_group="selected_listing.group_name = null" :currentPage="current_page" :currentListingIndex="index" :maximumPage="last_page"
            :itemsPerPage="paginatedListingsPrefiled.length" :successMessage="clrSuccessMessage"
            :setPredefinedReasons="setPredefinedReasons"
            @clickCLRLeft="onClientReviewDesignClick(paginatedListingsPrefiled[index - 1]?.id, index - 1)"
            @clickCLRRight="onClientReviewDesignClick(paginatedListingsPrefiled[index + 1]?.id, index + 1)" />

        <ar-import-ebay-modal @refresh="getListings" />

        <!-- <ar-dialog id="reassign-modal" heading="Reassign to other Researcher" heading_style="color: #2D599E;"
            sub_heading="Select from the list of Researchers below to reassign products(s).">
            <ar-select label="Researcher" a_class="reassign-select-label mt-4" :options="researcher_name_options">
                Select Researcher
            </ar-select>
            <template v-slot:buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" :outlined="true">
                    Cancel
                </ar-button>
                <ar-button>
                    Reassign
                </ar-button>
            </template>
        </ar-dialog> -->

        <ar-dialog id="group-seller-modal" large :has_footer="false" :has_button="false" key="seller_group_modal">
            <div class="py-3 seller-group-con">
                <template v-if="!found_sellers_with_group.length || is_adding_group">
                    <div class="row">
                        <div class="col-md-6 text-left">
                            <h4 class="mb-0">Seller Grouping</h4>
                            <p class="group-p">Input new group or select existing group.</p>
                            <div
                                :class="{ 'd-block': !is_adding_group, 'd-flex justify-content-between align-items-center': is_adding_group }">
                                <ar-select search-field-id="group-name-select" id="group-name-select-con"
                                    label="Group Name" type="search" v-model.trim="input_group_name"
                                    :style="`${is_adding_group ? 'width: 230px;' : ''}`" :options="group_names"
                                    emit-custom-value
                                    :err_txt="input_group_name == '' ? 'Group Name is required' : is_adding_group ? 'Group Name is available' : ''"
                                    :disabled="is_adding_group"
                                    :state="input_group_name == '' ? 'invalid' : is_adding_group ? 'success' : ''">
                                </ar-select>
                                <ar-button v-if="is_adding_group" style="top: -12px;" @click="resetGroupnameData()">
                                    Change</ar-button>
                            </div>
                            <ar-textfield v-if="is_adding_group" id="group-description-field" a_type="textarea"
                                label="Description" a_style="resize: none; height:66px;"
                                placeholder="Input description here" v-model.trim="group_description"></ar-textfield>
                            <p v-if="group_names.length" class="mb-3 group-p" style="font-size: 13px;">Note: You may
                                still proceed even the Group Name you typed is not found in the dropdown options.</p>
                            <ar-button a_class="mr-3"
                                @click="is_adding_group ? saveGroupSellers(selected_listings, input_group_name, group_description) : checkGroupSellers(selected_listings, input_group_name)"
                                :disabled="input_group_name == '' || is_proceeding || (is_adding_group && !group_description)">
                                {{ is_proceeding ? 'Proceeding...' : is_adding_group ? 'Add New Group' : 'Proceed' }}
                            </ar-button>
                            <ar-button :outlined="true" data-dismiss="modal">Cancel</ar-button>
                        </div>
                        <div class="col-md-6">
                            <img class="float-right position-relative" src="/static/svg/accounting/Modal_Photo.svg"
                                style="top: 48%; transform: translateY(-50%);">
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="row">
                        <div class="col-md-12 text-left">
                            <h4 class="text-danger">{{ found_sellers_with_group.length }} Selected
                                {{ found_sellers_with_group.length > 1 ? 'Sellers are' : 'Seller is' }} already in a
                                group</h4>
                            <img src="/static/svg/accounting/Group_Name.svg"> <span class="gname-at-image">{{
                                    input_group_name
                            }}</span>
                            <table class="table-scroll mb-2 mt-3">
                                <thead>
                                    <tr>
                                        <th class="col-md-4" scope="col">Doe #</th>
                                        <th class="col-md-4" scope="col">Seller Name</th>
                                        <th class="col-md-4" scope="col">Group Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(group, index) in found_sellers_with_group" :key="index">
                                        <td class="col-md-4 text-bold" scope="row">{{ group.doe_no }}</td>
                                        <td class="col-md-4">{{ group.seller_name }}</td>
                                        <td class="col-md-4">{{ group.group_name }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p class="overwrite-name">Overwrite Seller Group</p>
                            <input type="checkbox" v-model="is_overwrite_seller_group" class="custom-control-input"
                                id="" name="overwrite-seller-group">
                            <div class="custom-control custom-checkbox a-checkbox mb-3">
                                <input class="custom-control-input" type="checkbox" id="overwrite-seller"
                                    name="overwrite-seller-group" v-model="is_overwrite_seller_group">
                                <label for="overwrite-seller" class="custom-control-label">Check if you wish to
                                    overwrite the current value with the new group.</label>
                            </div>
                            <ar-button a_class="mr-3" @click="checkGroupSellers(selected_listings, input_group_name)"
                                :disabled="!is_overwrite_seller_group || is_proceeding">{{ is_proceeding ? "Updating..."
                                        : "Update"
                                }}</ar-button>
                            <ar-button :outlined="true" data-dismiss="modal">Cancel</ar-button>
                        </div>
                    </div>
                </template>
            </div>
        </ar-dialog>

        <DownloadCaseFilesModal :case-file-uploads="caseFileUploads_copy" :selected="selected_listings" @caseFileDeleted="doThisOnCaseFileUpdate()" @caseFileUploaded="doThisOnCaseFileUpdate()" />

        <ShowCaseFilesModal :case-file-uploads="current__case.files" :case-id="current__case.id" />

        <ar-dialog id="viewUploadsModal" large>
            <div class="uploadsContainer">
                <div class="settlementAgreement">
                    <h1 class="settlementHeader"> Uploading Settlement Agreement </h1>
                    <p> {{ current.seller_name }} </p>
                    <span class="uploadBorder" @dragover="fileDrag" @dragleave="fileLeave" @drop="fileDrop"
                        @change="changeFiles($event)">
                        <input type="file" name="fields[assetsFieldHandle][]" id="assetsFieldHandle"
                            class="inputFileAgreement" @change="changeFiles($event)" ref="file"
                            accept=".doc, .docx, .xls, .xlsx, .pdf, .jpg, .jpeg, .png, .bmp" />
                        <span v-if="fileList == ''" class="uploadSpan">
                            <img src="/static/svg/uploadSpg.svg">
                            <span class="uploadedFile" v-for="(file, index) in fileList" :key="index">
                                {{ file.name }}
                                <button type="button"
                                    @click="removeFiles(fileList.indexOf(file)); fileList = []; fileNameSelected = null"
                                    title="Remove File">
                                    ✕
                                </button>
                            </span>
                            <label for="assetsFieldHandle" class="uploadSpanDesc">
                                Upload or Drop Files here to upload
                            </label>
                        </span>
                        <span v-else class="uploadSpan" style="opacity: 1; background: #2D599E42 0% 0% no-repeat padding-box; border: 2px dashed #2D599E; border-radius: 8px; transition: .3s;">
                            <img :src="uploadedImage()" style="height: 60px; width: 60px">
                            <span class="uploadedFile" v-for="(file, index) in fileList" :key="index">
                                <label class="fileToUpload">{{ file.name }}</label>
                                <button type="button" class="removeUploaded"
                                    @click="removeFiles(fileList.indexOf(file)) ; fileNameSelected = null; selectedAgreementFile = null; fileList = []"
                                    title="Remove File">
                                    ✕
                                </button>
                            </span>
                            <label for="assetsFieldHandle" class="uploadSpanDesc">
                                Upload or Drop Files here to upload
                            </label>
                        </span>
                    </span>
                    <p style="font: normal normal bold 13px/18px Roboto; color: #333333DE; letter-spacing: 0px; text-align: left; padding-top: 10px; margin-bottom: 0px;">
                        File Name
                    </p>
                    <ar-textfield type="text" :disabled="selectedAgreementFile == null && fileList == ''"
                        style="z-index: 999" v-model="fileNameSelected" />
                    <p style="font: normal normal bold 13px/18px Roboto; color: #333333DE; letter-spacing: 0px; text-align: left; margin-top: 15px; margin-bottom: 0px;">
                        File Type
                    </p>
                    <select name="Settlement Agreement" id="agreement" class="agreementSelection"
                        v-model="selectedFileType">
                        <option disabled selected value="select"> Select here </option>
                        <option value="Unsigned Settlement Agreement"> Unsigned Settlement Agreement </option>
                        <option value="Signed Settlement Agreement"> Signed Settlement Agreement </option>
                    </select>
                    <div class="uploadBtns">
                        <button
                            v-if="((selectedAgreementFile == null) && (fileList == [] || fileNameSelected == null || selectedFileType == 'select'))"
                            disabled type="button" class="uploadBtn"
                            style="opacity: 0.5; cursor: no-drop">
                            Upload this File
                        </button>
                        <button v-else-if="(selectedAgreementFile == null) &&
                    (fileList?.length != 0 || fileNameSelected !== null ||
                        selectedFileType !== 'select')" type="button" class="uploadBtn" @click="uploadAgreement()">
                            Upload this File
                        </button>
                        <button v-else type="button" class="uploadBtn" @click="updateAgreement()">
                            Update this File
                        </button>
                        <button type="button" class="cancelBtn" @click="hideUploads();
                        selectedAgreementFile = null;
                        fileNameSelected = null;
                        selectedFileType = 'select';
                        fileList = ''">
                            Cancel
                        </button>
                    </div>
                    <!-- <div v-if="selectedAgreementFile !== null" class="removeFile" @click="removeConfirmation()">
                        <img src="/static/svg/delete-spgfile.svg" style="width: 6px; height: 9px; margin-top: 5px"><p>Remove this file instead</p>
                    </div> -->
                </div>
                <div class="divider" />
                <div class="uploadedSettlement">
                    <div class="uploadedSettlementDiv1">
                        <h1 class="agreementHeader"> Uploaded Settlement Agreement (Signed and Unsigned) </h1>
                        <p class="numberOfFiles"> Total number of {{ agreements_computed.length }} file(s) </p>
                    </div>
                    <div class="uploadedSettlementDiv2">
                        <div v-for="(file, index) in agreements_computed" :key="index" class="uploadedFilesList"
                            @click="selectedAgreementFile = index; fileNameSelected = file.file_name; selectedFileType = file.type; fileList = []"
                            :class="{ 'isSelected': selectedAgreementFile == index }">
                            <div class="fileImgs">
                                <img :src="displayImages(file)" class="fileImg" />
                            </div>
                            <div class="fileDetails">
                                <span
                                    style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 150px;">
                                    <p class="uploadedFileName"> {{ file.file_name }}</p>
                                </span>
                                <p class="uploadedFileStatus"> {{ file.type }} </p>
                            </div>
                            <div class="fileDownloadBtn">
                                <a href="javascript:void(0);" title="Download file"
                                    @click="selectedAgreementFile = index; downloadAgreementFile()"
                                    class="uploadDownloadBtn">
                                    <img style="width:28px; top:50%; right:46px;" src="/static/svg/Upload_Download.svg"
                                        alt="icon">
                                </a>
                                <a href="javascript:void(0);" title="Delete file" @click="removeConfirmation()"
                                    class="deleteFileBtn">
                                    <img style="width:28px; top:50%; right:12px;" src="/static/svg/Upload_Remove.svg"
                                        alt="icon">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ar-dialog>

        <ar-dialog id="removeFileConfirmation" custom_width="340px" custom_maxwidth="100%">
            <div class="confirmationDetails">
                <div class="finalConfirmation">
                    <p class=confirmDetails> Are you sure you want to delete this file? </p>
                </div>
                <div class="confirmationChoices">
                    <button class="choicesBtn" @click="deleteAgreement()"> Yes </button>
                    <button class="choicesBtn" @click="hideConfirmation()"> No </button>
                </div>
            </div>
        </ar-dialog>
        <ar-dialog id="removeSAConfirmation" custom_width="340px" custom_maxwidth="100%">
            <div class="confirmationDetails">
                <div class="finalConfirmation">
                    <p class=confirmDetails> Are you sure you want to delete this file? </p>
                </div>
                <div class="confirmationChoices">
                    <button class="choicesBtn" @click="deleteSAFiles()"> Yes </button>
                    <button class="choicesBtn" @click="hideSAConfirmation()"> No </button>
                </div>
            </div>
        </ar-dialog>
        <ar-dialog id="manage-groups" style="overflow-y: auto" custom_width="450px" custom_maxwidth="100%" :has_footer="false">
            <div class="manage-groups-con" style="min-height: 500px;">
                <div class="manage-groups-header">
                    <div class="manage-groups-header-img">
                        <img :src="campaign_data.img" style="height: 50px; width: 50px;">
                    </div>
                    <div style="display: flex; align-items: flex-start; justify-content: space-evenly; flex-direction: column">
                        <span class="manage-groups-head">Manage Groups</span>
                        <span class="manage-groups-desc">Select an existing group or add new group</span>
                    </div>
                </div>
                <div v-if="!new_group_mode">
                    <div v-if="multipleGroupDelete.length == 0" class="a-input-group" 
                        @click="selected_campaign_group = -1; campaign_group_isSelected = false"
                    >
                        <ar-textfield 
                            placeholder         ="Search Group Here"
                            type                ="text"
                            icon                ="fas fa-search"
                            v-model             ="search"
                            title               ="Search group name"
                        />
                    </div>
                    <div v-else style="display: flex; padding-top: 13px; padding-bottom: 18.5px; justify-content: space-between; align-items: center">
                        <span style="color:#202229; font-weight: bold; text-align: left; font: normal normal bold 14px/16px Roboto; letter-spacing: 0px;">
                             {{ `Selected ${multipleGroupDelete.length} out of ${filtered_groups.length} groups` }} 
                        </span>
                        <ar-button outlined style="height: 25px; font-size: 13px; display: flex; align-items: center"
                            @click="multipleGroupDelete = group_ids"
                        >
                            {{ groupSelectionBtn == true ? 'Unselect All' : 'Select All' }}
                        </ar-button>
                    </div>
                    <div v-if="filtered_groups.length !== 0" class="left-con mb-3" style="height: 360px;white-space: nowrap; overflow-y: auto; padding-right: 5px; padding-bottom: 10px;">
                        <div class="group-lists-con" v-for="(group, index) in filtered_groups" :key="index">
                            <div class="group-list" @mouseenter="groupHover = true" @mouseleave="groupHover = false">
                                <div class="group-lists">
                                    <span  class="group-checkbox-span">
                                        <input type="checkbox" class="group-checkbox" v-model="multipleGroupDelete"
                                            :id="`group - ${group.value}`" :value="group.value" @click="campaign_group_isSelected = false"
                                        >
                                    </span>
                                    <div class="group-list-img">
                                        <!-- <img :src="groupHover ? '/static/svg/group-management/group-blue.svg' : '/static/svg/Modal_Group.svg' "> -->
                                        <span class="group-list-img-span"></span>
                                    </div>
                                    <div class="group-list-desc">
                                        <span class="group-list-name" 
                                            style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 200px;"
                                            :title="group.label"
                                        >
                                            {{ group.label }}
                                        </span>
                                        <span class="group-list-length">
                                            {{  group.listing_count }} Number of data
                                        </span>
                                    </div>
                                    <div v-if="multipleGroupDelete.length == 0" class="animated fadeIn fast group-buttons"
                                        :class="{ 'deleteGroupClicked': selected_campaign_group == index && campaign_group_isSelected == true }"
                                    >
                                        <span class="button-group" @click="selected_campaign_group = index; campaign_group_isSelected = !campaign_group_isSelected;
                                                                    selected_group = filtered_groups[index]">
                                            <img src="/static/svg/group-management/delete-group.svg" alt="Delete" 
                                                title="Delete Group" class="group-btn"
                                            >
                                        </span>
                                        <span class="button-group" @click="selected_campaign_group = index; closeManageGroups(); openEditGroups();
                                                                    selected_group_name = filtered_groups[index].label; selected_group_name_copy = filtered_groups[index].label;
                                                                    selected_group = filtered_groups[index]">
                                            <img src="/static/svg/group-management/edit-group.svg" alt="Edit"
                                                title="Edit Group" class="group-btn"
                                            >
                                        </span>
                                    </div>
                                    <div v-if="selected_campaign_group == index && campaign_group_isSelected == true && multipleGroupDelete.length == 0" 
                                        class="confirm-group-delete"
                                    >
                                        <span class="confirm-delete-span">
                                            Confirm Delete:
                                        </span>
                                        <span style="display: flex; column-gap: 10px;">
                                            <span class="confirm-delete-button" @click="deleteGroup()">
                                                <img src="/static/svg/Checklist_Yes.svg" style="height: 13px; width: 13px">
                                            </span>
                                            <span class="confirm-delete-button" 
                                                @click="selected_campaign_group = -1; campaign_group_isSelected = false"
                                            >
                                                <img src="/static/svg/Checklist_No.svg" style="height: 13px; width: 13px">
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <hr class="m-1"/>
                        </div>
                    </div>
                    <div v-else  class="left-con mb-3" style="height: 360px; display: flex; align-items: center; justify-content: center;">
                        <img src="/static/svg/NoData.svg" style="height: 100%; width: 100%;">
                    </div>
                </div>
                <div v-else style="display: flex; flex-direction: column; justify-content: flex-start; text-align: left">
                    <ar-textfield
                        :label="'Group Name'"
                        v-model.trim="new_group_name"
                        @keyup="is_available = null"
                        :placeholder="'Input Here'"
                        :state="is_available == false ? 'invalid' : ''"
                        :err_txt="is_available == false ? 'This Group already existed' : null"
                    />
                    <div class="mb-2"></div>
                    <ar-textfield 
                        a_type="textarea"
                        label="Description"
                        a_style="resize: none; height:66px;"
                        placeholder="Input description here"
                        v-model.trim="new_group_description"
                        :err_txt="`Remaining characters: ${new_group_description ? 1000 - new_group_description.length: 1000}`"
                        :maxLength="1000"
                    ></ar-textfield>
                    <div class="mb-2"></div>
                </div>
            </div>
            <div v-if="!new_group_mode" style="display: flex; justify-content: space-between; margin-top: 20px;">
                <ar-button 
                    outlined @click="closeManageGroups()"
                    style="border-color: #000000; color: #000000"
                >
                    Cancel
                </ar-button>
                <ar-button v-if="multipleGroupDelete.length == 0" outlined style="width:180px; height: 36px; font: normal normal bold 12px/19px Roboto;"
                        @click="toggleGroupView(); selected_campaign_group = -1; 
                                campaign_group_isSelected = false; multipleGroupDelete = []" 
                >
                    <img src="/static/svg/Create_Group.svg" width="16px" height="16px" alt="icon"/>
                        Create new group
                </ar-button>
                <ar-button v-else outlined class="deleteGroupBtn" @click="openBulkDeleteGroupConfirm()">
                        Delete Group(s)
                </ar-button>
            </div>
            <div v-else style="display: flex; justify-content: space-between;">
                <ar-button @click="toggleGroupView()" outlined>
                    Back to Groups
                </ar-button>
                <ar-button
                    @click="add()"
                    class='mr-2'
                    :disabled="!new_group_name || processing || is_available === false"
                >
                    Proceed
                </ar-button>
            </div>
        </ar-dialog>
        <ar-dialog id="confirm-bulk-delete-groups" custom_width="300px">
            <div style="display: flex; flex-direction: column; row-gap: 10px; margin-bottom: -90px; margin-top: -30px; margin-left: -50px; margin-right: -50px">
                <div style="font-weight: bold; font-size: 15px;">
                    Are you sure you want to delete group(s)?
                </div>
                <div style="display: flex; column-gap: 10px; justify-content: center">
                    <ar-button @click="bulkGroupDelete()">
                        YES
                    </ar-button>
                    <ar-button @click="closeBulkDeleteGroupConfirm()">
                        NO
                    </ar-button>
                </div>
            </div>
        </ar-dialog>
        <ar-dialog id="edit-groups" custom_width="929px" custom_maxwidth="100%" :has_footer="false" style="overflow-y: auto;">
            <div class="edit-group-main-con">
                <div class="edit-group-left-con">
                    <div class="edit-group-left-header">
                        <span class="edit-group-header-icon">
                            <img :src="campaign_data.img" style="height: 50px; width: 50px;">
                        </span>
                        <span class="edit-group-header-desc">
                            <span style="font: normal normal bold 16px/21px Roboto; letter-spacing: 0px; color: #515365;">
                                Manage Groups
                            </span>
                            <span style="font: normal normal normal 12px/16px Roboto; letter-spacing: 0px; color: #515365">
                                You can update this group to fit your preference.
                            </span>
                        </span>
                    </div>
                    <div class="edit-group-left-groups">
                        <span style="width: 300px" @click="confirmDeleteListing = false; groupNameUpdateConfirm = true">
                            <ar-textfield label="Group Name" a_type="text" v-model.trim="selected_group_name"
                                placeholder="Input group name here" a_class="w-200" :disabled="listingsToTransfer.length > 0" />
                        </span>
                        <span v-show="listingsToTransfer.length == 0" style="width: 300px">
                            <span v-show="(selected_group_name !== '' && groupNameUpdateConfirmComputed) && 
                                (selected_group_name == '' || selected_group_name !== selected_group_name_copy)"
                                style="display: flex; flex-direction: column; row-gap: 10px;"
                            >
                                <span>
                                    <ar-button  id="reset-more-filter-btn" a_class="btn-primary"
                                                style="width: 300px;" @click="editGroupName();">
                                        Proceed Name Update
                                    </ar-button>
                                </span>
                                <span class="cancel-action">
                                    <ar-button outlined class="cancel-update-group-name" 
                                            @click="groupNameUpdateConfirm = false; 
                                                    selected_group_name = selected_group_name_copy">
                                        Cancel Action
                                    </ar-button>
                                </span>
                            </span>
                        </span>
                        <span v-show="listingsToTransfer.length > 0">
                            <span class="transfer-to"> Transfer To</span> <span style="font-size: 20px;"> ↓ </span>
                        </span>
                        <span v-show="listingsToTransfer.length > 0" style="width: 300px">
                            <ar-select label="Group Name" :options="filtered_new_groups" type="search" 
                                :val="filtered_new_groups.value" v-model="newGroupListingTransfer" placeholder="Search Here"
                            >
                            </ar-select>
                        </span>
                        <span v-show="newGroupListingTransfer"
                                style="display: flex; flex-direction: column; row-gap: 10px;"
                            >
                                <span>
                                    <ar-button  id="reset-more-filter-btn" a_class="btn-primary"
                                                style="width: 300px;" @click="transferGroupListings()">
                                        Confirm Transfer
                                    </ar-button>
                                </span>
                                <span class="cancel-action">
                                    <ar-button outlined class="cancel-update-group-name" 
                                            @click="clearGroupTransfer();">
                                        Cancel Action
                                    </ar-button>
                                </span>
                            </span>
                    </div>
                </div>
                <div class="edit-group-right-con">
                    <div v-if="listingsToTransfer.length == 0" class="group-listings-header">
                        <span class="group-listings-head"> Showing {{ filtered_group_listings.length }} Listings </span>
                        <span class="group-listings-search"> 
                            <ar-textfield 
                                placeholder         ="Search Seller / LID Here"
                                a_type                ="text"
                                :icon="groupListingSearch?.length ? 'fas fa-times text-danger' : groupListingSearch.toString().trim() != '' ? 'fas fa-times' : 'fas fa-search'"
                                @click="groupListingSearch = ''"
                                v-model             ="groupListingSearch"
                                title               ="Search seller name or listing id"
                            />
                        </span>
                    </div>
                    <div v-else class="group-listings-header">
                        <span class="selected-listings-length"> 
                            Selected {{ listingsToTransfer.length }} out of {{ filtered_group_listings.length }} Sellers
                        </span>
                        <span style="display: flex;">
                            <ar-button outlined style="height: 25px; font-size: 13px; display: flex; align-items: center"
                                @click="listingsToTransfer = seller_ids"
                            >
                                {{ sellerSelectionBtn == true ? ' Unselect All ' : 'Select All' }}
                            </ar-button>
                        </span>
                    </div>
                    <div v-if="filtered_group_listings.length > 0" class="group-listings-list">
                        <div v-for="(listing, index) in filtered_group_listings" :key="index" 
                            :class="{'listingChecked': listingsToTransfer.length !== 0,
                                    'listingSelected': listingsToTransfer.includes(listing.id)}"
                            class="listings-per-group">
                            <div class="group-list-logo">
                                <!-- <img :src="listing.evidences?.Preview !== undefined ? `${env}/files/${listing.evidences != null ? 
                                    listing.evidences.preview || listing.evidences.Preview : ''}` : `/static/svg/Listing Default Photo.svg`" 
                                    style="height: 91px; width: 113px; border-radius: 8px;"> -->
                                <div v-lazy-container="{ selector: 'img' }">
                                    <img style="height: 91px; width: 113px; border-radius: 8px;" :data-src="listing.evidences?.Preview !== undefined ? `${env}/files/${listing.evidences != null ? 
                                    listing.evidences.preview || listing.evidences.Preview : ''}` : `/static/svg/Listing Default Photo.svg`" data-error="/static/svg/Listing Default Photo.svg" alt="preview">
                                </div>
                            </div>
                            <div class="group-list-details">
                                <div class="group-list-detail-name" 
                                    :title="listing.seller[0].url != null ? listing.seller[0].url ? listing.seller[0].url : '' : ''"
                                >
                                    <a target="_blank" :href="listing.seller[0].url" style="color: #203154;">
                                        {{ listing.seller != null ? listing.seller[0] ? listing.seller[0].name : '' : '' }}
                                    </a>
                                </div>
                                <div class="group-list-labels">
                                    <div class="group-list-number" :title="listing.id" @click="copytoClipboard(listing.id)"> {{ listing.id }} </div>
                                    <div class="group-list-status" 
                                        :title="listing.status == 'Qualified' ? 'For Review' : 
                                        listing.status == 'Hacked' ? 'Auto Qualified' : listing.status"
                                    > 
                                        {{ listing.status == 'Qualified' ? 'For Review' : 
                                            listing.status == 'Hacked' ? 'Auto Qualified' : listing.status }} 
                                    </div>
                                    <div class="group-list-label" :title="listing.campaign">
                                        <div class="group-list-label-img">
                                            <img :src="campaign_data.img" class="group-list-label-logo">
                                        </div>
                                        <div class="group-list-label-name"> {{ listing.campaign }} </div>
                                    </div>
                                    <div class="group-list-label" :title="listing.platform">
                                        <div class="group-list-label-img">
                                            <img :src="listing.platform_image" class="group-list-label-logo">
                                        </div>
                                        <div class="group-list-label-name"> {{ listing.platform }} </div>
                                    </div>
                                </div>
                                <div class="group-list-label-description" 
                                    :title="listing.url != null ? listing.url : 'No Value'" 
                                >
                                    <a target="_blank" :href="listing.url" style="color: #203154;">
                                        {{ listing.listing_info != null ? listing.listing_info.listing_title : 'No Value' }}
                                    </a>
                                </div>
                                <div class="group-listings-buttons"
                                    :class="{ 'deleteListingClicked': selectedListingIndividual == index && confirmDeleteListing == true }"
                                >
                                    <div class="remove-listing-span" title="Delete Seller" :class="{'hidden': listingsToTransfer.length !== 0}"
                                         @click="confirmDeleteListing = !confirmDeleteListing; selectedListingIndividual = index;
                                                selectedListingToDelete = filtered_group_listings[index].id">
                                        <img src="/static/svg/group-management/delete-group.svg" alt="Delete" 
                                             class="remove-listing-btn"
                                        >
                                    </div>
                                    <div class="edit-listing-span" title="Transfer Seller">
                                        <label class="edit-listing-span-checkbox">
                                            <input :id="`group-listing - ${listing.id}`" type="checkbox" :value="listing.id" 
                                                    class="list-check" v-model="listingsToTransfer" @click="confirmDeleteListing = false">
                                            <img class="img-unchecked"  src="/static/svg/group-management/select-blue.svg" >
                                            <img class="img-hover"  	src="/static/svg/group-management/select-blue.svg" >
                                            <img class="img-checked"    src="/static/svg/group-management/select-white.svg" >
                                        </label>
                                    </div>
                                </div>
                                <div v-if="confirmDeleteListing == true && selectedListingIndividual == index" class="confirm-listing-delete">
                                    <span class="confirm-listing-span" >
                                        Confirm Delete:
                                    </span>
                                    <span style="display: flex; column-gap: 10px;">
                                        <span class="confirm-listing-button" @click="deleteGroupListing()">
                                            <img src="/static/svg/Checklist_Yes.svg" style="height: 14px; width: 14px">
                                        </span>
                                        <span class="confirm-listing-button"
                                            @click="confirmDeleteListing = false; selectedListingIndividual = -1" 
                                        >
                                            <img src="/static/svg/Checklist_No.svg" style="height: 14px; width: 14px">
                                        </span>
                                    </span>
                                    </div>
                            </div>
                        </div>
                        
                    </div>
                    <div v-else style="display: flex; justify-content: center; align-items: center; height: 100%; width: 100%;">
                        <img src="/static/svg/NoData.svg" style="height: 60%; width: 60%;">
                    </div>
                </div>
            </div>
            <div class="edit-group-left-back">
                <ar-button outlined class="edit-group-left-back-btn" 
                    @click="closeEditGroups(); openManageGroups(); cancelGroupTransfer();">
                    Back To Groups
                </ar-button>
            </div>
        </ar-dialog>

        <ar-dialog id="item-class-type-aggregator" large :has_footer="false">
            <div class="row d-flex justify-content-between mb-4">
                <div>
                    <h5>
                        Item Class / Type Aggregator
                    </h5>
                </div>
                <div class="d-flex  position-absolute" style="top: 15px; right: 0; gap: 6px;">
                    <!-- sorting -->
                    <div>
                        <div role="group" aria-label="Basic example" class="btn-group order-by-buttons d-flex align-items-start justify-content-center">
                            <button 
                                title="Refresh Data"
                                type="button"
                                class="btn btn-default mr-2"
                                style="border-radius: .25rem;"
                                @click="getItemClass_ItemType(true)"
                            >
                                <i class="fas fa-redo"></i>
                            </button>
                            <div class="mr-2">
                                <toggle-button
                                    v-model="count_toggle"
                                    :sync="true"
                                    :color="{checked: '#E4707F', unchecked: '#3B5998', disabled: '#CCCCCC'}"
                                    :width="80"
                                    :height="38"
                                    :labels="{checked: 'Type', unchecked: 'LID'}"
                                    :disabled="!show_itemClasses"
                                    :font-size="12"
                                ></toggle-button>
                            </div>
                            <button 
                                title="Order by Ascending"
                                type="button"
                                class="btn btn-default"
                                style="border-radius: 0px; border-top-left-radius: .25rem !important; border-bottom-left-radius: .25rem !important; "
                                :disabled="!show_itemClasses"
                                @click="item_class_order_by = 'asc'"
                                :class="{'active': item_class_order_by == 'asc'}"
                            >
                                <i class="fas fa-sort-alpha-up-alt"></i>
                            </button>
                            <button 
                                title="Order by Descending"
                                type="button"
                                class="btn btn-default"
                                style="border-radius: 0px; border-top-right-radius: .25rem !important; border-bottom-right-radius: .25rem !important; "
                                :disabled="!show_itemClasses"
                                @click="item_class_order_by = 'desc'"
                                :class="{'active': item_class_order_by != 'asc'}"
                            >
                                <i class="fas fa-sort-alpha-down-alt"></i>
                            </button>
                        </div>
                    </div>
                    <ar-select
                        :options="itemClass_options"
                        v-model="item_type"
                        parentStyle="width: 200px; position: relative; height: 38px"
                        :first_element_as_default="false"
                        placeholder = "Select Class"
                        :val="item_type"
                        :disabled="!show_itemClasses"
                    >
                        All Item Class
                    </ar-select>
                </div>
            </div>
            <!-- v-if="show_platform_data_breakdown" -->
            <div 
                class="accordion"
                id="itemAggregatorAccordion"
                style="max-height: 630px;overflow: auto;"
                v-if="show_itemClasses"
            >
                <div
                    class="card mb-0 item-class"
                    style="margin: .5rem !important"
                    v-for="(item, index) in filtered_itemClass_withItemTypesData"
                    :key="index"
                    :id="`item-class-${index}`"
                    :class="{'active': selected_ItemClass == index}"
                    @click="clickClass_Aggregator(index)"
                >
                    <div class="card-header" style="cursor:pointer" :id="`heading-item-class-${index}`"
                        data-toggle="collapse" :data-target="`#collapse-item-class-${index}`"
                        aria-expanded="true" :aria-controls="`#collapse-item-class-${index}`"
                    >
                        <div 
                            class="d-flex align-items-center justify-content-between"
                            style=" padding-right: 20px"
                        >
                            <p class="m-0" style="font-size: 16px;">{{item.formatted_className}}</p>
                            <p class="m-0" style="color: #3B5998; font-size: 14px;font-weight: 500;">{{count_toggle? `${item.item_types.length} Total No. of Item Type(s)` : `${item.totalLID_underThisClass} Total No. of LID(s)` }} </p>
                        </div>
                        <div class="d-flex align-items-center justify-content-end" style="height:100px;">    
                            <column
                                md="6"
                                :key="index2"
                                id="item-class-item-types-container"
                                style="min-height: 115px !important; padding: 10px 0px 0px 0px !important;"
                            >
                                <div 
                                    class="d-flex align-items-center justify-content-center"
                                    style="width: 100%;padding-top: 10px;gap:25px"
                                >
                                    <!-- left side -->
                                    <div class="d-flex flex-column align-items-start justify-content-start" style="gap: 10px;">
                                        <span class="d-flex align-items-center justify-content-start" style="gap: 6px;">
                                            <img src="/static/svg/Icon_Qualified.svg" alt="">
                                            <p>
                                                Qualified:
                                            </p>
                                        </span>
                                        <span class="d-flex align-items-center justify-content-start" style="gap: 6px;">
                                            <img src="/static/svg/Icon_HasPotential.svg" alt="">
                                            <p>
                                                Has Potential:
                                            </p>
                                        </span>
                                        <span class="d-flex align-items-center justify-content-start" style="gap: 6px;">
                                            <img src="/static/svg/Icon_Accepted.svg" alt="">
                                            <p>
                                                Accepted:
                                            </p>
                                        </span>
                                    </div>
                                    <!-- right side -->
                                    <div class="d-flex flex-column align-items-start justify-content-start" style="width:50%;gap: 10px;">
                                        <p 
                                            class="item-type-status-p"
                                            :title="`${item.totalQualified_count} Data of Qualified Status for '${item.type}'`"
                                        >
                                            {{item.totalQualified_count}} Data
                                        </p>
                                        <p
                                            class="item-type-status-p"
                                            :title="`${item.totalHasPotential_count} Data of Has Potential Status for '${item.type}'`"
                                        >
                                            {{item.totalHasPotential_count}} Data
                                        </p>
                                        <p
                                            class="item-type-status-p"
                                            :title="`${item.totalAccepted_count} Data of Accepted Status for '${item.type}'`"
                                        >
                                            {{item.totalAccepted_count}} Data
                                        </p>
                                    </div>
                                </div>
                            </column>
                            <column
                                md="6"
                                :key="index2"
                                id="item-class-item-types-container"
                                style="min-height: 115px !important; padding: 10px 0px 0px 0px !important;"
                            >
                                <div 
                                    class="d-flex align-items-center justify-content-center"
                                    style="width: 100%;padding-top: 10px;gap:25px"
                                >
                                    <!-- left side -->
                                    <div class="d-flex flex-column align-items-start justify-content-start" style="gap: 10px;">
                                        <span class="d-flex align-items-center justify-content-start" style="gap: 6px;">
                                            <img src="/static/svg/Icon_Submitted.svg" alt="">
                                            <p>
                                                Submitted:
                                            </p>
                                        </span>
                                        <span class="d-flex align-items-center justify-content-start" style="gap: 6px;">
                                            <img src="/static/svg/Icon_Filed.svg" alt="">
                                            <p>
                                                Filed:
                                            </p>
                                        </span>
                                    </div>
                                    <!-- right side -->
                                    <div class="d-flex flex-column align-items-start justify-content-start" style="width:50%;gap: 10px;">
                                        <p
                                            class="item-type-status-p"
                                            :title="`${item.totalSubmitted_count} Data of Submitted Status for '${item.type}'`"
                                        >
                                            {{item.totalSubmitted_count}} Data
                                        </p>
                                        <p
                                            class="item-type-status-p"
                                            :title="`${item.totalFiled_count} Data of Filed Status for '${item.type}'`"
                                        >
                                            {{item.totalFiled_count}} Data
                                        </p>
                                    </div>
                                </div>
                            </column>
                        </div>
                    </div>

                    <div :id="`collapse-item-class-${index}`" class="collapse slow p-0"
                        :aria-labelledby="`heading-item-class-${index}`"
                        data-parent="#itemAggregatorAccordion">
                        <div class="card-body p-0" style="min-height:200px;max-height: 400px; overflow:auto; overflow-x:hidden;">
                            <div-row v-if="item.item_types.length > 0">
                                <column
                                    md="6"
                                    v-for="(type, index2) in item.item_types"
                                    :key="index2"
                                    id="item-class-item-types-container"
                                    :class="[{'odd': (index2 + 1) % 2 == 1},{'not-last': (item.item_types.length % 2 == 1) ? index2 < (item.item_types.length - 1) : index2 < (item.item_types.length - 2) }]"
                                >
                                    <div 
                                        class="d-flex align-items-center justify-content-center"
                                        style="width: 100%;padding-top: 10px;gap:25px;"
                                    >
                                        <!-- left side -->
                                        <div class="d-flex flex-column align-items-start justify-content-start" style="gap: 10px;">
                                            <span class="d-flex align-items-center justify-content-start" style="gap: 6px;">
                                                <img src="/static/svg/item-type-gray.svg" alt="">
                                                <p>
                                                    Item Type:
                                                </p>
                                            </span>
                                            <span class="d-flex align-items-center justify-content-start" style="gap: 6px;">
                                                <img src="/static/svg/Icon_Qualified.svg" alt="">
                                                <p>
                                                    Qualified:
                                                </p>
                                            </span>
                                            <span class="d-flex align-items-center justify-content-start" style="gap: 6px;">
                                                <img src="/static/svg/Icon_HasPotential.svg" alt="">
                                                <p>
                                                    Has Potential:
                                                </p>
                                            </span>
                                            <span class="d-flex align-items-center justify-content-start" style="gap: 6px;">
                                                <img src="/static/svg/Icon_Accepted.svg" alt="">
                                                <p>
                                                    Accepted:
                                                </p>
                                            </span>
                                            <span class="d-flex align-items-center justify-content-start" style="gap: 6px;">
                                                <img src="/static/svg/Icon_Submitted.svg" alt="">
                                                <p>
                                                    Submitted:
                                                </p>
                                            </span>
                                            <span class="d-flex align-items-center justify-content-start" style="gap: 6px;">
                                                <img src="/static/svg/Icon_Filed.svg" alt="">
                                                <p>
                                                    Filed:
                                                </p>
                                            </span>
                                        </div>
                                        <!-- right side -->
                                        <div class="d-flex flex-column align-items-start justify-content-start" style="width:50%;gap: 10px;">
                                            <span style="width: 100%; padding: 2px 16px; border-radius: 18px; background-color: #3F88DA;">
                                                <p style="color:#FFFFFF;" class="text-truncate" :title="type.type">
                                                    {{ type.type }}
                                                </p>
                                            </span>
                                            <p 
                                                class="item-type-status-p"
                                                :title="`${type.qualified_count} Data of Qualified Status for '${type.type}'`"
                                            >
                                                {{type.qualified_count}} Data
                                            </p>
                                            <p
                                                class="item-type-status-p"
                                                :title="`${type.has_potential_count} Data of Has Potential Status for '${type.type}'`"
                                            >
                                                {{type.has_potential_count}} Data
                                            </p>
                                            <p
                                                class="item-type-status-p"
                                                :title="`${type.accepted_count} Data of Accepted Status for '${type.type}'`"
                                            >
                                                {{type.accepted_count}} Data
                                            </p>
                                            <p
                                                class="item-type-status-p"
                                                :title="`${type.submitted_count} Data of Submitted Status for '${type.type}'`"
                                            >
                                                {{type.submitted_count}} Data
                                            </p>
                                            <p
                                                class="item-type-status-p"
                                                :title="`${type.filed_count} Data of Filed Status for '${type.type}'`"
                                            >
                                                {{type.filed_count}} Data
                                            </p>
                                        </div>
                                    </div>
                                </column>
                            </div-row>
                            <span 
                                class="d-flex align-items-center justify-content-center"
                                style="width: 100%;height:100%;color: red;"
                                v-else
                            >
                                No Item Types available for this Item Class
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="d-flex flex-column align-items-center justify-content-center" style="gap: 15px">
                <template v-if="isRequesting_ItemTypeItemClass">
                    <img  src='/static/img/V-Loader.gif' alt='Loading...' width='150' />
                    Fetching Data Please wait...
                </template>
                <template v-else>
                    Encounter an Error while fetching the data. Please refresh to request again.
                </template>
            </div>
            <template v-slot:buttons>
                <div id="footer-button" class="d-flex align-items-center justify-content-end mt-2">
                    <ar-button 
                        @click="reset_ItemClassModal()"
                        data-dismiss="modal"
                    >
                        Got it, Thanks
                    </ar-button>
                </div>
            </template>
        </ar-dialog>

        <ar-dialog
            id                  = "listing-empty-info"
            heading             = "Missing Required Fields"
            :sub_heading         = "`Detected ${listingsWithEmptyField.length} listing(s) with missing required fields`"
            :has_footer         = "false"
            :custom_width       = "'fit-content'"
            xLarge
        >
            <template>
                <div style="overflow-y: auto; min-height: fit-content; max-height: 500px; background-color: #efefef; padding: 5px 10px;">
                    <div v-for="(list, index) in listingsWithEmptyField" :key="index" class="list-field-con">
                        <div style="width: 50px">
                            <span class="d-inline-block position-relative align-middle" @click=copyToClipboard2(list.id)>
                                <button type="button" class="btn a-btn btn-primary copyListingId" title="Copy listing id">
                                    {{ list.id }}
                                </button>
                            </span>
                        </div>
                        <div style="text-align: left; display: grid; grid-template-columns: auto auto auto; gap: 8px 70px">
                            <div v-show="list.seller_name === null || list.seller_name === ''" class="missing-field-con">
                                <i class="fas fa-times-circle" style="font-size:15px;color:red"></i>
                                <span class="missing-field-label" title="Seller Name"> Seller Name </span>
                            </div>
                            <div v-show="list.seller_contactable === null || list.seller_contactable === ''" class="missing-field-con">
                                <i class="fas fa-times-circle" style="font-size:15px;color:red"></i>
                                <span class="missing-field-label" title="Seller Contactable"> Seller Contactable </span>
                            </div>
                            <div v-show="list.listing_title === null || list.listing_title === ''" class="missing-field-con">
                                <i class="fas fa-times-circle" style="font-size:15px;color:red"></i>
                                <span class="missing-field-label" title="Listing Title"> Listing Title </span>
                            </div>
                            <div v-show="list.claim_type === null || list.claim_type === ''" class="missing-field-con">
                                <i class="fas fa-times-circle" style="font-size:15px;color:red"></i>
                                <span class="missing-field-label" title="Claim Type"> Claim Type </span>
                            </div>
                            <div v-show="list.shipping_location === null || list.shipping_location === ''" class="missing-field-con">
                                <i class="fas fa-times-circle" style="font-size:15px;color:red"></i>
                                <span class="missing-field-label" title="Shipping Location"> Shipping Location </span>
                            </div>
                            <div v-show="list.currency === null || list.currency === ''" class="missing-field-con">
                                <i class="fas fa-times-circle" style="font-size:15px;color:red"></i>
                                <span class="missing-field-label" title="Currency"> Currency </span>
                            </div>
                            <div v-show="list.purchase_type === null || list.purchase_type === ''" class="missing-field-con">
                                <i class="fas fa-times-circle" style="font-size:15px;color:red"></i>
                                <span class="missing-field-label" title="Purchase Type"> Purchase Type </span>
                            </div>
                            <div v-show="list.purchase_price === null || list.purchase_price === ''" class="missing-field-con">
                                <i class="fas fa-times-circle" style="font-size:15px;color:red"></i>
                                <span class="missing-field-label" title="Purchase Price"> Purchase Price </span>
                            </div>
                            <div v-show="list.seller_url === null || list.seller_url === ''" class="missing-field-con">
                                <i class="fas fa-times-circle" style="font-size:15px;color:red"></i>
                                <span class="missing-field-label" title="Seller URL"> Seller URL </span>
                            </div>
                            <div v-show="list.ship_to_us === null || list.ship_to_us === ''" class="missing-field-con">
                                <i class="fas fa-times-circle" style="font-size:15px;color:red"></i>
                                <span class="missing-field-label" title="Will Ship to US"> Will Ship to US </span>
                            </div>
                            <div v-show="list.keyword === null || list.keyword === ''" class="missing-field-con">
                                <i class="fas fa-times-circle" style="font-size:15px;color:red"></i>
                                <span class="missing-field-label" title="Keyword"> Keyword </span>
                            </div>
                            <div v-show="list.dummy_account === null || list.dummy_account === ''" class="missing-field-con">
                                <i class="fas fa-times-circle" style="font-size:15px;color:red"></i>
                                <span class="missing-field-label" title="Dummy Account Used"> Dummy Account Used </span>
                            </div>
                            <div v-show="list.payment_account === null" class="missing-field-con">
                                <i class="fas fa-times-circle" style="font-size:15px;color:red"></i>
                                <span class="missing-field-label" title="Payment Account"> Payment Account </span>
                            </div>
                            <div v-show="list.logo_on_item === null || list.logo_on_item === ''" class="missing-field-con">
                                <i class="fas fa-times-circle" style="font-size:15px;color:red"></i>
                                <span class="missing-field-label" title="Has logo on item image"> Has logo on item image </span>
                            </div>
                            <div v-show="list.payment_platform === null || list.payment_platform === ''" class="missing-field-con">
                                <i class="fas fa-times-circle" style="font-size:15px;color:red"></i>
                                <span class="missing-field-label" title="Payment Platforms"> Payment Platform </span>
                            </div>
                            <div v-show="list.date_captured === null || list.date_captured === ''" class="missing-field-con">
                                <i class="fas fa-times-circle" style="font-size:15px;color:red"></i>
                                <span class="missing-field-label" title="Date Captured"> Date Captured </span>
                            </div>
                            <div v-show="list.platform === null || list.platform === ''" class="missing-field-con">
                                <i class="fas fa-times-circle" style="font-size:15px;color:red"></i>
                                <span class="missing-field-label" title="Platform"> Platform </span>
                            </div>
                            <div v-show="list.listing_url === null || list.listing_url === ''" class="missing-field-con">
                                <i class="fas fa-times-circle" style="font-size:15px;color:red"></i>
                                <span class="missing-field-label" title="Listing URL"> Listing URL </span>
                            </div>
                            <div v-show="list.item_type === null || list.item_type == ''" class="missing-field-con">
                                <i class="fas fa-times-circle" style="font-size:15px;color:red"></i>
                                <span class="missing-field-label" title="Item Type"> Item Type </span>
                            </div>
                            <div v-show="list.item_location === null || list.item_location === ''" class="missing-field-con">
                                <i class="fas fa-times-circle" style="font-size:15px;color:red"></i>
                                <span class="missing-field-label" title="Item Location"> Item Location </span>
                            </div>
                            <div v-show="list.initial_price === null || list.initial_price === ''" class="missing-field-con">
                                <i class="fas fa-times-circle" style="font-size:15px;color:red"></i>
                                <span class="missing-field-label" title="Initial Price"> Initial Price </span>
                            </div>
                            <div v-show="list.brand_on_item === null || list.brand_on_item === ''" class="missing-field-con">
                                <i class="fas fa-times-circle" style="font-size:15px;color:red"></i>
                                <span class="missing-field-label" title="Has brand on item image"> Has brand on item image </span>
                            </div>
                            <div v-show="list.transaction_number === null || list.transaction_number === ''" class="missing-field-con">
                                <i class="fas fa-times-circle" style="font-size:15px;color:red"></i>
                                <span class="missing-field-label" title="Transaction Number"> Transaction Number </span>
                            </div>
                            <div v-show="list.is_merged == false || list.is_merged === null" class="missing-field-con">
                                <i class="fas fa-times-circle" style="font-size:15px;color:red"></i>
                                <span class="missing-field-label" title="Full Merge"> Full Merge </span>
                            </div>
                            <div v-show="list.infringement_reason?.length == 0" class="missing-field-con">
                                <i class="fas fa-times-circle" style="font-size:15px;color:red"></i>
                                <span class="missing-field-label" title="Infringement Reason"> Infringement Reason </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="margin-top: 15px; margin-bottom: -30px; display: flex; justify-content: flex-end; column-gap: 15px">
                    <ar-button v-show="isMultipleChecking" @click="removeIncompleteListings()" data-dismiss="modal"> Remove Incomplete Listing(s) </ar-button>
                    <ar-button outlined data-dismiss="modal"> Close </ar-button>
                </div>
            </template>
        </ar-dialog>

        <main class="main-con" :style="`${selected_listings.length ? 'padding-top: 158px' : $route.params.case_id != 0 ? 'padding-top: 255px' : ''}`">
            <div class="mb-2 text-bold" v-if="isFileCaseMode">
                <img src="/static/svg/info-circle.svg" alt="info icon" />
                Please select data to file
            </div>
            <template v-if="$route.params.status == 'Cases' && $route.params.case_id != 0">
                <!-- <div class="fab-con" :class="{ 'expanded': fab_expand }" v-if="access.amountsDefaults"> -->
                <div
                    class  ="fab-con cm-button-multiple-import-data"
                    :class ="{ 'expanded' : fab_expand }"
                    v-restrict="'cm-button-multiple-import-data'"
                >
                    <!-- v-if="showExportFrozenAccounts" -->
                    <a
                        href               ="javascript: void(0)"
                        class              ="extra-import-btn1 cm-link-import-released-accounts"
                        data-toggle        ="modal"
                        data-target        ="#import-released-modal"
                        @click             ="resetRelease()"
                        v-restrict ="'cm-link-import-released-accounts'"
                    >
                        <img src="/static/svg/defendants/Fab_Released.svg" alt="released" title="Import / Export Amount Released">
                    </a>

                    <!-- :class="[{ 'extra': !showExportFrozenAccounts }]" -->
                    <a
                        href               ="javascript: void(0)"
                        class              ="extra-import-btn2 cm-link-import-frozen-accounts"
                        data-toggle        ="modal"
                        data-target        ="#import-wizard-modal"
                        @click             ="resetImportAmountData()"
                        v-restrict ="'cm-link-import-frozen-accounts'"
                    >
                        <img src="/static/svg/defendants/Fab_Frozen.svg" alt="frozen" title="Import Frozen Accounts">
                    </a>

                    <!-- v-if="showExportFrozenAccounts" -->
                    <a
                        href               ="javascript: void(0)"
                        class              ="extra-import-btn3 cm-link-generate-frozen-accounts-summary"
                        data-toggle        ="modal"
                        data-target        ="#case-export-frozen-modal"
                        @click             ="resetExportAmountData()"
                        v-restrict ="'cm-link-generate-frozen-accounts-summary'"
                    >
                        <img src="/static/svg/defendants/Fab_Export_Frozen.svg" alt="frozen-export" title="Export Frozen Accounts Summary">
                    </a>

                    <!-- :class="[{ 'extra': !showExportFrozenAccounts }]" -->
                    <a
                        href               ="javascript: void(0)"
                        class              ="extra-import-btn4 cm-link-bulk-update-doe-numbers"
                        data-toggle        ="modal"
                        data-target        ="#update-doe-modal"
                        @click             ="removeSetUploadFile"
                        v-restrict ="'cm-link-bulk-update-doe-numbers'"
                    >
                        <img src="/static/svg/defendants/FAB_Doe.svg" alt="doe" title="Bulk Update Doe No.">
                    </a>

                    <!-- :class="[{ 'extra': !showExportFrozenAccounts }]" -->
                    <a
                        href               ="javascript: void(0)"
                        class              ="extra-import-btn5 cm-link-paypal-fit-compiler"
                        data-toggle        ="modal"
                        data-target        ="#compile-frozen-modal"
                        data-backdrop      ="static"
                        data-keyboard      ="false"
                        v-restrict ="'cm-link-paypal-fit-compiler'"
                    >
                        <img src="/static/svg/defendants/Compiler.svg" alt="frozen" title="Compile Frozen Accounts">
                    </a>

                    <a href="javascript:void(0)" @click="fab_expand = !fab_expand" class="d-block import-btn">
                        <template v-if="!fab_expand">
                            <div class="d-inline-block">
                                <img src="/static/svg/defendants/FAB_Import.svg" alt="import" title="Import Data">
                            </div>
                        </template>
                        <template v-else>
                            <div class="d-inline-block">
                                <img src="/static/svg/defendants/FAB_Cancel.svg" alt="import" title="Cancel">
                            </div>
                        </template>
                    </a>
                </div>

                 <!-- v-else-if="access.case && !access.readonly" -->
                 <!-- Removed since this button is already in expandable button above -->
                 <!-- <ar-button
                    is_fab
                    data-toggle ="modal"
                    data-target ="#update-doe-modal"
                    class       ="position-fixed"
                    style       ="width: 58px; height: 58px; padding: 0 0 0 3px; bottom: 33px; right: 343px; z-index: 4"
                    title       ="Bulk Update Doe No."
                    @click      ="removeSetUploadFile"
                >
                    <img src="/static/svg/Doe.svg" alt="icon">
                </ar-button> -->
            </template>
            <!--<template v-else-if="$route.params.status == 'Submitted' && show_actions.includes('filecase')">
                <div v-show="!(filter_submissions_pack.selected && selected_listings.length)" class="fab-con" style="bottom: 18px">
                    <a
                        @click="showSPGSelectModal()"
                        href="javascript:void(0)"
                        class="d-block import-btn">
                        <template  v-if="isFileCaseMode">
                            <div class="d-inline-block">
                                <img src="/static/svg/defendants/FAB_Cancel.svg" alt="import" title="Cancel">
                            </div>
                        </template>
                        <template v-else>
                            <div class="d-inline-block">
                                <img width="50" height="50" src="/static/svg/Rounded_Case.svg" alt="import" title="File a case">
                            </div>
                        </template>
                    </a>
                </div>
            </template>-->
            <div class="card-con" v-if="$route.params.status == 'Cases' && $route.params.case_id == '0'">
                <!-- v-for          ="listing in listings" -->
                <ArListCase @click="gotoCaseListings(listing.id, listing.case_number); setCurrentCaseFiles(listing.case_files)" v-for="listing in paginatedCases"
                    :status="listing.case_status" :has_checkbox="false" :key="listing.key_id"
                    :case_img_key="listing.case_img_key" :platform_img_key="listing.platform_img_key" type="case"
                    class="animated fadeIn fast" :case_name="listing.case_number"
                    :platform_imgs="getCasePlatforms(listing.platforms)"
                    :total_frozen="($parent.formatMoney(listing.total_frozen) || 0).toString()"
                    :total_released="($parent.formatMoney(listing.total_released) || 0).toString()"
                    :total_settled="($parent.formatMoney(listing.total_agreed_settlement) || 0).toString()"
                    :total_expected_settlement="($parent.formatMoney(listing.total_expected_settlement) || 0).toString()"
                    :total_predicted_default="($parent.formatMoney(listing.total_predicted_default) || 0).toString()"
                    :total_defendants="(listing.total_defendants || 0).toString()"
                    :total_received="($parent.formatMoney(listing.total_received) || 0).toString()"
                    :case_imgs="getCaseImages(listing.evidences || [])" :details="listing.case_title"
                    :owner="listing.verifier_owner != null ? ownerName(listing.verifier_owner.first_name, listing.verifier_owner.last_name) : ''"
                    :lawfirm_name="listing['`lawfirm_name`']" :judge="listing.Judge" :date_filed="listing.date_filed"
                    :case_status_options="case_status_options"
                    :total_settlement_received="($parent.formatMoney(listing.total_received_settlement) || 0).toString()"
                    :total_default_received="($parent.formatMoney(listing.total_default_received) || 0).toString()"
                    :total_ctda_default_received="($parent.formatMoney(listing.total_ctda_default_received) || 0).toString()"
                    :checked="selected_listings.some(l => l.case_id == listing.id)"
                    :case_files="listing.case_files"
                    @showCaseFilesUploaded="showCaseFiles(listing.id, listing.case_files)"
                >
                    <template v-slot:case-btns v-if="selected_listings.length == 0">
                        <div class="animated fadeIn fast">
                           <!-- v-if="(!access.readonly || [7].includes(access_level)) && access_level !== 6" -->
                           <!-- v-restrict ="'cm-link-edit-case'" #Remove v-restrict due to looping error-->
                            <ar-button
                                v-if="$featureAccess.isAllowed('cm-button-manage-case')"
                                class="mr-1 btn text-uppercase rounded"
                                outlined
                                style="padding: 3px 10px; letter-spacing: 1.07px; font: normal normal bold 12px / 16px Roboto;"
                                @click="gotoCaseListings(listing.id, listing.case_number)">
                                Manage Case <i class="fas fa-arrow-right ml-1" style="transform: rotate(-45deg)" />
                            </ar-button>
                           <a
                                href="javascript:void(0);"
                                class="case-btn cm-link-edit-case"
                                @click="editCaseDetails(listing.id)"
                                v-if="!isFeatureRestricted('cm-link-edit-case')"
                            >
                                <img src="/static/svg/Case_Edit.svg" alt="Edit Case" title="Edit Case">
                            </a>
                            <a
                                href               ="javascript: void(0);"
                                class              ="case-btn cm-link-frozen-breakdown"
                                @click             ="getFrozenAmountBreakdown(listing.id)"
                                v-restrict ="'cm-link-frozen-breakdown'"
                            >
                                <img src="/static/svg/Action_Frozen.svg" alt="Frozen Breakdown" title="Frozen Amount Breakdown">
                            </a>
                            <!-- <a href="javascript:void(0);" class="case-btn" @click="getSAFiles(listing.id)"> -->
                            <a href="javascript:void(0);" class="case-btn"
                                @click="getSAFiles(listing.id); countSettlementReceived(listing.id)">
                                <img src="/static/svg/Action_viewSA.svg" alt="Show Settlement Agreement Files"
                                    title="Show Settlement Agreement Files">
                            </a>
                            <a
                                href               = "javascript: void(0);"
                                class              = "case-btn cm-link-upload-case-files position-relative"
                                @click             = "!listing.hideUploadedCaseFileButton ? getUploadedCaseFiles(listing.id) : null"
                                v-restrict = "'cm-link-upload-case-files'"
                            >
                                <i v-if="listing.hideUploadedCaseFileButton" class="fas fa-circle-notch fa-spin position-absolute" style="background: #ed886e; margin: 3px 0 0 3px; padding: 2px; color: #fff; border-radius: 100%;"></i>
                                <img src="/static/svg/Action_viewfiles.svg" alt="Show Uploaded Files" title="Show Uploaded Files">
                            </a>
                        </div>
                    </template>
                    <!-- v-show="!isFeatureRestricted('cm-checkbox-select-cases')" -->
                    <div class="custom-control custom-checkbox a-checkbox cm-checkbox-select-cases" v-if="(!access.readonly || [7].includes(access_level))">
                    <!-- @click="!isFeatureRestricted('cm-checkbox-select-cases') ? selected_function({ case_id: listing.id, case_number: listing.case_number, deletable: listing.total_defendants == 0 }) : null" -->
                        <input type="checkbox" :id="listing.id"
                            @click="selected_function({ case_id: listing.id, case_number: listing.case_number, deletable: listing.total_defendants == 0 })"
                            class="custom-control-input" :value="listing.id" :checked="selected_listings.some(l => l.case_id == listing.id)" />
                        <label :for="listing.id" class="custom-control-label"></label>
                    </div>
                </ArListCase>
                <div v-if="!listings.length && loaded" class="empty-listing-con">
                    <img src="/static/svg/NoListings_Photo.svg" alt="No listings">
                </div>
            </div>
            <div class="card-con" v-else-if="$route.params.case_id != '0'">
                <!-- v-for          ="(listing, index) in paginatedListingsPostfiled" -->
                <ar-list v-for="(listing, index) in filteredListings" :has_checkbox="false" :key="listing.key_id"
                    :seller_url="listing.seller != null ? listing.seller[0] ? listing.seller[0].url : '' : ''"
                    :seller_name="listing.seller != null ? listing.seller[0] ? listing.seller[0].name : '' : ''"
                    :owner="listing.verifier_owner != null ? ownerName(listing.verifier_owner.first_name, listing.verifier_owner.last_name) : ''"
                    :rid="listing.id"
                    :status="listing.status == 'Qualified' ? 'For Review' : listing.status == 'Hacked' ? 'Auto Qualified' : listing.status"
                    :product_img="listing.evidences != null ? listing.evidences.preview || listing.evidences.Preview : ''"
                    :product_url="listing.url"
                    :details="listing.listing_info != null ? listing.listing_info.listing_title : ''"
                    :seller_accounts="listing.case_seller_accounts"
                    :doe_no="listing.case_listing != null ? listing.case_listing[0] ? (listing.case_listing[0].doe_number || 0).toString() : '' : ''"
                    :case_flag="listing.case_listing != null ? listing.case_listing[0] != null ? listing.case_listing[0].case_flag : 'Not Set' : ''"
                    :platform="listing.platform" :group_name="listing.group_name"
                    :group_description="listing.group_description"
                    :platform_img="$parent.getImage('platforms', listing.platform)"
                    :seller_account_id="listing.seller_account_id" :index="index" edit_seller_modal="#edit-seller-modal"
                    :edit_seller_account="editSellerAccount" target="#listing-modal" class="animated fadeIn fast"
                    type="defendant">
                    <!-- <template v-if="access_level !== 6" v-slot:viewUploads> -->
                    <template v-if="showViewUploadFilesButton" v-slot:viewUploads>
                        <div class="animated fadeIn fast defendants-btn-con">
                            <ar-button
                                class="mr-2 btn text-uppercase"
                                outlined
                                style="padding: 3px 10px; letter-spacing: 1.07px; font: normal normal bold 12px / 16px Roboto;"
                                @click="showDefendantModal(); currentPostFiling(listing.id, index); already_worked_lids = ''">
                                Manage Defendant <i class="fas fa-arrow-right ml-1" style="transform: rotate(-45deg)" />
                            </ar-button>
                            <button type="button" class="viewUploadsBtn"
                                @click="viewUploads(listing.id); selected_seller_name = listing.seller != null ? listing.seller[0] ? listing.seller[0].name : '' : ''"
                            >
                                View Uploads
                            </button>
                        </div>
                    </template>
                    <div class="custom-control custom-checkbox a-checkbox">
                        <input type="checkbox" :id="listing.id" v-model="selected_listings"
                            @click="selected_function(listing.id)" class="custom-control-input" :value="listing.id">
                        <label :for="listing.id" class="custom-control-label"></label>
                    </div>
                </ar-list>
                <div v-if="!listings.length && loaded" class="empty-listing-con">
                    <img src="/static/svg/NoListings_Photo.svg" alt="No listings">
                </div>
            </div>
            <div class="card-con" v-else>
                <!-- v-for            ="(listing, index) in paginatedListingsPostfiled" -->
                <!-- <template v-if="!hasUsed_globalSearch"> -->
                    <ar-list v-for="(listing, index) in paginatedListingsPrefiled" :has_checkbox="false"
                        :key="listing.key_id"
                        :seller_url="listing.seller?.length > 0 ? listing.seller[0] != null ? listing.seller[0].url : '' : ''"
                        :seller_name="listing.seller?.length > 0 ? listing.seller[0] != null ? listing.seller[0].name : '' : ''"
                        :owner="listing.verifier_owner != null ? ownerName(listing.verifier_owner.first_name, listing.verifier_owner.last_name) : ''"
                        :rid="listing.id"
                        :status="listing.status == 'Qualified' ? 'For Review' : listing.status == 'Hacked' ? 'Auto Qualified' : listing.status"
                        :product_img="listing.evidences != null ? listing.evidences.preview || listing.evidences.Preview : ''"
                        :product_url="listing.url"
                        :details="listing.listing_info != null ? listing.listing_info.listing_title : ''"
                        :seller_accounts="listing.case_seller_accounts"
                        :doe_no="listing.case_listing != null ? listing.case_listing[0] ? (listing.case_listing[0].doe_number || 0).toString() : '' : ''"
                        :case_flag="listing.case_listing != null ? listing.case_listing[0] != null ? listing.case_listing[0].case_flag : 'Not Set' : ''"
                        :platform="listing.platform" :platform_img="$parent.getImage('platforms', listing.platform)"
                        :seller_account_id="listing.seller_account_id" :index="index" :group_name="listing.group_name"
                        edit_seller_modal="#edit-seller-modal" :edit_seller_account="editSellerAccount"
                        :case_number="listing.qflag_id == 34 ? listing?.case?.case_number : ''" target="#listing-modal"
                        class="animated fadeIn fast"
                        @click="$route.params.case_id == '0' ? current(listing.id, index) : currentPostFiling(listing.id, index); already_worked_lids = ''; selected_listing = listing"
                        :checked="selected_listings.includes(listing.id)"
                    >
                        <div class="custom-control custom-checkbox a-checkbox">
                            <input type="checkbox" :id="listing.id"
                                @click="selected_function(listing.id)" class="custom-control-input" :value="listing.id"
                                :checked="selected_listings.includes(listing.id)">
                            <label :for="listing.id" class="custom-control-label"></label>
                        </div>
                    </ar-list>
                <!-- </template> -->
                <!-- For Global Search -->
                <!-- <template v-else>
                    <ar-list v-for="(listing, index) in paginatedListingsPrefiled" :has_checkbox="false"
                        :key="listing.key_id" :seller_url="listing.seller_url" :seller_name="listing.seller_name"
                        :rid="listing.id"
                        :status="listing.status == 'Qualified' ? 'For Review' : listing.status == 'Hacked' ? 'Auto Qualified' : listing.status"
                        :product_img="listing.product_img" :product_url="listing.url" :details="listing.details"
                        :platform="listing.platform" :platform_img="$parent.getImage('platforms', listing.platform)"
                        :index="index" edit_seller_modal="#edit-seller-modal" :edit_seller_account="editSellerAccount"
                        :case_number="listing.case_number" target="#listing-modal" class="animated fadeIn fast"
                        @click="$route.params.case_id == '0' ? current(listing.id, index) : currentPostFiling(listing.id, index); already_worked_lids = ''; selected_listing = listing">
                        <div class="custom-control custom-checkbox a-checkbox">
                            <input type="checkbox" :id="listing.id" v-model="selected_listings"
                                @click="selected_function(listing.id)" class="custom-control-input" :value="listing.id">
                            <label :for="listing.id" class="custom-control-label"></label>
                        </div>
                    </ar-list>
                </template> -->
                <div v-if="!listings.length && loaded" class="empty-listing-con">
                    <img src="/static/svg/NoListings_Photo.svg" alt="No listings">
                </div>
            </div>
        </main>

        <footer class="search-footer" v-if="this.$route.params.case_id == '0'">
            <div class="d-flex justify-content-between">
                <ar-pagination class="animated fadeIn mr-3 d-inline-block" :page_no="current_page"
                    :last_page="last_page" @first-click="updatePage(1)" @prev-click="updatePage(prev_page)"
                    @next-click="updatePage(next_page)" @last-click="updatePage(last_page)" @enter="updateListingPage"
                    @keypress.native="isNumber" />
                <!-- <ar-textfield a_type="number" a_style="width: 220px" v-model="page_size" icon="fas fa-list"
                    @input="current_page = 1" @keyup.enter.native="updatePageSize(page_size)"
                    title="Show number of listing(s) (Limit to 500 listings)" /> -->
                    <ar-textfield a_type="number" a_style="width: 220px" v-model="page_size" icon="fas fa-list"
                    @input="current_page = 1" />
                    <!-- title="Show number of listing(s) (Limit to 10000 listings) -->
            </div>
        </footer>

        <potential-whitelists :whitelists="$store.state.potentialWhitelists" @click="acknowledgeWhitelists"
            @close="onModalClose" />
    </div>
</template>

<script>
import GlobalComponents from '@/globalComponents.js'
import ListingModal from '@/components/pages/lawfirm/ListingModal'
import titlebar from '../../../components/layouts/TitleBar'
import textfield from '../../../components/layouts/Textfield'
import button from '../../../components/layouts/Button'
import list from '../../../components/layouts/List'
import select from '../../../components/layouts/Select'
import dialog from '../../../components/layouts/Dialog'
/* import modal from '../../../components/layouts/Modal'
import togglebtn from '../../../components/layouts/ButtonToggle'
import actionbar from '../../../components/layouts/ActionBar' */ //Unused imports
import pagination from '../../layouts/Pagination'
import moreFilters from '../../layouts/MoreFilters'
import chip from '../../layouts/Chip'
import FrozenAccountCompiler from '../../pages/lawfirm/FrozenAccountCompiler'
import MoveListing from '../../pages/lawfirm/MoveListings'
import GroupListing from '../../pages/lawfirm/GroupListings'
/* import Checkbox from '../../layouts/Checkboxes' */ //Unused imports
import MultiSelect from 'vue-multiselect';
import axios from '../../../axiosMime'
import { mapActions, mapState } from 'vuex'
/* import draggable from 'vuedraggable'
import { confirmDialog } from '../../../mixins/confirmDialog'
import CheckboxesVue from '../../layouts/Checkboxes.vue' */ //Unused imports
import { capitalize, ucWords } from '@/utils/string'
import { isEmpty, buildHeader, getImage, getUserFullName, getQFlag } from '@/utils/common'
import ArImportEbayModal from '@/components/layouts/modals/ModalImportEbay';
import moment from 'moment';
import qs from 'qs';
import ArWhitelistedModal from '@/components/layouts/modals/WhitelistedModal';
import { isFeatureRestricted } from '../../../utils/common';
import { Role } from '../../../utils/constants';
import { send as globalSend } from '../../../events'

/** Pinia Store */
import { useFeatureAccessStore } from '../../../store/pinia/feature-access';
import { useReasonSelectionStore } from '../../../store/pinia/reasons-selection';
import { useCommonStore } from '../../../store/pinia/common';
import { useCaseFileStore } from '../../../store/pinia/case-files';
import { useSubStatusesStore } from '../../../store/pinia/substatuses';
import { mapWritableState } from 'pinia';
/** Pinia Store */

/**
 * Link for the documentation of this Component:
 *      https://github.com/euvl/vue-js-toggle-button
 * 
 * used command for npm install:
 *      npm install vue-js-toggle-button --save
 */
import Vue from 'vue'
import { ToggleButton } from 'vue-js-toggle-button'
// Vue.component('ToggleButton', ToggleButton)

const layouts = vm.$layouts
export default {
    name: 'ar-lawfirm-listings',
    components: {
        ...GlobalComponents,
        'ar-titlebar': titlebar,
        'ar-textfield': textfield,
        'ar-button': button,
        'ar-list': list,
        'ar-select': select,
        'ar-dialog': dialog,
        // 'ar-action-bar'            : actionbar,
        'listing-modal': ListingModal,
        'ar-pagination': pagination,
        'ar-chip': chip,
        'ar-frozen-account-compiler': FrozenAccountCompiler,
        'ar-release-amount-import': () => import('./ReleaseAmountImport'),
        'ar-add-to-case': () => import('./listings/AddToCase'),
        'ar-submission-pack-generator': () => import('./listings/SubmissionPackGenerator'),
        'ar-move-listing': MoveListing,
        'ar-group-listing': GroupListing,
        'multiselect': MultiSelect,
        PotentialWhitelists: () => import('@/components/layouts/modals/PotentialWhitelists.vue'),
        ArReasonSelection: () => import('@/components/pages/verification/listing/ReasonsSelection'),
        //ArAcceptedModal: () => import('./listings/AcceptedModal'),
        ArImportWizard: () => import('./accounting/import-frozen/ImportWizard'),
        ArMessageDialog: () => import('@/components/layouts/MessageDialog.vue'),
        DownloadCaseFilesModal: () => import('@/components/layouts/modals/DownloadCaseFilesModal'),
        ShowCaseFilesModal: () => import('@/components/layouts/modals/ShowCaseFilesModal'),
        ArLoaderinator: _ => import('@/components/layouts/loading/Loaderinator'),
        ArListCase: _ => import('@/components/layouts/ListCase'),
        ArWhitelistedModal,
        ArImportEbayModal,
        'toggle-button': ToggleButton,
        // draggable,
        // 'ar-checkbox': Checkbox
    },
    props: {
        p_campaigns: {
            type: Object,
            default: () => ({})
        },
        // groups_new_copy: {
        //     type: [Array, Object],
        //     default: () => ({})
        // }
    },
    beforeCreate() {
        if (localStorage.getItem("token") == null) {
            this.$router.push({ name: 'lawfirm' })
        }
    },
    async mounted() {
        document.addEventListener('keydown', event => {
            if (event.altKey && event.key === 'x' || event.altKey && event.key === 'X') {
                this.filtered_listing_id = []
            }
        })
        if (this.$route.query.listing_id) {
            this.search_by = 'listing_id';
            this.search_input = this.$route.query.listing_id;
            this.lid_status = this.$route.params.status;
        }
        this.getPlatformPrioritization();
        await this.initializeSortOptions()
        // await this.getStatuses()
        await this.getCaseStatuses()
        this.daFilterDrawer = this.$parent.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
        await this.getListings()
            .then(() => {
                this.checkWhitelist()
            })
        document.title = this.$route.meta.title
        this.getCaseSellerAccounts()
        this.getUsers()
        // this.getUploadFileTypes()
        this.initializeReasons()

        /**
         * Functions that returns data 
         * that are used in Additional Filters
         */
        // if (['Submitted', 'Accepted'].includes(this.$route.params.status)) this.getPacks();
        // await this.getPaymentTypes()
        // this.getInfringementReasons()
        // this.getItemTypes()
        // this.getItemClasses()
        // this.getClaimTypes()
        // this.getSellerLocations()
        // this.getGroupNames()

        this.getPredefinedReasonsFilter()
        this.getImportPlatforms()
        if (this.$route.params.status == "Cases" && this.$route.params.case_id != '0') {
            this.getCaseDetails()
            this.getSellerFlags()
            this.getCaseInfo()
        }
        this.access.readonly = await this.checkFeatureAccess([2])
        this.access.amounts = await this.checkFeatureAccess([3])
        this.access.defaults = await this.checkFeatureAccess([4])
        this.access.amountsDefaults = await this.checkFeatureAccess([3, 4])
        this.access.accounting = await this.checkFeatureAccess([5]);
        this.access.all = await this.checkFeatureAccess([1, 3, 4])
        this.access.case = await this.checkFeatureAccess([1])
        $('.spg-defendant-filter-con').click(function (e) {
            e.stopPropagation();
        });
        this.get();
        this.getNewGroups();
        // this.copyListings()

        if (!this.caseFileStore.caseFiles.length && this.$route.params.case_id != 0) {
            this.getDefendantCaseFiles(this.$route.params.case_id);
        }

        // this.getItemClass_ItemType()
    },
    computed: {
        ...mapWritableState(useReasonSelectionStore, ['showReasonWithRestriction', 'hasSeenReasonWithRestriction', 'hasSelectedReasonWithRestriction', 'isRestrictionLoading']),
        ...mapState(['relatedCampaignBypass', "filters", "feature_access", "cancellationTokenSource", 'hasDeletedCaseFile', 'hasUploadedCaseFile', /*'filed_listings'*/ 'globalSearch_input', 'hasUsed_globalSearch', 'globalSearch_listings', 'globalSearch_count', 'addNewGroupListing', 'lawfirmCoverPage','case_seller_accounts_vuex', 'lf_platforms_vuex2']),
        commonStore() {
            return useCommonStore()
        },
        caseFileStore() {
            return useCaseFileStore()
        },
        currentCaseStatus() {
            const status = this.caseFileStore.caseData?.[0]?.case_status
            return this.case_status_options.find(c => c.value == status)?.label
        },
        transferToAnotherSubStatusOptions() {
            if (this.isInCasesPage) return []

            if (this.isInMonitoringTab)
                return this.monitoringSubStatuses

            if (this.isInHasPotentialTab)
                return this.hasPotentialSubStatuses

            if (this.isInForReviewTab)
                return this.forreviewSubStatuses

            if (this.isInInitialReviewCompletedTab)
                return this.ircSubStatuses

            if (this.isInAcceptedTab)
                return this.acceptedSubStatuses

            if (this.isInSubmittedTab)
                return this.submittedSubStatuses
            
            if (this.isInRejectedTab)
                return this.rejectedSubStatuses

            if (this.isInForInternalReviewTab)
                return this.firSubStatuses

            if (this.isInRecycledTab)
                return this.recycledSubStatuses

            if (this.isInFiledTab)
                return []

            return []
        },
        subStatusesStore() {
            return useSubStatusesStore()
        },
        wipSubStatuses() {
            const sub = this.subStatusesStore?.subStatuses?.total_item_by_substatus?.["Work in Progress"] ?? [];
            return sub.length === 1 ? sub.filter(s => s.id) : sub.map(s => ({ ...s, id: s.id ?? -1}));
        },
        hasPotentialSubStatuses() {
            const sub = this.subStatusesStore?.subStatuses?.total_item_by_substatus?.["Has Potential"] ?? [];
            return sub.length === 1 ? sub.filter(s => s.id) : sub.map(s => ({ ...s, id: s.id ?? -1}));
        },
        monitoringSubStatuses() {
            const sub = this.subStatusesStore?.subStatuses?.total_item_by_substatus?.["Monitoring"] ?? [];
            return sub.length === 1 ? sub.filter(s => s.id) : sub.map(s => ({ ...s, id: s.id ?? -1}));
        },
        forreviewSubStatuses() {
            const sub = this.subStatusesStore?.subStatuses?.total_item_by_substatus?.["Qualified"] ?? [];
            return sub.length === 1 ? sub.filter(s => s.id) : sub.map(s => ({ ...s, id: s.id ?? -1}));
        },
        rejectedSubStatuses() {
            const sub = this.subStatusesStore?.subStatuses?.total_item_by_substatus?.["Rejected"] ?? [];
            return sub.length === 1 ? sub.filter(s => s.id) : sub.map(s => ({ ...s, id: s.id ?? -1}));
        },
        acceptedSubStatuses() {
            const sub = this.subStatusesStore?.subStatuses?.total_item_by_substatus?.["Accepted"] ?? [];
            return sub.length === 1 ? sub.filter(s => s.id) : sub.map(s => ({ ...s, id: s.id ?? -1}));
        },
        submittedSubStatuses() {
            const sub = this.subStatusesStore?.subStatuses?.total_item_by_substatus?.["Submitted"] ?? [];
            return sub.length === 1 ? sub.filter(s => s.id) : sub.map(s => ({ ...s, id: s.id ?? -1}));
        },
        firSubStatuses() {
            const sub = this.subStatusesStore?.subStatuses?.total_item_by_substatus?.["For Internal Review"] ?? [];
            return sub.length === 1 ? sub.filter(s => s.id) : sub.map(s => ({ ...s, id: s.id ?? -1}));
        },
        ircSubStatuses() {
            const sub = this.subStatusesStore?.subStatuses?.total_item_by_substatus?.["Internal Review Completed"] ?? [];
            return sub.length === 1 ? sub.filter(s => s.id) : sub.map(s => ({ ...s, id: s.id ?? -1}));
        },
        recycledSubStatuses() {
            const sub = this.subStatusesStore?.subStatuses?.total_item_by_substatus?.["Recycled"] ?? [];
            return sub.length === 1 ? sub.filter(s => s.id) : sub.map(s => ({ ...s, id: s.id ?? -1}));
        },
        isDateGrantedRestricted() {
            return this.isFeatureRestricted('cm-modal-case-file-date-granted')
        },
        acceptedAutoSortConditions() {
            let content = ""

            const conditions = [
                "Listing's seller location is not in any Asian Location AND",
                "Listing's platform is either in (Aliexpress, Alibaba) OR",
                "Listing's payment method is either in (Alipay, Alibaba No Account)"
            ]

            content += 'If the listing met the conditions below, it will be transferred to <b>Recycled</b>.'
            content += '<ul class="mb-0 pl-4">'
            conditions.forEach(c => {
                content += `<li>${c}</li>`
            })
            content += '</ul>'

            return content
        },
        active_li() {
            return this.selected_listing ?? {}
        },
        selectedListingsValues() {
            return this.listings?.filter(l => this.selected_listings?.includes(l.id))
        },
        isClientListingReview() {
            // TODO: change to client access_level = 6
            return this.user.access_level == 6 && ["For Review", "Qualified", "Has Potential", "Monitoring", "Accepted", "Submitted", "Rejected", "Initial Review Completed"].includes(this.$route.params.status)
        },
        isClient() {
            return this.user.access_level == 6
        },
        filtered_case_seller_accounts() {
            let case_seller_accounts = this.case_seller_accounts
            if (!this.hasUsed_globalSearch) {
                let arr_payment = {}

                let hasPaypal = false
                let hasPaypal_noAccount = false
                let hasAlibaba = false
                let hasAlipay = false
                let hasDHpay = false
                let hasDHGate = false

                let previous_Alipay = {}
                let previous_Alibaba_noAccount = {}
                let previous_Paypal = {}
                let previous_Paypal_noAccount = {}
                let previous_DHpay = {}
                let previous_DHGate_noAccount = {}

                let alipay = 'Alipay'
                let paypal = 'Paypal'
                let dhpay = 'DHPay'

                // checking first

                for (var j in case_seller_accounts) {
                    if (j.toString().toLowerCase() == 'paypal') {
                        hasPaypal = true
                        previous_Paypal = case_seller_accounts[j]
                    }
                    if (j.toString().toLowerCase() == 'alipay') {
                        hasAlipay = true
                        previous_Alipay = case_seller_accounts[j]
                    }
                    if (j.toString().toLowerCase() == 'dhpay') {
                        hasDHpay = true
                        previous_DHpay = case_seller_accounts[j]
                    }
                }

                for (var i in case_seller_accounts) {

                    if (!i.toString().toLowerCase().includes('no account')) {
                        if (!(i.toString().toLowerCase().includes('alipay') || i.toString().toLowerCase().includes('dhpay') || i.toString().toLowerCase().includes('paypal'))) {
                            arr_payment[this.remove_noAccountLabel(i)] = case_seller_accounts[i]
                        }

                    } else {

                        // for payment_type ==  "No Account"
                        if (i.toString().toLowerCase() == 'no account') {
                            arr_payment[i] = case_seller_accounts[i]
                        }

                        if (i.toString().toLowerCase().includes('paypal') && i.toString().toLowerCase().includes('no account')) {
                            hasPaypal_noAccount = true
                            previous_Paypal_noAccount = case_seller_accounts[i]
                            if (hasPaypal) {
                                arr_payment[paypal] = [...previous_Paypal, ...previous_Paypal_noAccount]
                            } else {
                                arr_payment[paypal] = case_seller_accounts[i]
                            }
                        }
                        if (i.toString().toLowerCase().includes('alibaba') && i.toString().toLowerCase().includes('no account')) {
                            previous_Alibaba_noAccount = case_seller_accounts[i]
                            hasAlibaba = true
                            if (hasAlipay) {
                                arr_payment[alipay] = [...previous_Alipay, ...previous_Alibaba_noAccount]
                            } else {
                                arr_payment[alipay] = previous_Alibaba_noAccount
                            }
                        }
                        if (i.toString().toLowerCase().includes('dhgate') && i.toString().toLowerCase().includes('no account')) {
                            previous_DHGate_noAccount = case_seller_accounts[i]
                            hasDHGate = true
                            if (hasDHpay) {
                                arr_payment[dhpay] = [...previous_DHpay, ...previous_DHGate_noAccount]
                            } else {
                                arr_payment[dhpay] = previous_DHGate_noAccount
                            }
                        }
                    }
                }
                // console.log(case_seller_accounts)
                // console.log(arr_payment)
                // return case_seller_accounts
                return arr_payment
            }
            return case_seller_accounts
        },
        agreements_computed() {
            let agreements = this.agreements
            return agreements
        },
        saUploads_computed() {
            let saUploads = this.saUploads
            let filterType = this.filter_sa_type
            let filtered = saUploads
            // console.log(filterType)
            if (filterType == 'Signed Settlement Agreement') {
                filtered = saUploads.filter(uploads => uploads.type == filterType)
            }
            else if (filterType == 'Unsigned Settlement Agreement') {
                filtered = saUploads.filter(uploads => uploads.type == filterType)
            }
            return filtered
        },

        filtered_uploaded_files() {
            let files = this.filtered_case_files

            let filterType = this.filter_upload_type
            let filtered = files

            if (['Complaint','Schedule A', 'TRO', 'PI', 'FJO', 'Dismissed', 'Case Terminated', 'Case Closed'].includes(filterType)) {
                filtered = files.filter(file => (file.type == "Case Closed" ? "Case Terminated" : file.type) == filterType)
            }
            return filtered
        },
        filtered_claim_types_dropdown() {
            return this.claim_types_dropdown.filter(claimType => this.listingsClaimTypes.includes(claimType.value === -1 ? null : claimType.value));
        },
        filtered_claim_types_dropdown_new() {
        let claim_types = this.filteredListings.map(l => l.claim_type_id)
        claim_types = [...new Set(claim_types)]
        // console.log(claim_types = [...new Set(claim_types)])

        const mapped_types = claim_types.map(value => {
            switch (value) {
                case -1:
                    return {label:'No Claim Type', value: -1}
                case 1:
                    return {label: 'Copyright', value: 1}
                case 3:
                    return {label: 'Patent', value: 3}
                case 5:
                    return {label: 'Trademark (Standard)', value: 5}
                case 7:
                    return {label: 'Unknown', value: 8}
                case 9:
                    return {label: 'Unfair Competition', value: 9}
                case 10:
                    return {label: 'Trademark & Copyright', value: 10}
                case 11:
                    return {label: 'Patent and Trademark', value: 11}
                default:
                    return value
            }
        })

        return mapped_types
        },
        frozen_options() {
            // return [{
            //     label: "Paypal",
            //     value: "paypal"
            // },
            // {
            //     label: "Alipay",
            //     value: "alipay"
            // },
            // {
            //     label: "Amazon",
            //     value: "amazon"
            // },
            // {
            //     label: "Wish",
            //     value: "wish"
            // },
            // {
            //     label: "DHPay",
            //     value: "dhpay"
            // }]
            let platforms = this.import_platforms
            return platforms.map(platform => ({ label: platform.type, value: platform.type?.toLowerCase() ?? platform.type }))
        },
        filteredListings() {
            //Pre-filed listings page
            if (this.$route.params.status !== 'Cases') {
                return this.filteredListingsPrefiled
            }
            //Cases page
            else if (this.$route.params.status === 'Cases' && this.$route.params.case_id == '0') {
                // return this.listings;
                return this.filteredCases
            }
            // else if (this.$route.params.status === 'Cases' && this.$route.params.case_id !== '0') {
            //     if(this.filter_group) {
            //         if(this.filter_group !== 0 || this.filter_group !== null || this.filter_group !== '0') {
            //             return this.filteredListingsPostfiled.filter(listing => listing.group_name?.toLowerCase() == this.filter_group?.toLowerCase());
            //         }
            //         else return this.filteredListingsPostfiled
            //     }
            //     else return this.filteredListingsPostfiled;
            // }
            //Post-filed listings page
            else {
                // return this.filteredListingsPostfiled;

                return this.filter_group && this.filter_group != 0
                    ? this.filteredListingsPostfiled.filter(listing => listing.group_name?.toLowerCase() == this.filter_group?.toLowerCase())
                    : this.filteredListingsPostfiled
            }
        },
        filteredCases() {
            let result = [...this.listings]
            let sortBy = this.sort_by
            let orderBy = this.order_by

            //  Change the value of date_submitted from NULL - '0000-00-00'
            //  Only in Front-end
            result.map(r => {
                if (r.date_submitted == null) {
                    r.date_submitted = '0000-00-00'
                }
                return r
            })
            result = result.sort(function (a, b) {
                let valueGetter = {
                    'created_at': (listing) => listing.created_at,
                    'case_number': (listing) => listing.case_number,
                    'date_submitted': (listing) => listing.date_submitted,
                    'judge': (listing) => listing.Judge,
                    'case_status': (listing) => listing.case_status
                }

                let aValue = valueGetter[sortBy](a)
                let bValue = valueGetter[sortBy](b)

                if (orderBy === 'asc') {
                    return ((aValue < bValue) ? -1 : ((aValue > bValue) ? 1 : 0))
                }
                else {
                    return ((aValue < bValue) ? 1 : ((aValue > bValue) ? -1 : 0))
                }
            })
            return result
        },
        filteredListingsPostfiled() {
            if (!this.listings || this.listings.length === 0) {
                return [];
            }
            if (this.$route.params.status !== 'Cases' || this.$route.params.case_id == '0') {
                return [];
            }
            let result = [...this.listings];
            let searchBy = this.search_by;
            let searchInput = this.search_input;
            let sortBy = this.sort_by;
            let orderBy = this.order_by;
            let filters = [];
            result = result.sort(function (a, b) {
                let valueGetter = {
                    'doe_number': (listing) => listing.case_listing?.[0].doe_number,
                    's.name': (listing) => listing.seller?.[0]?.name?.toLowerCase().trim(),
                    'created_at': (listing) => listing.created_at,
                    'case_number': (listing) => listing.case_number,
                    'date_submitted': (listing) => listing.date_filed,
                    'judge': (listing) => listing.Judge,
                    'case_status': (listing) => listing.case_status
                };
                let aValue = valueGetter[sortBy](a);
                let bValue = valueGetter[sortBy](b);

                if (orderBy === 'asc') {
                    return ((aValue < bValue) ? -1 : ((aValue > bValue) ? 1 : 0));
                }
                else {
                    return ((aValue < bValue) ? 1 : ((aValue > bValue) ? -1 : 0));
                }
            });
            if (searchInput) {
                searchInput = searchInput.toLowerCase();
                let filterByBehavior = {
                    'email': listing =>
                        listing.case_seller_accounts?.find(account => account.name_email?.toLowerCase().includes(searchInput)) ||
                        listing.case_seller_info?.find(info => info.field === 'e_mail')?.value?.includes(searchInput),
                    'account_number': listing => listing.case_seller_accounts?.find(account => account.number?.toLowerCase().includes(searchInput)),
                    'doe_no': listing => {
                        return listing.case_listing?.[0]?.doe_number == searchInput
                    },
                    //search by defendant's name
                    '0': listing => listing.seller?.[0]?.name?.toLowerCase().includes(searchInput)
                };
                filters.push(listing => filterByBehavior[searchBy](listing));
            }
            if (+this.filter_group) {
                filters.push(listing => listing.group_name === this.filter_group);
            }
            if (this.filters.selected_lf_platforms?.length) {
                filters.push(listing => this.filters.selected_lf_platforms.includes(+listing.platform_id))
            }
            if (this.filters.selected_payment_methods?.length) {
                filters.push(listing => listing.case_seller_accounts?.find(account => this.filters.selected_payment_methods.includes(account.type)))
            }
            if (this.filters.selected_counsels?.length) {
                filters.push(listing => this.filters.selected_counsels.includes(listing.case_listing?.[0]?.counsel))
            }
            if (this.filters.selected_seller_flags?.length) {
                filters.push(listing => this.filters.selected_seller_flags.includes(listing.case_listing?.[0]?.case_flag))
            }
            return result.filter(listing => filters.every(func => func(listing)));
        },
        filteredListingsPrefiled() {
            let hasUsed_globalSearch = this.hasUsed_globalSearch
            let globalSearch_listings = this.globalSearch_listings
            if (!hasUsed_globalSearch) {
                if (!this.listings || this.listings.length === 0 || this.$route.params.status === 'Cases') {
                    return [];
                }
            }
            let result = hasUsed_globalSearch ? [...globalSearch_listings] : [...this.listings];
            let searchBy = this.search_by;
            let searchInput = this.search_input;
            let sortBy = this.sort_by;
            let orderBy = this.order_by;

            let valueGetter = {}
            if (this.customSorting == false) {
                result = result.sort(function (a, b) {
                    if (hasUsed_globalSearch) {
                        valueGetter = {
                            'seller_name': (listing) => listing.seller_name?.toLowerCase().trim(),
                            'platform': (listing) => listing.platform && listing.platform.toLowerCase().trim(),
                            'created_at': (listing) => listing.created_at
                        };
                    } else {
                        valueGetter = {
                            'seller_name': (listing) => listing.seller && listing.seller[0] && listing.seller[0].name && listing.seller[0].name.toLowerCase().trim(),
                            'platform': (listing) => listing.platform && listing.platform.toLowerCase().trim(),
                            'created_at': (listing) => listing.created_at
                        };
                    }

                    let aValue = valueGetter[sortBy](a);
                    let bValue = valueGetter[sortBy](b);

                    if (orderBy === 'asc') {
                        return ((aValue < bValue) ? -1 : ((aValue > bValue) ? 1 : 0));
                    }
                    else {
                        return ((aValue < bValue) ? 1 : ((aValue > bValue) ? -1 : 0));
                    }
                });
            }

            if (this.access_level == 6) {
                // Change payment type here
                // and add as another object "client_payment_type"
                // if(this.hasUsed_globalSearch){

                // }
                if (!this.hasUsed_globalSearch) {
                    result = result.map(listing => {
                        let type = listing.type
                        if (listing.type?.toString().toLowerCase().includes('paypal') && listing.type?.toString().toLowerCase().includes('no account')) {
                            type = 'Paypal'
                        }
                        if (listing.type?.toString().toLowerCase().includes('alibaba') && listing.type?.toString().toLowerCase().includes('no account')) {
                            type = 'Alipay'
                        }
                        if (listing.type?.toString().toLowerCase().includes('dhgate') && listing.type?.toString().toLowerCase().includes('no account')) {
                            type = 'DHPay'
                        }
                        return {
                            ...listing,
                            client_payment_type: type,
                        }
                    })
                }
            }

            if (searchInput) {
                if (searchBy === 'listing_id') {
                    result = result.filter(l => l.id == searchInput)
                }
                else {
                    searchInput = searchInput.toLowerCase();
                    if (!hasUsed_globalSearch) {
                        result = result.filter(l => l.seller && l.seller[0] && l.seller[0].name?.toLowerCase().includes(searchInput))
                    } else {
                        result = result.filter(l => l.seller_name?.toLowerCase().includes(searchInput))
                    }
                }
            }
            if (this.filters.selected_lf_platforms && this.filters.selected_lf_platforms.length) {
                result = result.filter(l => this.filters.selected_lf_platforms.includes(+l.platform_id));
            }
            if (this.filters.selected_payment_types && this.filters.selected_payment_types.length) {
                if (this.hasUsed_globalSearch) {
                    // console.log(result)
                    result = result.filter(l => l.seller_accounts.filter(sa => this.filters.selected_payment_types.includes([6].includes(this.access_level) ? sa.client_payment_type : sa.type)).length > 0);
                    // console.log(result.filter(l => l.seller_accounts.filter(sa => this.filters.selected_payment_types.includes([6].includes(this.access_level) ? sa.client_payment_type : sa.type)).length > 0))
                    // console.log(result.filter(l => l.seller_accounts.filter(sa => this.filters.selected_payment_types.includes([6].includes(this.access_level) ? sa.client_payment_type : sa.type))))
                } else {
                    result = result.filter(l => this.filters.selected_payment_types.includes([6].includes(this.access_level) ? l.client_payment_type : l.type));
                }
                // console.log(result)
            }
            return result;
        },
        paginatedListingsPostfiled() {
            return this.filteredListingsPostfiled
            // .slice( // Commented pagination not yet applicable to defendant listings - Marvin 14/06/2022
            //     (this.current_page - 1) * this.page_size,
            //     this.page_size * this.current_page
            // );
        },
        paginatedListingsPrefiled() {
            return this.filteredListingsPrefiled.slice(
                (this.current_page - 1) * this.page_size,
                this.page_size * this.current_page
            );
        },
        paginatedCases() {
            return this.filteredCases.slice(
                (this.current_page - 1) * this.page_size,
                this.page_size * this.current_page
            );
        },
        selected: function () {
            return this.selected_listings.length > 0
        },
        subject_action_bar: function () {
            if (this.$route.params.case_id != '0' && typeof this.$route.params.case_id !== 'undefined') {
                return 'defendants'
            } else if (this.$route.params.status == "Cases") {
                return 'Cases'
            }

            // return this.listings.length > 1 ? 'Listings' : 'Listing'

            // return this.$t('Listings');
            return this.$t('Data');
        },
        show_actions: function () {
            let isGodzillaUser = this.user?.cxids?.some(c => c.id === 723) ?? null
            if (this.$route.params.status == 'Has Potential') {
                let actions = [
                    'export',
                    'excel',
                    'vendor',
                    'word',
                    'forreview',
                    'monitoring',
                    'rejected',
                ]
                if (this.access_level != 6)
                    actions.push('accepted')
                if (this.access_level == 7) {
                    actions.push('group_listings')
                    // actions.push('haspo');
                    actions.push('recycled')
                }
                if (this.access_level == 7 && this.isCampaignGodzilla) {
                    actions.push(
                        'submitted',
                        'for-internal-review',
                        'initial-review-completed'
                    );
                }
                return actions
            }
            if (this.$route.params.status == 'For Review') {
                let actions = [
                    'export',
                    'excel',
                    'vendor',
                    'word',
                    'monitoring',
                    'rejected',
                    'accepted',
                    'initial-review-completed'
                ]
                if (isGodzillaUser === null && this.access_level != 7)
                    return actions

                // if (this.isCampaignGodzilla && this.access_level == 6) commented by Marvin, something about Godzilla Toho ticket request 07/28/2022
                //     actions = actions.filter(a => a != "accepted")

                if (this.access_level == 7) {
                    actions.push('for-internal-review')
                    actions.push('group_listings')
                    actions.push('haspo')
                    actions.push('recycled')
                }

                if ((this.access_level == 7 || isGodzillaUser) && this.isCampaignGodzilla) {
                    // actions.push('initial-review-completed');
                    if (this.access_level == 7) {
                        actions.push(
                            'submitted',
                            'haspo'
                        );
                    }
                }

                if (isGodzillaUser && this.isCampaignGodzilla)
                    actions = actions.filter(a => a != 'accepted')

                return actions
            }
            if (this.$route.params.status == 'Initial Review Completed') {
                let actions = [
                    'forreview',
                    'rejected',
                    'accepted',
                    'monitoring'
                ]
                if (this.isCampaignGodzilla && this.access_level == 6)
                    actions = actions.filter(a => a != "haspo" && a != "accepted")
                if (this.access_level == 7) {
                    actions.push('for-internal-review')
                    actions.push('group_listings')
                    actions.push('haspo')
                    actions.push('recycled')
                }
                if (this.isCampaignGodzilla && this.access_level == 7) {
                    actions.push(
                        'haspo',
                        'accepted',
                        'submitted'
                    );
                }
                return actions
            }
            if (this.$route.params.status == 'Monitoring') {
                let actions = [
                    'export',
                    'excel',
                    'vendor',
                    'word',
                    'rejected',
                    'accepted',
                    'initial-review-completed'
                ]
                if (isGodzillaUser == null)
                    return actions;

                if (this.access_level != 6)
                    actions.push('forreview')

                // if (this.isCampaignGodzilla && this.access_level == 6) commented by Marvin, something about Godzilla Toho ticket request 07/28/2022
                //     actions = actions.filter(a => a != "accepted")

                if ((this.access_level == 7 || isGodzillaUser) && this.isCampaignGodzilla) {
                    // actions.push('initial-review-completed');
                    if (this.access_level == 7) {
                        actions.push(
                            'for-internal-review',
                            'submitted',
                            'haspo'
                        );
                    }
                }
                if (this.access_level == 7)
                    actions.push(
                        'group_listings',
                        'haspo',
                        'recycled'
                    )

                return actions
            }
            if (this.$route.params.status == 'Accepted') {
                let actions = [
                    'export',
                    'spg',
                    'excel',
                    'vendor',
                    'word',
                    'schedulea',
                    'forreview',
                    'rejected',
                    'monitoring',
                    'submitted',
                ]
                if (this.access_level == 7)
                    actions.push(
                        'for-internal-review',
                        'group_listings',
                        'haspo',
                        'recycled'
                    )

                if (this.isCampaignGodzilla && this.access_level == 7)
                    actions.push(
                        'initial-review-completed',
                        'haspo'
                    );

                if (this.access_level == 6) {
                    // actions = actions.filter(a => !['spg', 'submitted', 'forreview', 'rejected', 'monitoring'].includes(a)) // requested that clients can tag forreview, rejected, and monitoring again - commented by Marvin 09/03/2023
                    actions = actions.filter(a => !['spg', 'submitted'].includes(a)) // <-- requested changes mentioned above

                    // Toho Co., Ltd user restrictions
                    if (this.user.id == 693 && this.isCampaignGodzilla) {
                        actions = actions.filter(a => !['rejected', 'monitoring'].includes(a))
                    }
                }

                return actions
            }
            if (this.$route.params.status == 'Rejected') {
                let actions = [
                    'export',
                    'excel',
                    'vendor',
                    'word',
                    'forreview',
                    'accepted',
                    'reassign',
                ]

                if (this.access_level == 6)
                    actions.push('monitoring')

                if (this.access_level == 7)
                    actions.push(
                        'group_listings',
                        'haspo',
                        'recycled'
                    )

                if (this.isCampaignGodzilla && this.access_level == 7)
                    actions.push(
                        'monitoring',
                        'for-internal-review',
                        'initial-review-completed',
                        'haspo',
                        'submitted'
                    );

                return actions
            }
            if (this.$route.params.status == 'Auto Qualified') {
                let actions = [
                    'export',
                    'excel',
                    'vendor',
                    'word',
                    'monitoring',
                    'rejected',
                    'accepted',
                ]
                if (this.access_level == 7)
                    actions.push(
                        'wip',
                        'group_listings',
                        'haspo',
                        'recycled'
                    )

                if (this.isCampaignGodzilla && this.access_level == 7)
                    actions.push(
                        'submitted',
                        'for-internal-review',
                        'initial-review-completed',
                        'haspo'
                    );

                return actions
            }
            if (this.$route.params.status == 'Cases' && this.$route.params.case_id == '0') {
                let actions = [
                    'frozen',
                    // 'excel',
                    // 'schedulea',
                    // 'vendor',
                    'removecase',
                ]
                if (this.access_level == 6) {
                    actions = actions.filter(a => !['removecase'].includes(a))
                }
                if (this.access_level == 7) actions.push('vendorCase')
                return actions
            }
            if (this.$route.params.status == 'Cases' && this.$route.params.case_id != '0') {
                let show = [
                    'export',
                    'settlementAgreement',
                    'groupSeller',
                    'excel',
                    'schedulea',
                    'vendor',
                    'removedefendants',
                ]
                if (this.access_level == 7)
                    show.push('moveData')
                return show
            }
            if (this.$route.params.status == 'Submitted') {
                let show = [
                    'export',
                    'spg',
                    'excel',
                    'vendor',
                    'word',
                    'schedulea',
                    'forreview',
                    'monitoring',
                    'accepted',
                    'rejected',
                ]

                if (this.access_level == 6) {
                    show = show.filter(s => !['accepted', 'rejected', 'monitoring', 'forreview', 'spg'].includes(s))
                }

                if ((this.access.case && this.$route.params.status == 'Submitted') || this.access_level == 7)
                    show.push(
                        'filecase',
                        'add-to-case'
                    )

                if (this.access_level == 7)
                    show.push(
                        'for-internal-review',
                        'group_listings',
                        'haspo',
                        'recycled'
                    )

                if (this.isCampaignGodzilla && this.access_level == 7)
                    show.push(
                        'initial-review-completed',
                        'haspo'
                    );

                return show
            }
            if (this.$route.params.status == 'For Internal Review') {
                let actions = [
                    'export',
                    'excel',
                    'forreview',
                    'accepted',
                    'rejected',
                    'submitted',
                    'reassign',
                    'group_listings',
                ]
                if (this.access_level == 7) {
                    actions.push('group_listings')
                    actions.push('haspo')
                    actions.push('word')
                    actions.push('recycled')
                    actions.push('vendor') // uncomment basta pangayoon na or if naa nay deliverable - Marvin Hehe :) 07/26/2023
                }

                if (this.isCampaignGodzilla && this.access_level == 7) {
                    actions.push(
                        'monitoring',
                        'initial-review-completed',
                        'haspo'
                    );
                }
                return actions
            }

            if (this.$route.params.status == 'Recycled') {
                let actions = [
                    'monitoring',
                    'forreview',
                    'for-internal-review',
                    'accepted',
                    'rejected',
                    'submitted',
                    'haspo',
                    'initial-review-completed',
                    'group_listings',
                    'export',
                    'word',
                    'excel',
                    'vendor'
                ]

                return actions
            }

            if (this.$route.params.status === 'Filed') {
                return ['rejected', 'submitted'];
            }

            return [];
        },
        access_level() {
            return JSON.parse(localStorage.getItem('lawfirm')).access_level
        },
        next_page() {
            var page = this.current_page
            if (this.current_page < this.last_page) page = +page + 1
            return page
        },
        prev_page() {
            var page = this.current_page
            if (this.current_page > 1) page = +page - 1
            return page
        },
        last_page() {
            return Math.ceil(this.filteredListings.length / this.page_size);
        },
        listing_from_count() {
            return (this.current_page > 1) ? ((this.current_page * this.page_size) - this.page_size) + 1 : this.listings.length > 0 ? 1 : 0
        },
        listing_to_count() {
            // this.$route.params.case_id != '0' ? this.listings.length : ((this.current_page * this.page_size) - this.page_size + this.lists_meta.items_in_this_page) || 0
            return this.$route.params.case_id != 0 && typeof this.$route.params.case_id !== 'undefined'
                ? this.filteredListings.length
                : Math.min(this.filteredListings.length, this.current_page * this.page_size)
        },
        more_filters_header() {
            return this.$route.params.status != 'Cases' ? 'More Filters' : 'Selected Filters'
        },
        lfPlatforms() {
            let filter_drawer = this.siblings_component.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
            return filter_drawer.lf_platforms.filter(platform => this.filters.selected_lf_platforms.includes(platform.value))
        },
        insideCase() {
            return this.filters.status === 'Cases' && (this.$route.params.case_id != '0' && typeof this.$route.params.case_id !== 'undefined')
        },
        canDelete() {
            for (let x in this.selected_listings) {
                if (!this.selected_listings[x].deletable) return false
            }
            return true
        },
        canUploadCaseFile() {
            return this.caseFileUploads.some(c => typeof c.file.name != 'undefined')
        },
        hasFileButNoDateGranted() {
            return !this.isDateGrantedRestricted && this.caseFileUploads.some(c => (c.file || c.link) && !c.date_granted)
        },
        sort_by_display() {
            let display = ""
            switch (this.sort_by) {
                case "platform":
                    display = "Platform"
                    break
                case "email":
                    display = "Email"
                    break
                case "account_number":
                    display = "Account Number"
                    break
                case "doe_number":
                    display = "Doe No."
                    break
                case "created_at":
                    display = "Date Added"
                    break
                case "case_number":
                    display = "Case Number"
                    break
                case "date_submitted":
                    display = "Date Submitted"
                    break
                case "judge":
                    display = "Judge"
                    break
                case "case_status":
                    display = "Case Status"
                    break
                default:
                    display = this.$route.params.case_id != '0' ? "Defendant Name" : "Seller Name"
                    break
            }
            return display
        },
        today() {
            return new Date().toISOString().split('T')[0]
        },
        env() {
            return process.env.VUE_APP_URL
        },
        table_th_tr_width() {
            const header = Object.keys(this.frozen_accounts[0])
                .filter(key => !this.non_displayable_keys.includes(key))
                .length + 3 //Additional 4 for action, import, error, and current amount
            return `${(97 / header)}%`
        },
        related_accounts_th_tr_width() {
            return 100 / (Object.values(this.related_frozen_account_columns).filter(column => { return !['0', 'exclude'].includes(column) }).length + 1) + '%'
        },
        listing_defendant_label() {
            return this.$route.params.case_id == '0' ? 'data' : 'defendants'
        },
        user() {
            return JSON.parse(localStorage.getItem('lawfirm'))
        },
        /**
         * @returns {string[]}
         */
        non_updatable_errors() {
            return [
                'Seller not found',
                'Account has no match in database',//For paypal (when importing paypal, account should already in DB)
                'An email having the same account already exists',
                'Seller has existing same Ebay account'
            ]
        },
        /**
         * @returns {string[]}
         */
        non_displayable_keys() {
            return [
                'doe_number',
                'action',
                'payment_type',
                'campaign_id',
                'seller_id',
                'listing_id',
                'seller_account_id',
                'amount_frozen',
                'prev_value',
                'from_csi',
                'account_not_found',
                'is_updatable',
                'error',
                'other_accounts'
            ]
        },
        /**
         * @returns {Array<object>}
         */
        frozen_accounts_with_errors() {
            return this.frozen_accounts.filter(({ error }) => !isEmpty(error))
        },
        /**
         * @returns {{label: string, value: string}[]}
         */
        frozen_payment_types() {
            // const platforms = ['Alipay', 'Amazon', 'DHGate', 'Wish', 'Paypal', 'Ebay', 'Joom']
            // const platforms = this.import_platforms
            const platforms = this.frozen_import_platforms
            return platforms.map(platform => ({ label: platform.type, value: platform.type }))
        },
        /**
         * @returns {{label: string, value: string}[]}
         */
        frozen_db_columns() {
            const columns = [
                { label: 'Doe Number', value: 'doe_number' },
                { label: 'Notes', value: 'notes' },
                { label: 'Location', value: 'location' },
                { label: 'Seller Name', value: 'seller_name' },
                { label: 'Seller ID or Merchant ID', value: '_seller_id' },
                { label: 'Email or Account Name', value: 'name_email' },
                { label: 'Account Number', value: 'number' },
                { label: 'Frozen USD Amount (Balance)', value: 'amount_frozen' },
                { label: 'Ebay ID', value: 'platform_account_id' },
                { label: 'Number of Items Sold', value: 'no_items_sold' },
                { label: 'Available Items', value: 'available_items' },
                { label: 'Gross Merchandise Volume', value: 'gross_merch_vol' },
                { label: 'Related Account Number', value: 'related_account_number' },
                { label: 'Amount Frozen CNY', value: 'amount_frozen_cny' },
                { label: 'Amount Updated Frozen USD', value: 'amount_updated_frozen' },
                { label: 'Amount Updated Frozen CNY', value: 'amount_updated_frozen_cny' },
            ].sort((a, b) => a.label.localeCompare(b.label))
            return [{ label: 'Exclude', value: 'exclude' }, ...columns]
        },
        /**
         * @returns {{label: string, value: string}[]}
         */
        frozen_update_actions() {
            return [{ 'label': 'Skip', 'value': 'skip' }, { 'label': 'Update', 'value': 'update' }]
        },
        /**
         * Check if frozen accounts has updatable and warning/error
         * that needs user confirmation
         * @return {boolean}
         */
        has_updatable_frozen_account() {
            return this.frozen_accounts.some((is_updatable, error) => is_updatable && error !== '')
        },
        filtered_templates() {
            return !this.templates.keywords ? this.templates.data : this.templates.data.filter(t => t.heading.toLowerCase().includes(this.templates.keywords.toLowerCase()) || t.sub_heading.toLowerCase().includes(this.templates.keywords.toLowerCase()))
        },
        settlement_selected_defendants() {
            return this.listings.filter(list => this.selected_listings.includes(list.id));
        },
        campaign_id() {
            return this.$route.params.campaign_id
        },
        qflag_status() {
            return this.$parent.getQFlag(this.already_worked_lids[0]?.qflag) || 0
        },
        allow_settlement_generate() {
            return !this.settlement_selected_defendants.map(defendant => defendant.seller_account_id).includes(null)
        },
        canMove() {
            return this.move[this.move.selected].value != 0;
        },
        templatesGenerateData() {
            return this.templates.generate.sa_setter.status ? this.templates.generate.sa_setter.data : this.templates.generate.rd_setter.data
        },
        exportOptions() {
            let options = [{
                id: 1,
                label: "Export to Excel",
                icon: "/static/svg/Action_Excel.svg",
                condition: this.show_actions.includes('excel') && (!this.access.readonly || [7].includes(this.access_level)),
            },
            {
                id: 2,
                label: "Export to Word",
                icon: "/static/svg/Action_Word.svg",
                condition: this.show_actions.includes('word') && (!this.access.readonly || [7].includes(this.access_level)),
            },
            {
                id: 3,
                label: "Export Schedule A",
                icon: "/static/svg/Action_ScheduleA.svg",
                condition: this.show_actions.includes('schedulea'),
            },
            {
                id: 4,
                label: "Export Vendor Data",
                icon: "/static/svg/Action_Vendor.svg",
                condition: this.show_actions.includes('vendor'),
            }
            ]

            if (this.$featureAccess.isAllowed('cm-button-export-vendor-data-seller-status-history')) {
                options.push({
                    id: 5,
                    label: "Export Vendor Data (Seller Status History)",
                    icon: "/static/svg/Action_VendorHistory.svg",
                    condition: this.show_actions.includes('vendorHistory')
                });
            }

            if (this.access_level == 7) {
                options.push({
                    id: 6,
                    label: "Export Submission Pack",
                    icon: "/static/svg/export/Export_Submission_Modal.svg",
                    condition: this.show_actions.includes('spg'),
                })
            }
            return options
        },
        dragOptions() {
            return {
                animation: 200,
                group: "spg",
                disabled: false,
                ghostClass: "ghost"
            };
        },
        spg_desc() {
            return this.spg.tab ? 'Drag and Drop Platforms to sort export format' : 'Schedule A default arrangement'
        },
        item_type_options_display() {
            if (this.filter_item_class == 0 || this.filter_item_class.length == 0) {
                return this.filtered_item_types
                // return this.item_type_options
            }
            return this.item_type_options.filter(type => this.filter_item_class.some(itemClass => itemClass.value === type.class));
        },
        groupFilterLabel() {
            return this.group_names.length == 0 ? "No Group found on this case"
                : !this.filter_group || this.filter_group == 0 ? "Group Name - All"
                    : `Group Name - ${this.group_names.filter(item => item.value == this.filter_group)[0].label}`
        },
        showExportFrozenAccounts() {
            return ([3, 6, '3', '6'].includes(this.access_level) && (this.access.amounts || this.access.defaults) && !this.access.readonly) || this.access_level == 7
        },
        hasEbayListing() {
            return this.listings.some(l => l.platform_id == 16)
        },
        isCampaignRightsHolderZuru() {
            return [313, 319, 375, 377, 711].includes(this.daFilterDrawer?.currentCampaign.rightsholder_id ?? 0)
        },
        isCampaignGodzilla() {
            return this.$route.params.campaign_id == 723
        },
        customSorting() {
            let custom = false
            let sort = this.customPrioritySort
            let route = this.$route.params.status
            if( sort == true) {
                custom = true
            }
            else {
                custom = false
            }
            return custom
        },
        groupNameUpdateConfirmComputed() {
            let confirm = this.groupNameUpdateConfirm
            let name = this.selected_group_name
            if (name == ''){
                confirm = false
            }
            else {
                confirm = true
            }
            return confirm
        },
        filtered_groups() {
            let groups = this.groups_new_copy
            let campaign_id = this.campaign_id
            if (this.search) {
                let search = this.search.toLowerCase();
                return groups.filter(g => {
                    return g.label.toLowerCase().includes(search);
                }).filter(ng => {
                    return ng.campaign_id == campaign_id
            }).sort((a, b) => {
                    return (a.label).localeCompare(b.label)
                });
            }
            return groups.filter(ng => {
                    return ng.campaign_id == campaign_id
                }).sort((a, b) => {
                    return (a.label).localeCompare(b.label)
            });
        },
        filtered_new_groups() {
            let new_groups = this.groups_new_copy
            let campaign_id = this.campaign_id
            return new_groups.filter(ng => {
                return ng.campaign_id == campaign_id
            }).sort((a, b) => {
                    return (a.label).localeCompare(b.label)
            });
        },
        filtered_group_listings() {
            if (this.$route.path.includes('/Cases/')) {
                return [];
            }
            let listings = this.listings;

            let group = this.selected_group?.value
            if (this.groupListingSearch) {
                let search = this.groupListingSearch.toLowerCase();
                return listings.filter(gl => {
                    return gl.seller[0]?.name?.toLowerCase().includes(search) || 
                            gl.id?.toString().toLowerCase().includes(search);
                }).filter(l => l.group_listing_id?.pre_filing_group_id == group)
            }
            return listings.filter(l => {
                return l.group_listing_id?.pre_filing_group_id == group
            })
        },
        sellerSelectionBtn() {
            let select = this.selectAllSellers
            let sellers1 = this.filtered_group_listings.length
            let sellers2 = this.listingsToTransfer.length
            if (sellers1 == sellers2) {
                select = true
            }
            else {
                select = false
            }
            return select
        },
        seller_ids() {
            let sellers1 = this.filtered_group_listings
            let sellers2 = this.listingsToTransfer.length
            if (sellers1.length !== sellers2) {
                return sellers1 = sellers1.map((s => {
                    return s.id
                }))
            }
            else {
                return []
            }
        },
        groupSelectionBtn() {
            let select = this.selectAllGroups
            let groups1 = this.filtered_groups.length
            let groups2 = this.multipleGroupDelete.length
            if (groups1 == groups2) {
                select = true
            }
            else {
                select = false
            }
            return select
        },
        group_ids() {
            let group1 = this.filtered_groups
            let group2 = this.multipleGroupDelete.length
            if (group1.length !== group2) {
                return group1 = group1.map((g => {
                    return g.value
                }))
            }
            else {
                return []
            }
        },
        filtered_item_types() {
            let type = this.new_item_types
            let type2 = type.map(s => {
                return {
                    label: s.listing_info?.item_type,
                    value: s.listing_info?.item_type
                }
            }).reduce((h, g) => {
                h[g.label] = g
                return h
            }, {})
            return Object.values(type2).sort((a,b) => a.label?.localeCompare(b.label))
        },
        item_type_copy(){
            let type1 = this.item_type_options
            let type2 = this.filtered_item_types
            const type3 = type1.filter((t1) => {
                return type2.some((t2) => {
                    return t2.label == t1.label
                })
            })
            return type3
        },
        filtered_item_class() {
            let class1 = this.item_class
            let class2 = this.item_type_copy
            const class3 = class1.filter((c1) => {
                return class2.some((c2) => {
                    return c2.cid == c1.id
                })
            })
            return class3
        },
        qFlagID() {
            let qflag = this.$route.params.status
            let qflag_id = 0

            if (qflag == 'Has Potential') {
                qflag_id = 98
            }
            else if (qflag == 'For Review') {
                qflag_id = 3
            }
            else if (qflag == 'Monitoring') {
                qflag_id = 31
            }
            else if (qflag == 'Accepted') {
                qflag_id = 32
            }
            else if (qflag == 'Submitted') {
                qflag_id = 35
            }
            else if (qflag == 'Rejected') {
                qflag_id = 33
            }
            else if (qflag == 'For Internal Review') {
                qflag_id = 36
            }
            else if (qflag == 'Recycled') {
                qflag_id = 38
            }
            else if (qflag == 'Filed') {
                qflag_id = 34
            }
            return qflag_id
        },
        itemClass_options() {
            return this.itemClass_withItemTypesData.map(ic => {
                return {
                    label: ic.class,
                    value: ic.id,
                }
            })
        },
        filtered_itemClass_withItemTypesData() {
            let itemClass_withItemTypesData = this.itemClass_withItemTypesData
            let order_by = this.item_class_order_by

            if(this.item_type != 0 && this.item_type > 0) {
                itemClass_withItemTypesData = itemClass_withItemTypesData.filter(ic => ic.id == this.item_type)
            }

            // remove class with no item types
            itemClass_withItemTypesData = itemClass_withItemTypesData.filter(ic => ic.item_types.length > 0)

            // count_toggle = true -> Item Types
            // count_toggle = false -> LID
            if(order_by == 'desc') {
                itemClass_withItemTypesData.sort((a,b) => b.id.toString().localeCompare(a.id, undefined, { numeric: true }))
                if(this.count_toggle) {
                    itemClass_withItemTypesData.sort((a,b) => b.item_types.length.toString().localeCompare(a.item_types.length, undefined, { numeric: true }))
                } else {
                    itemClass_withItemTypesData.sort((a,b) => b.totalLID_underThisClass.toString().localeCompare(a.totalLID_underThisClass, undefined, { numeric: true }))
                }
            } else {
                if(this.count_toggle) {
                    itemClass_withItemTypesData.sort((a,b) => a.item_types.length.toString().localeCompare(b.item_types.length, undefined, { numeric: true }))
                } else {
                    itemClass_withItemTypesData.sort((a,b) => a.totalLID_underThisClass.toString().localeCompare(b.totalLID_underThisClass, undefined, { numeric: true }))
                }
            }

            return itemClass_withItemTypesData
        },
        showBulkUpdateDoeOnly() {
            const showExpandableImportButtons = !this.isFeatureRestricted('cm-button-case-page-expandable-import-buttons');

            return !showExpandableImportButtons;
        },
        /* showDefaultedFigures() {
            return !this.isFeatureRestricted('cm-text-defaulted-figures');
        }, */
        /* showNonDefaultedFigures() {
            return !this.isFeatureRestricted('cm-text-non-defaulted-figures');
        }, */
        showViewUploadFilesButton() {
            return !this.isFeatureRestricted('cm-button-view-uploaded-sa-files');
        },
        restrictedFeatures() {
            return useFeatureAccessStore().restrictedFeatures;
        },
        isInCasesPage() {
            const { status, case_id } = this.$route.params;
            return status === 'Cases' && +case_id === 0;
        },
        isInDefendantsPage() {
            const { status, case_id } = this.$route.params;
            return status === 'Cases' && +case_id !== 0;
        },
        currentTab() {
            return this.$route.params.status;
        },
        isInMonitoringTab() {
            return this.currentTab === 'Monitoring';
        },
        isInHasPotentialTab() {
            return this.currentTab === 'Has Potential';
        },
        isInForReviewTab() {
            return this.currentTab === 'For Review';
        },
        isInInitialReviewCompletedTab() {
            return this.currentTab === 'Initial Review Completed';
        },
        isInAcceptedTab() {
            return this.currentTab === 'Accepted';
        },
        isInSubmittedTab() {
            return this.currentTab === 'Submitted';
        },
        isInRejectedTab() {
            return this.currentTab === 'Rejected';
        },
        isInForInternalReviewTab() {
            return this.currentTab === 'For Internal Review';
        },
        isInRecycledTab() {
            return this.currentTab === 'Recycled';
        },
        isInFiledTab() {
            return this.currentTab === 'Filed';
        },
        willBeAcceptedAutoSorted() {
            return this.user?.access_level == 7 && (this.isInMonitoringTab || this.isInInitialReviewCompletedTab || this.isInForReviewTab)
        },
        markAsAcceptedModalSubheading() {
            /** @type { 'en' | 'ja'} */
            const locale = this.$i18n.locale;
            const selectedListings = this.selected_listings;

            if (locale === 'ja') {
                return selectedListings.length + this.$t(`You are about to tag listings for accepted. Confirm by clicking on the 'Proceed' button.`);
            }

            return `You are about to tag ${selectedListings.length} ${selectedListings.length > 1 ? 'listings' : 'listing'} for accepted. Confirm by clicking on the 'Proceed' button.`;
        },
        translatedPredefinedReasons () {
            /** @type { 'en' | 'ja' } */
            const locale = this.$i18n.locale;
            const predefinedReasons = this.predefinedReasons;

            if (locale === 'en') {
                return predefinedReasons;
            }

            const translatedReasons = predefinedReasons.map(item => ({ ...item, reason: this.$t(item.reason) }));
            return translatedReasons;
        },
        changeStatusRestrictionName() {
            const changeStatusRestriction = {
                'has potential'           : 'cm-status-change-in-has-potential',
                'for review'              : 'cm-status-change-in-for-review',
                'initial review completed': 'cm-status-change-in-initial-review-completed',
                'for internal review'     : 'cm-status-change-in-for-internal-review',
                monitoring                : 'cm-status-change-in-monitoring',
                accepted                  : 'cm-status-change-in-accepted',
                submitted                 : 'cm-status-change-in-submitted',
                rejected                  : 'cm-status-change-in-rejected',
                recycled                  : 'cm-status-change-in-recycled',
                filed                     : 'cm-status-change-in-filed'
            };

            const status = this.$route.params.status.toLocaleLowerCase();

            return changeStatusRestriction[status];
        },
        canStatusChange() {
            return !this.isFeatureRestricted(this.changeStatusRestrictionName);
        },
        slicedPlatformData() {
            if (this.modifyPlatformBreakdown) {
                return this.savePlatformDisplayCounts()
            } else {
                // return this.lf_platformx_vuex2
                return this.platform_data_breakdown
            }
        },
        computedLFPlatforms() {
            return this.lf_platforms_vuex2
        },
        filteredCaseSellerAccount() {
            const lfPlatforms = this.computedLFPlatforms;
            const caseSellerAccount = this.case_seller_accounts;

            if (!lfPlatforms || !caseSellerAccount) return [];
            
            const lfSellerNames = Object.values(lfPlatforms).flatMap(platform => platform.map(item => item.seller_name));

            return Object.fromEntries(
            Object.entries(caseSellerAccount).map(([platform, sellers]) => [
                platform,
                sellers.filter(seller => lfSellerNames.includes(seller.seller_name))
            ])
            );
        },
        mappedIndividualListingValues() {
            if (!this.listing || Object.keys(this.listing).length === 0) {
                return []; // or return null if you prefer
            }
            else {
                let listing = [this.listing].map(list => ({
                    id: list.id,
                    seller_name: (list.seller && list.seller[0] ? list.seller[0].name : undefined) ?? null,
                    seller_url: (list.seller && list.seller[0] ? list.seller[0].url : undefined) ?? null,
                    platform: list.platform ?? null,
                    seller_contactable: (list.seller && list.seller[0] ? list.seller[0].contactable : undefined) ?? null,
                    ship_to_us: 1,
                    listing_url: list.url ?? null,
                    listing_title: list.listing_info?.listing_title ?? null,
                    infringement_reason: list.listing_info?.reasons ?? null,
                    keyword: list.keyword ?? null,
                    item_type: list.listing_info?.item_type_id ?? null,
                    claim_type: list.claim_type_id ?? null,
                    dummy_account: list.listing_info.dummy_account ?? null,
                    item_location: list.listing_info.listing_location ?? null,
                    payment_account: (list.seller && list.seller[0] ? list.seller[0].seller_accounts : undefined) ?? null,
                    initial_price: list.listing_info.listing_price ?? null,
                    currency: list.listing_info.currency ?? null,
                    logo_on_item: list.listing_info.logo_on_item_image ?? null,
                    brand_on_item: list.listing_info.brand_on_item_image ?? null,
                    purchase_type: list.listing_info.purchase_type ?? null,
                    payment_platform: list.listing_info.payment_platform_id ?? null,
                    transaction_number: list.listing_info.transaction_number ?? null,
                    purchase_price: list.listing_info.purchase_price ?? null,
                    date_captured: list.listing_info.date_captured ?? null,
                    is_merged: list.evidences["full merge"] ?? null,
                    shipping_location: list.listing_info.shipping_location ?? null
                }));

                return listing;
            }
        },
        mappedSelectedListingsValues() {
            let listings = this.selectedListingsValues.map(list => ({
                id: list.id,
                seller_name: list.seller[0]?.name ?? null,
                seller_url: list.seller[0]?.url ?? null,
                platform: list.platform ?? null,
                seller_contactable: list.seller[0]?.seller_contactable ?? null,
                ship_to_us: list.ship_to_us ?? null,
                listing_url: list.url ?? null,
                listing_title: list.listing_info?.listing_title ?? null,
                infringement_reason: list.infringement_reason ?? null,
                keyword: list.keyword ?? null,
                item_type: list.listing_info?.item_type ?? null,
                claim_type: list.claim_type_id ?? null,
                dummy_account: list.dummy_account ?? null,
                item_location: list.item_location ?? null,
                payment_account: list.main_account ?? null,
                initial_price: list.initial_price ?? null,
                currency: list.currency ?? null,
                logo_on_item: list.logo_on_item ?? null,
                brand_on_item: list.brand_on_item ?? null,
                purchase_type: list.purchase_type ?? null,
                payment_platform: list.payment_platform ?? null,
                transaction_number: list.transaction_number ?? null,
                purchase_price: list.purchase_price ?? null,
                date_captured: list.date_captured ?? null,
                is_merged: list.mergedEvidences ?? null,
                shipping_location: list.shipping_location ?? null
            }))
            return listings ?? null
         }
    },
    data() {
        return {
            areMainAccountsOnly: false,
            capitalize,
            isEmpty,
            duplicateListingModalOnContinue: () => ({}),
            duplicateListingModalProceedAnyway: () => ({}),
            is_uploading_doe: false,
            prepare_add_to_case: false,
            case_seller_accounts: {},
            selected_payment_method: '0',
            modalLoading: true,
            search_input: '',
            search_by: '0',
            account_name: '',
            account_index: 0,
            amount_frozen: 0,
            amount_updated_frozen: 0,
            amount_frozen_cny: 0,
            amount_updated_frozen_cny: 0,
            amount_released: 0,
            amount_settled: 0,
            fit_number: 0,
            notes: '',
            location: '',
            received_amount_settled: 0,
            default_amount_received: 0,
            default_ctda_amount_received: 0,
            seller_flag: '',
            searched_lid: this.$route.params.lid ? this.$route.params.lid : "",
            seller_flags: [],
            // campaigns: this.p_campaigns,
            campaigns: [],
            filtered_platforms: [],
            groups: [],
            filter_group: null,
            filtered_status: "",
            filtered_listing_id: [],
            filtered_listing_id_input: null,
            can_status: false,
            change_status: false,
            qa: false,
            sidebar_campaigns: [],
            platforms: [],
            lists: [],
            lists_meta: {},
            lists_links: {},
            options: [{
                label: "Sort by Platform",
                value: "platform"
            }],
            campaign_data: {
                img: "/static/svg/Campaign Default Photo.svg",
                name: ""
            },
            search_options: [{
                label: "Search by Listing ID",
                value: "listing_id"
            }],
            status_options: [{
                label: 'Submitted',
                value: '35'
            }, {
                label: 'Accepted',
                value: '32'
            }, {
                label: 'For Review',
                value: '3'
            }, {
                label: 'For Monitoring',
                value: '31'
            }, {
                label: 'Has Potential',
                value: '98'
            }, {
                label: 'Rejected',
                value: '33'
            }],
            // {
            //     label: "Search by Email",
            //     value: "email"
            // },
            // {
            //     label: "Search by Item Location",
            //     value: "item_location"
            // }
            case_search_options: [{
                label: "Search by Email",
                value: "email"
            },
            {
                label: "Search by Account Number",
                value: "account_number"
            },
            {
                label: "Search by Doe No.",
                value: "doe_no"
            }],
            researcher_name_options: [{
                label: "Sample Researcher",
                value: "Sample Researcher"
            }],
            listing_info: [],
            loaded: false,
            listings: [],
            listingsClaimTypes: [],
            listing: '',
            index: '',
            headers: {},
            statuses: '',
            selected_listing: null,//current active listing
            selected_listings: [],
            whitelistedListings: [],
            cases: [],
            case_name: '',
            payment_methods: [],
            page_size: 100,
            current_page: 1,
            order_by: "asc",
            order_by_option: [{
                label: "Ascending",
                value: "asc"
            },
            {
                label: "Descending",
                value: "desc"
            }],
            order_by_option_frozen: [{
                label: "Descending",
                value: "desc"
            }],
            has_default_option_frozen: [{
                label: "No",
                value: 1
            }],
            has_default_frozen: 0,
            // sort_by: "seller_name",
            sort_by: "created_at",
            sort_by_option: [{
                label: "Date Added",
                value: "created_at"
            }, {
                label: "Platform",
                value: "platform"
            }, {
                label: "Seller Name",
                value: "seller_name"
            }],
            select_all_text: "Select",
            is_select_all: true,
            export_token: "",
            selected_reassign_user: 0,
            reassign_users: [],
            batch_comment: "",
            show_reassign_users: true,
            first_load: false,
            show_available_payment_accounts: true,
            siblings_component: this.$parent.$children,
            rejected_reasons: [],
            case_status_options: [],
            frozen_type: "0",
            frozen_order: "asc",
            doe_generated: true,
            file_data: '',
            doe_updates: {},
            doe_updates_meta: {},
            selected_reasons: [],
            searching: false,
            lid_status: "",
            case_files: [],
            upload_file_types: [],
            upload_file_type_select: [],
            selected_upload_file_type: "",
            upload_case_filename: "",
            current_show_file_case_id: 0,
            possible_errors: [
                "*No doe starting with 1 has been found",
                "*A number is skipped or a doe number is not greater than one to its preceding",
                "*A doe number is not numeric",
                "*A doe number is a negative value",
                "*Duplicate doe number has been found"
            ],
            file_case_uploaded: true,
            case_files_loaded: false,
            sa_files_loaded: false,
            delete_file_data: { id: "", name: "" },
            filter_upload_type: "0",
            filter_sa_type: 0,
            filter_upload_type_select: [],
            filtered_case_files: [],
            isInitialLoadOfCaseFiles: true,
            saUploads: [],
            loadingSAUploads: false,
            fileUploadsSA: [
                {
                    id: 1,
                    label: 'Signed Settlement',
                    value: 'Signed Settlement Agreement'
                },
                {
                    id: 2,
                    label: 'Unsigned Settlement',
                    value: 'Unsigned Settlement Agreement'
                }
            ],
            expanded: false,
            min_date_added: "",
            max_date_added: "",
            min_date_qualified: "",
            max_date_qualified: "",
            selected_payment_methods: [],
            reasons: [],
            selected_infringement_reasons: [],
            infringement_option: "1",
            filter_item_location: "",
            filter_seller_location: "0",
            seller_locations: [],
            seller_lid_count: "",
            filter_account_name_email: "",
            generic_email: "",
            filter_alias: "", // Newly added filter feature ALIAS - 7/21/22
            filter_account_number: "",
            filter_item_type: [],
            filter_item_class: [],
            filter_claim_type: "0",
            filter_predefined_reasons: {
                data: [],
                selected: {
                    main: [],
                    sub: []
                }
            },
            filters__: {
                item_location: {
                    options: [],
                    selected: 0,
                    search: ''
                }
            },
            item_type_options: [],
            item_class_options: [],
            applied_filters_count: 0,
            fab_expand: false,
            case_accounting_details: {},
            //Group Sellers Feature Data
            input_group_name: "",
            group_names: [],
            found_sellers_with_group: [],
            is_overwrite_seller_group: false,
            is_proceeding: false,
            is_adding_group: false,
            group_description: "",
            //Accounting Frozen Account
            file_name: "",
            //Frozen Account V2
            frozen_payment_type: "",
            frozen_accounts: [],//fai
            frozen_columns: [],
            frozen_account_columns: {},//For source and associate_columns
            related_frozen_accounts: [],
            related_frozen_columns: [],
            related_frozen_account_columns: {},//For source and associate_columns
            is_frozen_loading: false,
            frozen_log: "",
            platform_data_breakdown: {},
            selected_platform_data_breakdown: "0",
            show_platform_data_breakdown: true,
            frozen_log_already_download: false,
            frozen_source_file: '',
            frozen_success_update: false,
            update_all_frozen_accounts: false,
            is_proceed_to_related_accounts: false,
            is_related_frozen_accounts_verified: true,
            show_related_accounts_error: false,
            update_frozen_accounts: true,
            //Release Account v2,
            release_accounts: [],
            is_release_loading: false,
            is_release_generating: false,
            overwrite_release_account: false,
            release_has_prev_value: false,
            status_changing: false,
            claim_types_dropdown: [],
            is_frozen_export_generating: false,
            reset_release: false,
            templates: {
                data: [],
                keywords: "",
                searching: false,
                current: {},
                activeIndex: null,
                confirmRemove: false,
                action: "template",
                upload: {
                    file_name: "",
                    payment_type: "",
                    valid: true
                },
                generate: {
                    file_name: "",
                    error: false,
                    error_message: "",
                    done: null,
                    download_link: "",
                    sa_setter: {
                        status: false,
                        data: [],
                        value: ""
                    },
                    rd_setter: {
                        status: false,
                        data: [],
                        value: ""
                    }
                }
            },
            frozen_required_message: '',
            account_initial: "",
            confirm_update: {
                header: "",
                sub: "",
                status: null
            },
            access: {
                amounts: false,
                defaults: false,
                amountsDefaults: false,
                all: false,
                readonly: false,
                accounting: false
            },
            active_settlement_edit: {
                index: -1,
                value: ""
            },
            is_loading: false,
            already_worked_lids: '',
            move: {
                selected: 'status',
                status: {
                    value: 0,
                    options: []
                },
                case: {
                    value: 0,
                    options: []
                }
            },
            detected_whitelist: {},
            spg: {
                tab: 0,
                show_similar: false,
                pack_info: {
                    package: {
                        name: "",
                        description: ""
                    }
                },
                payment_type: {
                    selected: []
                },
                listings: {
                    copy: [],
                    selected: []
                },
                files: {
                    vendorDataSheet: true,
                    scheduleA: true,
                    evidences: true,
                    exhibitA: true
                }
            },
            whitelisted_listings: [],
            duplicate_seller: [],
            duplicate_listings: [],
            listings_to_be_filed: '',
            isCheckingPotentials: false,
            onModalClose: () => ({}),
            acknowledgeWhitelists: () => ({}),
            hasAcknowledged: false,
            filter_submissions_pack: {
                options: [],
                selected: null
            },
            allPredefinedReasons: {},
            predefinedReasons: [],
            fetchingReasons: false,
            filterDateCaptured: '',
            isFileCaseMode: false,
            caseFileUploads: [],
            caseFileUploads_copy: [],
            vendor_data: {
                reasons: false,
                evidences: false
            },
            listingsToBeAccepted: [],
            currentFrozenBreakdown: {
                loading: false,
                data: []
            },
            import_platforms: [],
            frozen_import_platforms: [],
            daFilterDrawer: {},
            includeEbayAccountName: true,
            isColumnSelection: false,
            importExportLoading: {
                caseExportFrozen: false,
                casePlatforms: false
            },
            filter_email: '',
            selectedAgreementFile: null,
            fileNameUpdate: null,
            selectedFileType: 'select',
            fileNameSelected: null,
            fileList: [],
            agreements: [],
            agreementIsUploading: false,
            listing_id_current: null,
            confirmDeletion: false,
            isUpdatingAgreement: false,
            // filed_listings: [],
            hasFiled_listings: false,
            selected_seller_name : '',
            customPrioritySort: true,
            selected_group: null,
            new_group_mode: false,
            is_available: null,
            new_group_name: null,
            new_group_description: null,
            processing: false,
            search: null,
            remove_listing: null,
            threads: {
                active: 0,
                limit: 50,
                errors: 0,
                functions: []
            },
            selected_campaign_group: -1,
            campaign_group_isSelected: false,
            update_group_name_btn: "default",
            update_group_name_enabled: false,
            selected_group_name: '',
            selected_group_name_copy: '',
            selectedListingsToEdit: [],
            confirmDeleteListing: false,
            selectedListingIndividual: -1,
            listingsToTransfer: [],
            group_listings_clicked: false,
            newGroupListingTransfer: '',
            groupListingSearch: '',
            groups_copy: [],
            groups_new_copy: [],
            selectedListingToDelete: null,
            groupNameUpdateConfirm: false,
            groupHover: false,
            selectAllSellers: false,
            selectAllGroups: false,
            multipleGroupDelete: [],
            targetChangeStatus: null,
            getQFlag,
            ucWords,
            item_class: [],
            new_item_types: [],
            // v-model for item class/type aggregator
            item_type: 0,
            selected_ItemClass: -1,
            itemClass_withItemTypesData: [],
            show_itemClasses: false,
            item_class_order_by: 'asc',
            count_toggle: false,
            isRequesting_ItemTypeItemClass: false,
            listings_meta: {},
            clrSuccessMessage: "",
            settlementPaymentCount: 0,
            getDataFor_AdditionalFilters: false,
            isDownloading: false,
            platform_prioritization: {
                data: []
            },
            frozenAmountExportFormats: [
                { value: 'kav',     label: 'KAV Format' },
                { value: 'hsprd',   label: 'HSPRD Format' }
            ],
            frozenAmountExportFormat: 'default',
            selected_status_string: '',
            platformDisplayCounts: {},
            modifyPlatformBreakdown: false,
            listingCopy: [],
            word_export: { exclude: false },
            emptyListingInfo: false,
            listingsWithEmptyField: [],
            current__case: {
                id: null,
                files: []
            },
            caseFileDateGranted: {
                adding: false,
                cancelled: false,
                data: "",
                temp: "",
                label: ""
            },
            isUploadingCaseFiles: false,
            isIndividualChecking: false,
            isMultipleChecking: false,
        }
    },
    methods: {
        ...mapActions(['showSidebar', 'showNavbar', "showFilterDrawer", 'greyBg', 'checkFeatureAccess', "refreshCancelToken", "cancelRequests", 'setRelatedCampaignDuplicates', 'setRelatedCampaignDuplicateBypass', 'setRelatedCampaignDuplicateContinueFunction']),
        showDefendantModal() {
            $('#listing-modal').modal('show')
        },
        showTransferSubStatusModal(option) {
            this.subStatusesStore.currentSelectedSubStatus = option.id; 
            this.subStatusesStore.currentSelectedSubStatusLabel = option.label

            this.getPredefinedReasons(this.$parent.getQFlagId(this.currentTab))
        },
        async showCaseFiles(id, files) {
            $('#case-show-case-files-modal').modal('show')

            this.current__case.id = id
            this.current__case.files = files
        },
        async updateListingSubStatus() {
            this.status_changing = true
            
            if (!['Accepted', 'Initial Review Completed'].includes(this.$route.params.status)) {
                this.status_changing = false
                const hasNoReasons = this.$store.state.checked_reasons.length === 0
                    && this.$store.state.checked_subreasons.length === 0
                    && isEmpty(this.$store.state.checked_other_reason)
                if (hasNoReasons)
                    return this.$parent.showSnackbar('Please select reason(s)', false, 'invalid', 'fas fa-times-circle')
                if (!isEmpty(this.$store.state.checked_other_reason)) {
                    if (isEmpty(this.$store.state.other_reason_comment))
                        return this.$parent.showSnackbar('Please provide comment', false, 'invalid', 'fas fa-times-circle')
                }
            }

            try {
                const config = this.$session.get('lawfirm_headers')
                config.headers["Allow-Checking-Bypass"] = 1

                const data = {
                    target_substatus_id: this.subStatusesStore.currentSelectedSubStatus ?? -1,
                    listing_ids: this.selected_listings.join(','),
                    comment: '',
                    reasons: this.setPredefinedReasons(),
                    is_same_status: true
                }

                await this.$http.patch(`/listings/multiple/statuschange/${this.$parent.getQFlagId(this.$route.params.status)}`, data, config)

                this.showSnackbar(`Successfully transferred listing to new substatus.`, false, 'success', 'fas fa-check')

                this.is_select_all = false
                this.selectAll()
                this.getListings(false)
                this.refreshCM_statuses()

                $('.modal').modal('hide')
            } catch (e) {
                console.log(e)
                this.showSnackbar('Something went wrong. Please contact system admin.', false, 'success', 'fas fa-check-circle')
            }

            this.status_changing = false
        },
        async getItemLocations() {
            try {
                const headers = this.$session.get('lawfirm_headers')

                if (this.subStatusesStore.canSeeSubStatuses) {
                    const currentSubStatus = this.$route.query.substatus_id?.toString()?.split('_')?.[0] ?? this.$route.query.substatus_id
                    if (currentSubStatus && currentSubStatus != 0 && currentSubStatus != -1 && currentSubStatus != "undefined")
                        headers.headers['Substatus-id'] = currentSubStatus
                }

                const { data } = await this.$http.get(`/api/listing-locations/campaigns/${this.$route.params.campaign_id}/status/${this.$parent.getQFlagId(this.$route.params.status)}`, headers)

                let result = data.data.map(d => ({ label: `${d.value} [${d.count}]`, value: d.value, count: d.count }))
                result.sort((a, b) => (a.label > b.label)? 1 : ((b.label > a.label)? -1 : 0))
                this.filters__.item_location.options = result
                
                if (this.filters__.item_location.options.length) {
                    const countNoItemLocation = data.meta.noLocationCount
                    this.filters__.item_location.options.unshift({ label: `No Item Location [${countNoItemLocation}]`, value: '---', count: countNoItemLocation })
                }
            } catch (ex) {
                console.log(ex)
            }
        },
        async onClientReviewDesignClick(lid, index) {
            this.modalLoading = true
            let listing_id = lid
            let iindex = index

            if (iindex > (this.paginatedListingsPrefiled.length - 1)) {
                // TODO: uncomment this when the listings pagination is converted to back-end requests
                await this.updatePage(this.current_page + 1, false)
                await sleep(1000)
                this.index = 0;
                iindex = this.index;
                listing_id = this.paginatedListingsPrefiled[iindex].id
            } else if (iindex == -1) {
                // TODO: uncomment this when the listings pagination is converted to back-end requests
                await this.updatePage(this.current_page - 1, false)
                await sleep(1000)
                this.index = this.paginatedListingsPrefiled.length - 1;
                iindex = this.index;
                listing_id = this.paginatedListingsPrefiled[iindex].id
            }
            
            this.current(listing_id, iindex)

            await sleep(3000)

            $('body').addClass('modal-open')
        },
        remove_noAccountLabel(payment_type) {
            let paypal_counter = 0
            let loweredCase_payment_type = payment_type.toString().toLowerCase()
            if (loweredCase_payment_type.includes('no account')) {
                let splitted_type = payment_type.split(' ')
                payment_type = splitted_type[0]
            }
            return payment_type
        },
        async initialize() {
            this.selected_group = null;
            this.search = null;
            this.new_group_mode = false;
            this.processing = false;
            this.remove_listing = null;
            this.groups = (await this.get({"campaign_id": this.campaign_id})).map(g => {
                g.sellers = [];
                return g;
            });
            // this.groups_copy = (await this.get({"campaign_id": this.campaign_id, "qflag_id": this.$parent.getQFlagId(this.$route.params.status)})).map(g => {
            //     g.sellers = [];
            //     return g;
            // });
        },
        get(filters = {}, include_deleted = 0, sort = "name,asc") {
            let headers = JSON.parse(localStorage.getItem('headers'));
            headers.headers['Where'] = JSON.stringify(filters);
            headers.headers['Include-Deleted'] = include_deleted;
            headers.headers['Sort'] = sort;
            let queries = {
                filter: filters,
                include: ['listingsCount']
            }
            return axios
                // previous before adding filter for qflag_id
                // .get(`${process.env.VUE_APP_URL}/groups?page_size=10000`, headers)
                .get(`${process.env.VUE_APP_URL}/api/v2/pre_filing_groups?${qs.stringify(queries, { encode: false })}&page_size=10000`, headers)
                .then(response => {
                    this.groups_copy = response.data.data;
                    return response.data.data;
                })
                .catch(ex => {
                    console.log(ex);
                    this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-times-circle');
                    return false;
                });
        },
        async add(){
            this.processing = true;
            this.$parent.showSnackbar('Processing...', true);
            let duplicate = await this.get({"campaign_id": this.campaign_id, "name": this.new_group_name}, 1);
            if (duplicate && duplicate.length > 0) {
                this.is_available = false;
                this.$parent.showSnackbar('Name is not available', false, 'invalid', 'fas fa-times-circle');
                this.processing = false;
                return;
            }
            this.is_available = true;
            let headers = JSON.parse(localStorage.getItem('headers'))
            headers.headers['Include-Resource-Data'] = 1;
            axios.post(`${process.env.VUE_APP_URL}/groups`, {
                name: this.new_group_name,
                description: this.new_group_description,
                campaign_id: this.campaign_id,
                qflag_id: this.qFlagID
            }, headers)
            .then(response => {
                let result = response.data.meta.resource_data[0];
                result.sellers = [];
                this.$parent.showSnackbar('New Group Successfully Added!', false, 'success', 'fas fa-check-circle');
                this.new_group_mode = false;
                this.selected_group = result;
                this.new_group_name = null;
                this.new_group_description = null;
                this.processing = false;
                this.groups;
                this.getNewGroups();
            }).catch(ex => {
                console.log(ex);
                this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-times-circle');
                this.processing = false;
            });
        },
        async editGroupName(){
            this.processing = true;
            this.$parent.showSnackbar('Processing...', true);
            let duplicate = await this.get({"campaign_id": this.campaign_id, "name": this.selected_group_name}, 1);
            if (duplicate && duplicate.length > 0) {
                this.is_available = false;
                this.$parent.showSnackbar('Name is not available', false, 'invalid', 'fas fa-times-circle');
                this.processing = false;
                return;
            }
            this.is_available = true;
            let group_id = this.selected_group.value
            let headers = JSON.parse(this.headers)
            let payload = { name: this.selected_group_name }
            await axios.patch(`${process.env.VUE_APP_URL}/api/v2/pre_filing_groups/${group_id}`, payload, headers).then(
                response => {
                        this.$parent.showSnackbar('Group name successfully updated!', false, 'success', 'fas fa-check-circle');
                        this.groupNameUpdateConfirm = false
                        this.selected_group_name_copy = this.selected_group_name
                        this.processing = false
                }
            ).catch (
                error => {
                    console.log(error)
                    this.$parent.showSnackbar("Something went wrong. Please contact system admin.", false, "invalid", "fas fa-times-circle")
                }
            )
            this.getNewGroups();
            this.getListings();
        },
        goToCaseFilesExplorer() {
            this.$parent.showBackdrop();
            
            this.cancelRequests();
            this.refreshCancelToken();
        },
        async globalSearch(search) {
            this.cancelRequests();
            this.refreshCancelToken();

            if (search == '') {
                this.$parent.showSnackbar('Invalid search, it is Empty.', false, 'invalid', 'fas fa-times-circle')
                this.$store.dispatch('isGlobalSearch', false)
                return
            }

            if (search.includes('/') || search.includes('\\')) {
                this.$parent.showSnackbar(`Searching with "/ or \\" is invalid`, false, 'invalid', 'fas fa-times-circle')
                this.$store.dispatch('isGlobalSearch', false)
                return
            }
            /*if (this.hasUsed_globalSearch) {
                return
            }*/

            let searchBy = this.search_by
            let headers = JSON.parse(this.headers)
            let campaign_id = this.$route.params.campaign_id
            let response = []

            headers.cancelToken = this.cancellationTokenSource.token;


            let queries = {
                filter: {
                    campaign_id: campaign_id,
                    qflag_id: [3, 30, 31, 32, 33, 34, 35, 37]
                },
                include: [
                    'campaign',
                    'user',
                    'info',
                    'preview',
                    'platform',
                    'mainAccount',
                    'preFilingGroups',
                    'preFilingGroupListings',
                    'seller.accounts',
                    'seller.platform',
                    'qualifier',
                    'case',
                    'caseSeller',
                    'caseSellerAccounts'
                ],
                includeFilter: {
                    caseSellerInfo: {
                        field: 'e_mail'
                    },
                    info: {
                        field: 'listing_title'
                    }
                },
                page_size: 100000
            };

            if (searchBy == 'listing_id') {
                if (isNaN(parseInt(search))) {
                    this.$parent.showSnackbar(`Invalid search for Listing ID, "${search}" is not a number`, false, 'invalid', 'fas fa-times-circle')
                    return
                }

                queries.filter.id = search

                this.$parent.showSnackbar('Processing... Please wait.', true)
                // response = await axios.get(`${process.env.VUE_APP_URL}/listings/global/${campaign_id}/${search}/search/listing_id`, headers)
                response = await axios.get(`${process.env.VUE_APP_URL}/api/v2/listings?${qs.stringify(queries, {encodeValuesOnly: true})}`, headers)
            } else {
                queries.filter.seller_name = search
                this.$parent.showSnackbar('Processing... Please wait.', true)
                // response = await axios.get(`${process.env.VUE_APP_URL}/listings/global/${campaign_id}/${search}/search/sellername`, headers)
                response = await axios.get(`${process.env.VUE_APP_URL}/api/v2/listings?${qs.stringify(queries, {encodeValuesOnly: true})}`, headers)
            }

            let statuses = [...new Set(response.data.data.map(l => getQFlag(l.qflag_id)))]
            let all_Status = response.data.data.map(l => getQFlag(l.qflag_id))
            let count = [0]

            if (response.data.data.length == 0) {
                this.$parent.showSnackbar(`There is no result for "${search}"`, true, 'info', 'fas fa-info-circle')
                setTimeout(() => {
                    this.clearKeywords()
                    this.$store.dispatch('isGlobalSearch', false)
                }, searchBy == 'listing_id' ? 1500 : 1500)

            } else {
                this.$parent.showSnackbar('', false)
                this.$parent.showSnackbar(`Successfully searched for "${search}"`, false, 'success', 'fas fa-check')
            }

            // declaring the count based on the length of status
            // to prevent NaN
            if (statuses.length > 1) {
                for (let i = 0; i < statuses.length; i++) {
                    count[i] = 0
                }
            }
            for (let i = 0; i < statuses.length; i++) {
                for (let j = 0; j < all_Status.length; j++) {
                    if (statuses[i] == all_Status[j]) {
                        count[i] += 1
                    }
                }
            }
            let status_withCount = statuses.map(status => {
                return { status, count: count[statuses.indexOf(status)] }
            })

            let obj = {
                // "Has Potential": 0,
                "For Review": parseInt(status_withCount.filter(s => s.status == 'Qualified').map(s => s.count) == '' ? 0 : status_withCount.filter(s => s.status == 'Qualified').map(s => s.count)),
                "Monitoring": parseInt(status_withCount.filter(s => s.status == 'Monitoring').map(s => s.count) == '' ? 0 : status_withCount.filter(s => s.status == 'Monitoring').map(s => s.count)),
                "Accepted": parseInt(status_withCount.filter(s => s.status == 'Accepted').map(s => s.count) == '' ? 0 : status_withCount.filter(s => s.status == 'Accepted').map(s => s.count)),
                "Rejected": parseInt(status_withCount.filter(s => s.status == 'Rejected').map(s => s.count) == '' ? 0 : status_withCount.filter(s => s.status == 'Rejected').map(s => s.count)),
                "Submitted": parseInt(status_withCount.filter(s => s.status == 'Submitted').map(s => s.count) == '' ? 0 : status_withCount.filter(s => s.status == 'Submitted').map(s => s.count)),
                // "For Internal Review": 0,
                "Filed": parseInt(status_withCount.filter(s => s.status == 'Filed').map(s => s.count) == '' ? 0 : status_withCount.filter(s => s.status == 'Filed').map(s => s.count)),
                // "Cases": 0,
            }
            // console.log(count)
            // console.log(status_withCount)
            // console.log(obj)

            // previous code
            // let data = response.data.data.map(listing => ({
            //     key_id: this.getRandomKey(),
            //     seller_url: listing.seller_url,
            //     seller_name: listing.name,
            //     seller_accounts: listing.seller_accounts,
            //     id: listing.id,
            //     status: listing.status,
            //     details: listing.title,
            //     product_img: `${listing.id}/${listing.preview}`,
            //     url: listing.url,
            //     listing_info: {
            //         listing_title: this.getListingInfoValue('listing_title', listing)
            //     },
            //     platform: listing.platform,
            //     platform_id: listing.platform_id,
            //     // index
            //     case_number: listing.qflag_id == 34 ? listing.case_number : '',
            //     qflag_id: listing.qflag_id,
            //     created_at: listing.created_at,
            // }))
            let data = response.data.data.map(listing => ({
                campaign: listing.campaign.name,
                campaign_id: listing.campaign_id,
                campaign_image: getImage('campaigns', listing.campaign.name),
                case_seller_accounts: listing.case_seller_accounts,
                claim_type: null,
                claim_type_id: listing.claim_type_id,
                created_at: listing.created_at,
                evidences: {
                    Preview: listing.preview && listing.preview[0] && `${listing.id}/${listing.preview[0].file_name}`,
                },
                for_commission: listing.for_commission,
                group_name: listing.pre_filing_groups && listing.pre_filing_groups[0] && listing.pre_filing_groups[0].name,
                id: listing.id,
                key_id: this.getRandomKey(),
                listing_info: {
                    date_captured: this.getListingInfoValue('date_captured', listing),
                    item_type: this.getListingInfoValue('item_type', listing),
                    listing_title: this.getListingInfoValue('listing_title', listing)
                },
                name_email: listing.main_account && listing.main_account.name_email,
                number: listing.main_account && listing.main_account.number,
                platform: listing.platform && listing.platform.name,
                platform_id: listing.platform_id,
                platform_image: listing.platform && getImage('platforms', listing.platform.name),
                qflag_id: listing.qflag_id,
                qualifier: listing.qualifier && getUserFullName(listing.qualifier),
                seller: [{
                    name: listing.qflag_id == 34 ? listing.case_seller && listing.case_seller[0] && listing.case_seller[0].name : listing.seller && listing.seller.name,
                    url: listing.qflag_id == 34 ? listing.case_seller && listing.case_seller[0] && listing.case_seller[0].url : listing.seller && listing.seller.url,
                    site_id: listing.qflag_id == 34 ? listing.case_seller && listing.case_seller[0] && listing.case_seller[0].site_id : listing.seller && listing.seller.site_id,
                    accounts: listing.qflag_id == 34 ? listing.case_seller_accounts : listing.seller && listing.seller.accounts,
                }],
                seller_name: listing.qflag_id == 34 ? listing.case_seller && listing.case_seller[0] && listing.case_seller[0].name : listing.seller && listing.seller.name,
                seller_accounts: listing.qflag_id == 34 ? listing.case_seller_accounts : listing.seller && listing.seller.accounts,
                status: getQFlag(listing.qflag_id),
                type: listing.main_account && listing.main_account.type,
                url: listing.url,
                user_id: listing.user_id,
                verifier_owner: {
                    first_name: listing.user && listing.user.first_name,
                    last_name: listing.user && listing.user.last_name
                },
                main_account: listing.main_account,
                case: listing.case,
                group_listing_id: listing.pre_filing_group_listings.length > 0 ? listing.pre_filing_group_listings[0] : {}
            }))
            // console.log(data)
            let payment_types = {}
            for (let i of data) {
                for (let j = 0; j < i.seller[0].accounts.length; j++) {
                    let name_email = i.seller[0].accounts[j].name_email
                    // console.log(name_email)
                    // console.log(i.seller[0].accounts[j])
                    if (i.seller[0].accounts[j].type == null) {
                        i.seller[0].accounts[j].type = 'Unknown'
                    }
                    if (typeof payment_types[i.seller[0].accounts[j].type] == 'undefined') {
                        payment_types[i.seller[0].accounts[j].type] = []
                    }
                    payment_types[i.seller[0].accounts[j].type].push({ type: i.seller[0].accounts[j].type, seller_name: i.seller[0].name, name_email: name_email })
                }
            }
            if (this.access_level == 6) {
                // Change payment type here
                // and add as another object "client_payment_type"
                // if(this.hasUsed_globalSearch){

                // }
                data = data.map(listing => {
                    return {
                        ...listing,
                        seller: [{
                            ...listing.seller[0],
                            accounts: listing.seller[0].accounts.map(sa => {
                                let type = sa.type
                                if (sa.type.toString().toLowerCase().includes('paypal') && sa.type.toString().toLowerCase().includes('no account')) {
                                    type = 'Paypal'
                                }
                                if (sa.type.toString().toLowerCase().includes('alibaba') && sa.type.toString().toLowerCase().includes('no account')) {
                                    type = 'Alipay'
                                }
                                if (sa.type.toString().toLowerCase().includes('dhgate') && sa.type.toString().toLowerCase().includes('no account')) {
                                    type = 'DHPay'
                                }
                                return {
                                    ...sa,
                                    client_payment_type: type,
                                }
                            })
                        }]
                    }
                })

            }

            // console.log(this.globalSearch_listings)
            this.$store.dispatch('setGlobalSearch', obj) // count
            this.$store.dispatch('isGlobalSearch', true) // hasUsed_globalSearch
            this.$store.dispatch('globalSearchItem', search) // global searched Item
            this.$store.dispatch('setGlobalListings', data) // data
            this.listings = data
            // console.log(this.globalSearch_count)
            // console.log(statuses)
            let sample_data = [...data, payment_types]
            // console.log(sample_data)
        },
        showExportFrozenModal() {
            $('#export-frozen-modal').modal('show')
            this.getImportPlatforms()
        },
        getImportPlatforms(type = '') {
            this.importExportLoading.caseExportFrozen = true
            const temp = this.selected_listings.length ? this.selected_listings.map(c => c.case_id).join(",") : 0
            const case_ids = this.$route.params.case_id != 0 ? this.$route.params.case_id : temp
            this.$http.get(`platforms/in/case/${case_ids}`, this.$session.get('lawfirm_headers'))
                .then(({ data }) => {
                    this.import_platforms = data.data
                    // this.import_platforms = [{"type":"Amazon"},{"type":"Paypal"},{"type":"Pingpong"},{"type":"Wish"},{"type":"Ebay"}]
                    this.frozen_import_platforms = data.data
                    if (type == 'frozen_amount_import') {
                        if (this.hasEbayListing) {
                            [{ type: "Ebay" }, { type: "Paypal" }].forEach(p => {
                                if (!this.frozen_import_platforms.some(f => f.type == p.type)) this.frozen_import_platforms.push(p)
                            })
                        }

                        if (this.isCampaignRightsHolderZuru) {
                            [{ type: "Ebay" }, { type: "Paypal" }, { type: "Payoneer" }, { type: "Amazon" }].forEach(p => {
                                if (!this.frozen_import_platforms.some(f => f.type == p.type)) this.frozen_import_platforms.push(p)
                            })
                        }
                    }
                })
                .catch(ex => {
                    console.log(ex)
                })
                .finally(() => {
                    this.importExportLoading.caseExportFrozen = false
                })
        },
        getFrozenAmountBreakdown(case_id) {
            this.currentFrozenBreakdown.loading = true
            $('#frozen-amount-breakdown-modal').modal('show')
            this.$http.get(`frozen_amounts/breakdown/by/platform/case/${case_id}/${this.$route.params.campaign_id}`, this.$session.get('lawfirm_headers'))
                .then(({ data }) => {
                    this.currentFrozenBreakdown.data = data.data
                })
                .catch(ex => {
                    console.log(ex)
                })
                .finally(() => {
                    this.currentFrozenBreakdown.loading = false
                })
        },
        async getCaseSellerAccounts() {
            // console.log('triggered: Listings -> getCaseSellerAccounts')
            if (!this.hasUsed_globalSearch) {
                this.show_available_payment_acocunts = false
                let payload = {
                    status: this.$route.params.status,
                    campaign_id: this.$route.params.campaign_id,
                    selected_payment_method: this.selected_payment_method,
                    substatus_id: this.$route.query?.substatus_id == -1 ? undefined : this.$route.query?.substatus_id?.toString().includes('0_') ? -1 : this.$route.query?.substatus_id
                }
                await this.$store.dispatch('getCaseSellerAccount', payload)

                if(this.case_seller_accounts_vuex.data !== undefined || this.case_seller_accounts_vuex.data !== null) {
                    this.show_available_payment_accounts = true
                    this.selected_payment_method = '0'
                    this.case_seller_accounts = {}

                    for(let x of this.case_seller_accounts_vuex.data) {
                        if(x.payment_type == null) {
                            x.payment_type = 'Unknown'
                        }
                        if(typeof this.case_seller_accounts[x.payment_type] == 'undefined') {
                            this.case_seller_accounts[x.payment_type] = []
                        }
                        this.case_seller_accounts[x.payment_type].push(x)
                    }
                }
                
                // if(this.case_seller_accounts_vuex === undefined || this.case_seller_accounts_vuex === null) {
                //     this.show_available_payment_accounts = false
                //     let payload = {
                //         status: this.$route.params.status,
                //         campaign_id: this.$route.params.campaign_id,
                //         selected_payment_method: this.selected_payment_method
                //     }
                //     await this.$store.dispatch('getCaseSellerAccount',payload)
    
                //     // console.log('Listings -> case_seller_accounts_vuex: ',this.case_seller_accounts_vuex.data)
                //     if(this.case_seller_accounts_vuex.data !== undefined || this.case_seller_accounts_vuex.data !== null) {
                //         this.show_available_payment_accounts = true
                //         this.selected_payment_method = '0'
                //         this.case_seller_accounts = {}
                //         for (let x of response.data.data) {
                //             if (x.payment_type == null) {
                //                 x.payment_type = 'Unknown'
                //             }
                //             if (typeof this.case_seller_accounts[x.payment_type] == 'undefined') {
                //                 this.case_seller_accounts[x.payment_type] = []
                //             }
                //             this.case_seller_accounts[x.payment_type].push(x)
                //         }
                //         setTimeout(() => {
                //             this.filterAvailablePaymentAccounts()
                //         }, 500)
                        
                //     }
                // }



                // axios.get(`${process.env.VUE_APP_URL}/case_seller_accounts/${this.$route.params.status}/in/campaign/${this.$route.params.campaign_id}/with/${this.selected_payment_method}?page_size=1000`,
                //     JSON.parse(localStorage.getItem('headers'))).then(
                //         response => {
                //             this.show_available_payment_accounts = true
                //             this.selected_payment_method = '0'
                //             this.case_seller_accounts = {}
                //             for (let x of response.data.data) {
                //                 if (x.payment_type == null) {
                //                     x.payment_type = 'Unknown'
                //                 }
                //                 if (typeof this.case_seller_accounts[x.payment_type] == 'undefined') {
                //                     this.case_seller_accounts[x.payment_type] = []
                //                 }
                //                 this.case_seller_accounts[x.payment_type].push(x)
                //             }
                //             setTimeout(() => {
                //                 this.filterAvailablePaymentAccounts()
                //             }, 500)
                //         }
                //     )
            } else {
                let globalListings = this.globalSearch_listings
                globalListings = globalListings.map(listing => {
                    if(listing.qflag_id == 34) {
                        return {
                            case_seller_accounts: listing.case_seller_accounts,
                            seller_accounts: listing.seller[0].accounts,
                            seller_name: listing.seller[0].name,
                        }
                    } else {
                        return {
                            seller_accounts: listing.seller[0].accounts,
                            seller_name: listing.seller[0].name,
                        }
                    }
                })
                this.case_seller_accounts = {}
                if (this.access_level != 6) {
                    for (let i of globalListings) {
                        for (let j = 0; j < i.seller_accounts.length; j++) {
                            let name_email = i.seller_accounts[j].name_email
                            // console.log(name_email)
                            // console.log(i.seller_accounts[j])
                            if (i.seller_accounts[j].type == null) {
                                i.seller_accounts[j].type = 'Unknown'
                            }
                            if (typeof this.case_seller_accounts[i.seller_accounts[j].type] == 'undefined') {
                                this.case_seller_accounts[i.seller_accounts[j].type] = []
                            }
                            this.case_seller_accounts[i.seller_accounts[j].type].push({ type: i.seller_accounts[j].type, seller_name: i.seller_name, name_email: name_email })

                        }
                    }
                } else {
                    for (let i of globalListings) {
                        for (let j = 0; j < i.seller_accounts.length; j++) {
                            let name_email = i.seller_accounts[j].name_email
                            // console.log(name_email)
                            // console.log(i.seller_accounts[j])
                            if (i.seller_accounts[j].client_payment_type == null) {
                                i.seller_accounts[j].client_payment_type = 'Unknown'
                            }
                            if (typeof this.case_seller_accounts[i.seller_accounts[j].client_payment_type] == 'undefined') {
                                this.case_seller_accounts[i.seller_accounts[j].client_payment_type] = []
                            }
                            this.case_seller_accounts[i.seller_accounts[j].client_payment_type].push({ type: i.seller_accounts[j].client_payment_type, seller_name: i.seller_name, name_email: name_email })

                        }
                    }
                }
            }
        },
        selectAll() {
            // this.select_all_text = this.is_select_all ? "Unselect" : "Select"
            this.selected_listings = []
            this.frozen_type = '0'
            this.show_reassign_users = false
            if (this.$route.params.status == 'Cases' && this.$route.params.case_id == '0') {
                if (this.is_select_all == true) {
                    for (let x of this.filteredListings) {
                        var datum = { case_id: x.id, case_number: x.case_number }
                        this.selected_listings.push(datum)
                        let checkbox = document.querySelector(`input[value="${x.id}"]`)
                        checkbox.checked = true
                        checkbox.parentNode.parentNode.parentNode.parentNode.classList.add('checked')
                    }
                    this.is_select_all = false
                    this.selected_reassign_user = 0
                    this.show_reassign_users = true
                    return
                }
            } else {
                if (this.is_select_all == true) {
                    let listings = this.$route.params.status == 'Cases' ? this.paginatedListingsPostfiled : this.paginatedListingsPrefiled
                    for (let x of listings) {
                        this.selected_listings.push(x.id)
                        let checkbox = document.querySelector(`input[value="${x.id}"]`)
                        checkbox.parentNode.parentNode.parentNode.parentNode.classList.add('checked')
                    }
                    this.is_select_all = false
                    this.selected_reassign_user = 0
                    this.show_reassign_users = true
                    return
                }
            }
            if (this.listings.length > 0) {
                let stuffs = document.querySelectorAll('.card.a-list')
                for (let index = 0; index < stuffs.length; index++) {
                    let list = stuffs[index]
                    list.classList.remove('checked')
                    if (list.querySelector('.custom-control-input')) {
                        list.querySelector('.custom-control-input').checked = false
                    }
                }
            }
            this.is_select_all = true
            this.selected_reassign_user = 0
            this.show_reassign_users = true
        },
        gotoCaseListings: function (case_id, case_name) {
            // this.case_name = case_name
            this.$parent.showSnackbar('Loading case defendants.. Please wait..', false);

            this.searching = false
            this.cancelRequests();
            this.refreshCancelToken();
            this.$router.push({
                name: 'lawfirmlisting',
                params: {
                    campaign_id: this.$route.params.campaign_id,
                    status: 'Cases',
                    case_id: case_id
                }
            })
            // this.getListings()
        },
        selected_function: function (lid) {
            this.show_reassign_users = false
            if (this.$route.params.status == 'Cases' && this.$route.params.case_id == '0') {
                let listing = document.querySelector(`input[value="${lid.case_id}"]`).parentNode.parentNode.parentNode.parentNode
                if (this.selected_listings.some(x => x.case_id === lid.case_id)) {
                    // this.selected_listings.splice(this.selected_listings.indexOf(index), 1)
                    this.selected_listings = this.selected_listings.filter(list => list.case_id != lid.case_id)
                    listing.classList.remove('checked')
                } else {
                    // console.log(listing)
                    // this.selected_listings.push(index)
                    this.selected_listings.push(lid)
                    listing.classList.add('checked')
                }
            } else {
                let listing = document.querySelector(`input[value="${lid}"]`).parentNode.parentNode.parentNode.parentNode
                if (this.selected_listings.includes(lid)) {
                    // this.selected_listings.splice(this.selected_listings.indexOf(index), 1)
                    this.selected_listings = this.selected_listings.filter(list => { return list != lid })
                    listing.classList.remove('checked')
                } else {
                    // console.log(listing)
                    // this.selected_listings.push(index)
                    this.selected_listings.push(lid)
                    listing.classList.add('checked')
                }
            }
            if (this.selected_listings.length == 0) this.frozen_type = '0'
            this.selected_reassign_user = 0
            this.show_reassign_users = true
        },
        newCase: async function () {
            this.cancelRequests();
            this.refreshCancelToken();
            if (this.$route.params.status == 'Submitted' && !this.filter_submissions_pack.selected) {
                this.$parent.showSnackbar('Please specify the Submission Pack Name filter first.', false, 'invalid', '/static/svg/Error_Snackbar.svg');
                return;
            }

            this.is_loading = true
            if (!this.hasAcknowledged) {
                const callback = async () => {
                    const acknowledgement = { feature: 'file a case', status: 1, notes: '' }
                    await this.$store.dispatch('acknowledgeWhitelist', acknowledgement)
                    $('#potential-whitelists').modal('hide')

                    this.hasAcknowledged = true
                    await this.newCase()
                    this.hasAcknowledged = false
                }
                const hasPotentials = await this.checkPotentialWhitelists(this.selected_listings, '', callback)
                this.isCheckingPotentials = false
                if (hasPotentials) return
            }
            // let listing_ids = ''
            // if(this.$route.params.status !== 'Submitted') {
            //     return this.goToNewCase('wah!, me and my jowa, wah')
            // }
            /* listing_ids = this.selected_listings
                .filter(id => !this.whitelisted_listings.includes(id))
                .join()
            if(listing_ids === '') {
                return this.$parent.showSnackbar(`All selected listing(s) are whitelist`, false, 'invalid', 'fas fa-times-circle')
            }
            let selected_whitelist_listings = ''
            selected_whitelist_listings = this.selected_listings
                .filter(id => this.whitelisted_listings.includes(id))
                .join()
            if(selected_whitelist_listings.length > 0) {
                this.$parent.showSnackbar(`Removing whitelist listing(s): ${selected_whitelist_listings} from filing`, true, 'info', 'fas fa-info-circle')
            } */
            this.isFileCaseMode = false
            setTimeout(async () => {
                this.$parent.hideSnackbar()
                const listingIds = this.selected_listings.join()
                //Check for duplicate listings
                const { data, status } = await this.checkDuplicateListings(listingIds, 34, true)

                if (data.data && data.data[0] && data.data[0]?.duplicate_logs != null) {
                    const d = data.data[0]
                    const duplicate_logs = { location: d.duplicate_logs, data: d.duplicates }
                    $('.modal').modal('hide')
                    await sleep(500)
                    this.setRelatedCampaignDuplicateContinueFunction(() => this.newCase())
                    this.setRelatedCampaignDuplicates(duplicate_logs)
                    this.$parent.showSnackbar(`${response.meta.message}`,false,'invalid','fas fa-times-circle')

                    return
                }

                if (status !== 200)
                    return this.$parent.showSnackbar('Something went wrong. Please contact System Admin', false, 'invalid', 'fas fa-times-circle')
                const response = data.data
                const show = this.showDuplicateListingsModal(response)
                if (show) {
                    const listingsWithDuplicate = response.map(({ reference }) => reference.id)
                    this.listings_to_be_filed = listingIds.split(',')
                        .filter(listing => !listingsWithDuplicate.includes(+listing))
                        .join()
                    this.duplicateListingModalOnContinue = () => this.goToNewCase(this.listings_to_be_filed)
                    this.duplicateListingModalProceedAnyway = () => this.goToNewCase(listingIds)
                    return
                }
                this.setRelatedCampaignDuplicateBypass(false)
                this.goToNewCase(this.$route.params.status !== 'Submitted' ? 'wah!, me and my jowa, wah' : listingIds)
            }, 100);
        },
        showSPGSelectModal() {
            this.isFileCaseMode = !this.isFileCaseMode;
            if (this.isFileCaseMode) {
                $('#new-case-modal').modal({ backdrop: 'static', keyboard: false });
            }
        },
        async applySPGFilter() {
            $('#new-case-modal').modal('hide');
            this.applyMoreFilters();
        },
        /**
         * Display duplicates in #duplicate-listing-modal
         *  @param {array} listings - data to be displayed
         *  @return boolean - true when has duplicates upon checking
         */
        showDuplicateListingsModal(listings) {
            if (!listings.length) {
                return false
            }
            this.duplicate_listings = listings.map(({ reference, duplicates }) => (
                {
                    id: reference.id,
                    duplicates: duplicates.map(({ id, name, case_number }) => ({ id, name, case_number }))
                }
            ))
            /*this.duplicate_seller = duplicates.map(({reference, duplicate}) => (
                {
                    ref     : reference.id,
                    id      : duplicate.id,
                    name    : duplicate.name,
                    qflag_id: duplicate.qflag_id
                }
            ))*/
            this.closeModal()
            $('#duplicate-listing-modal').modal('show')
            return true
        },
        goToNewCase(listing_ids) {
            if (listing_ids === '') {
                return this.$parent.showSnackbar('All selected listing(s) has duplicate', false, 'invalid', 'fas fa-times-circle')
            }
            const caseObj = { campaign_id: this.$route.params.campaign_id, unique_key: Date.now() }
            const caseToken = this.$parent.base64encode(JSON.stringify(caseObj))
            localStorage.setItem(caseToken, listing_ids.includes("wah!") ? '' : listing_ids)
            if (this.$route.params.status == "Submitted")
                localStorage.setItem(`${caseToken}-package_gen_request_id`, this.filter_submissions_pack.selected.value);
            this.filter_submissions_pack.selected = null
            this.$router.push(`/lawfirm/campaign/${this.$route.params.campaign_id}/case/new/${caseToken}`)
        },
        /**
         * Remove duplicate listings before showing #add-to-case modal
         * @returns {boolean} - true if duplicates found
         */
        async checkDuplicateWhenAddingToCase() {
            const { data, status } = await this.checkDuplicateListings(this.selected_listings, 34)
            if (status !== 200) {
                return this.$parent.showSnackbar('Something went wrong. Please contact System Admin', false, 'invalid', 'fas fa-times-circle')
            }
            const show = this.showDuplicateListingsModal(data.data)
            if (show) {
                const __selected_listings = this.selected_listings
                const duplicates = data.data.map(({ reference }) => +reference.id)
                this.selected_listings = this.selected_listings.filter(listing => !duplicates.includes(+listing))
                this.duplicateListingModalProceedAnyway = () => {
                    this.selected_listings = __selected_listings

                    this.prepare_add_to_case = true
                    $('#add-to-case-modal').modal('show')
                }
                return true
            }
            return false
        },
        async showAddToCaseModal() {
            const hasDuplicate = await this.checkDuplicateWhenAddingToCase()
            if (!hasDuplicate) {
                this.prepare_add_to_case = true
                return $('#add-to-case-modal').modal('show')
            }
            this.duplicateListingModalOnContinue = () => {
                if (!this.selected_listings.length) {
                    return this.$parent.showSnackbar('All selected listing(s) has duplicate', false, 'invalid', 'fas fa-times-circle')
                }
                this.prepare_add_to_case = true
                $('#add-to-case-modal').modal('show')
            }
        },
        /**
         * Return duplicate listings from provided listings and qflag
         *  @param {string | number[]} listing_ids - IDs to check for duplicates
         *  @param {number} qflag - what qflag to check for duplicate
         *  @return Promise<AxiosResponse>
         */
        async checkDuplicateListings(listing_ids, qflag) {
            if (!Array.isArray(listing_ids)) {
                listing_ids = [listing_ids];
            }
            const headers = this.$session.get('lawfirm_headers')

            return this.$http.post(`/duplicate_listings/${qflag}`, {
                listing_ids: listing_ids
            }, headers)
                .catch(err => err)
        },
        editCaseListing: function () {
            document.getElementById('dismiss-modal').click();
            this.$router.push(`/lawfirm/${this.$route.params.campaign_id}/case/${this.$route.params.case_id}/listing/${this.listing.id}/edit`)
        },
        mapGroup(groups) {
            this.groups = groups.map(g => ({ label: g.name, value: g.id, campaign_id: g.campaign_id, 
                        created_at: g.created_at, deleted_at: g.deleted_at, description: g.description, 
                        listing_count: g.listings_count, sellers: g.sellers, updated_at: g.updated_at}));
        },
        editCaseDetails(case_id) {
            this.cancelRequests();
            this.refreshCancelToken();
            this.$router.push(`/lawfirm/campaign/${this.$route.params.campaign_id}/case/edit/${case_id}`)
        },
        removeCase: function () {
            let headers = JSON.parse(localStorage.getItem('headers'))
            headers.headers["Campaign-id"] = this.$route.params.campaign_id
            for (let x of this.selected_listings) {
                axios.delete(`${process.env.VUE_APP_URL}/cases/${x.case_id}`,
                    headers
                ).then(
                    response => {
                        this.loaded = true
                        // hide Snackbar
                        this.$parent.hideSnackbar()
                        this.$parent.showSnackbar(`Successfully removed case(s).`, false, 'success', 'fas fa-check')
                        // for(let y in this.listings){
                        //     if(this.listings[y].id == x.case_id){
                        //         this.listings.splice(y, 1);
                        //     }
                        // }
                        this.getListings()
                        this.refreshCM_statuses()
                    })
            }
            this.closeModal()
            this.is_select_all = false
            this.selectAll()
        },
        removeDefendants() {
            let headers = JSON.parse(localStorage.getItem('headers'))
            headers.headers['Campaign-id'] = this.$route.params.campaign_id
            let payload = {}
            payload.listings = this.selected_listings
            this.$parent.showSnackbar(`Removing selected defendant(s).`, true)
            axios.patch(`${process.env.VUE_APP_URL}/case_listings/remove`,
                payload,
                headers
            ).then(
                response => {
                    this.loaded = true
                    this.$parent.showSnackbar(`Successfully removed defendant(s).`, false, 'success', 'fas fa-check')
                    this.closeModal()
                    this.getListings()
                })
        },
        exportExcel: function () {
            window.open(
                `${process.env.VUE_APP_URL}/listings/export/ver/${this.$route.params.campaign_id}/${this.selected_listings.join(",")}`,
                '_blank' // <- This is what makes it open in a new window.
            );
        },
        initializeSearch: function () {
            if (this.$route.params.status == 'Cases') {
                let has_case_filters = false
                let filter_drawer = this.siblings_component.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
                let thingsToCheck = [filter_drawer.selected_lf_platforms, filter_drawer.selected_payment_methods, filter_drawer.selected_seller_flags, filter_drawer.selected_counsels]
                for (var i = 0; i < thingsToCheck.length; i++) {
                    if (thingsToCheck[i] == null || typeof thingsToCheck[i] == 'undefined')
                        continue
                    if (thingsToCheck[i].length > 0) {
                        has_case_filters = true
                        break
                    }
                }
                if (has_case_filters)
                    filter_drawer.applyCaseFilters()
                else
                    this.getListings()
            } else {
                setTimeout(() => {
                    this.getListings()
                }, 500);
            }
        },
        async searchByLID(keywords) {
            let headers = JSON.parse(this.headers)
            await axios.get(`${process.env.VUE_APP_URL}/listings/${this.$route.params.campaign_id}/all/SearchBy/listing_id/${keywords}/with/platforms/0?page=1&page_size=1`, headers).then(
                response => {
                    let listings = response.data.data
                    this.listings = response.data.data
                    this.lid_status = listings.length > 0
                        ? listings[0].status == "Qualified"
                            ? "For Review"
                            : listings[0].status == "Hacked"
                                ? "Auto Qualified"
                                : listings[0].status
                        : this.$route.params.status
                    this.searching = false
                })
        },
        /**
         * Perform a request if the listings in listing page are whitelisted
         * and append is_whitelist key in each listings(a data property) item
         * */
        checkWhitelist() {
            //Commented function since checking of whitelist is updated into checking of potential whitelists
            //See deliverable for reference: https://podio.com/apmcllccom/artemis/apps/deliverables/items/227
            /* const headers = this.$session.get('lawfirm_headers')
            headers.headers['Listings'] = this.listings.map(({ id }) => id).join()
            this.$http.get('add_to_case/sellers', headers)
                .then(({data}) => {
                    this.whitelisted_listings = data.data.filter(({is_whitelist}) => is_whitelist)
                        .map(({ listing_id }) => +listing_id)
                    this.listings = this.listings.map(item => {
                        item.is_whitelist = this.whitelisted_listings.includes(+item.id)
                        return item
                    })
                }) */
        },
        async getPlatformPrioritization() {
            try {
                const { data } = await this.$http.get(`api/campaign-platform-prioritization/get-platforms/campaign/${this.$route.params.campaign_id}`, this.$session.get('lawfirm_headers'))
                this.platform_prioritization.data = data.data
            } catch (e) {
                console.error(`getPlatformPrioritization error; Message=>${e}`);
            }
        },
        getListings: async function (refresh = true) {
            this.subStatusesStore.currentSelectedSubStatus = null
            
            this.$store.dispatch('isGlobalSearch', false)
            this.headers = localStorage.getItem('headers')
            this.page_size = this.page_size != "" && this.page_size <= 10000 ? parseInt(this.page_size) : 10000
            try {
                if (this.searching) return
                if (this.$route.params.status != "Cases" || this.$route.params.case_id != '0') {
                    this.getPlatformDataBreakdown()
                }
                this.fab_expand = false
                this.searching = true
                let campaign_name = null
                let campaign_display_interval = setInterval(() => {
                    if (this.campaigns.length > 0) {
                        if ((campaign_name = this.campaigns.filter(c => c.id == this.$route.params.campaign_id)) != null) {
                            campaign_name = ((campaign_name[0] || {}).campaign || "")
                            this.campaign_data = { img: this.$parent.getImage('campaigns', campaign_name), name: campaign_name }
                            clearInterval(campaign_display_interval)
                        }
                    } else {
                        setTimeout(() => {
                            for (let cindex = 0; cindex < this.siblings_component.length; cindex++) {
                                let c = this.siblings_component[cindex]
                                console.log
                                if (c.$vnode.componentOptions.tag == 'ar-sidebar') {
                                    this.campaigns = c.campaigns
                                    for (let index = 0; index < this.campaigns.length; index++) {
                                        let campaign = this.campaigns[index]
                                        if (campaign.id == this.$route.params.campaign_id) {
                                            campaign_name = campaign.campaign
                                            this.campaign_data = { img: this.$parent.getImage('campaigns', campaign_name), name: campaign_name }
                                            return
                                        }
                                    }
                                }
                            }
                        }, 500)
                    }
                }, 3500);
                let filter_drawer = this.siblings_component.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
                if (this.$route.query.lid != null) {
                    this.search_by = 'listing_id'
                    this.search_input = this.$route.query.lid
                    document.getElementById('search-by-filter').value = 'listing_id'
                }
                if (this.lid_status == "" && this.search_by == 'listing_id' && this.search_input != "") {
                    await this.searchByLID(this.search_input)
                    this.$router.push({ name: 'lawfirmlisting', params: { status: this.lid_status, campaign_id: this.$route.params.campaign_id, case_id: 0 }, query: {} })
                    filter_drawer.filters.status = this.lid_status
                    return
                }
                var status = this.$route.params.status
                this.loaded = false
                if (refresh) {
                    this.listings = []
                    this.selected_listings = []
                }
                let conditions = {}
                let headers = JSON.parse(this.headers)
                if (status == 'For Review') {
                    status = 'Qualified'
                }
                if (status == 'Auto Qualified') {
                    status = 'Hacked'
                }
                status = status.toLowerCase()
                // console.log(status)
                if (refresh)
                    this.$parent.showBackdrop()
                //Post-filed listings page
                if (this.$route.params.case_id != '0' && typeof this.$route.params.case_id !== 'undefined') {
                    // filter_drawer.applyCaseFilters()
                    let getMainAccountType = (listing) => {
                        try {
                            let main = listing.case_seller_accounts.filter(account => account.seller_account_id == listing.seller_account_id)[0]
                            let methods = this.getDefendantPaymentMethods(listing)
                            return methods.filter(method => main.type == method.label)[0].value
                        } catch (ex) {
                            return "0"
                        }
                    }
                    let queries = {
                        filter: {
                            campaign_id: this.$route.params.campaign_id,
                            qflag_id: 34,
                            case_id: this.$route.params.case_id
                        },
                        include: [
                            'campaign',
                            'info',
                            'preview',
                            'platform',
                            'qualifier',
                            'caseListing.caseFlag',
                            'caseSeller.sellerGroup',
                            // 'caseSellerAccounts',
                            'caseListing.caseSeller.caseSellerAccounts',
                            'caseSellerInfo',
                            'caseSeller',
                            'evidences'
                        ],
                        includeFilter: {
                            caseSellerInfo: {
                                field: 'e_mail'
                            },
                            info: {
                                field: 'listing_title'
                            },
                            evidences: {
                                type: 'merged'
                            }
                        },
                        page_size: 100000
                    };
                    let response = await axios.get(
                        `${process.env.VUE_APP_URL}/api/v2/listings?${qs.stringify(queries, { encodeValuesOnly: true })}`,
                        {
                            headers: buildHeader()
                        }
                    );
                    this.listings = response.data.data.map(listing => ({
                        campaign: listing.campaign.name,
                        campaign_id: listing.campaign_id,
                        campaign_image: getImage('campaigns', listing.campaign.name),
                        claim_type_id: listing.claim_type_id,
                        case_listing: [{
                            case_flag: listing.case_listing && listing.case_listing[0] && listing.case_listing[0].case_flag && listing.case_listing[0].case_flag.name,
                            case_flag_id: listing.case_listing && listing.case_listing[0] && listing.case_listing[0].case_flag_id,
                            counsel: listing.case_listing && listing.case_listing[0] && listing.case_listing[0].counsel,
                            doe_number: listing.case_listing && listing.case_listing[0] && listing.case_listing[0].doe_number,
                            id: listing.case_listing && listing.case_listing[0] && listing.case_listing[0].id
                        }],
                        // case_seller_accounts: listing.case_seller_accounts,
                        case_seller_accounts: listing.case_listing[0]?.case_seller?.case_seller_accounts ?? [],
                        evidences: {
                            Preview: listing.preview && listing.preview[0] && `${listing.id}/${listing.preview[0].file_name}`,
                        },
                        for_commission: listing.for_commission,
                        group_description: listing.case_seller && listing.case_seller[0] && listing.case_seller[0].seller_group && listing.case_seller[0].seller_group.description,
                        group_name: listing.case_seller && listing.case_seller[0] && listing.case_seller[0].seller_group && listing.case_seller[0].seller_group.name,
                        id: listing.id,
                        key_id: this.getRandomKey(),
                        listing_info: {
                            listing_title: this.getListingInfoValue('listing_title', listing)
                        },
                        pay_through: "1",
                        payment_platform: getMainAccountType(listing),
                        platform: listing.platform && listing.platform.name,
                        platform_id: listing.platform_id,
                        platform_image: listing.platform && getImage('platforms', listing.platform.name),
                        qflag_id: listing.qflag_id,
                        qualifier: listing.qualifier && getUserFullName(listing.qualifier),
                        seller: [{
                            name: listing.case_seller && listing.case_seller[0] && listing.case_seller[0].name,
                            url: listing.case_seller && listing.case_seller[0] && listing.case_seller[0].url,
                            site_id: listing.case_seller && listing.case_seller[0] && listing.case_seller[0].site_id
                        }],
                        seller_account_id: listing.seller_account_id,
                        status: getQFlag(listing.qflag_id),
                        url: listing.url,
                        user_id: listing.user_id,
                        verifier_owner: {
                            first_name: '',
                            last_name: ''
                        },
                        case_seller_info: listing.case_seller_info
                    }));

                    this.getCaseSellerAccountsTypes();

                    await sleep(1000)
                }
                //Cases page
                else if (this.$route.params.status == 'Cases' && this.$route.params.case_id == '0') {
                    // headers.headers.where = `{"campaign_id":${this.$route.params.campaign_id}}` // Updated endpoint for faster query of cases information, Marvin 03/18/2021
                    if (this.$route.query.case_status > 0) {
                        headers.headers["Case-status"] = this.$route.query.case_status
                    }
                    headers.headers.Sort = `${this.sort_by == '0' ? 'created_at' : this.sort_by},${this.order_by == '0' ? 'desc' : this.order_by}`
                    // headers.headers.Sort = `${this.sort_by == '0' ? 'seller_name' : this.sort_by},${this.order_by == '0' ? 'asc' : this.order_by}`
                    headers.cancelToken = this.cancellationTokenSource.token;
                    // await axios.get(`${process.env.VUE_APP_URL}/cases/multi?page_size=${this.page_size}`, // Updated endpoint for faster query of cases information, Marvin 03/18/2021
                    await axios.get(`${process.env.VUE_APP_URL}/cases/${this.$route.params.campaign_id}/raw?page_size=${this.page_size}`,
                        headers).then(
                            response => {
                                this.searching = false
                                let listings = response.data.data
                                this.lists_meta = response.data.meta
                                this.listings = listings.map(listing => {
                                    listing.platforms = ["Default"]
                                    listing.evidences = { something: { preview: "" } }

                                    const case_files = JSON.parse(JSON.stringify(this.caseFileUploads_copy))
                                    
                                    const removeCount = 2;
                                    for (let i = 0; i < removeCount; i++) case_files.pop()

                                    listing.case_files = case_files
                                    return { ...listing, hideUploadedCaseFileButton: true, key_id: this.getRandomKey(), case_img_key: this.getRandomKey(), platform_img_key: this.getRandomKey() }
                                })
                            }
                        )

                    /**
                     * This will only load all case files during inital loading
                     * to avoid updating the case files of unrelated cases during
                     * deleting and adding of case files.
                     */
                    if (this.isInitialLoadOfCaseFiles) {
                        // get the uploaded case files of each cases
                        // this.listings.forEach(async (l, index) => {
                        //     this.current_show_file_case_id = l.id;
                        //     this.getCaseFiles(l.id, index)
                        // })
                        this.getAllCaseFiles()

                        // Reset active case modal
                        this.current_show_file_case_id = 0;

                        // Set to false to avoid reloading of case files
                        this.isInitialLoadOfCaseFiles = false;
                    }


                    this.current_show_file_case_id = 0;

                    if (refresh) this.$parent.hideBackdrop()
                    this.loaded = true
                    let is_cancelled = false
                    for (let index = 0; index < this.listings.length; index++) {
                        if (is_cancelled)
                            break;
                        axios.get(`${process.env.VUE_APP_URL}/cases/${this.$route.params.campaign_id}/raw/${this.listings[index].id}/evidence_platforms?page_size=${this.page_size}`,
                            headers).then(response => {
                                if (this.$route.params.status == 'Cases' && this.$route.params.case_id == '0') {
                                    let misc = response.data.data
                                    this.listings[index].platforms = misc.platforms
                                    this.listings[index].evidences = misc.evidences
                                    this.listings[index].case_img_key = this.getRandomKey()
                                    this.listings[index].platform_img_key = this.getRandomKey()
                                    this.listings[index].key_id = this.getRandomKey()
                                }
                            }).catch(error => {
                                if (axios.isCancel(error))
                                    is_cancelled = true
                            })
                        await sleep(500)
                    }
                }
                //Pre-filed listings page
                else {
                    filter_drawer.applyPlatformFilters()
                    if (this.hasUsed_globalSearch) {
                        this.search_input = this.globalSearch_input
                    }
                    let item_type = undefined;
                    if (this.filter_item_type.length > 0) {
                        item_type = this.filter_item_type.map(type => type.label);
                    }
                    if (this.filter_item_class != 0 && this.filter_item_class.length > 0) {
                        let exclude_classes = this.filter_item_type.map(type => type.class);
                        let item_types = this.item_type_options_display.filter(type => this.filter_item_type.includes(type) || !exclude_classes.includes(type.class));
                        if (item_types.length > 0) {
                            item_type = item_types.map(type => type.label);
                        }
                        else {
                            item_type = this.item_type_options_display.map(type => type.label);
                        }
                    }
                    if (this.filter_item_type.map(type => type.value).includes('No Item Type')) {
                        item_type = ['---'];
                    }

                    const substatus = this.$route.query.substatus_id?.toString()?.split('_')?.[0] ?? this.$route.query.substatus_id

                    // Include Recycled when status is Accepted for Client Access only
                    let qflag = (this.access_level == 6 && this.$route.params.status.toLowerCase() == 'accepted') ? [32,38] : this.$route.params.status
                    // console.log(qflag)
                    let queries = {
                        filter: {
                            campaign_id: this.$route.params.campaign_id,
                            qflag_id: {
                                "Has Potential": 98,
                                "For Review": 3,
                                "Monitoring": 31,
                                "Accepted": 32,
                                "Submitted": 35,
                                "Rejected": 33,
                                "For Internal Review": 36,
                                "Initial Review Completed": 37,
                                "Recycled": 38,
                                // newly added Case Status 9/8/22
                                "Filed": 34
                            }[qflag],
                            id: this.filtered_listing_id && this.filtered_listing_id.length ? this.filtered_listing_id.join() : undefined,
                            // location: this.filter_item_location ? this.filter_item_location : undefined,
                            location: this.filters__.item_location.selected != 0 ? this.filters__.item_location.selected : undefined,
                            created_at: this.min_date_added ? {
                                from: moment(this.min_date_added).format('YYYY-MM-DD 00:00:00'),
                                to: this.max_date_added ? moment(this.max_date_added).format('YYYY-MM-DD 23:59:59') : undefined
                            } : undefined,
                            seller_location: this.filter_seller_location && this.filter_seller_location != '0' ? this.filter_seller_location : undefined,
                            qualified_at: this.min_date_qualified ? {
                                from: moment(this.min_date_qualified).format('YYYY-MM-DD 00:00:00'),
                                to: this.max_date_qualified ? moment(this.max_date_qualified).format('YYYY-MM-DD 23:59:59') : undefined
                            } : undefined,
                            generic_email: this.filter_email ? this.filter_email : undefined, // changed to generic_email for new Feature - 7/29/22
                            // seller_info_email: this.filter_email ? this.filter_email : undefined,
                            seller_alias: this.filter_alias ? this.filter_alias : undefined,    // Newly added filter feature ALIAS - 7/21/22
                            seller_account_name_email: this.filter_account_name_email ? this.filter_account_name_email : undefined,
                            submitted_group: this.filter_group && this.filter_group != '0' ? this.filter_group : undefined,
                            seller_account_number: this.filter_account_number ? this.filter_account_number : undefined,
                            claim_type: this.filter_claim_type && this.filter_claim_type != '0' ? this.filter_claim_type : undefined,
                            item_type: item_type,
                            item_class: this.filter_item_class.map( c => c.value),
                            date_captured: this.filterDateCaptured ? this.filterDateCaptured : undefined,
                            seller_payment_type: this.selected_payment_methods && this.selected_payment_methods.length ? this.selected_payment_methods : undefined,
                            infringement_indicators: this.selected_infringement_reasons && this.selected_infringement_reasons.length ? {
                                contain: this.infringement_option == 1 ? '1' : '0',
                                data: this.selected_infringement_reasons
                            } : undefined,
                            package_gen_request_id: this.filter_submissions_pack.selected ? this.filter_submissions_pack.selected.value : undefined,
                            substatus_id: (!this.subStatusesStore.canSeeSubStatuses || substatus == 0 || substatus == -1) ? undefined : substatus,
                            with_substatus_set: !this.subStatusesStore.canSeeSubStatuses || substatus == -1 ? undefined : substatus != 0
                        },
                        include: ['campaign', 'user', 'info', 'preview', 'platform', 'mainAccount', 'preFilingGroups', 'preFilingGroupListings', 'seller.accounts', 'seller.platform', 'qualifier', 'case', 'caseSeller', 'evidences', 'infringementIndicators'],
                        // includeFilter: {
                        //     evidences: {
                        //         type: 'merged'
                        //     }
                        // },
                        page_size: 10000
                    };
                    if(this.access_level == 6 && this.$route.params.status.toLowerCase() == 'accepted'){
                        queries.filter.qflag_id = qflag
                    }
                    if(this.customSorting) {
                        queries.sort = "-campaign_platform_prioritization_priority,-platform_data_prioritization_priority,date_captured,created_at"
                        // queries.sort = "data_prioritization"
                        // queries.sort = "-date_captured,created_at,-platform_priority"
                    }
                    let response = await axios.get(
                        `${process.env.VUE_APP_URL}/api/v2/listings?${qs.stringify(queries, { encodeValuesOnly: true })}`,
                        {
                            headers: buildHeader()
                        }
                    );
                    console.log(response.data.data)
                    this.listings = response.data.data.map(listing => ({
                        campaign: listing.campaign.name,
                        campaign_id: listing.campaign_id,
                        campaign_image: getImage('campaigns', listing.campaign.name),
                        claim_type: null,
                        claim_type_id: listing.claim_type_id,
                        created_at: listing.created_at,
                        evidences: {
                            Preview: listing.preview && listing.preview[0] && `${listing.id}/${listing.preview[0].file_name}`,
                        },
                        mergedEvidences: (listing.evidences.filter(l => l.type == 'merged').length) > 0,
                        for_commission: listing.for_commission,
                        group_name: listing.pre_filing_groups && listing.pre_filing_groups[0] && listing.pre_filing_groups[0].name,
                        id: listing.id,
                        key_id: this.getRandomKey(),
                        listing_info: {
                            date_captured: this.getListingInfoValue('date_captured', listing),
                            item_type: this.getListingInfoValue('item_type', listing),
                            listing_title: this.getListingInfoValue('listing_title', listing),
                        },
                        name_email: listing.main_account && listing.main_account.name_email,
                        number: listing.main_account && listing.main_account.number,
                        platform: listing.platform && listing.platform.name,
                        platform_id: listing.platform_id,
                        platform_image: listing.platform && getImage('platforms', listing.platform.name),
                        qflag_id: listing.qflag_id,
                        qualifier: listing.qualifier && getUserFullName(listing.qualifier),
                        seller: [{
                            name: listing.qflag_id == 34 ? listing.case_seller && listing.case_seller[0] && listing.case_seller[0].name : listing.seller && listing.seller.name,
                            url: listing.qflag_id == 34 ? listing.case_seller && listing.case_seller[0] && listing.case_seller[0].url : listing.seller && listing.seller.url,
                            site_id: listing.qflag_id == 34 ? listing.case_seller && listing.case_seller[0] && listing.case_seller[0].site_id : listing.seller && listing.seller.site_id,
                            seller_contactable: listing.seller?.contactable
                        }],
                        status: this.$route.params.status,
                        type: listing.main_account && listing.main_account.type,
                        url: listing.url,
                        user_id: listing.user_id,
                        verifier_owner: {
                            first_name: listing.user && listing.user.first_name,
                            last_name: listing.user && listing.user.last_name
                        },
                        main_account: listing.main_account,
                        case: listing.case,
                        group_listing_id: listing.pre_filing_group_listings.length > 0 ? listing.pre_filing_group_listings[0] : {},
                        ship_to_us: 1,
                        keyword: listing.keyword,
                        dummy_account: this.getListingInfoValue('dummy_account', listing) ?? null,
                        item_location: this.getListingInfoValue('listing_location', listing) ?? null,
                        shipping_location: this.getListingInfoValue('shipping_location', listing) ?? null,
                        initial_price: this.getListingInfoValue('listing_price', listing) ?? null,
                        currency: this.getListingInfoValue('currency', listing) ?? null,
                        logo_on_item: this.getListingInfoValue('logo_on_item_image', listing) ?? null,
                        brand_on_item: this.getListingInfoValue('brand_on_item_image', listing) ?? null,
                        purchase_type: this.getListingInfoValue('purchase_type', listing) ?? null,
                        payment_platform: this.getListingInfoValue('payment_platform', listing) ?? null,
                        transaction_number: this.getListingInfoValue('transaction_number', listing) ?? null,
                        purchase_price: this.getListingInfoValue('purchase_price', listing) ?? null,
                        date_captured: this.getListingInfoValue('date_captured', listing) ?? null,
                        infringement_reason: listing.infringement_indicators
                    }));

                    this.copyListings()
                    this.listings_meta = response.data
                    delete this.listings_meta.data

                    let filters = Object.keys(queries.filter).filter(filterKey => !["qflag_id", "campaign_id"].includes(filterKey) && queries.filter[filterKey] && queries.filter[filterKey].length);
                    if (!filters.length) {//meaning, an initialization request
                        this.listingsClaimTypes = Array.from(this.listings.reduce((set, listing) => {
                            set.add(listing.claim_type_id || null);//there are other listings that have a 0 claim_type_id instead of null. To unite null and 0 values into 1, null is used instead
                            return set;
                        }, new Set()));
                    }

                    
                }
            } catch (err) {
                console.log({ err })
                this.searching = false
            }
            this.loaded = true
            this.first_load = true
            this.searching = false
            if (refresh) this.$parent.hideBackdrop()
        },
        getCaseSellerAccountsTypes() {
            let types = [];

            for (const listing of this.listings) {
                for (const caseSellerAccount of listing.case_seller_accounts) {
                    types = [...types, caseSellerAccount.type.toLowerCase()];
                }
            }

            const uniqueTypes = [ ...new Set(types) ];

            useCommonStore().caseSellerAccountsTypes = uniqueTypes;
        },
        preview(rid) {
            return `${process.env.VUE_APP_URL}/files/${rid}/Preview.jpg`
        },
        /*
        * Function to check if the value or the value entered is a number, dot, or comma, if not, then no no no. hahaha - Dev: Marvin 12/14/2019
        */
        isNumber(e) {
            var event = e || window.event;
            var keyCode = event.keyCode || event.which;
            var key = String.fromCharCode(keyCode);
            var regex = /^[0-9.,\b]+$/;
            if (key.length == 0) return;
            if (keyCode == 188 || keyCode == 190) return;
            else if (this.$route.params.case_id != '0' && keyCode == 45) return;
            else {
                if (!regex.test(key)) {
                    event.returnValue = false;
                    if (event.preventDefault) event.preventDefault();
                }
            }
        },
        navigate(page) {
            this.$router.push({ name: page })
        },
        backToCases() {
            this.$router.push({
                name: "lawfirmlisting", params: {
                    status: this.$route.params.status,
                    campaign_id: this.$route.params.campaign_id,
                    case_id: 0
                }
            })
        },
        backToCampaigns() {
            this.$router.push("/lawfirm/campaigns")
            // window.location.href = '/lawfirm/campaigns'
        },
        current: async function (id, index) {
            this.modalLoading = true
            let currentItemType = await this.getCurrentItemType(id);
            await new Promise(resolve => {
                this.show
                this.index = index
                // this.modalLoading = true
                let headers = JSON.parse(localStorage.getItem('headers'))
                // headers.headers.where = `{"id" : ${id}}`
                axios.get(`${process.env.VUE_APP_URL}/listings/${id}`, headers).then(
                    response => {
                        this.listing = response.data.data[0]
                        this.listing.listing_info.item_type_id = currentItemType;
                        this.modalLoading = false
                        resolve()
                    })
            })
        },
        currentPostFiling: async function (id, index) {
            await new Promise(resolve => {
                this.show
                this.index = index
                this.modalLoading = true
                let headers = JSON.parse(localStorage.getItem('headers'))
                // headers.headers.where = `{"id" : ${id}}`
                axios.get(`${process.env.VUE_APP_URL}/post_filed_listings/${id}/${this.$route.params.case_id}`, headers).then(
                    response => {
                        // console.log(response.data)
                        this.listing = response.data.data[0]
                        this.modalLoading = false
                        resolve()
                    })
            })
        },
        ucFirst(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        getStatuses: async function () {
            this.headers = localStorage.getItem('headers')
            await axios.get(process.env.VUE_APP_URL + "/qflag?page_size=100", JSON.parse(this.headers)).then(
                response => {
                    let statuses = {}
                    for (let x of response.data.data) {
                        statuses[x.label.toLowerCase()] = x.id
                    }
                    this.statuses = statuses
                })
        },
        money(amount) {
            if (!Number(amount)) {
                return "0"
            }
            return amount
        },
        total(type, index = '') {
            let total = 0
            type = `amount_${type}`
            if (index == '') {
                for (let x of this.listings) {
                    for (let y of x.case_seller_accounts || []) {
                        if (typeof y[type] != 'undefined' && y[type] != null) {
                            let val = parseInt(y[type])
                            total = total + val
                        }
                    }
                }
            } else {
                for (let x of this.listings[index].case_seller_accounts) {
                    if (typeof x[type] != 'undefined' && x[type] != null) {
                        let val = parseInt(x[type])
                        total = total + val
                    }
                }
            }
            return this.money(total)
        },
        editSellerAccount: async function (index, x, type = 'edit') {
            this.index = index
            this.account_index = x
            const current = this.filteredListings[index]
            let headers = JSON.parse(this.headers)
            headers.headers.Listing = current.id.toString()
            headers.headers['Campaign-id'] = this.$route.params.campaign_id
            let account = current.case_seller_accounts[x]
            if (type == 'save') {
                let initial = JSON.parse(this.account_initial)
                if (initial.received_amount_settled != this.received_amount_settled) {
                    if (parseInt(this.amount_settled) == 0) {
                        this.$parent.showSnackbar("Agreed Settlement amount is not set.", false, 'invalid', 'fas fa-times-circle')
                        return;
                    }
                    if (parseInt(initial.default_amount_received) > 0) {
                        this.confirmUpdate("Warning", "Default money already received. If you proceed, you might need to refund the defendant")
                        while (this.confirm_update.status !== true && this.confirm_update.status !== false) {
                            await sleep(500);
                        }
                        if (!this.confirm_update.status) {
                            // $('#edit-seller-modal').modal('show')
                            return;
                        }
                    }
                }
                await sleep(400);
                if (initial.default_amount_received != this.default_amount_received && this.access.defaults) {
                // if (initial.default_amount_received != this.default_amount_received && !this.isFeatureRestricted('cm-text-defaulted-figures')) {
                    if (parseInt(initial.received_amount_settled) > 0) {
                        this.confirmUpdate("Warning", "Settlement payment already received. If you proceed, you might need to refund the defendant")
                        while (this.confirm_update.status !== true && this.confirm_update.status !== false) {
                            await sleep(1000);
                        }
                        if (!this.confirm_update.status) {
                            // $('#edit-seller-modal').modal('show')
                            return;
                        }
                    }
                }
                this.$parent.showSnackbar(`Updating seller account.. Please wait..`)
                let payload = {}
                payload.case_seller_accounts = {}
                payload.case_listings = {}
                payload.case_listings[current.case_listing[0].id] = {}
                payload.case_seller_accounts[account.id] = {}
                payload.case_listings[current.case_listing[0].id].case_flag_id = this.seller_flag
                payload.case_seller_accounts[account.id].amount_frozen = this.amount_frozen
                payload.case_seller_accounts[account.id].amount_updated_frozen = this.amount_updated_frozen
                payload.case_seller_accounts[account.id].amount_frozen_cny = this.amount_frozen_cny
                payload.case_seller_accounts[account.id].amount_updated_frozen_cny = this.amount_updated_frozen_cny
                payload.case_seller_accounts[account.id].amount_released = this.amount_released
                payload.case_seller_accounts[account.id].amount_settled = this.amount_settled
                payload.case_seller_accounts[account.id].amount_received_settlement = this.received_amount_settled
                payload.case_seller_accounts[account.id].amount_received_default = this.default_amount_received
                payload.case_seller_accounts[account.id].amount_received_ctda_default = this.default_ctda_amount_received
                payload.case_seller_accounts[account.id].name_email = this.account_name
                payload.case_seller_accounts[account.id].number = this.fit_number
                payload.case_seller_accounts[account.id].notes = this.notes
                payload.case_seller_accounts[account.id].location = this.location
                await axios.patch(
                    process.env.VUE_APP_URL + "/multi",
                    payload,
                    headers
                ).then(
                    async response => {
                        this.$parent.showSnackbar(`Successfully updated seller account.`, false, 'success', 'fas fa-check')
                        this.closeModal()
                        // this.getListings(false)
                        let copy = this.listings
                        await this.currentPostFiling(current.id, index)
                        let c_account = this.listing.case_seller_accounts.filter(account => account.id == copy[index].case_seller_accounts[x].id)[0]
                        let c_listing = this.listing.case_listing[0]
                        copy[index].case_seller_accounts[x].amount_frozen = c_account.amount_frozen
                        copy[index].case_seller_accounts[x].amount_updated_frozen = c_account.amount_updated_frozen
                        copy[index].case_seller_accounts[x].amount_frozen_cny = c_account.amount_frozen_cny
                        copy[index].case_seller_accounts[x].amount_updated_frozen_cny = c_account.amount_updated_frozen_cny
                        copy[index].case_seller_accounts[x].amount_settled = c_account.amount_settled
                        copy[index].case_seller_accounts[x].amount_received_settlement = c_account.amount_received_settlement
                        copy[index].case_seller_accounts[x].amount_received_default = c_account.amount_received_default
                        copy[index].case_seller_accounts[x].amount_received_ctda_default = c_account.amount_received_ctda_default
                        copy[index].case_seller_accounts[x].number = c_account.number
                        copy[index].case_seller_accounts[x].notes = c_account.notes
                        copy[index].case_seller_accounts[x].location = c_account.location
                        copy[index].case_seller_accounts[x].name_email = c_account.name_email
                        // copy[index].case_seller_accounts = c_account
                        copy[index].case_listing[0].case_flag_id = c_listing.case_flag_id
                        copy[index].case_listing[0].case_flag = c_listing.case_flag
                        copy[index].key_id = this.getRandomKey()
                        this.listings = copy
                        // this.listings[index].case_seller_accounts[x].amount_frozen = this.decimal(this.amount_frozen)
                        // // this.listings[index].case_seller_accounts[x].amount_released = this.amount_released
                        // this.listings[index].case_seller_accounts[x].amount_settled = this.decimal(this.amount_settled)
                        // this.listings[index].case_seller_accounts[x].amount_received_settlement = this.decimal(this.received_amount_settled)
                        // this.listings[index].case_seller_accounts[x].amount_received_default = this.decimal(this.default_amount_received)
                        // this.listings[index].case_seller_accounts[x].amount_received_ctda_default = this.decimal(this.default_ctda_amount_received)
                        // this.listings[index].case_listing[0].case_flag_id = this.seller_flag
                        // this.listings[index].case_seller_accounts[x].number = this.fit_number
                        // this.listings[index].case_seller_accounts[x].name_email = this.account_name
                        // for(let x of this.seller_flags){
                        //     if(x.value == this.seller_flag){
                        //         this.listings[index].case_listing[0].case_flag = x.label
                        //         break
                        //     }
                        // }
                    })
                    .catch(err => {
                        this.$parent.showSnackbar(err.response.data.meta.message, false, 'invalid', 'fas fa-times-circle')
                    })
            } else {
                this.amount_frozen = account.amount_frozen == null ? '' : account.amount_frozen
                this.amount_updated_frozen = account.amount_updated_frozen == null ? '' : account.amount_updated_frozen
                this.amount_frozen_cny = account.amount_frozen_cny == null ? '' : account.amount_frozen_cny
                this.amount_updated_frozen_cny = account.amount_updated_frozen_cny == null ? '' : account.amount_updated_frozen_cny
                this.amount_released = account.amount_released == null ? '' : account.amount_released
                this.amount_settled = account.amount_settled == null ? "" : account.amount_settled
                this.received_amount_settled = account.amount_received_settlement == null ? "" : account.amount_received_settlement
                this.default_amount_received = account.amount_received_default == null ? "" : account.amount_received_default
                this.default_ctda_amount_received = account.amount_received_ctda_default == null ? "" : account.amount_received_ctda_default
                this.seller_flag = current.case_listing[0].case_flag_id
                this.fit_number = account.number
                this.notes = account?.notes || ''
                this.location = account?.location || ''
                this.account_name = account.name_email?.trim() ?? null
                this.account_initial = JSON.stringify({
                    amount_frozen: this.amount_frozen,
                    amount_updated_frozen: this.amount_updated_frozen,
                    amount_frozen_cny: this.amount_frozen_cny,
                    amount_updated_frozen_cny: this.amount_updated_frozen_cny,
                    amount_released: this.amount_released,
                    amount_settled: this.amount_settled,
                    received_amount_settled: this.received_amount_settled,
                    default_amount_received: this.default_amount_received,
                    default_ctda_amount_received: this.default_ctda_amount_received,
                    seller_flag: this.seller_flag,
                    fit_number: this.fit_number,
                    notes: this.notes,
                    location: this.location,
                    account_name: this.account_name
                })
            }
        },
        resetPredefinedReasonState() {
            this.selected_reasons = [];
            return this.$store.dispatch('resetPredefinedReasonState');
        },
        getPredefinedReasons(qflagId) {
            const headers = this.$session.get('lawfirm_headers')
            this.selected_reasons = []
            this.already_worked_lids = ''
            this.$store.dispatch('resetPredefinedReasonState')
            const qflag = this.$parent.getQFlag(qflagId)
            if (this.allPredefinedReasons[qflag]?.length > 0) {
                this.predefinedReasons = this.allPredefinedReasons[qflag]
                this.fetchingReasons = true
                return
            }
            this.is_loading = true
            this.$http.get(`/predefined_reasons/${qflagId}?page_size=100`, headers)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;

                        /* this.predefinedReasons = result.data
                            .filter(reason => reason.status && !isReasonRestricted(reason))
                            .map(reason => ({
                                id: reason.id,
                                type: reason.type,
                                reason: reason.sub_reasons.length
                                    ? {
                                        [reason.reason]: reason.sub_reasons
                                            .filter(sub => sub.status && !isReasonRestricted(sub))
                                            .map(sub => ({ id: sub.id, reason: sub.text }))
                                    }
                                    : reason.reason
                            })); */

                        const reasons = result.data;

                        const role = this.user?.role ?? this.user.access_level;
                        const isReasonRestricted = ({ restricted_roles }) => restricted_roles !== null && restricted_roles.includes(role);

                        this.predefinedReasons = reasons.filter(reason => reason.status && !isReasonRestricted(reason))
                            .map(item => {
                                //TODO: refactor this code. Change the returned from this function.
                                const { id, type, severity_level, sub_reasons, reason, restricted_roles } = item;

                                const getSubReasons = () => sub_reasons
                                    .filter(item => item.status && !isReasonRestricted(item))
                                    .map(item => ({ id: item.id, severity_level: item.severity_level, reason: item.text, restricted_roles: item.restricted_roles }));

                                const mainReason = sub_reasons.length ? { [reason]: getSubReasons() } : reason;

                                const WITH_SUBREASONS = 2;

                                // if (+type === WITH_SUBREASONS && mainReason[reason].length === 0) {

                                if (+type === WITH_SUBREASONS && sub_reasons?.length === 0) {
                                    return null;
                                }

                                return { id, type, severity_level, reason: mainReason, restricted_roles };
                            })
                            .filter(item => item !== null);

                        this.allPredefinedReasons[qflag] = this.predefinedReasons
                        this.fetchingReasons = true
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$parent.showSnackbar('Something went wrong. Please contact system admin.', false, 'success', 'fas fa-check-circle')
                })
                .finally(() => this.is_loading = false)
        },
        multiMarkAs(status, modal) {
            this.markAs(status, this.batch_comment, this.selected_listings.join(), this.selected_reasons, modal)
        },
        multiReassign() {
            let payload = { "listings": {} }
            for (let x of this.selected_listings) {
                payload.listings[x] = { user_id: this.selected_reassign_user, qflag_id: 2 }
            }
            this.reassign(payload)
        },
        /**
         * @param {string | number} status
         * @param {string} comment
         * @param {string | number} id
         * @param {Array<string | number>} checked_reasons
         * @param {string} modal
         */
        async markAs(status, comment, id, checked_reasons = [], modal = 'listing-modal') {
            if (this.showRestrictedReasons()) {
                return;
            }
            this.checkSelectedListing()
            if (this.emptyListingInfo && !this.isMultipleChecking) {
                $('.modal').modal('hide')
                this.$parent.hideBackdrop();
                this.is_loading = false
                this.status_changing = false
                this.isIndividualChecking = true
                this.isMultipleChecking = false
                $('#listing-empty-info').modal('show')
                return
            }
            //Check for potentials when accepting listing
            if (status === 'accepted') {
                const callback = async () => {
                    const hasWhitelist = this.$store.state.potentialWhitelists.some(({ potentials }) => {
                        return potentials.some(potential => potential.is_whitelist)
                    })
                    if (hasWhitelist) {
                        this.$parent.showSnackbar('Seller is whitelist', false, 'invalid', 'fas fa-times-circle')
                        return
                    }
                    const acknowledgement = { feature: 'status change', status: 1, notes: '' }
                    await this.$store.dispatch('acknowledgeWhitelist', acknowledgement)
                    $('#potential-whitelists').modal('hide')
                    $(`#${modal}`).modal('show')

                    this.hasAcknowledged = true
                    await this.multipleStatusChange('' + id, 32)
                    this.hasAcknowledged = false
                }
                const hasPotentials = await this.checkPotentialWhitelists([id], modal, callback)
                if (hasPotentials) return
            }
            // if (['monitoring', /* 'accepted', */ 'rejected', 'for-internal-review', 'haspo', 'recycled'].includes(status) || this.isInFiledTab) {
            if (!['accepted', 'initial-review-completed', 'submitted'].includes(status) || this.isInFiledTab) {
                const hasNoReasons = this.$store.state.checked_reasons.length === 0
                    && this.$store.state.checked_subreasons.length === 0
                    && isEmpty(this.$store.state.checked_other_reason)
                if (hasNoReasons)
                    return this.$parent.showSnackbar('Please select reason(s)', false, 'invalid', 'fas fa-times-circle')
                if (!isEmpty(this.$store.state.checked_other_reason)) {
                    if (isEmpty(this.$store.state.other_reason_comment))
                        return this.$parent.showSnackbar('Please provide comment', false, 'invalid', 'fas fa-times-circle')
                }
            }

            if (!this.isClientListingReview)
                    this.$parent.showSnackbar('Updating status.. Please wait..', true)

            this.status_changing = true
            this.already_worked_lids = ''
            if (status == '') {
                this.$parent.showSnackbar(`Status should be set.`, false, 'invalid', 'fas fa-exclamation-circle')
                this.status_changing = false
                return
            }
            // if (this.user.id == 814)
            //     comment = ''
            // if (this.user.id != 814) {
            //     if (checked_reasons.length > 0 && status == 'rejected') {
            //         let otherIndex = ""
            //         if ((otherIndex = checked_reasons.indexOf("Others:")) !== -1) {
            //             if (comment == '') {
            //                 this.$parent.showSnackbar(`Comment should be set.`, false, 'invalid', 'fas fa-exclamation-circle')
            //                 this.status_changing = false
            //                 return
            //             }
            //             checked_reasons[otherIndex] = `Others: ${comment || 'No Comment'}`
            //             // this.selected_reasons.push(comment)
            //         }
            //         let selected_reasons = checked_reasons
            //         let otherReason = ""
            //         let reasons = []
            //         // sort reasons and put other reason at the end
            //         for (var index = 0; index < selected_reasons.length; index++) {
            //             if (selected_reasons[index].includes("Others:"))
            //                 otherReason = selected_reasons[index]
            //             else
            //                 reasons.push(selected_reasons[index])
            //             if (index === selected_reasons.length - 1 && otherReason != "")
            //                 reasons.push(otherReason)
            //         }
            //         comment = reasons.join(', ')
            //     } else if (status == 'rejected') {
            //         this.$parent.showSnackbar(`Reasons or comment should be set.`, false, 'invalid', 'fas fa-exclamation-circle')
            //         this.status_changing = false
            //         return
            //     }
            // }
            if (status == 'wip') status = 'work in progress'
            if (status == 'forreview') status = 'qualified'
            if (status == 'for-internal-review') status = 'for internal review'
            if (status == 'initial-review-completed') status = 'initial review completed'
            if (status == 'haspo') status = 'has potential'
            let listing_ids = id
            let attempt = 0
            while (attempt < 4) {
                if (typeof this.statuses[status] !== "undefined")
                    break;
                await this.getStatuses();
                attempt++
            }
            if (typeof this.statuses[status] === "undefined")
                return this.$parent.showSnackbar('Status change issue encountered.. Please refresh and try again', false, 'warning', 'fas fa-exclamation-triangle')
            const response = await this.checkDuplicateListings(listing_ids, this.statuses[status])

            if (response.data.data && response.data.data[0] && response.data.data[0]?.duplicate_logs != null) {
                const d = response.data.data[0]
                const duplicate_logs = { location: d.duplicate_logs, data: d.duplicates }
                $('.modal').modal('hide')
                await sleep(500)
                this.setRelatedCampaignDuplicateContinueFunction(() => this.markAs(status, comment, id, checked_reasons, modal))
                this.setRelatedCampaignDuplicates(duplicate_logs)

                // this.$parent.showSnackbar(`${response.data.meta.message}`, false, 'invalid', 'fas fa-times-circle');
                this.$parent.hideSnackbar();
                return
            }

            if (response.status !== 200) {
                return this.$parent.showSnackbar('Something went wrong. Please contact System Admin', false, 'invalid', 'fas fa-times-circle')
            }

            const show = this.showDuplicateListingsModal(response.data.data)

            if (show) {
                this.is_loading = false
                this.$parent.hideSnackbar()
                const __listing_ids = listing_ids
                const listingsWithDuplicate = response.data.data.map(({ reference }) => +reference.id)
                //Remove listings with duplicate from body
                listing_ids = id.toString().split(',')
                    .filter(listing => !listingsWithDuplicate.includes(+listing))
                    .join()
                if (listing_ids === '') {
                    this.is_loading = false
                    this.status_changing = false
                    this.closeModal()
                    return this.$parent.showSnackbar('All selected listing(s) has duplicate', false, 'invalid', 'fas fa-times-circle')
                }
                this.duplicateListingModalOnContinue = () => this.multipleStatusChange(listing_ids, this.statuses[status], modal, comment)
                this.duplicateListingModalProceedAnyway = () => this.multipleStatusChange(__listing_ids, this.statuses[status], modal, comment)
                return
            }
            this.setRelatedCampaignDuplicateBypass(false)
            this.multipleStatusChange(listing_ids, this.statuses[status], modal, comment)
            /*
            const headers = JSON.parse(this.headers)
            headers.headers["Campaign-id"] = this.$route.params.campaign_id
            const body = {
                comment    : comment,Z
                listing_ids: id.toString(),
                from_lf    : true//Use as reference to avoid checking pre-qualified statuses
            }
            // listings/${id}/statuschange/${this.statuses[status]} - old endpoint //? Marvin 02/10/2020
            // this.$http.patch(`/listings/multiple/statuschange/${this.statuses[status]}`, body, headers)
            this.$http.patch(`/listings/multiple/statuschange/${ this.statuses[status] }`, body, headers)
                .then( response => {
                    let result = response.data
                    //Alert user when seller is whitelisted
                    if(result.meta.whitelisted) {
                        this.detected_whitelist = {
                            name     : result.data.seller_name,
                            url      : result.data.seller_url,
                            site_id  : result.data.site_id,
                            platforms: result.data.platforms.join(', '),
                            campaigns: result.data.campaigns .join(', ')
                        }
                        $('.modal').modal('hide')
                        $('#whitelist-info-modal').modal('show')
                        this.status_changing = false
                        return this.$parent.showSnackbar('Seller is whitelisted', false, 'invalid', 'fas fa-times-circle')
                    }
                    if(status == 'qualified') {
                        status = 'for review'
                    }
                    if(status == 'hacked') status = 'auto qualified'
                    this.$parent.showSnackbar(`Successfully marked listing as ${this.ucFirst(status)}.`, false, 'success', 'fas fa-check')
                    this.status_changing = false
                    // this.listings.splice(this.index, 1);
                    this.batch_comment   = ""
                    this.is_select_all   = false
                    this.selectAll()
                    setTimeout(() => {
                        this.closeModal()
                        // to call and reinitialize the statuses in the filter drawer - Marvin 01/28/20
                        let filter_drawer = this.siblings_component.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
                        filter_drawer.dem_statuses = []
                        filter_drawer.initializeStatuses()
                        this.selected_reasons = []
                        this.getListings()
                    });
                })
                .catch(error => {
                    console.error(error)
                    this.already_worked_lids = error.response.data.data.map(list => {
                        let worked = list.duplicate[0]
                        return { origin_lid: list.reference.id, seller_name: worked?.name, lid: worked?.id, qflag: worked?.qflag_id }
                    } )
                    if (this.selected_listings.length != this.already_worked_lids.length) {
                        let filter_drawer = this.siblings_component.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
                        filter_drawer.dem_statuses = []
                        filter_drawer.initializeStatuses()
                        this.selected_reasons = []
                        this.getListings(false)
                    }
                    //Show duplicate sellers when trying to change status
                    if(error.response.data.meta.has_duplicate) {
                        this.closeModal()
                        this.duplicate_seller = error.response.data.data.map(({duplicate, reference}) => ({...duplicate, ref: reference.id}))
                        $('#duplicate-seller-modal').modal('show')
                    }
                    this.$parent.showSnackbar(error.response.data.meta.message, false, "invalid", "fas fa-exclamation-circle")
                    this.status_changing = false
                })
                */
        },
        updateAcknowledged(value) {
            this.hasAcknowledged = value
        },
        updateStatusChanging(value) {
            this.status_changing = value
        },
        updateWorkedLids(value) {
            this.already_worked_lids = value
        },
        /**
         * @param {Array<number>} lids
         * @param {number} status
         * @param {string} initiator
         * @param {string} modal
         * @returns {Promise<boolean>} Has potential whitelist sellers
         */
        async checkPotentialWhitelists(lids, modal = 'listing-modal', callback) {
            if (this.isCheckingPotentials) return
            this.isCheckingPotentials = true

            if (modal !== '') {
                this.onModalClose = () => {
                    this.isCheckingPotentials = false
                    $(`#${modal}`).modal('show')
                    setTimeout(() => $('body').addClass('modal-open'), 1000)
                }
            }
            const sellers = this.listings.filter(({ id }) => lids.includes(id))
                .map(({ seller }) => ({ name: seller[0].name, url: seller[0].url }))
            const { success, data, message } = await this.$store.dispatch('checkPotentialWhitelists', sellers)
            if (!success) {
                this.showSnackbar(message, false, 'invalid', 'fas fa-times-circle')
                return false
            }
            if (data.length === 0) return false
            await this.$store.dispatch('setPotentialWhitelists', data)
            if (modal !== '') $(`#${modal}`).modal('hide')

            this.acknowledgeWhitelists = callback
            
            if(this.access_level != 6 && this.access_level != 3) {
                $('#potential-whitelists').modal('show')
            }
            else {
                this.acknowledgeWhitelists()
            }

            return true
        },
        setPredefinedReasons() {
            const reasons = this.$store.state.checked_reasons
            const reasonsInfo = this.$store.state.checked_subreasons
            const otherReason = this.$store.state.checked_other_reason[0] ?? null
            const comment = this.$store.state.other_reason_comment

            let selectedReasons = []
            if (reasons.length) {
                const tempReasons = reasons.map(reason => ({ id: reason.id, reasons_info: [], other_reason: '' }))
                selectedReasons = [...selectedReasons, ...tempReasons]
            }

            if (reasonsInfo.length) {
                const reasonIds = [...new Set(reasonsInfo.map(({ reason_id }) => reason_id))]
                for (const id of reasonIds) {
                    const filteredReasonsInfo = reasonsInfo.filter(ri => ri.reason_id === id).map(ri => ri.id)
                    const tempReasonsInfo = { id, reasons_info: filteredReasonsInfo, other_reason: '' }
                    selectedReasons = [...selectedReasons, tempReasonsInfo]
                }
            }

            if (otherReason !== null) {
                const tempOther = { id: otherReason, reasons_info: [], other_reason: comment }
                selectedReasons = [...selectedReasons, tempOther]
            }

            return selectedReasons
        },
        /**
         * Update the status of provided listing ids
         * @param {string} listing_ids
         * @param {number} status
         */
        async multipleStatusChange(listing_ids, status, modal, comment) {
            const exceptedStatus = [33, 99]
            if (!this.hasAcknowledged && !exceptedStatus.includes(+status)) {
                const lids = String(listing_ids).split(',').map(id => +id)
                const callback = async () => {
                    const acknowledgement = { feature: 'status change', status: 1, notes: '' }
                    await this.$store.dispatch('acknowledgeWhitelist', acknowledgement)
                    $('#potential-whitelists').modal('hide')
                    if(modal)
                        $(`#${modal}`).modal('show')

                    this.hasAcknowledged = true
                    await this.multipleStatusChange(lids.join(), status)
                    this.hasAcknowledged = false
                }
                const hasPotentials = await this.checkPotentialWhitelists(lids, modal, callback)
                this.isCheckingPotentials = false
                if (hasPotentials) {
                    this.$parent.hideSnackbar()
                    this.status_changing = false
                    return
                }
            }

            const subStatusMainStatus = this.$parent.getQFlag(status)

            if (this.subStatusesStore?.subStatuses?.total_item_by_substatus?.[subStatusMainStatus]?.filter(s => s.id)?.length && this.subStatusesStore.currentSelectedSubStatus == null) {
                this.status_changing = false
                return this.$parent.showSnackbar('Please select a sub status', false, 'warning', 'fas fa-exclamation-triangle')
            }

            const headers = JSON.parse(this.headers)
            headers.headers['Campaign-id'] = this.$route.params.campaign_id
            const body = {
                target_substatus_id: this.subStatusesStore.currentSelectedSubStatus ?? -1,
                listing_ids,
                comment,
                reasons: this.setPredefinedReasons(),
                from_lf: true /** Use as reference to avoid checking pre-qualified statuses */
            }

            // listings/${id}/statuschange/${this.statuses[status]} - old endpoint //? Marvin 02/10/2020
            this.$http.patch(`/listings/multiple/statuschange/${status}`, body, headers)
                .then(async response => {
                    let result = response.data

                    //Alert user when seller is whitelisted
                    if (result?.meta?.whitelisted) {
                        this.detected_whitelist = {
                            name: result.data.seller_name,
                            url: result.data.seller_url,
                            site_id: result.data.site_id,
                            platforms: result.data.platforms.join(', '),
                            campaigns: result.data.campaigns.join(', ')
                        }
                        $('.modal').modal('hide')
                        $('#whitelist-info-modal').modal('show')
                        this.status_changing = false
                        return this.$parent.showSnackbar('Seller is whitelisted', false, 'invalid', 'fas fa-times-circle')
                    }

                    let stringStatus = this.$parent.getQFlag(status)
                    if (stringStatus == 'qualified') stringStatus = 'for review'
                    if (stringStatus == 'hacked') stringStatus = 'auto qualified'

                    if (!this.isClientListingReview)
                        this.$parent.showSnackbar(`Successfully marked listing as ${this.ucFirst(stringStatus)}.`, false, 'success', 'fas fa-check')
                    else {
                        this.clrSuccessMessage = `Successfully marked listing as ${this.ucFirst(stringStatus)}.`
                        $('#message-dialog').modal('show')
                    }

                    this.status_changing = false
                    // this.listings.splice(this.index, 1);
                    this.batch_comment = ""
                    this.is_select_all = false
                    this.selectAll()
                    
                    if (!this.isClientListingReview) {
                        await sleep(500)
                        // this.closeModal()
                        // to call and reinitialize the statuses in the filter drawer - Marvin 01/28/20
                        this.refreshCM_statuses()
                        this.selected_reasons = []
                        await this.getListings()
                        $('.modal').modal('hide')
                    } else {
                        await this.getListings(false)

                        await sleep(1000);
    
                        let attempt = 1
                        while (!this.paginatedListingsPrefiled[this.index]) {
                            if (attempt == 1)
                                this.index += 1
                            else
                                this.index -= 1
                            
                            attempt++;

                            if (attempt == 5) break;
                        }

                        this.onClientReviewDesignClick(this.paginatedListingsPrefiled[this.index].id, this.index)

                        await sleep(2500)

                        $('#message-dialog').modal('hide')
                    }

                    this.setRelatedCampaignDuplicateBypass(false)

                    //Set predefined reason
                    // this.setPredefinedReasons(listing_ids, status)
                })
                .catch(error => {
                    console.error(error)
                    const duplicate_logs = error?.response?.data?.meta?.duplicate_logs
                    if (duplicate_logs) { 
                        this.status_changing = false
                        this.setRelatedCampaignDuplicateContinueFunction(() => this.multipleStatusChange(listing_ids, status, modal, comment))
                        this.setRelatedCampaignDuplicates(duplicate_logs)
                        this.$parent.showSnackbar(error.response.data.meta.message, false, "invalid", "fas fa-exclamation-circle")
                        return
                    }
                    this.already_worked_lids = error.response.data.data.map(list => {
                        const worked = list.duplicate[0]
                        return {
                            origin_lid: list.reference.id,
                            seller_name: worked?.name,
                            lid: worked?.id,
                            qflag: worked?.qflag_id
                        }
                    })
                    if (this.selected_listings.length != this.already_worked_lids.length) {
                        // let filter_drawer = this.siblings_component.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0].$children[0]
                        // filter_drawer.dem_statuses = []
                        // filter_drawer.initializeStatuses()
                        this.refreshCM_statuses()
                        this.selected_reasons = []
                        this.getListings(false)
                    }
                    //Show duplicate sellers when trying to change status
                    if (error.response.data.meta.has_duplicate) {
                        this.closeModal()
                        this.duplicate_seller = error.response.data.data.map(({ duplicate, reference }) => ({ ...duplicate, ref: reference.id }))
                        $('#duplicate-seller-modal').modal('show')
                    }
                    this.$parent.showSnackbar(error.response.data.meta.message, false, "invalid", "fas fa-exclamation-circle")
                    this.status_changing = false
                })
        },
        reassign(payload) {
            let header = JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers)
            header.headers["Campaign-id"] = this.$route.params.campaign_id
            axios.patch(process.env.VUE_APP_URL + "/multi", payload, header)
                .then(response => {
                    if (response.data[0].meta.code == 200) {
                        
                        // trigger notification for user
                        this.selected_listings.forEach(li => {
                            globalSend({ type: 'notification', data: { updater_id: 0, researcher_id: payload[li].user_id, qa_user_id: 0 } });
                        })

                        this.$parent.showSnackbar("Successfully reassigned listing(s)", false, "success", "fas fa-check-circle")
                        this.closeModal()
                        this.selected_reassign_user = 0
                        this.is_select_all = false
                        this.selectAll()
                        setTimeout(() => {
                            this.getListings()
                        }, 1500);
                    }
                })
                .catch(err => { alert(err.response.data.meta.message + "Please contact system admin.") })
        },
        closeModal() {
            $('.modal').modal('hide')
        },
        initializeExport(type, include = false, status_reason = false) {
            let exportObj = {
                campaign_id: this.$route.params.campaign_id,
                export_type: type,
                unique_key: Date.now(),
                export_from: this.$route.params.status == "Cases" ? 'lf' : 'ver'
            }
            if (type == "vendor") {
                exportObj.include = include
                exportObj.status_reason = status_reason
            }

            if (type == "vendorHistory") {
                exportObj.include = include
                exportObj.status_reason = status_reason
            }
            let exportToken
            let lawfirm_item = JSON.parse(localStorage.getItem('lawfirm'))
            if (type == "frozen") {
                exportObj.frozen_type = this.frozen_type == '0' ? 'all' : this.frozen_type
                exportObj.frozen_order = this.frozen_order == '0' ? 'asc' : this.frozen_order

                exportObj.frozen_has_default = this.access_level == 7 ? (this.has_default_frozen == '0') : ''
                // exportObj.frozen_has_default = !this.isFeatureRestricted('cm-text-defaulted-figures') ? (this.has_default_frozen == '0') : '';

                exportToken = this.$parent.base64encode(JSON.stringify(exportObj))
                let cases = {}
                let selected = this.selected_listings
                for (var i = 0; i < selected.length; i++) {
                    cases[selected[i].case_id] = selected[i].case_number
                }
                lawfirm_item[exportToken] = JSON.stringify(cases)
                localStorage.setItem('lawfirm', JSON.stringify(lawfirm_item))
            } else {
                if (type == 'word') {
                    exportObj.excludeOtherDetails = this.word_export.exclude
                }

                if (type === 'excel') {
                    exportObj.areMainAccountsOnly = this.areMainAccountsOnly;
                }

                exportToken = this.$parent.base64encode(JSON.stringify(exportObj))
                lawfirm_item[exportToken] = ['vendorCase', 'vendorCaseHistory'].includes(type) ? this.selected_listings.map(s => s.case_id).join() : this.selected_listings.join()
                localStorage.setItem('lawfirm', JSON.stringify(lawfirm_item))
            }
            let exportLink = this.$router.resolve({ name: 'lawfirmexport', params: { exportToken: exportToken } }).href
            window.open(exportLink, '_blank')

            if (type === 'excel') {
                this.areMainAccountsOnly = false;
                $('#excel-export-modal').modal('hide');
            }
        },
        getUsers() {
            let headers = JSON.parse(this.headers)
            headers.headers.Where = JSON.stringify({ id: [33, 191, 131, 201, 207, 251] })
            axios.get(process.env.VUE_APP_URL + "/user/multi", headers).then(
                response => {
                    if (response.data.meta.code == 200) {
                        this.reassign_users = response.data.data.map(user => { return { "value": user.id, "label": user.first_name + " " + (user.last_name != null ? user.last_name : "") } })
                    }
                })
        },
        getAgreements(listing_id) {

            let headers = JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers)
            axios.get(`${process.env.VUE_APP_URL}/files/sa_templates/case/${this.$route.params.case_id}/${listing_id}`, headers).then(
                response => {
                    if (response.status == 200) {
                        this.agreements = response.data.data
                        // console.log(this.agreements_computed)
                    }
                })
        },
        getSAUploads(id) {
            this.loadingSAUploads = true
            let headers = JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers)
            axios.get(`${process.env.VUE_APP_URL}/file_uploads/case/${id}/SA?page_size=1000`, headers).then(
                response => {
                    if (response.status == 200) {
                        this.saUploads = response.data.data
                        this.loadingSAUploads = false
                        // console.log(this.saUploads)
                    }
                }
            )
        },
        countSettlementReceived(id) {
            let headers = JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers)
            headers.headers.Where = JSON.stringify({ case_flag_id: 3, case_id: id })
            axios.get(`${process.env.VUE_APP_URL}/case_listings?page_size=1`, headers).then(
                response => {
                    if (response.status == 200) {
                        let meta = response.data.meta
                        this.settlementPaymentCount = meta.total_no_of_items
                    }
                }
            )
        },
        async uploadAgreement() {
            this.agreementIsUploading = true
            this.$parent.showSnackbar('Uploading File... Please wait.', true)

            let extensions = [".doc",".docx",".xls",".xlsx",".pdf",".jpg",".jpeg",".png",".bmp"]
            let filename = this.fileNameSelected
            extensions.map((ext)=>{
                if(filename.endsWith(ext)){
                    filename = filename.split(ext).join("")
                }
            })

            let headers = this.$session.get('lawfirm_headers')
            let data = new FormData()

            data.append("file", this.fileList[0])
            data.append("name", filename)
            data.append("type", this.selectedFileType)
            /**
             * AXIOS POST 
             * @param {link, payload (need items to be pass into database), headers}
             */
            await axios.post(`${process.env.VUE_APP_URL}/files/sa_templates/case/${this.$route.params.case_id}/${this.listing_id_current}`, data, headers)
                .then(response => {
                    if (response.data.meta.code == 201) {
                        this.agreementIsUploading = false
                        this.fileNameSelected = null
                        this.selectedFileType = 'select'
                        this.fileList = []
                        this.selectedAgreementFile = null
                        this.$parent.showSnackbar('Sucessfully uploaded file', false, 'success', 'far fa-file-alt')
                    }
                })
            this.getAgreements(this.listing_id_current)
        },
        async deleteAgreement() {
            this.confirmDeletion = true
            this.$parent.showSnackbar('Deleting File... Please wait', true)

            let headers = this.$session.get('lawfirm_headers')
            await axios.delete(`${process.env.VUE_APP_URL}/files/sa_templates/${this.agreements_computed[this.selectedAgreementFile].id}`, headers)
                .then(response => {
                    if (response.status == 200) {
                        this.confirmDeletion = false
                        this.fileNameSelected = null
                        this.selectedFileType = 'select'
                        this.fileList = []
                        this.selectedAgreementFile = null
                        this.$parent.showSnackbar('Successfully Deleted File', false, 'success', 'far fa-file-alt')
                        this.hideConfirmation()
                    }
                })
            this.getAgreements(this.listing_id_current)
        },
        async deleteSAFiles() {
            this.confirmDeletion = true
            this.$parent.showSnackbar('Deleting File... Please wait', true)

            let headers = this.$session.get('lawfirm_headers')
            await axios.delete(`${process.env.VUE_APP_URL}/files/sa_templates/${this.selectedAgreementFile}`, headers)
                .then(response => {
                    if (response.status == 200) {
                        this.confirmDeletion = false
                        this.selectedAgreementFile = null
                        this.hideSAConfirmation()
                        this.$parent.showSnackbar('Successfully Deleted File', false, 'success', 'far fa-file-alt')
                    }
                })
            this.getSAUploads(this.listing_id_current)
        },
        async updateAgreement() {
            this.isUpdatingAgreement = true
            this.$parent.showSnackbar('Updating File... Please wait.', true)

            let headers = this.$session.get('lawfirm_headers')

            let extensions = [".doc",".docx",".xls",".xlsx",".pdf",".jpg",".jpeg",".png",".bmp"]
            let filename = this.fileNameSelected
            extensions.map((ext)=>{
                if(filename.endsWith(ext)){
                    filename = filename.split(ext).join("")
                }
            })

            /**
             * AXIOS POST 
             * @param {link, payload (need items to be pass into database), headers}
             */
            await axios.patch(`${process.env.VUE_APP_URL}/files/sa_templates/${this.agreements_computed[this.selectedAgreementFile].id}`, { name: filename, type: this.selectedFileType }, headers)
                .then(response => {
                    if (response.data.meta.code == 200) {
                        this.isUpdatingAgreement = false
                        this.fileNameSelected = null
                        this.selectedFileType = 'select'
                        this.selectedAgreementFile = null
                        this.fileList = []
                        this.$parent.showSnackbar('Sucessfully Updated File', false, 'success', 'far fa-file-alt')
                    }
                })
            this.getAgreements(this.listing_id_current)
        },
        emptyReassignFields() {
            this.selected_reassign_user = 0
            setTimeout(() => {
                var fields = document.querySelectorAll('.modal .search-select input[type=text]')
                if (fields.length > 0) {
                    fields.forEach(field => {
                        field.value = ""
                    })
                }
            })
        },
        updatePage(page, loading = true) {
            this.current_page = page
            this.is_select_all = false
            this.selectAll()
            //this.getListings()
            window.scrollTo(0, 0)
        },
        updateListingPage() {
            var page_input = document.getElementById('pagination_input')
            if (page_input.value > this.last_page) page_input.value = this.last_page
            else if (page_input.value < 1) page_input.value = 1
            this.updatePage(page_input.value)
        },
        updatePageSize(size) {
            /**
             *  Changed maximum from 10000 to 500
             *  Deliverable: `CM: Bulk Transfer from Tab to Another Tab`
             */
            // if (size != "" && size <= 10000)
            // if (size != "" && size <= 500)
            //     this.page_size = parseInt(size)
            // else {
            //     // this.page_size = 10000
            //     // this.$parent.showSnackbar(`Maximum show number of listing is 10000`, false, 'warning', 'fas fa-exclamation-circle')
            //     this.page_size = 500
            //     this.$parent.showSnackbar(`Maximum show number of listing is 500`, false, 'warning', 'fas fa-exclamation-circle')
            // }
            this.current_page = 1;
            this.updatePage(1)
        },
        // filterAvailablePaymentAccounts() {
        //     let paymentAccountsAccordion = document.getElementById('paymentAccountsAccordion')
        //     // console.log(paymentAccountsAccordion.children)
        //     if (this.selected_payment_method != 0) {
        //         paymentAccountsAccordion?.children.forEach(card => {
        //             if (card.id == this.selected_payment_method) {
        //                 card.style.display = ""
        //                 card.style.visibility = ""
        //                 card.style.height = ""
        //             } else {
        //                 card.style.display = "none"
        //                 card.style.visibility = "hidden"
        //                 card.style.height = "0"
        //             }
        //         })
        //         return
        //     }
        //     if (paymentAccountsAccordion != null && typeof paymentAccountsAccordion != "undefined") {
        //         for (const card of paymentAccountsAccordion?.children)
        //         {
        //             card.style.display = ""
        //             card.style.visibility = ""
        //             card.style.height = ""
        //         }
        //     }
        // },
        filterAvailablePaymentAccounts() {
            let paymentAccountsAccordion = document.getElementById('paymentAccountsAccordion')
            // console.log(paymentAccountsAccordion.children)
            if (this.selected_payment_method != 0) {
                for (const card of paymentAccountsAccordion.children) {
                    if (card.id == this.selected_payment_method) {
                        card.style.display = ""
                        card.style.visibility = ""
                        card.style.height = ""
                    } else {
                        card.style.display = "none"
                        card.style.visibility = "hidden"
                        card.style.height = "0"
                    }
                }
                return
            }
            if (paymentAccountsAccordion != null && typeof paymentAccountsAccordion != 'undefined') {
                for (const card of paymentAccountsAccordion.children) {
                    card.style.display = ""
                    card.style.visibility = ""
                    card.style.height = ""
                }
            }
        },
        filterPlatformDataBreakdown() {
            let platformDataBreakdownAccordion = document.getElementById('platformDataBreakdownAccordion')
            // console.log(platformDataBreakdownAccordion.children)
            if (this.selected_platform_data_breakdown != 0) {
                for (const card of platformDataBreakdownAccordion.children) {
                    if (card.id == this.selected_platform_data_breakdown) {
                        card.style.display = ""
                        card.style.visibility = ""
                        card.style.height = ""
                    } else {
                        card.style.display = "none"
                        card.style.visibility = "hidden"
                        card.style.height = "0"
                    }
                }
                return
            }
            if (platformDataBreakdownAccordion != null && typeof platformDataBreakdownAccordion != "undefined") {
                for (const card of platformDataBreakdownAccordion.children) {
                    card.style.display = ""
                    card.style.visibility = ""
                    card.style.height = ""
                }
            }
        },
        clearKeywords() {
            this.search_input = ''
            this.initializeSearch()
        },
        clearGlobalSearch() {
            if (this.search_input == '') {
                this.$store.dispatch('isGlobalSearch', false)
            }
        },
        ownerName(first, last) {
            var first_name = ""
            var last_name = ""
            if (first != null)
                first_name = first.trim()
            if (last != null)
                last_name = last.trim()
            return first_name + " " + last_name
        },
        getCaseImages(evidences) {
            let case_images = []
            let stuffs = Object.values(evidences)
            for (var index = 0; index < stuffs.length; index++) {
                if (case_images.length > 3) break
                var evidence = stuffs[index]
                if (evidence != null && (evidence.preview != null || evidence.Preview != null)) case_images.push(evidence.preview || evidence.Preview)
            }
            return case_images
        },
        getCasePlatforms(listing_platforms) {
            if (listing_platforms == null || listing_platforms.length == 0)
                return { platforms: [], platform_count: 0 }
            let case_platforms = listing_platforms
            let platforms = []
            for (var index = 0; index < case_platforms.length; index++) {
                // if (platforms.length > 2) break -- Commented out Earl, requested to show all platforms in case grid (September, 19, 2022)
                platforms.push({ name: this.$parent.getImage('platforms', case_platforms[index].name || "Default"), count: case_platforms[index].count })
            }
            return { platforms, platform_count: case_platforms.length }
        },
        showMoreFilters() {
            setTimeout(() => {
                let filter_drawer_dom = document.querySelector('.a-sidebar.a-sidebar-filter')
                if (this.$route.params.status == 'Cases' && this.$route.params.case_id != '0') {
                    let thingsToCheck = [this.filters.selected_lf_platforms, this.filters.selected_payment_methods, this.filters.selected_seller_flags, this.filters.selected_counsels]
                    for (var i = 0; i < thingsToCheck.length; i++) {
                        if (thingsToCheck[i] == null || typeof thingsToCheck[i] == 'undefined')
                            continue
                        if (thingsToCheck[i].length > 0) {
                            filter_drawer_dom.style.paddingBottom = "117px"
                            return true
                        }
                    }
                }
                // If decided to show the more filters on the side
                // if (this.$route.params.status != 'Cases') {
                //     filter_drawer_dom.style.paddingBottom = "60px"
                //     return true
                // }
                // filter_drawer_dom.style.paddingBottom = "0"
                filter_drawer_dom.style.paddingBottom = "78px"
                return false
            }, 1000);
        },
        applyMoreFilters() {
            this.appendListingID();
            if (this.expanded) this.expanded = !this.expanded
            // this.updateShowNumberItems()
            this.applied_filters_count = this.filter_set_count()
            this.updatePage(1)
            this.getListings()
        },
        async resetMoreFilters() {
            if (this.expanded)
                this.expanded = !this.expanded
            let filter_drawer = this.$parent.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
            let checked = document.querySelectorAll('input[name=platform]:checked')
            await this.initializeSortOptions()
            // this.show_number_of_items = '0'
            // this.updateShowNumberItems()
            // this.order_by = "desc"
            this.filtered_listing_id = [];
            this.filtered_listing_id_input = null;
            this.infringement_option = '1'
            this.selected_infringement_reasons = []
            this.selected_payment_methods = []
            this.min_date_added = ''
            this.max_date_added = ''
            this.min_date_qualified = ''
            this.max_date_qualified = ''
            this.filter_seller_location = '0'
            this.filter_item_location = ''
            this.filter_email = ''
            this.filter_alias = '' // Newly added filter feature ALIAS - 7/21/22
            this.filter_account_name_email = ''
            this.filter_account_number = ''
            this.filter_item_type = []
            this.filter_submissions_pack.selected = null;
            this.filter_item_class = [];
            this.filter_claim_type = '0'
            this.filters__.item_location.selected = 0
            this.filter_group = '0';
            this.filterDateCaptured = ''
            this.filter_predefined_reasons.selected = { main: [], sub: [] }
            if (this.$route.params.status != 'Cases' || this.$route.params.case_id != '0') {
                checked.forEach(platform => {
                    platform.checked = false
                })
                // document.querySelectorAll('input[name=platform]')[0].checked = true
                filter_drawer.joined_platforms = '0'
                filter_drawer.applyCaseFilters()
            }
            this.applied_filters_count = this.filter_set_count()
            this.updatePage(1)
            this.getListings()
        },
        // removeMoreFilter(filter_type, id) {
        //     let filter_drawer = this.$parent.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
        //     if (filter_type == 'reasons') {
        //         this.selected_infringement_reasons = this.selected_infringement_reasons.filter(flag => flag != id)
        //         this.updatePage(1)
        //     } else if (filter_type == 'payment_methods') {
        //         this.selected_payment_methods = this.selected_payment_methods.filter(method => method != id)
        //         this.updatePage(1)
        //     } else if (filter_type == 'platforms') {
        //         let split = (this.$route.query.platform || '').split(',')
        //         let filtered = split.filter(platform => platform != id)
        //         let checked = document.querySelectorAll('input[name=platforms]:checked')
        //         for (var i = 0; i < checked.length; i++) {
        //             if (checked[i].value == 0) continue
        //             if (checked[i].value == id)
        //                 checked[i].checked = false
        //         }
        //         checked = document.querySelectorAll('input[name=platforms]:checked')
        //         if (checked.length == 0) {
        //             document.querySelectorAll('input[name=platforms]')[0].checked = true
        //         }
        //         filter_drawer.joined_platforms = (filter_drawer.platforms.filter(platform => filtered.includes(platform.value.toString())) || []).map(platform => platform.value).join()
        //         filter_drawer.applyPlatformFilters()
        //     }
        // },
        applyFilters() {
            this.getListings()
        },
        resetFilters() {
            if (this.$route.params.status != "Cases" && this.$route.params.case_id == '0') {
                this.page_size = 100
                this.order_by = 0
                this.sort_by = 0
                this.updatePage(1)
            } else {
                let filter_drawer = this.siblings_component.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
                filter_drawer.selected_lf_platforms = []
                filter_drawer.selected_counsels = []
                filter_drawer.selected_payment_methods = []
                filter_drawer.selected_seller_flags = []
                filter_drawer.applyCaseFilters()
            }
        },
        removeFilter(filter_type, id) {
            let filter_drawer = this.siblings_component.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
            if (filter_type == 'seller_flags') {
                filter_drawer.selected_seller_flags = filter_drawer.selected_seller_flags.filter(flag => flag != id)
            } else if (filter_type == 'counsels') {
                filter_drawer.selected_counsels = filter_drawer.selected_counsels.filter(counsel => counsel != id)
            } else if (filter_type == 'payment_methods') {
                filter_drawer.selected_payment_methods = filter_drawer.selected_payment_methods.filter(method => method != id)
            } else if (filter_type == 'platforms') {
                filter_drawer.selected_lf_platforms = filter_drawer.selected_lf_platforms.filter(platform => platform != id)
            }
            filter_drawer.applyCaseFilters()
        },
        async getReasons(qflag_id) {
            let headers = JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers)
            let reasons = []
            await axios.get(`${process.env.VUE_APP_URL}/status_change_categories/status/${qflag_id}`, headers).then(
                response => {
                    if (response.data.meta.code == 200) {
                        reasons = response.data.data
                    }
                })
            return reasons
        },
        async initializeReasons() {
            this.rejected_reasons = await this.getReasons(33)
        },
        generateDoeExcel() {
            this.doe_generated = false
            let headers = JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers)
            headers.headers["Campaign-id"] = this.$route.params.campaign_id
            axios.post(`${process.env.VUE_APP_URL}/listings/export/doe/${this.$route.params.campaign_id}`, {
                case_id: this.$route.params.case_id
            }, headers)
                .then(response => {
                    if (response.data.meta.code == 200) {
                        this.doe_generated = true
                        window.open(`${process.env.VUE_APP_URL}/excel/${response.data.data}`, '_blank')
                        this.$parent.showSnackbar(`Successfully generated ${response.data.data}`, false, 'success', 'far fa-file-alt')
                    }
                })
        },
        uploadDoeExcel() {
            this.is_uploading_doe = true
            this.$parent.showSnackbar(`Processing request.. Please wait..`, true)
            let token = JSON.parse(localStorage.getItem("lawfirm")).token
            let headers = { headers: { "Content-Type": "multipart/form-data", "Authorization": `Bearer ${token}`, "Gui": "Case Management", "Campaign-id": this.$route.params.campaign_id } }
            let data = new FormData()
            data.append("file", this.file_data)
            axios.post(`${process.env.VUE_APP_URL}/listings/import/doe/${this.$route.params.case_id}`, data, headers)
                .then(response => {
                    if (response.data.meta.code == 200) {
                        this.is_uploading_doe = false
                        this.doe_updates_meta = response.data.meta
                        this.doe_updates = response.data.data
                        this.$parent.showSnackbar(`Successfully uploaded file.`, false, 'success', 'far fa-file-alt')
                        this.file_data = {}
                        this.closeModal()
                        setTimeout(() => {
                            $('#review-doe-changes-modal').modal('show')
                        }, 700)
                    }
                })
        },
        applyDoeChanges() {
            this.is_uploading_doe = true
            this.$parent.showSnackbar(`Processing request.. Please wait..`, true)
            let payload = {
                data: this.doe_updates
            }
            let headers = JSON.parse(this.headers)
            headers.headers["Campaign-id"] = this.$route.params.campaign_id
            axios.patch(`${process.env.VUE_APP_URL}/listings/doe`, payload, headers)
                .then(response => {
                    this.is_uploading_doe = false
                    this.$parent.showSnackbar(`Updates were successfully applied`, false, 'success', 'fas fa-check')
                    this.closeModal()
                    this.initializeSearch()
                })
        },
        viewSourceFile(event) {
            if (event.target.files[0] != null) {
                this.file_case_uploaded = true
                this.file_data = event.target.files[0]
                this.file_name = this.file_data.name
                let name = this.file_data.name.split('.')[0].trim()
                let file = this.file_data.name.split('.')
                let ext = file[file.length - 1].trim();
                this.upload_case_filename = name
                if (ext.toLowerCase() == "doc" || ext.toLowerCase() == "docx") {
                    this.templates.upload.valid = true
                    this.templates.upload.file_name = name
                } else {
                    this.templates.upload.valid = false
                    this.templates.upload.file_name = ""
                }
            }
        },
        showCommentsTextbox(reasons = []) {
            if (reasons.length == 0 || this.selected_reasons.includes('Others:'))
                return true
            this.batch_comment = ''
            return false
        },
        removeSetUploadFile() {
            this.file_data = ""
            let doe_field = document.getElementById('doe-upload-field')
            let released_field = document.getElementById('released-upload-field')
            let frozen_field = document.getElementById('frozen-upload-field')
            if (doe_field != null) doe_field.value = null
            if (released_field != null) released_field.value = null
            if (frozen_field != null) frozen_field.value = null
            this.file_name = ""
            this.file_data = ""
        },
        getUploadedCaseFiles(case_id) {
            $('#uploaded-files-modal').modal('toggle')
            this.filter_upload_type = '0'
            this.current_show_file_case_id = case_id
            this.getCaseFiles(this.current_show_file_case_id, -2)
            this.getCaseStatuses()

            // this.removeCaseUploadFile()
        },
        getSAFiles(id) {
            this.listing_id_current = id
            $('#sa-files-modal').modal('toggle')
            this.filter_sa_type = 'All'
            this.getSAUploads(id)
        },
        removeCaseUploadFile() {
            this.file_data = {}
            this.upload_case_filename = ''
            this.selected_upload_file_type = '0'
            this.file_case_uploaded = false
            document.getElementById('upload-case-file-field').value = ""
        },
        async showUploadCaseFileModal(status) {
            this.isUploadingCaseFiles = false

            $('.modal').modal('hide')
            this.filter_upload_type = '0'
            this.resetCaseFileUploads()
            this.getCaseStatuses()
            
            await sleep(500)

            if (!status) {
                $('#uploaded-files-modal').modal('toggle')
                this.getCaseFiles(this.current_show_file_case_id)
            } else {
                this.isUploadingCaseFiles = true
                $('#upload-case-file-modal').modal('toggle')
            }
        },
        async updateCurrentFileDateGranted(id, date_granted, index = -1) {
            if (index !== -1 && !this.validateDateGranted(index)) return

            try {
                await this.$http.patch(`file_uploads/${id}`, { date_granted }, this.$session.get('lawfirm_headers'))

                this.caseFileUploads[index].date_granted = date_granted
                this.caseFileUploads_copy[index].date_granted = date_granted
            } catch (e) {
                this.showSnackbar(`Error occured when updating current file date granted.`, false, 'invalid', 'fas fa-times-circle')
            }
        },
        showPromptRemoveFile(status, id = "", name = "") {
            $('.modal').modal('hide')
            this.delete_file_data.id = id
            this.delete_file_data.name = name
            setTimeout(() => {
                if (!status) {
                    $('#uploaded-files-modal').modal('toggle')
                    this.getCaseFiles(this.current_show_file_case_id)
                } else {
                    $('#remove-file-modal').modal('toggle')
                }
            }, 500);
        },

        async uploadCaseFile() {
            if (!this.isDateGrantedRestricted) {
                let allowToContinue = true

                this.caseFileUploads.forEach((c, index) => {
                    if (!this.validateDateGranted(index)) {
                        allowToContinue = false
                        return
                    }
                })

                if (!allowToContinue) return
            }

            this.cancelRequests();
            this.refreshCancelToken();
            this.showSnackbar(`Uploading file. Please wait..`, true)
            this.file_case_uploaded = false
            let token = JSON.parse(localStorage.getItem("lawfirm")).token
            let headers = { headers: { "Content-Type": "multipart/form-data", "Authorization": `Bearer ${token}`, "Gui": "Case Management", "Campaign-id": this.$route.params.campaign_id } }

            const filename = [];
            for (let index = 0; index < this.caseFileUploads.length; index++) {
                const current = this.caseFileUploads[index]
                const current_copy = this.caseFileUploads_copy[index]

                if (!this.isDateGrantedRestricted && current.link && current.date_granted != current_copy.date_granted)
                    this.updateCurrentFileDateGranted(current.file_uploads_id, current.date_granted)

                if (current.file == "")
                    continue;

                let data = new FormData()

                data.append("file", current.file)
                data.append("campaign_id", this.$route.params.campaign_id)
                data.append("case_id", this.current_show_file_case_id)
                data.append("type", current.label)
                data.append("case_status_id", current.id)
                data.append("file_name", current.label)
                data.append("date_granted", current.date_granted)

                await axios.post(`${process.env.VUE_APP_URL}/file_uploads/upload`, data, headers)
                filename.push(current.label);
            }
            this.file_case_uploaded = true
            this.isUploadingCaseFiles = false

            /* this.getListings(false)
            this.getCaseFiles(this.current_show_file_case_id, -2) */

            // Update case files directly
            const caseId = this.current_show_file_case_id;

            for (const [ index, listing ] of this.listings.entries()) {
                if (listing.id != caseId) {
                    continue;
                }

                const caseFileUploads = this.caseFileUploads
                const uploads = [...this.listings[index].case_files, caseFileUploads[caseFileUploads.length - 2], caseFileUploads[caseFileUploads.length - 1]]

                this.listings[index].case_files = uploads.map(caseFile => {
                    const fileIndex = filename.findIndex(f => caseFile.label.toLowerCase() === f.toLowerCase())
                    
                    if (fileIndex !== -1) {
                        caseFile.link = `${process.env.VUE_APP_URL}/file_uploads/case/${caseId}/${filename[fileIndex]}.pdf`;
                    }

                    return caseFile;
                });
                break;
            }

            this.updateCaseStatus(this.current_show_file_case_id)

            this.showUploadCaseFileModal(false)
            this.$store.dispatch('uploadedCaseFile', true) // uploadedCaseFile = true in Vuex
            this.showSnackbar(`Successfully uploaded file(s)`, false, 'success', 'far fa-file-alt')
        },
        updateCaseStatus(case_id) {
            const listingIndex = this.listings.findIndex(listing => listing.id == case_id);
            const latest = this.listings[listingIndex].case_files.filter(c => c.link).pop()
            this.listings[listingIndex].case_status = latest.id
            
            if (this.listings[listingIndex].case_files.length == 7) {
                const removeCount = 2;
                for (let i = 0; i < removeCount; i++) this.listings[listingIndex].case_files.pop()
            }
        },
        deleteCaseFile(id, file_name, index = -1, hide_modal = true) {
            this.cancelRequests();
            this.refreshCancelToken();
            this.$parent.showSnackbar(`Deleting file. Please wait..`, true)
            if (hide_modal) $('.modal').modal('hide')
            if (index !== -1) {
                this.removeUploadCaseFile(index, file_name)
                file_name += ".pdf"
            }
            let headers = JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers)
            headers.headers["Campaign-id"] = this.$route.params.campaign_id
            axios.delete(`${process.env.VUE_APP_URL}/file_uploads/case/${id}/${file_name}`, headers)
                .then(async response => {

                    /* this.getListings(false);
                    this.getCaseFiles(id, -2); */

                    // Update case files directly
                    for (const [ index, listing ] of this.listings.entries()) {
                        if (listing.id != id) {
                            continue;
                        }

                        const caseFileUploads = this.caseFileUploads
                        const uploads = [...this.listings[index].case_files, caseFileUploads[caseFileUploads.length - 2], caseFileUploads[caseFileUploads.length - 1]]
                        
                        this.listings[index].case_files = uploads.map(caseFile => {
                            const isTheDeletedFile = caseFile.link.toLowerCase().includes(file_name.toLowerCase())
                            if (isTheDeletedFile) {
                                caseFile.link = '';
                                caseFile.date_granted = '';
                            }

                            return caseFile;
                        });
                        break;
                    }

                    this.updateCaseStatus(id)

                    this.$parent.showSnackbar(`Successfully deleted file.`, false, 'success', 'fas fa-trash')
                    // setTimeout(() => {
                    //     this.getCaseStatuses();
                    // }, 500);
                    // this.closeModal()
                    this.$store.dispatch('deletedCaseFile', true) // deletedCaseFile = true in Vuex
                })
        },
        getCaseFiles(id, index = -1) {
            if (+this.current_show_file_case_id !== +id) {
                return;
            }

            this.case_files_loaded = false

            let headers = JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers)

            axios.get(`${process.env.VUE_APP_URL}/file_uploads/case/${id}?page_size=100000`, headers)
                .then(response => {
                    this.filtered_case_files = response.data.data
                    this.case_files = response.data.data

                    if (index !== -1) {
                        if (index === -2) index = this.listings.findIndex(l => l.id == id)

                        this.case_files.forEach(f => {
                            const uploadsIndex = this.listings[index]?.case_files.findIndex(c => c.label == f.type)

                            if (this.listings[index]?.case_files?.[uploadsIndex] !== undefined) {
                                const fileLink = this.getCaseFileLink(id, f.file_name)
                                this.listings[index].case_files[uploadsIndex].link = f?.file_name.includes('.pdf') ? fileLink?.replace('/download', '') : fileLink
                            }
                        })
                    }
                    this.case_files_loaded = true
                })
        },
        async getDefendantCaseFiles(id) {
            try {
                const { data } = await this.$http.get(`file_uploads/case/${id}?page_size=100000`, this.$session.get('lawfirm_headers'))

                const cFiles = data.data
                const case_files = JSON.parse(JSON.stringify(this.caseFileUploads_copy))
                                    
                const removeCount = 2;
                for (let i = 0; i < removeCount; i++) case_files.pop()

                cFiles.forEach(f => {
                    const uploadsIndex = case_files.findIndex(c => c.label == f.type)

                    if (case_files[uploadsIndex] !== undefined) {
                        const fileLink = this.getCaseFileLink(id, f.file_name)
                        case_files[uploadsIndex].link = f?.file_name.includes('.pdf') ? fileLink?.replace('/download', '') : fileLink
                    }
                })

                this.caseFileStore.caseFiles = case_files
            } catch (e) {
                //
            }
        },
        setCurrentCaseFiles(case_files) {
            this.caseFileStore.caseFiles = case_files
        },
        getAllCaseFiles() {
            this.case_files_loaded = false

            this.listings.forEach(async (l, index) => {
                const listing = l
    
                const { data } = await this.$http.get(`file_uploads/case/${listing.id}?page_size=100000`, this.$session.get('lawfirm_headers'))

                data.data.forEach(f => {
                    const uploadsIndex = this.listings[index]?.case_files.findIndex(c => c.label == f.type)

                    if (this.listings[index]?.case_files?.[uploadsIndex] !== undefined) {
                        const fileLink = this.getCaseFileLink(listing.id, f.file_name)
                        this.listings[index].case_files[uploadsIndex].link = f?.file_name.includes('.pdf') ? fileLink?.replace('/download', '') : fileLink
                    }
                })
    
                this.listings[index].hideUploadedCaseFileButton = false
            })

            this.case_files_loaded = true
        },
        getCaseFileLink(id, file_name) {
            return `${process.env.VUE_APP_URL}/file_uploads/case/${id}/${file_name}/download`
        },
        getUploadFileTypes() {
            let headers = JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers)
            axios.get(`${process.env.VUE_APP_URL}/upload_file_types?page_size=100000`, headers)
                .then(response => {
                    if (response.data.meta.code == 200) {
                        this.upload_file_type_select = []
                        this.upload_file_types = response.data.data
                        let index = 0
                        this.upload_file_types.forEach(type => {
                            this.filter_upload_type_select.push({ label: type.file_type, value: type.file_type })
                            if (index == 0) {
                                index++
                                return
                            }
                            this.upload_file_type_select.push({ label: type.file_type, value: type.file_type })
                        })
                    }
                })
        },
        getSellerFlags() {
            let headers = JSON.parse(localStorage.getItem('headers'))
            this.seller_flags = []
            if (headers != null) {
                axios.get(`${process.env.VUE_APP_URL}/case_flags`,
                    headers
                ).then(
                    response => {
                        for (let x of response.data.data) {
                            // Don't include inactive flags
                            if (x.status == 0) continue;

                            this.seller_flags.push({
                                value: x.id,
                                label: x.name
                            })
                        }
                        //this.seller_flags = this.seller_flags.filter(flag => flag.value != 0)
                    }
                )
            }
        },
        async getPaymentTypes() {
            this.payment_methods = []
            await axios.get(`${process.env.VUE_APP_URL}/payment_types/in/campaign/${this.$route.params.campaign_id}/post-filed?page_size=1000000`,
                JSON.parse(localStorage.getItem('headers'))
            ).then(
                response => {
                    for (let x of response.data.data) {
                        this.payment_methods.push({
                            value: x.id,
                            label: x.payment_name
                        })
                    }
                }
            )
        },
        getExtensionName(file) {
            let ext = file.file_name.split('.')[file.file_name.split('.').length - 1] || 'jpg'
            if (['xls', 'xlsx', 'xlsm', 'csv'].includes(ext))
                return 'excel'
            if (['doc', 'docx', 'txt', 'rtf'].includes(ext))
                return 'doc'
            if (['zip', 'rar'].includes(ext))
                return 'zip'
            return ext
        },
        blockSpecialChar(e) {
            let key = e.key
            let keyCharCode = key.charCodeAt(0)
            if ([45, 95, 32].includes(keyCharCode) || (keyCharCode >= 48 && keyCharCode <= 57) || (keyCharCode >= 65 && keyCharCode <= 90) || (keyCharCode >= 97 && keyCharCode <= 122)) {
                return key
            }
            e.preventDefault()
        },
        isFileTypeUploadable(ext) {
            if (["pdf", "jpg", "png", "gif", "doc", "docx", "txt", "rtf", "ppt", "odf", "pptx", "xls", "xlsx", "xlsm", "csv", "zip", "rar"].includes(ext))
                return true
            return false
        },
        updateInfringementOption(option) {
            this.infringement_option = option
        },
        async getSellerLocations() {
            let config = JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers)
            config.headers.paymenttypes = this.filters.selected_payment_types
            /**
             * Old Endpoint
             */
            // console.log(this.$route.params.campaign_id)
            // axios.get(`${process.env.VUE_APP_URL}/countries/in/campaign/${this.$route.params.campaign_id}`, config)
            //     .then(response => {
            //         this.seller_locations = response.data.data.map(country => ({ value: country.id, label: `${country.name} [${country.count}]`, count: country.count }))
            //         this.seller_lid_count = response.data.data.map(lid => lid.count)
            //     }
            //     )
            this.seller_locations_hasCount = false
            const queries = {
                include: 'sellersCount',
                includeFilter: {
                    sellerInfos: {
                        ["seller.listings.campaign_id"]: this.$route.params.campaign_id,
                        ["seller.listings.qflag_id"]: this.qFlagID,
                        ["seller.listings.platform_id"]: this.filters.selected_lf_platforms,
                    }
                }
            }

            await axios.get(`${process.env.VUE_APP_URL}/api/v2/countries?${qs.stringify(queries, {encode: false})}`, config).then(
                response => {
                    this.seller_locations = response.data.data.map(country => ({ value: country.id, label: `${country.name} [${country.sellers_count}]`, count: country.sellers_count })).filter(c => c.count > 0)
                    // console.log(this.seller_locations.filter(c => c.sellers_count > 0))
                    this.seller_lid_count = response.data.data.map(lid => lid.sellers_count)
                }
            )
        },
        trySearch() {
            if (this.expanded) return;
            this.applyMoreFilters()
        },
        getInfringementReasons() {
            var config = JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers)
            axios.get(process.env.VUE_APP_URL + "/criteria?page_size=100", config)
                .then(response => {
                    var results = response.data
                    this.reasons = results.data.map(result => {
                        return { label: result.title, value: result.id }
                    })
                }).catch(err => { alert(`${err.response.data.meta.message}. Please contact system admin.`) })
        },
        async initializeSortOptionsOld() {
            return new Promise(resolve => {
                if (this.$route.params.status == "Cases" && this.$route.params.case_id == '0') {
                    this.sort_by = "created_at"
                    if ([6].includes(this.access_level)) {
                        this.sort_by_option = [{
                            label: "Date Added",
                            value: "created_at"
                        },
                        {
                            label: "Case Status",
                            value: "case_status"
                        }]
                    }
                    else {
                        this.sort_by_option = [{
                            label: "Date Added",
                            value: "created_at"
                        },
                        {
                            label: "Case Number",
                            value: "case_number"
                        },
                        {
                            label: "Date Submitted",
                            value: "date_submitted"
                        },
                        {
                            label: "Judge",
                            value: "judge"
                        },
                        {
                            label: "Case Status",
                            value: "case_status"
                        }]
                    }
                    this.order_by = "desc"
                }
                else if (this.$route.params.case_id != "0") {
                    this.sort_by = "doe_number"
                    this.sort_by_option = [{
                        label: "Defendant Name",
                        value: "s.name"
                    },
                    // {
                    //     label: "Email",
                    //     value: "email"
                    // },
                    // {
                    //     label: "Account Number",
                    //     value: "account_number"
                    // },
                    {
                        label: "Doe No.",
                        value: "doe_number"
                    }]
                    this.order_by = "asc"
                } else if ([6].includes(this.access_level)) {
                    this.sort_by = "created_at"

                    this.sort_by_option = [{
                        label: "Date Added",
                        value: "created_at"
                    }, {
                        label: "Seller Name",
                        value: "seller_name"
                    }]
                    this.order_by = "asc"
                } else {
                    this.sort_by = "created_at"
                    // this.sort_by_option = [{
                    //     label: "Seller Name",
                    //     value: "seller_name"
                    // },
                    // {
                    //     label: "Platform",
                    //     value: "platform"
                    // }]
                    this.sort_by_option = [{
                        label: "Date Added",
                        value: "created_at"
                    }, {
                        label: "Platform",
                        value: "platform"
                    }, {
                        label: "Seller Name",
                        value: "seller_name"
                    }]
                    this.order_by = "asc"
                }
                resolve()
            })
        },
        async initializeSortOptions() {
            const sortOptions = await new Promise(resolve => {
                const isParamStatusCases = this.$route.params.status === 'Cases';
                const isParamCaseIdZero = +this.$route.params.case_id === 0;

                this.sort_by = 'created_at';
                this.order_by = 'desc';

                if (isParamStatusCases && isParamCaseIdZero) {
                    let sortByOption = [];

                    if (!this.isFeatureRestricted('cm-sort-by-date-added')) {
                        sortByOption = [...sortByOption, { label: 'Date Added', value: 'created_at' }];
                    }

                    if (!this.isFeatureRestricted('cm-sort-by-case-status')) {
                        sortByOption = [...sortByOption, { label: 'Case Status', value: 'case_status'}];
                    }

                    if (!this.isFeatureRestricted('cm-sort-by-case-number')) {
                        sortByOption = [...sortByOption, { label: 'Case Number', value: 'case_number'}];
                    }

                    if (!this.isFeatureRestricted('cm-sort-by-date-submitted')) {
                        sortByOption = [...sortByOption, { label: 'Date Submitted', value: 'date_submitted'}];
                    }

                    if (!this.isFeatureRestricted('cm-sort-by-case-judge')) {
                        sortByOption = [...sortByOption, { label: 'Judge', value: 'judge'}];
                    }

                    this.sort_by_option = sortByOption;

                    return resolve();
                }

                if (!isParamCaseIdZero) {
                    this.sort_by = 'doe_number';
                    this.order_by = 'asc';

                    this.sort_by_option = [
                        { label: 'Defendant Name', value: 's.name' },
                        { label: 'Doe No.', value: 'doe_number' }
                    ];

                    return resolve();
                }

                let sortByOption = [];

                if (!this.isFeatureRestricted('cm-sort-by-date-added')) {
                    sortByOption = [...sortByOption, { label: 'Date Added', value: 'created_at' }];
                }

                if (!this.isFeatureRestricted('cm-sort-by-seller-name')) {
                    sortByOption = [...sortByOption, { label: 'Seller Name', value: 'seller_name' }];
                }

                if (!this.isFeatureRestricted('cm-sort-by-platform')) {
                    sortByOption = [...sortByOption, { label: 'Platform', value: 'platform' }];
                }

                this.order_by = 'asc';
                this.sort_by_option = sortByOption;

                return resolve();
            });

            return sortOptions;
        },
        getItemTypes() {
            var headers = JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers)
            axios.get(process.env.VUE_APP_URL + `/classification_types/inCampaign/${this.$route.params.campaign_id}?page_size=100000`, headers)
                .then(response => {
                    let classes = [];
                    this.item_type_options = [];
                    this.item_class_options = [];
                    response.data.data.filter(item => item.type != null && item.type != '').forEach(item => {
                        this.item_type_options.push({ label: item.type, class: item.class, cid: item.cid });
                        if (!classes.includes(item.cid)) {
                            this.item_class_options.push({ label: `[Class ${item.number ?? 'n/a'}] ${item.class}`, value: item.class });
                            classes.push(item.cid);
                        }
                    })
                    this.item_type_options.sort(function (a, b) {
                        if (a.label < b.label) return -1;
                        if (a.label > b.label) return 1;
                        return 0;
                    });
                    this.item_class_options.sort(function (a, b) {
                        if (a.label < b.label) return -1;
                        if (a.label > b.label) return 1;
                        return 0;
                    });
                    // this.item_type_options.unshift({ label: "No Item Type", value: "No Item Type" })
                })
        },
        getNewItemTypes() {
            if (this.new_item_types.length == 0) {
                this.new_item_types = this.listings
            }
        },
        getItemClasses() {
            let config = JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers)
            axios.get(process.env.VUE_APP_URL+'/classifications?page_size=1000', config)
                .then(response => {
                    let results = response.data
                    this.item_class = results.data.map(result => {
                        let label = ""
                        let class_num = ""
                        let class_name = ""
                        class_num = result.class.split('-')
                        class_name = class_num[1]?.trim() || ''
                        class_num = class_num[0].trim()
                        if (class_num?.toLowerCase().includes('class') && !isNaN(class_num.split('')[1])){
                            label = `[${class_num}] ${class_name}`
                        } else
                            label = result.class
                        return {
                            id: result.id,
                            label: `[Class ${result.number == null || result.number == 0 || result.number == "  " ?
                                'N/A' : result.number}] ${label}`,
                            value:result.class
                        }
                    })
                }).catch(err => {alert(`${err.response.data.meta.message}. Please contact system admin.`)})
        },
        getPacks() {
            var headers = JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers)
            headers.headers['Where'] = `{"campaign_id": ${this.$route.params.campaign_id}}`;
            axios.get(process.env.VUE_APP_URL + `/package_gen_requests/get-list?page_size=100000`, headers)
                .then(response => {
                    this.filter_submissions_pack.options = response.data.data.map(d => ({ label: d.package_name, value: d.id }));
                })
                .catch(err => {
                    console.log(err)
                    this.$parent.showSnackbar("Something went wrong while retrieving submission packs", false, "invalid", "fas fa-times-circle")
                });
        },
        filter_set_count() {
            let filters_count = 0
            filters_count = this.selected_payment_methods.length + this.selected_infringement_reasons.length
            if (this.filtered_listing_id && this.filtered_listing_id.length) filters_count++;
            if (this.min_date_added != '') filters_count++
            if (this.min_date_qualified != '') filters_count++
            if (this.filter_item_location != '' || this.filters__.item_location.selected != 0) filters_count++
            if (this.filter_seller_location != '0') filters_count++
            if (this.filter_account_number != '') filters_count++
            if (this.filter_email != '') filters_count++
            if (this.filter_alias != '') filters_count++    // Newly added filter feature ALIAS - 7/21/22
            if (this.filter_account_name_email != '') filters_count++
            if (this.filter_item_type.length) filters_count++
            if (this.filter_submissions_pack.selected) filters_count++;
            if (this.filter_claim_type != '0') filters_count++
            if (this.filter_group && this.filter_group != 0) filters_count++;
            if (this.filter_item_class.length) filters_count++;
            if (this.filterDateCaptured !== '') filters_count++

            return filters_count
        },
        showAccountingDetails() {
            $('#accounting-details-modal').modal('toggle')
        },
        //Group Sellers Feature Methods
        getGroupNames() {
            const headers = this.$session.get("lawfirm_headers")
            let case_id = this.$route.params.case_id
            this.$http.get("seller_groups/" + case_id, headers)
                .then(response => {
                    if (response.status == 200) {
                        let result = response.data
                        this.group_names = result.data.map(group => ({ "label": group.name, "value": group.name }))
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$parent.showSnackbar("Something went wrong while fetching Seller Group Names. Please contact System Admin.", false, "invalid", "fas fa-times-circle")
                })
        },
        checkGroupSellers(selected_listings, group_name) {//Check if any of selected sellers are already in group
            if (group_name != "") {
                let headers = { headers: { ...this.$session.get("lawfirm_headers").headers } };
                headers.headers["Campaign-id"] = this.$route.params.campaign_id;
                let body = { "listing_ids": selected_listings, "case_id": this.$route.params.case_id }
                this.is_proceeding = true
                this.is_adding_group = false
                this.$http.post("sellers_with_group", body, headers)
                    .then(response => {
                        if (response.status == 200) {
                            let result = response.data
                            if (result.data.length && !this.found_sellers_with_group.length) {//If there are, show seller
                                this.is_proceeding = false
                                this.found_sellers_with_group = result.data.map(group => ({ "seller_name": group.seller_name, "group_name": group.group_name, "doe_no": group.doe_number }))
                            } else if (this.group_names.findIndex(g => g.value.trim().toLowerCase() == this.input_group_name.trim().toLowerCase()) !== -1) {
                                this.saveGroupSellers(selected_listings, group_name)
                            } else {//If not, update sellers group_seller_id
                                this.is_proceeding = false
                                this.is_adding_group = true
                                setTimeout(() => {
                                    document.getElementById("group-name-select").value = group_name
                                    document.getElementById('group-description-field').focus()
                                }, 200);
                            }
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        this.$parent.showSnackbar("Something went wrong while Checking Seller Group. Please contact System Admin.", false, "invalid", "fas fa-times-circle")
                    })
            } else {
                console.log("Group Name is required.")
            }
        },
        saveGroupSellers(selected_listings, group_name, group_description) {
            let headers = { headers: { ...this.$session.get("lawfirm_headers").headers } };
            headers.headers["Campaign-id"] = this.$route.params.campaign_id;
            let body = {
                "group_name": group_name,
                "listing_ids": selected_listings,
                "case_id": this.$route.params.case_id,
                "campaign_id": this.$route.params.campaign_id,
                "group_description": group_description
            }
            this.is_proceeding = true
            this.$http.post("seller_groups", body, headers)
                .then(response => {
                    if (response.status == 200) {
                        let result = response.data
                        this.$parent.showSnackbar("Successfully added Seller to Group.", false, "success", "fas fa-check-circle")
                        for (let list of this.listings) {
                            if (selected_listings.includes(list.id)) {
                                console.log(group_description)
                                list.group_name = group_name
                                list.group_description = group_description
                            }
                        }
                        this.is_proceeding = false
                        $("#group-seller-modal").modal("hide")
                        this.selected_listings = []
                        this.is_select_all = false;
                        this.selectAll();
                        this.getGroupNames();
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$parent.showSnackbar("Something went wrong while saving Seller Group. Please contact System Admin.", false, "invalid", "fas fa-times-circle")
                })
        },
        resetGroupnameData() {
            this.getGroupNames()
            this.found_sellers_with_group = []
            this.is_overwrite_seller_group = false
            this.input_group_name = ""
            this.is_proceeding = false
            this.is_adding_group = false
            setTimeout(() => {
                document.getElementById("group-name-select").value = ""
                let clearButton = document.querySelector("#group-name-select-con .input-icon.fas.fa-times")
                if (clearButton != null)
                    clearButton.click()
            }, 200);
        },
        async getCaseDetails() {
            let headers = this.$session.get("lawfirm_headers")
            const { data } = await this.$http.get(`cases/${this.$route.params.case_id}`, headers)
            this.case_name = data.data[0].case_number
            this.caseFileStore.caseData = data.data
        },
        getCaseInfo() {
            let headers = this.$session.get("lawfirm_headers")
            headers.headers.Where = JSON.stringify({ "campaign_id": this.$route.params.campaign_id, "id": this.$route.params.case_id })
            this.$http.get("cases/multi", headers)
                .then(response => {
                    let results = response.data.data
                    if (results.length) {
                        this.case_accounting_details = results.map(result => ({
                            case_number: this.displayNotSet(result.case_number),
                            details: this.displayNotSet(result.case_title),
                            case_status: result.case_status,
                            primary_details: {
                                date_filed: this.displayNotSet(this.formatDate(result.date_filed)),
                                jurisdiction: this.displayNotSet(result.Jurisdiction),
                                tro_issue_date: this.displayNotSet(this.formatDate(result.date_granted)),
                                judge: this.displayNotSet(result.Judge),
                                pio_issue_date: this.displayNotSet(this.formatDate(result.date_pio_issued)),
                                total_defendants: this.displayNotSet(result.total_defendants),
                                lawfirm_name: this.displayNotSet(result.lawfirm_name)
                            },
                            accounting_reports: {
                                total_frozen_amount: this.displayNotSet(this.$parent.formatMoney(result.total_frozen), true, true),
                                total_agreed_settlement: this.displayNotSet(this.$parent.formatMoney(result.total_agreed_settlement), true, true),
                                expected_settlement_amount: this.displayNotSet(this.$parent.formatMoney(result.expected_settlement_amount), true, true),
                                predicted_total_default_amount: this.displayNotSet(this.$parent.formatMoney(result.predicted_total_default_amount), true, true),
                                total_received_default_amount: this.displayNotSet(this.$parent.formatMoney(result.total_received_default_amount), true, true),
                                total_received_settlement_amount: this.displayNotSet(this.$parent.formatMoney(result.total_received_settlement_amount), true, true),
                                total_defendants_settled: this.displayNotSet(result.total_defendants_settled, true),
                                total_defendants_defaulted: this.displayNotSet(result.total_defendants_defaulted, true)
                            },
                            comments: this.displayNotSet(result.comment)
                        }))[0]
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$parent.showSnackbar('Something went wrong. Please contact System admin.', false, 'invalid', 'fas fa-times-circle')
                })
        },
        displayNotSet(value, isNumber = false, isMoney = false) {
            return value || (isMoney ? '0.00' : isNumber ? '0' : "Not Set")
        },
        formatDate(date) {
            if (['', null].includes(date)) return date
            const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
            const formatDate = new Date(date)
            const year = formatDate.getFullYear()
            const day = formatDate.getDate()
            const month = months[formatDate.getMonth()]
            return `${day} ${month} ${year}`
        },
        decimal(value) {
            if (value.includes('.') && value.split('.').length > 1) return Math.round((parseFloat(value) + Number.EPSILON) * 100) / 100
            return value.replace('.', '') + '.00'
        },
        resetImportAmountData() {
            this.getImportPlatforms('frozen_amount_import')
            //Frozen Amount Data
            this.frozen_payment_type = ""
            this.frozen_accounts = []
            this.frozen_columns = []
            this.frozen_account_columns = {}
            this.related_frozen_accounts = []
            this.related_frozen_columns = []
            this.related_frozen_account_columns = {}
            this.file_data = ""
            this.file_name = ""
            this.frozen_log = ""
            this.frozen_success_update = false
            this.frozen_log_already_download = false
            this.frozen_source_file = ""
            this.update_all_frozen_accounts = false
            this.is_proceed_to_related_accounts = false
            this.update_frozen_accounts = true
            this.show_related_accounts_error = false
            this.is_related_frozen_accounts_verified = true
            // document.getElementById("frozen-platform").value = 0
            this.includeEbayAccountName = true
            this.isColumnSelection = false
            this.frozen_required_message = ''
            //Release Amount Data
            this.release_accounts = []
            this.is_release_loading = false
            this.is_release_generating = false
            this.overwrite_release_account = false
            this.relase_has_prev_value = false

        },
        //Frozen Account V2
        importFrozenAccount() {
            this.is_frozen_loading = true
            const platform = this.frozen_payment_type
            const file = this.file_data
            if (isEmpty(file) && isEmpty(platform)) {
                this.is_frozen_loading = false
                const message = isEmpty(platform) ? 'Please select Platform' : 'Please upload a file'
                return this.$parent.showSnackbar(message, false, 'invalid', 'fas fa-times-circle')
            }
            const allowed_types = ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
            if (!allowed_types.includes(file.type)) {
                this.is_frozen_loading = false
                return this.$parent.showSnackbar('Invalid file type', false, 'invalid', 'fas fa-times-circle')
            }
            const { case_id, campaign_id } = this.$route.params
            const token = this.$session.get('lawfirm_headers')
            token.headers['Content-Type'] = 'multipart/form-data'
            token.headers['Campaign-id'] = campaign_id
            const data = new FormData()
            data.append('file', file)
            data.append('campaign_id', campaign_id)
            this.$http.post(`/frozen_account_import/${campaign_id}/${case_id}/${platform}`, data, token)
                .then(({ data, status }) => {
                    if (status === 200) {
                        const {
                            accounts,
                            columns,
                            related_accounts,
                            related_columns
                        } = data.data
                        this.frozen_accounts = accounts
                        this.frozen_columns = columns
                        this.related_frozen_accounts = related_accounts
                        this.related_frozen_columns = related_columns
                        this.frozen_source_file = data.meta.filename
                        this.isColumnSelection = true
                        this.$parent.showSnackbar('Successfully imported source file', false, 'success', 'fas fa-check-circle')
                    }
                })
                .catch(err => {
                    console.log(err.response.data.meta.message)
                    const message = err.response.data.meta.message || 'Something went wrong while importing. Please contact system admin.'
                    this.$parent.showSnackbar(message, false, 'invalid', 'fas fa-times-circle')
                })
                .finally(() => this.is_frozen_loading = false)
        },
        //Outdated function
        //Will be deleted if testing for new import frozen deliv is already done
        importFrozenAccount_X() {
            let platform = this.frozen_payment_type
            let file = this.file_data
            this.is_frozen_loading = true
            if (file != "" && platform != "" && platform != 0) {
                //Allowed mime types
                const allowed_types = ["text/csv", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]
                if (allowed_types.includes(file.type)) {
                    let case_id = this.$route.params.case_id
                    let campaign_id = this.$route.params.campaign_id
                    let data = new FormData()
                    let token = this.$session.get("lawfirm_headers")
                    token.headers["Content-Type"] = "multipart/form-data"
                    token.headers["Campaign-id"] = this.$route.params.campaign_id
                    data.append("file", file)
                    data.append("campaign_id", campaign_id)
                    //"frozen_account_import/"+campaign_id+"/"+case_id+"/"+platform
                    this.$http.post(`/frozen_account_import/${campaign_id}/${case_id}/${platform}`, data, token)
                        .then(response => {
                            if (response.status === 200) {
                                let result = response.data
                                this.frozen_accounts = result.data.accounts
                                this.frozen_columns = result.data.columns
                                console.log(result.data.related_accounts)
                                this.related_frozen_accounts = result.data.related_accounts
                                this.related_frozen_columns = result.data.related_columns
                                this.is_frozen_loading = false
                                this.frozen_source_file = result.meta.filename
                                this.$parent.showSnackbar('Successfully imported source file', false, 'success', 'fas fa-check-circle')
                            }
                        })
                        .catch(err => {
                            console.log(err.response.data.meta.message)
                            let message = err.response.data.meta.message || "Something went wrong while importing. Please contact system admin."
                            this.is_frozen_loading = false
                            this.$parent.showSnackbar(message, false, 'invalid', 'fas fa-times-circle')
                        })
                } else {
                    this.is_frozen_loading = false
                    let message = "Invalid file type"
                    this.$parent.showSnackbar(message, false, "invalid", "fas fa-times-circle")
                }
            } else {
                this.is_frozen_loading = false
                let message = platform == "" ? "Please select Platform" : "Please upload a file"
                this.$parent.showSnackbar(message, false, "invalid", "fas fa-times-circle")
            }
        },
        validateImportedAccount() {
            const columns = this.frozen_account_columns
            this.is_frozen_loading = true
            if (!Object.entries(columns).length) {
                this.is_frozen_loading = false
                this.frozen_required_message = 'Please select associate columns'
                return this.$parent.showSnackbar(this.frozen_required_message, false, 'invalid', 'fas fa-exclamation-triangle')
            }
            let associates = []
            for (const value of Object.values(columns)) {
                if (value !== 'exclude' && value != 0) {
                    if (associates.includes(value)) {
                        this.is_frozen_loading = false
                        return this.$parent.showSnackbar(
                            `Multiple ${this.ucFirst(value.replace('_', ''))} found. Please remove one`,
                            false,
                            'invalid',
                            'fas fa-times-circle'
                        )
                    }
                    // associates.push(value)
                    associates = [...associates, value]
                }
            }
            if (!this.checkFrozenRequiredFields(associates, this.frozen_payment_type)) {
                this.is_frozen_loading = false
                return
            }
            //Check first if related_accounts are verified, if there are.
            if (this.related_frozen_accounts.length && !this.is_proceed_to_related_accounts) {
                this.is_related_frozen_accounts_verified = false
                this.is_proceed_to_related_accounts = true
            }
            if (!this.is_related_frozen_accounts_verified) {
                this.is_frozen_loading = false
                return
            }
            //Get Necessary Columns
            const necessary_columns = Object.keys(columns)
                .reduce((acc, curr) => {
                    if (columns[curr] !== 'exclude' && columns[curr] !== '0') {
                        acc[curr] = columns[curr]
                    }
                    return acc
                }, {})
            const { campaign_id, case_id } = this.$route.params
            let accounts = []
            for (const account of this.frozen_accounts) {
                const temp = {}
                for (let key in necessary_columns) {
                    temp[necessary_columns[key]] = account[key]
                }
                temp.campaign_id = campaign_id
                // accounts.push(temp)
                accounts = [...accounts, temp]
            }
            this.frozen_accounts = accounts
            const payment_type = this.frozen_payment_type
            const headers = this.$session.get('lawfirm_headers')
            headers.headers['Campaign-id'] = campaign_id
            const payload = { accounts, source: this.frozen_source_file }
            this.$http.post(`/frozen_account_validation/${case_id}/${payment_type}`, payload, headers)
                .then(({ status, data }) => {
                    if (status === 200) {
                        const { accounts } = data.data
                        this.frozen_accounts = accounts.map(account => {
                            Object.keys(account).forEach(a => {
                                account[a] = !isEmpty(account[a]) ? account[a] : ''
                            })
                            account.action = isEmpty(account.error) ? 'update' : 'skip'
                            return account
                        })
                        this.frozen_log = data.meta.filename
                        //Update if no error found in frozen_accounts and related_frozen_accounts has seller_id
                        const has_no_error = !this.frozen_accounts.filter(({ error }) => !isEmpty(error)).length
                        if (has_no_error) {
                            this.$parent.showSnackbar('No issues encountered. Updating accounts...', true)
                            this.updateImportedAccount(this.frozen_accounts)
                        }
                        this.isColumnSelection = false
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$parent.showSnackbar('Something went wrong while validating. Please contact System Admin.', false, 'invalid', 'fas fa-times-circle')
                })
                .finally(() => this.is_frozen_loading = false)
        },
        //Outdated function
        //Will be deleted if testing for new import frozen deliv is already done
        validateImportedAccount_X() {
            let columns = this.frozen_account_columns
            this.is_frozen_loading = true
            if (Object.entries(columns).length) {
                let associates = []
                let error = ""
                for (let value of Object.values(columns)) {
                    if (value != "exclude" && value != 0) {
                        if (associates.includes(value)) {
                            error = "Multiple " + this.ucFirst(value.replace("_", " ")) + " found. Please remove one."
                            break;
                        }
                        associates.push(value)
                    }
                }
                if (error === "") {
                    if (this.checkFrozenRequiredFields(associates, this.frozen_payment_type)) {
                        //Check first if related_accounts are verified, if there are.
                        if (this.related_frozen_accounts.length && !this.is_proceed_to_related_accounts) {
                            this.is_related_frozen_accounts_verified = false
                            this.is_proceed_to_related_accounts = true
                        }
                        if (this.is_related_frozen_accounts_verified) {
                            //Get Necessary Columns
                            let necessary_columns = Object.keys(columns).reduce((acc, curr) => {
                                if (columns[curr] !== "exclude" && columns[curr] !== "0") acc[curr] = columns[curr]
                                return acc
                            }, {})
                            let accounts = []
                            for (let account of this.frozen_accounts) {
                                let temp = {}
                                for (let key in necessary_columns) {
                                    temp[necessary_columns[key]] = account[key]
                                }
                                temp["campaign_id"] = this.$route.params.campaign_id
                                accounts.push(temp)
                            }
                            this.frozen_accounts = accounts
                            let headers = this.$session.get('lawfirm_headers')
                            let case_id = this.$route.params.case_id
                            let payment_type = this.frozen_payment_type
                            // let related_accounts = this.related_frozen_accounts
                            headers.headers["Campaign-id"] = this.$route.params.campaign_id
                            // "frozen_account_validation/"+case_id+"/"+payment_type,{"accounts":accounts, "source" : this.frozen_source_file}
                            const payload = { 'accounts': accounts, 'source': this.frozen_source_file }
                            this.$http.post(`/frozen_account_validation/${case_id}/${payment_type}`, payload, headers)
                                .then(response => {
                                    if (response.status == 200) {
                                        let result = response.data.data
                                        this.frozen_accounts = result.accounts.map(account => {
                                            Object.keys(account).forEach(a => {
                                                account[a] = account[a] != null ? account[a] : ''
                                            })
                                            account.action = account.error == '' ? 'update' : 'skip'
                                            return account
                                        })
                                        this.is_frozen_loading = false
                                        this.frozen_log = response.data.meta.filename
                                        //Update if no error found in frozen_accounts and related_frozen_accounts has seller_id
                                        let has_no_error = !this.frozen_accounts.filter(account => account['error'] != '').length
                                        if (has_no_error) {
                                            this.$parent.showSnackbar("No issues encountered. Updating accounts...", true)
                                            this.updateImportedAccount(this.frozen_accounts)
                                        }
                                    }
                                }).catch(err => {
                                    console.log(err)
                                    this.is_frozen_loading = false
                                    this.$parent.showSnackbar("Something went wrong while validating. Please contact System Admin.", false, "invalid", "fas fa-times-circle")
                                })
                        } else {
                            this.is_frozen_loading = false
                        }
                    } else {
                        this.is_frozen_loading = false
                    }
                } else {
                    this.is_frozen_loading = false
                    this.$parent.showSnackbar(error, false, "invalid", "fas fa-times-circle")
                }
            } else {
                this.is_frozen_loading = false
                this.frozen_required_message = 'Please select associate columns'
                this.$parent.showSnackbar("Please select associate columns.", false, "invalid", "fas fa-exclamation-triangle")
            }
        },
        checkFrozenRequiredFields(fields, type, is_related_account = false) {
            let message = ''
            if (type !== 'Ebay') {
                if (!fields.includes('amount_frozen'))
                    message = 'Amount Frozen'
                else if (!fields.includes('related_account_number') && this.is_proceed_to_related_accounts && is_related_account)
                    message = 'Related Account Number'
                if (type !== 'Paypal') {
                    // if(!(fields.includes('seller_name') || fields.includes('doe_number')) && !is_related_account)
                    // message = "Please select Seller Name or Doe Number"
                    if (!fields.includes('seller_name'))
                        message = 'Seller Name'
                    else if (!(fields.includes('name_email') || fields.includes('number')))
                        message = 'Account Email or Account Number'
                } else {
                    if (!fields.includes('number'))
                        message = 'Account Number'
                    else if (!fields.includes('name_email'))
                        message = 'Account Email'
                }
            } else {
                if (!fields.includes('seller_name'))
                    message = 'Seller Name'
                else if (!fields.includes('name_email') && this.includeEbayAccountName)
                    message = 'Account Email'
                else if (!fields.includes('platform_account_id'))
                    message = 'Ebay ID'
            }
            if (!isEmpty(message)) {
                this.frozen_required_message = `Please select ${message}`
                this.$parent.showSnackbar(this.frozen_required_message, false, 'invalid', 'fas fa-exclamation-triangle')
                return false
            }
            this.frozen_required_message = ''
            return true
        },
        /**
         * Check the associated columns of parsed related_accounts
         */
        checkRelatedFrozenAccounts() {
            this.is_frozen_loading = true
            //Check if there is associate column selected
            if (!Object.entries(this.related_frozen_account_columns).length) {
                this.is_frozen_loading = false
                this.frozen_required_message = 'Please select Associate Columns';
                return this.$parent.showSnackbar('Please select Associate Columns', false, 'invalid', 'fas fa-exclamation-triangle')
            }
            const related_columns = this.related_frozen_account_columns
            let related_associates = []
            //Check if there is duplicate associate columns selected
            for (const related_value of Object.values(related_columns)) {
                if (related_value !== 'exclude' && related_value != 0) {
                    if (related_associates.includes(related_value)) {
                        this.is_frozen_loading = false
                        return this.$parent.showSnackbar(
                            `Multiple ${this.ucFirst(related_value.replace('_', ' '))} found. Please remove one.`,
                            false,
                            'invalid',
                            'fas fa-times-circle'
                        )
                    }
                    // related_associates.push(related_value)
                    related_associates = [...related_associates, related_value]
                }
            }
            //Check if required columns are selected
            if (!this.checkFrozenRequiredFields(related_associates, this.frozen_payment_type, true)) {
                this.is_frozen_loading = false
                return
            }
            //Remove excluded columns
            const included_columns = Object.keys(related_columns).reduce((acc, curr) => {
                if (related_columns[curr] !== 'exclude' && related_columns[curr] != '0') {
                    acc[curr] = related_columns[curr]
                }
                return acc
            }, {})
            this.related_frozen_accounts = this.related_frozen_accounts.map(account => {
                const temp = {}
                Object.keys(included_columns).forEach(key => { temp[included_columns[key]] = account[key] })
                return temp
            })
            //Validate Frozen Accounts
            this.is_related_frozen_accounts_verified = true
            this.validateImportedAccount()
        },
        //Outdated function
        //Will be deleted if testing for new import frozen deliv is already done
        checkRelatedFrozenAccounts_X() {
            this.is_frozen_loading = true
            //Check if there is associate column selected
            if (!Object.entries(this.related_frozen_account_columns).length) {
                this.is_frozen_loading = false
                this.frozen_required_message = 'Please select Associate Columns';
                return this.$parent.showSnackbar('Please select Associate Columns', false, 'invalid', 'fas fa-exclamation-triangle')
            }
            // if(Object.entries(this.related_frozen_account_columns).length) {
            const related_columns = this.related_frozen_account_columns
            let related_associates = []
            //Check if there is duplicate associate columns selected
            for (let related_value of Object.values(related_columns)) {
                if (related_value !== "exclude" && related_value != 0) {
                    if (related_associates.includes(related_value)) {
                        //"Mutiplie "+this.ucFirst(related_value.replace("_"," "))+" found. Please remove one."
                        this.is_frozen_loading = false
                        return this.$parent.showSnackbar(
                            `Multiple ${this.ucFirst(related_value.replace('_', ' '))} found. Please remove one.`,
                            false,
                            'invalid',
                            'fas fa-times-circle'
                        )
                    }
                    related_associates.push(related_value)
                }
            }
            //Check if required columns are selected
            if (this.checkFrozenRequiredFields(related_associates, this.frozen_payment_type, true)) {
                //Remove excluded columns
                const included_columns = Object.keys(related_columns).reduce((acc, curr) => {
                    if (related_columns[curr] !== 'exclude' && related_columns[curr] != '0') acc[curr] = related_columns[curr]
                    return acc
                }, {})
                this.related_frozen_accounts = this.related_frozen_accounts.map(account => {
                    const temp = {}
                    Object.keys(included_columns).forEach(key => { temp[included_columns[key]] = account[key] })
                    return temp
                })
                //Validate Frozen Accounts
                this.is_related_frozen_accounts_verified = true
                this.validateImportedAccount()
            } else {
                this.is_frozen_loading = false
            }
            /* } else {
                this.is_frozen_loading = false
                this.frozen_required_message = 'Please select Associate Columns';
                this.$parent.showSnackbar('Please select Associate Columns', false, 'invalid', 'fas fa-exclamation-triangle')
            } */
        },
        removeRelatedAccountNotFound() {
            //Remove Related Accounts with unknown seller
            this.is_frozen_loading = true
            this.update_frozen_accounts = true
            this.updateImportedAccount(this.frozen_accounts)
        },
        /**
         * Check if key is in object
         * @param {object} obj
         * @param {string} key
         * @returns {boolean}
         */
        isKeyInObject(obj, key) {
            return Object.keys(obj).includes(key)
        },
        /**
         * Transform the given key into
         * proper column name
         * @param {string} key
         * @returns {string}
         */
        setImportFrozenColumnName(key) {
            switch (key) {
                case 'doe_number':
                    return 'Doe'
                case 'seller_name':
                    return 'Seller'
                case 'name_email':
                    return 'Email'
                case 'amount_frozen_cny':
                    return 'Frozen CNY'
                case 'amount_updated_frozen':
                    return 'Updated Frozen'
                case 'amount_updated_frozen_cny':
                    return 'Updated Frozen CNY'
                default:
                    return capitalize(this.ucFirst(key).replaceAll('_', ' '))
            }
        },
        updateImportedAccount(frozen_accounts) {
            this.is_frozen_loading = true
            // const accounts = frozen_accounts
            // let case_id            = this.$route.params.case_id
            const { campaign_id, case_id } = this.$route.params
            const accounts = frozen_accounts.filter(({ is_updatable }) => is_updatable)
                .map(account => ({
                    payment_type: this.frozen_payment_type,
                    campaign_id,
                    ...account
                }))
            /* .map(account => {
                account.payment_type = this.frozen_payment_type
                account.campaign_id  = this.$route.params.campaign_id
                return account
            }) */
            if (this.update_frozen_accounts) {
                /* let related_accounts = this.related_frozen_accounts.map(account => {
                    // m.campaign_id = this.$route.params.campaign_id
                    const { campaign_id } = this.$route.params
                    return { campaign_id, ...account }
                }) */
                const headers = this.$session.get('lawfirm_headers')
                headers.headers['Campaign-id'] = campaign_id
                const related_accounts = this.related_frozen_accounts.map(account => ({ ...account, campaign_id }))
                const body = {
                    'accounts': accounts,
                    'related_accounts': related_accounts,
                    'filename': this.frozen_log,
                    'source': this.frozen_source_file
                }
                const campaign = this.frozen_payment_type
                //"frozen_account_update/"+case_id+"/"+campaign
                this.$http.patch(`/frozen_account_update/${case_id}/${campaign}`, body, headers)
                    .then(response => {
                        let result = response.data
                        this.frozen_log = result.meta.filename
                        this.$parent.showSnackbar('Successfully updated accounts', false, 'success', 'fas fa-check-circle')
                        this.frozen_success_update = true
                        this.frozen_log_already_download = false
                    })
                    .catch(err => {
                        console.log(err)
                        this.$parent.showSnackbar('Something went wrong while updating accounts. Please contact Sytem Admin.', false, 'invalid', 'fas fa-times-circle')
                    })
                    .finally(() => this.is_frozen_loading = false)
            }
        },
        downloadFrozenLog() {
            if (!isEmpty(this.frozen_log) && !this.frozen_log_already_download) {
                this.frozen_log_already_download = true
                const case_id = this.$route.params.case_id
                window.open(`${this.env}/frozen_account_log/${case_id}/${this.frozen_log}`, '_blank')
                if (this.frozen_success_update) {
                    this.$parent.showSnackbar('Reloading page to retrieve new data...', true)
                    setTimeout(() => location.reload(), 2000);
                }
            }
            this.includeEbayAccountName = true
            this.isColumnSelection = false
            this.frozen_required_message = ''
        },
        getPlatformDataBreakdown() {
            if (!this.hasUsed_globalSearch) {
                this.show_platform_data_breakdown = false
                const headers = this.$session.get("lawfirm_headers")

                if (this.subStatusesStore.canSeeSubStatuses && this.$route.query.substatus_id && this.$route.query.substatus_id != -1)
                    headers.headers["Substatus-id"] = this.$route.query.substatus_id?.toString().includes('0_') ? -1 : this.$route.query.substatus_id

                this.$http.get(`defendants/breakdown/${this.$route.params.status}/in/campaign/${this.$route.params.campaign_id}/case/${this.$route.params.case_id}/by/platforms`, headers)
                    .then(response => {
                        this.show_platform_data_breakdown = true
                        this.platform_data_breakdown = {}
                        for (let x of response.data.data) {
                            if (typeof this.platform_data_breakdown[x.platform] == 'undefined') this.platform_data_breakdown[x.platform] = []
                            this.platform_data_breakdown[x.platform].push(x)
                        }
                        setTimeout(() => {
                            this.filterPlatformDataBreakdown()
                        }, 500)
                    })
            } else {
                let globalListings = this.globalSearch_listings
                globalListings = globalListings.map(listing => {
                    return {
                        listing_id: listing.id,
                        platform:   listing.platform,
                        platform_id: listing.platform_id,
                        seller_name: listing.seller[0] && listing.seller[0].name,
                    }
                }).sort((a, b) => {
                    return ((a || {}).label || "").localeCompare((b || {}).label || "")
                })
                this.platform_data_breakdown = {}
                for (let i of globalListings) {
                    if (typeof this.platform_data_breakdown[i.platform] == 'undefined') this.platform_data_breakdown[i.platform] = []

                    this.platform_data_breakdown[i.platform].push({
                        listing_id: i.listing_id,
                        platform:   i.platform,
                        platform_id: i.platform_id,
                        seller_name: i.seller_name,
                    })
                }
            }
        },
        removeSpaceAndSpecChars(value) {
            return (value || '').toString().toLowerCase().replace(/[^\w\s*]/ig, '').replace(' ', '')
        },
        //Release Account V2
        exportReleaseAmountTemplate() {
            let case_id = this.$route.params.case_id
            const headers = this.$session.get("lawfirm_headers")
            this.is_release_generating = true
            this.$http.get("release_account_export/" + case_id, headers)
                .then(response => {
                    if (response.status == 200) {
                        this.is_release_generating = false
                        let result = response.data.filename
                        window.open(this.env + "/release_account_template/" + result, "_blank")
                    }
                }).catch(err => {
                    this.is_release_generating = false
                    console.log(err)
                    this.$parent.showSnackbar("Something went wrong while generating Release Amount Template. Please contact System Admin.", false, "invalid", "fas fa-times-circle")
                })
        },
        importReleaseAmountTemplate() {
            let file = this.file_data
            this.is_release_loading = true
            if (file != "") {
                //Check mime type
                if (file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                    let case_id = this.$route.params.case_id
                    let data = new FormData();
                    let token = this.$session.get("lawfirm_headers")
                    token.headers["Content-Type"] = "multipart/form-data"
                    token.headers["Campaign-id"] = this.$route.params.campaign_id
                    data.append("file", file)
                    this.$http.post("release_account_import/" + case_id, data, token)
                        .then(response => {
                            if (response.status == 200) {
                                let result = response.data.data
                                this.release_accounts = result.accounts
                                this.is_release_loading = false
                            }
                        }).catch(err => {
                            console.log(err)
                            this.is_release_loading = false
                            this.$parant.showSnackbar("Something went wrong while importing. Please contact System Admin", false, "invalid", "fas fa-times-cirlce")
                        })
                } else {
                    this.is_release_loading = false
                    this.$parent.showSnackbar("Please upload an Excel file", false, "invalid", "fas fa-times-circle")
                }
            } else {
                this.is_release_loading = false
                this.$parent.showSnackbar("Please upload a file", false, "invalid", "fas fa-times-circle")
            }
        },
        updateReleaseAmount() {
            this.is_release_loading = true
            let accounts = this.release_accounts
            let case_id = this.$route.params.case_id
            let headers = this.$session.get('lawfirm_headers')
            headers.headers["Campaign-id"] = this.$route.params.campaign_id
            Object.keys(accounts).map(key => accounts[key]["Campaign ID"] = this.$route.params.campaign_id)
            if (accounts.length) {
                this.$http.patch("release_account_update/" + case_id, { "accounts": accounts }, headers)
                    .then(response => {
                        if (response.status == 200) {
                            let result = response.data.data
                            this.is_release_loading = false
                            this.$parent.showSnackbar('Successfully updated Release Amounts', false, 'success', 'fas fa-check-circle')
                            $('#import-released-modal').modal('hide')
                            setTimeout(() => {
                                this.$parent.showSnackbar('Refreshing Page...', true)
                                location.reload()
                            }, 3000)
                        }
                    }).catch(err => {
                        console.log(err)
                        this.is_release_loading = false
                        this.$parent.showSnackbar("Something went wrong while updating. Please contact System Admin.", false, "invalid", "fas fa-times-circle")
                    })
            } else {
                this.is_release_loading = false
                this.$parent.showSnackbar("No accounts to be updated", false, "invalid", "fas fa-times-cirlce")
            }
        },
        getReleaseIssue(account) {
            if (
                (parseFloat(account['Received Settlement']) != parseFloat(account['Prev Settlement']) && parseFloat(account['Prev Settlement']) != 0 && parseFloat(account['Received Settlement']) != 0)
                ||
                (parseFloat(account['Received Default']) != parseFloat(account['Prev Default']) && parseFloat(account['Prev Default']) != 0 && parseFloat(account['Received Default']) != 0)
            ) {
                this.release_has_prev_value = true
                return 'release-with-prev-value'
            } else {
                if (account['Seller Group'] != null && account['Seller Group'] != '' && (parseFloat(account['Received Settlement']) != 0))
                    return 'release-in-group'
            }
            return ''
        },
        validateFilterDates() {
            if (this.min_date_added > this.today)
                this.min_date_added = this.today
            else if (this.min_date_added > this.max_date_added)
                this.max_date_added = this.min_date_added
            if (this.max_date_added < this.min_date_added)
                this.max_date_added = this.min_date_added
            if (this.min_date_qualified > this.today)
                this.min_date_qualified = this.today
            else if (this.min_date_qualified > this.max_date_qualified)
                this.max_date_qualified = this.min_date_qualified
            if (this.max_date_qualified < this.min_date_qualified)
                this.max_date_qualified = this.min_date_qualified
        },
        getClaimTypes() {
            if (!this.claim_types_dropdown.length) {
                const headers = this.$session.get("lawfirm_headers")
                this.$http.get('claimtype?page_size=100', headers)
                    .then(response => {
                        this.claim_types_dropdown = response.data.data.map(ct => ({ label: ct.claim_type, value: ct.id }))
                        this.claim_types_dropdown.unshift({ label: "No Claim Type", value: -1 })
                    })
            }
        },
        resetExportAmountData() {
            this.getImportPlatforms()
            $('#case-export-frozen-modal').modal('show');
            this.is_frozen_export_generating = false
            this.frozen_type = this.frozen_type == '0' ? 'all' : this.frozen_type
            this.frozen_order = this.frozen_order == '0' ? 'asc' : this.frozen_order
        },
        exportFrozenAccounts() {
            this.is_frozen_export_generating = true
            let headers = this.$session.get("lawfirm_headers")
            headers.headers["Campaign-id"] = this.$route.params.campaign_id
            let object = {}
            object[this.$route.params.case_id] = this.case_name,
                this.$http.post(`frozen/summary/export`, {
                    campaign: this.$route.params.campaign_id,
                    cases: JSON.stringify(object),
                    type: this.frozen_type,
                    order: this.frozen_order,
                    has_default: this.has_default_frozen == "0",
                    exportFormat: this.frozenAmountExportFormat
                }, headers)
                    .then(response => {
                        this.is_frozen_export_generating = false
                        window.open(`${process.env.VUE_APP_URL}/excel/${response.data.meta["excel-file"]}`, '_blank');
                    })
        },
        resetRelease() {
            this.getImportPlatforms()
            this.reset_release = true
            setTimeout(() => {
                this.reset_release = false
            }, 3000)
        },
        asterisk(number) {
            number = `${number}`.trim()
            return (number.charAt(0) != '*' && number != '') ? `*${number}` : number
        },
        showSettlementAgreementModal() {
            $('#settlement-agreement-modal').modal({ backdrop: 'static', keyboard: false });
            this.resetActiveSettlementEditIndex();
            // this.getSettlementAgreementTemplates();
            this.resetSettlementModal();
        },
        resetSettlementModal() {
            this.templates.current = {}
            this.templates.action = "template"
            this.templates.keywords = ""
            this.templates.confirmRemove = false
            this.templates.generate.done = null
            this.templates.generate.error = false
            this.templates.generate.error_message = 'Error occured on generation. Please try again.'
            this.templates.generate.file_name = ""
            this.templates.generate.download_link = ""
            this.templates.generate.sa_setter.status = false
            this.templates.generate.sa_setter.data = []
            this.templates.generate.sa_setter.value = ""
            this.templates.generate.rd_setter.status = false
            this.templates.generate.rd_setter.data = []
            this.templates.generate.rd_setter.value = ""
        },
        getSettlementAgreementTemplates() {
            let headers = this.$session.get('lawfirm_headers')
            headers.headers["Campaign-id"] = this.$route.params.campaign_id;
            this.$http.get(`files/sa_templates/case/${this.$route.params.case_id}`, headers)
                .then(response => {
                    this.templates.data = response.data.data.map(item => ({ id: item.id, heading: item.file_name, sub_heading: item.type }))
                })
        },
        setSettlementAgreementCurrent(data) {
            if (this.templates.current.id == data.id)
                this.templates.current = {}
            else
                this.templates.current = data
            this.templates.confirmRemove = false;
        },
        async removeTemplate(data) {
            let headers = this.$session.get('lawfirm_headers')
            headers.headers["Campaign-id"] = this.$route.params.campaign_id;
            await this.$http.delete(`files/sa_templates/${data.id}`, headers)
                .then(response => {
                    this.$parent.showSnackbar(`Successfully removed ${data.heading}`, false, 'success', 'fas fa-check-circle');
                    this.removeSettlementAgreementTemplate(data.id)
                })
                .catch(error => {
                    this.$parent.showSnackbar('Error encountered when deleting template. Please try again.', false, 'invalid', 'fas fa-times-circle');
                })
            this.resetSettlementModal();
        },
        buildPaymentAccount(id, accounts) {
            let stuffs = []
            // console.log(accounts)
            accounts.filter(account => account.seller_account_id == id).forEach(account => {
                let keys_to_get = [
                    { image: "/static/svg/defendants/Details_Account.svg", title: "Account Name", key: "name_email" },
                    { image: "/static/svg/defendants/Details_Account.svg", title: "Account Type", key: "type" },
                    { image: "/static/svg/defendants/Details_Account.svg", title: "Account Number", key: "number" },
                    { image: "/static/svg/Case_Settled.svg", title: "Agreed Settlement", key: "amount_settled" },
                    { image: "/static/svg/Case_Frozen.svg", title: "Amount Frozen", key: "amount_frozen" }
                ]
                let details = []
                let order = ["Account Name", "Account Type", "Account Number", "Amount Frozen", "Agreed Settlement"]
                const mapOrder = (a, order, key) => {
                    const map = order.reduce((r, v, i) => ((r[v] = i), r), {})
                    return a.sort((a, b) => map[a[key]] - map[b[key]])
                }
                for (let [key, val] of Object.entries(account)) {
                    keys_to_get.forEach(item => {
                        if (item.key == key) {
                            details.push({
                                image: item.image,
                                title: item.title,
                                value: val
                            })
                            return
                        }
                    })
                }
                stuffs.push(mapOrder(details, order, 'title'))
            })
            return stuffs[0]
        },
        changeSettlementAction(action) {
            this.file_name = ""
            $("#upload-new-template").val("");
            this.templates.action = action;
            this.templates.upload.valid = true;
            this.templates.upload.file_name = "";
            this.templates.upload.payment_type = "0";
            this.templates.generate.done = null;
            this.templates.generate.error = false;
            this.templates.generate.file_name = "";
            this.templates.generate.download_link = "";
        },
        generateSettlementAgreement() {
            // let allow_generation = this.allowSettlementGeneration()
            // if (!allow_generation.status) {
            //     let does = allow_generation.defendants.map(defendant => defendant.case_listing[0].doe_number)
            //     this.$parent.showSnackbar(`Can't continue generation, Defendants (DOE NUMBER ${does.join(', ')}) has no settlement agreement amount`, false, 'invalid', 'fas fa-times-circle');
            //     return
            // }
            if (!this.allow_settlement_generate) return
            let headers = this.$session.get('lawfirm_headers')
            headers.headers["Campaign-id"] = this.$route.params.campaign_id;
            this.changeSettlementAction('generate');
            this.templates.generate.download_link = "";
            this.templates.generate.error = false;
            this.templates.generate.done = false;
            this.templates.generate.sa_setter.status = false;
            this.templates.generate.sa_setter.data = [];
            this.templates.generate.sa_setter.value = "0";
            this.templates.generate.rd_setter.status = false;
            this.templates.generate.rd_setter.data = [];
            this.templates.generate.rd_setter.value = "0";
            let payload = { sa: [] }
            this.settlement_selected_defendants.forEach(defendant => {
                payload.sa.push(JSON.parse(`{ "${defendant.id}": { "case_seller_account_id" : ${defendant.seller_account_id}, "method" : "${defendant.pay_through == '1' ? 'direct' : this.payment_methods.filter(method => method.value == defendant.payment_platform)[0].label}" }}`))
            })
            this.$http.post(`files/sa_templates/${this.$route.params.case_id}/generate`, payload, headers)
                .then(response => {
                    let getMainAccountType = (listing) => {
                        try {
                            let main = listing.case_seller_accounts.filter(account => account.seller_account_id == listing.seller_account_id)[0]
                            let methods = this.getDefendantPaymentMethods(listing)
                            return methods.filter(method => main.type == method.label)[0].value
                        } catch (ex) {
                            return "0"
                        }
                    }
                    let result = response.data.data
                    let meta = response.data.meta
                    if (result != null) {
                        result = result['successful generation']
                    } else {
                        throw "response null"
                    }
                    this.$parent.showSnackbar(meta.message, false, 'success', 'fas fa-check-circle');
                    this.templates.generate.file_name = result.file_name.trim();
                    this.templates.generate.download_link = result.download_url;
                    this.templates.generate.done = true;
                    this.listings.forEach(listing => {
                        listing.pay_through = "1";
                        listing.payment_platform = getMainAccountType(listing);
                    })
                })
                .catch(error => {
                    this.templates.generate.done = null;
                    this.templates.generate.error = true;
                    if (error.response?.data?.meta?.callback != null && error.response?.data?.meta.callback == 'agreed_settlement_modal_setter') {
                        this.templates.generate.sa_setter.status = true;
                        this.templates.generate.sa_setter.data = error.response.data.meta.callback_values
                        this.templates.generate.error_message = error.response.data.meta.message
                    }
                    if (error.response?.data?.meta.callback != null && error.response?.data?.meta.callback == 'received_default_modal_setter') {
                        this.templates.generate.rd_setter.status = true;
                        this.templates.generate.rd_setter.data = error.response.data.meta.callback_values
                        this.templates.generate.error_message = error.response.data.meta.message
                    }
                    // this.$parent.showSnackbar('Error encountered in generation. Please try again.', false, 'invalid', '/static/svg/Error_Snackbar.svg');
                    this.$parent.showSnackbar(error.response.data.meta.message, false, 'invalid', '/static/svg/Error_Snackbar.svg');
                })
        },
        async uploadNewSATemplate() {
            let headers = { headers: { "Content-Type": "multipart/form-data", "Campaign-id": this.$route.params.campaign_id, ...this.$session.get('lawfirm_headers').headers } }
            let payment_type = this.payment_methods.filter(method => method.value == this.templates.upload.payment_type)
            payment_type = payment_type[0] != null ? payment_type[0] : []
            if (payment_type.length)
                return
            let payload = new FormData()
            payload.append("file", this.file_data)
            payload.append("name", this.templates.upload.file_name)
            payload.append("payment_type", payment_type.label)
            await this.$http.post(`files/sa_templates/case/${this.$route.params.case_id}`, payload, headers)
                .then(response => {
                    this.$parent.showSnackbar('New template upload successful!', false, 'success', 'fas fa-check-circle');
                })
                .catch(error => {
                    this.$parent.showSnackbar('Error encountered when uploading. Please try again.', false, 'invalid', 'fas fa-times-circle');
                })
            // this.getSettlementAgreementTemplates();
            this.resetSettlementModal();
        },
        removeSettlementAgreementTemplate(id) {
            this.templates.data = this.templates.data.filter(template => template.id != id)
        },
        downloadSettlementAgreement() {
            $('#settlement-agreement-modal').modal('hide');
            window.open(this.templates.generate.download_link, '_blank');
        },
        convertToWords(number) {
            const first = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
            const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
            const mad = ['', 'thousand', 'million', 'billion', 'trillion'];
            let word = '';
            for (let i = 0; i < mad.length; i++) {
                let tempNumber = number % (100 * Math.pow(1000, i));
                if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
                    if (Math.floor(tempNumber / Math.pow(1000, i)) < 20)
                        word = first[Math.floor(tempNumber / Math.pow(1000, i))] + mad[i] + ' ' + word;
                    else
                        word = tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] + '-' + first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] + mad[i] + ' ' + word;
                }
                tempNumber = number % (Math.pow(1000, i + 1));
                if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0) word = first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] + 'hundred ' + word;
            }
            return word;
        },
        confirm(value) {
            this.confirm_update.status = value
            this.closeModal()
        },
        confirmUpdate(header, sub) {
            this.closeModal()
            this.confirm_update.status = null
            this.confirm_update.header = header
            this.confirm_update.sub = sub
            $('#confirm-rdupdate-modal').modal('show')
        },
        allowSettlementGeneration() {
            let defendants = []
            this.settlement_selected_defendants.forEach(defendant => {
                let accounts = defendant.case_seller_accounts;
                for (let index = 0; index < accounts.length; index++) {
                    let account = accounts[index];
                    if (defendant.seller_account_id != account.seller_account_id)
                        continue;
                    if (parseFloat(account.amount_settled ?? 0) == 0)
                        defendants.push(defendant)
                }
            })
            return { defendants, status: defendants.length == 0 }
        },
        resetActiveSettlementEditIndex() {
            this.active_settlement_edit.index = -1;
            this.active_settlement_edit.value = "";
        },
        setActiveSettlementEdit(index, value) {
            this.active_settlement_edit.index = index;
            this.active_settlement_edit.value = value;
            // this.account_initial = JSON.stringify(this.active_settlement_edit.values)
        },
        async updateSelectedDefendantSettlementAgreement(selected_index) {
            let defendant = this.settlement_selected_defendants[selected_index]
            let headers = JSON.parse(this.headers)
            headers.headers.Listing = defendant.id.toString()
            headers.headers['Campaign-id'] = this.$route.params.campaign_id
            let account_id = defendant.seller_account_id
            let account = defendant.case_seller_accounts.filter(account => account.seller_account_id == account_id)[0]
            if (account == null) return;
            this.$parent.showSnackbar(`Updating seller account.. Please wait..`)
            let payload = {}
            payload.case_seller_accounts = {}
            payload.case_listings = {}
            payload.case_listings[defendant.case_listing[0].id] = {}
            payload.case_seller_accounts[account.id] = {}
            payload.case_listings[defendant.case_listing[0].id].case_flag_id = defendant.case_listing[0].case_flag_id
            payload.case_seller_accounts[account.id].amount_frozen = account.amount_frozen
            payload.case_seller_accounts[account.id].amount_released = account.amount_released
            payload.case_seller_accounts[account.id].amount_settled = this.decimal(this.active_settlement_edit.value.toString())
            payload.case_seller_accounts[account.id].amount_received_settlement = account.received_amount_settled
            payload.case_seller_accounts[account.id].amount_received_default = account.default_amount_received
            payload.case_seller_accounts[account.id].amount_received_ctda_default = account.default_ctda_amount_received
            payload.case_seller_accounts[account.id].name_email = account.account_name
            payload.case_seller_accounts[account.id].number = account.fit_number
            await axios.patch(
                process.env.VUE_APP_URL + "/multi",
                payload,
                headers
            ).then(
                async response => {
                    this.$parent.showSnackbar(`Successfully updated seller account.`, false, 'success', 'fas fa-check')
                    // this.closeModal()
                    let copy = this.listings
                    let listing_index = copy.findIndex(listing => defendant.id == listing.id)
                    let account_index = defendant.case_seller_accounts.findIndex(account => account.seller_account_id == account_id)
                    await this.current(this.listings[listing_index].id, listing_index)
                    let c_account = this.listing.case_seller_accounts[account_index]
                    let c_listing = this.listing.case_listing[0]
                    copy[listing_index].case_seller_accounts[account_index].amount_frozen = c_account.amount_frozen
                    copy[listing_index].case_seller_accounts[account_index].amount_settled = c_account.amount_settled
                    copy[listing_index].case_seller_accounts[account_index].amount_received_settlement = c_account.amount_received_settlement
                    copy[listing_index].case_seller_accounts[account_index].amount_received_default = c_account.amount_received_default
                    copy[listing_index].case_seller_accounts[account_index].amount_received_ctda_default = c_account.amount_received_ctda_default
                    copy[listing_index].case_seller_accounts[account_index].number = c_account.number
                    copy[listing_index].case_seller_accounts[account_index].name_email = c_account.name_email
                    copy[listing_index].case_listing[0].case_flag_id = c_listing.case_flag_id
                    copy[listing_index].case_listing[0].case_flag = c_listing.case_flag
                    copy[listing_index].key_id = this.getRandomKey()
                    this.listings = copy
                    this.resetActiveSettlementEditIndex();
                })
                .catch(err => {
                    console.log(err)
                    this.$parent.showSnackbar(err.response.data.meta.message, false, 'invalid', 'fas fa-times-circle')
                })
        },
        getRandomKey() {
            const shuffle = v => [...v].sort(_ => Math.random() - .5).join('')
            return shuffle(Math.random().toString(36).substr(2, 5));
        },
        checkPayThrough() {
            let allow = true
            this.listings.forEach(listing => {
                if (listing.pay_through == "2" && listing.payment_platform == "0") {
                    allow = false;
                    return;
                }
            })
            return allow
        },
        getDefendantPaymentMethods(defendant) {
            return defendant.case_seller_accounts.map(account => ({ label: account.type, value: this.payment_methods.filter(method => method.label == account.type)[0].value }))
        },
        updateGroupSettlement() {
            let headers = JSON.parse(this.headers)
            let listing_id = this.templates.generate.sa_setter.data[0].listing_id
            let payload = { amount: this.templates.generate.sa_setter.value }
            headers.headers.Listing = listing_id.toString()
            headers.headers['Campaign-id'] = this.$route.params.campaign_id
            axios.patch(
                process.env.VUE_APP_URL + `/listings/${listing_id}/amount_settled/group`,
                payload,
                headers
            ).then(response => {
                this.$parent.showSnackbar(`Successfully updated settlement amount for seller group.`, false, 'success', 'fas fa-check')
                this.templates.generate.sa_setter.status = false
                let copy = this.listings
                this.templates.generate.sa_setter.data.forEach(async defendant => {
                    let listing_index = copy.findIndex(listing => defendant.listing_id == listing.id)
                    if (listing_index === -1) return;
                    await this.current(defendant.listing_id, listing_index)
                    let c_account = this.listing.case_seller_accounts
                    let c_listing = this.listing.case_listing[0]
                    copy[listing_index].case_seller_accounts = c_account
                    copy[listing_index].case_listing[0].case_flag_id = c_listing.case_flag_id
                    copy[listing_index].case_listing[0].case_flag = c_listing.case_flag
                    copy[listing_index].key_id = this.getRandomKey()
                })
                this.listings = copy
            })
                .catch(error => {
                    this.$parent.showSnackbar('Error encountered in updating. Please try again.', false, 'invalid', '/static/svg/Error_Snackbar.svg');
                })
        },
        updateGroupReceivedDefault() {
            let headers = JSON.parse(this.headers)
            let listing_id = this.templates.generate.rd_setter.data[0].listing_id
            let payload = { amount: this.templates.generate.rd_setter.value }
            headers.headers.Listing = listing_id.toString()
            headers.headers['Campaign-id'] = this.$route.params.campaign_id
            axios.patch(
                process.env.VUE_APP_URL + `/listings/${listing_id}/amount_received_default/group`,
                payload,
                headers
            ).then(response => {
                this.$parent.showSnackbar(`Successfully updated received default amount for seller group.`, false, 'success', 'fas fa-check')
                this.templates.generate.rd_setter.status = false
                let copy = this.listings
                this.templates.generate.rd_setter.data.forEach(async defendant => {
                    let listing_index = copy.findIndex(listing => defendant.listing_id == listing.id)
                    if (listing_index === -1) return;
                    await this.current(defendant.listing_id, listing_index)
                    let c_account = this.listing.case_seller_accounts
                    let c_listing = this.listing.case_listing[0]
                    copy[listing_index].case_seller_accounts = c_account
                    copy[listing_index].case_listing[0].case_flag_id = c_listing.case_flag_id
                    copy[listing_index].case_listing[0].case_flag = c_listing.case_flag
                    copy[listing_index].key_id = this.getRandomKey()
                })
                this.listings = copy
            })
                .catch(error => {
                    this.$parent.showSnackbar('Error encountered in updating. Please try again.', false, 'invalid', '/static/svg/Error_Snackbar.svg');
                })
        },
        appendListingID(e) {
            if (this.filtered_listing_id_input && !this.filtered_listing_id.includes(this.filtered_listing_id_input.trim())) {
                this.filtered_listing_id_input.replace(/\s+/g, ',').split(',').filter(listing_id => {
                    if (listing_id && !this.filtered_listing_id.includes(listing_id))
                        this.filtered_listing_id.push(listing_id.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ''))
                })
                this.filtered_listing_id_input = null;
                $('#input-rids').val('');
            }
        },
        removeSelectedListing() {
            for (let listing of this.selected_listings) {
                let idx = this.listings.findIndex(l => l.id == listing);
                this.listings.splice(idx, 1);
            }
            this.selected_listings = [];
        },
        removeLastListingID() {
            if (this.filtered_listing_id_input == '') this.filtered_listing_id.pop()
        },
        removeListingID(listing_id) {
            this.filtered_listing_id.splice(this.filtered_listing_id.indexOf(listing_id), 1);
        },
        cleanInput() {
            this.filtered_listing_id_input = this.filtered_listing_id_input.replace(/[^0-9,]+s/g, '');
        },
        showMoveModal() {
            $('#vendor-move-modal').modal({ backdrop: 'static', keyboard: false });
            $('#vendor-move-modal').modal('show')
        },
        showScheduleASPG() {
            $('#submissions-pack-generator-modal').modal('show')
        },
        showExportDialog(option = null) {
            $('.modal').modal('hide');
            if (option == null) {
                $('#exports-modal').modal('show')
            } else if (option == 1) {
                $('#excel-export-modal').modal('show')
                // this.initializeExport('excel')
            } else if (option == 2) {
                // this.initializeExport('word')
                $('#word-export-modal').modal('show')
                this.word_export.exclude = false
            } else if (option == 3) {
                this.initializeExport('schedulea')
            } else if (option == 4) {
                $('#vendor-extract-modal').modal('show')
            } else if (option == 5) {
                this.initializeExport('vendorHistory')
            } else {
                this.showScheduleASPG()
            }
        },
        /**
         * @param {array} a
         * @param {array} order
         * @param {string} key
         */
        mapOrder(a, order, key) {
            const map = order.reduce((r, v, i) => ((r[v] = i), r), {})
            return a.sort((a, b) => map[a[key]] - map[b[key]])
        },
        applySPGFormat() {
            let order = []
            let included = []
            let copy = this.spg.listings.copy
            this.spg.payment_type.selected.forEach(p => {
                order.push(p.label)
                if (p.included) included.push(p.label)
            })
            copy = copy.filter(listing => included.includes(listing.type))
            copy = this.mapOrder(copy, order, `type`)
            this.spg.listings.selected = copy
        },
        async doWhenFilterisChanged() {
            if (this.$route.params.status != 'Cases') this.getCaseSellerAccounts()
            await this.initializeSortOptions()
            if (this.first_load) await this.getListings().then(() => this.checkWhitelist())
            // this.checkWhitelist()
            this.is_select_all = false
            this.selectAll()
        },
        getPredefinedReasonsFilter() {
            let headers = this.$session.get("lawfirm_headers")
            this.$http.get(`api/admin/reasons/${this.$route.params.campaign_id}/${this.$parent.getQFlagId(this.$route.params.status)}/listing/count`, headers)
                .then(({ data }) => this.filter_predefined_reasons.data = data.data.map(p => ({ ...p, toggled: false })))
        },
        showSnackbar(text, loading, type, icon) {
            this.$parent.showSnackbar(text, loading, type, icon)
        },
        validateDateGranted(index) {
            this.caseFileUploads[index].error = false

            try {
                const tempDate = new Date(this.caseFileUploads[index]?.date_granted)
            } catch (e) {
                this.$parent.showSnackbar(`Please enter a valid date`, false, 'warning', 'fas fa-exclamation-triangle')
                this.caseFileUploads[index].error = true
                return false
            }

            if (moment(this.caseFileUploads[index]?.date_granted).isAfter(moment())) {
                this.$parent.showSnackbar(`Future dates are invalid. Please enter a valid date.`, false, 'warning', 'fas fa-exclamation-triangle')
                this.caseFileUploads[index].error = true
                return false 
            }

            return true
        },
        async addCaseFileToBeUploaded(e, index, label) {
            this.caseFileDateGranted.cancelled = false
            this.caseFileDateGranted.added = false
            this.caseFileDateGranted.label = label
            this.caseFileDateGranted.temp = ""

            if (e.target.files[0].type !== 'application/pdf') {
                e.target.value = ""
                return this.showSnackbar('Only PDFs are accepted.', false, 'invalid', 'fas fa-times-circle')
            }
            if (!(e.target.files[0].size < 30 * 1024 * 1024)) {
                e.target.value = ""
                return this.$parent.showSnackbar(`File size exceeds to 30mb limit.`, false, 'invalid', 'fas fa-times-circle')
            }

            this.caseFileUploads[index].file = e.target.files[0]
            this.caseFileUploads[index].blob = URL.createObjectURL(e.target.files[0])
        },
        checkListings() {
            this.$parent.showSnackbar('Processing...', true)
            $('#listingsToBeAcceptedModal').on('hidden.bs.modal', () => {
                this.listingsToBeAccepted = []
            })
            this.listingsToBeAccepted = []

            this.checkListingsInfo()
            if(this.emptyListingInfo && !this.isIndividualChecking) {
                $('.modal').modal('hide')
                this.is_loading = false
                this.status_changing = false
                this.isIndividualChecking = false
                this.isMultipleChecking = true
                this.$parent.hideBackdrop();
                $('#listing-empty-info').modal('show')
                return
            }

            const headers = this.$session.get('lawfirm_headers')
            const listings = this.selected_listings
            const campaign = +this.$route.params.campaign_id
            const payload = { listings, campaign }

            this.$http.post('/api/whitelist-sellers?check-duplicate=1', payload, headers)
                .then((res) => {
                    const whitelistedSellers = res.data.data;
                    if (whitelistedSellers.some(whitelistedSeller => whitelistedSeller.isWhitelist || whitelistedSeller.hasPotentials || whitelistedSeller.hasDuplicates)) {
                        const { data } = res.data
                        this.listingsToBeAccepted = data
                        if(whitelistedSellers.some(whitelistedSeller => whitelistedSeller.isWhitelist || whitelistedSeller.hasDuplicates))
                            $('#listingsToBeAcceptedModal').modal('show')
                        else {
                            // proceed in changing status
                            this.proceedToAcceptListings({ sellers: this.listingsToBeAccepted, allowDuplicateBypass: false })
                        }
                        this.$parent.showSnackbar('Showing sellers', false, 'success', 'fas fa-check-circle')
                    }
                    else {
                        this.submitListings(listings);
                    }
                })
                .catch(err => {
                    console.error(err)
                    this.$parent.showSnackbar('Something went wrong while checking for whitelist sellers. Please contact the system admin.', false, 'invalid', 'fas fa-times-circle')
                })
        },
        showRestrictedReasons() {
            const role = this.user?.role ?? this.user.access_level;
            const IS_INTERNAL_USER = role === Role.Lawyer || role === Role.Client;

            if (IS_INTERNAL_USER) {
                return false;
            }

            if (this.isRestrictionLoading) {
                this.isRestrictionLoading = false;
            }

            if (!this.hasSelectedReasonWithRestriction) {
                return false;
            }

            this.showReasonWithRestriction = true;
            this.is_loading                = false;

            if (this.$store.state.other_reason_comment === '') {
                this.hasSeenReasonWithRestriction = true;
                this.$parent.showSnackbar('Please provide a custom reason.', false, 'invalid', 'fas fa-info-circle');

                return true;
            }

            if (!this.hasSeenReasonWithRestriction) {
                this.hasSeenReasonWithRestriction = true;
                this.$parent.showSnackbar('Selected reason(s) are restricted to some role(s).', false, 'warning', 'fas fa-info-circle');

                return true;
            }

            this.isRestrictionLoading = true;
            this.resetReasonsSelectionStore();

            return false;
        },
        resetReasonsSelectionStore() {
            return useReasonSelectionStore().resetReasonsSelectionStore();
        },
        checkListings2(targetStatus) {

            this.checkListingsInfo()
            if(this.emptyListingInfo && !this.isIndividualChecking) {
                $('.modal').modal('hide')
                this.is_loading = false
                this.status_changing = false
                this.isIndividualChecking = false
                this.isMultipleChecking = true
                this.$parent.hideBackdrop();
                $('#listing-empty-info').modal('show')
                return
            }

            if ([31, 33, 36, 98, 38].includes(targetStatus) || this.isInFiledTab) {
                const hasNoReasons = this.$store.state.checked_reasons.length === 0
                    && this.$store.state.checked_subreasons.length === 0
                    && isEmpty(this.$store.state.checked_other_reason)
                if (hasNoReasons)
                    return this.$parent.showSnackbar('Please select reason(s)', false, 'invalid', 'fas fa-times-circle')
                if (!isEmpty(this.$store.state.checked_other_reason)) {
                    if (isEmpty(this.$store.state.other_reason_comment))
                        return this.$parent.showSnackbar('Please provide comment', false, 'invalid', 'fas fa-times-circle')
                }
            }

            if (this.showRestrictedReasons()) {
                return;
            }

            this.$parent.showBackdrop();
            this.is_loading = true;
            this.status_changing = true;
            this.targetChangeStatus = targetStatus;
            const headers = this.$session.get('lawfirm_headers')
            const listings = this.selected_listings
            const campaign = +this.$route.params.campaign_id
            const payload = { listings, campaign }



            this.$http.post(`/api/whitelist-sellers?check-duplicate=${this.targetChangeStatus == 33 ? '0': '1'}`, payload, headers)
                .then(async (res) => {
                    const whitelistedSellers = res.data.data;
                    if (whitelistedSellers.some(whitelistedSeller => whitelistedSeller.isWhitelist || whitelistedSeller.hasPotentials || whitelistedSeller.hasDuplicates)) {
                        const { data } = res.data
                        this.whitelistedListings = data
                        $('.modal').modal('hide')
                        await sleep(500)
                        // $('#statusChangeModal').modal('show')
                        
                        const params = {
                            sellers: this.whitelistedListings,
                            allowDuplicateBypass: false,
                        }

                        if(whitelistedSellers.some(whitelistedSeller => whitelistedSeller.isWhitelist || whitelistedSeller.hasDuplicates))
                            $('#statusChangeModal').modal('show')
                        else {
                            //Check for potentials when accepting listing
                            if (targetStatus === 32) {
                                await this.multipleStatusChange(listings.join(),32,'');
                            } else {
                                this.proceedMultipleStatusChange(params)
                            }
                        }
                        this.$parent.showSnackbar('Showing sellers', false, 'success', 'fas fa-check-circle')
                        this.is_loading = false;
                        this.status_changing = false;
                        this.$parent.hideBackdrop();
                    }
                    else {
                        this.multipleStatusChange2(listings.join());
                    }
                })
                .catch(err => {
                    console.error(err)
                    this.$parent.showSnackbar('Something went wrong while checking for whitelist sellers. Please contact the system admin.', false, 'invalid', 'fas fa-times-circle')
                })
        },
        proceedMultipleStatusChange({ sellers, allowDuplicateBypass }) {
            this.multipleStatusChange2(sellers.map(listing => listing.id).join(), allowDuplicateBypass);
        },
        notifyListingUpdate() {
            // trigger notification to users
            this.selectedListingsValues.forEach(li => {
                globalSend({ type: 'notification', data: { updater_id: 0, researcher_id: li.user_id, qa_user_id: 0 } });
            })
        },
        checkSelectedListing() {
            let type = ['Purchased, shipping pending', 'Purchased and shipped', 'Delivery delayed', 'Delivery failed/unsuccessful', 'Delivered']
            let listing = this.mappedIndividualListingValues
            this.emptyListingInfo = false
            this.listingsWithEmptyField = []
            this.isIndividualChecking = true
            this.isMultipleChecking = false
            listing.forEach(listing => {
                const emptyFields = {};
                if (!listing.seller_name && this.$featureAccess.isAllowed('cm-field-edit-seller-name')) emptyFields.seller_name = listing.seller_name;
                if (!listing.seller_url && this.$featureAccess.isAllowed('cm-field-edit-seller-url')) emptyFields.seller_url = listing.seller_url;
                if (!listing.platform && this.$featureAccess.isAllowed('cm-field-edit-seller-platform')) emptyFields.platform = listing.platform;
                if (listing.seller_contactable === undefined && this.$featureAccess.isAllowed('cm-field-edit-seller-contactable')) emptyFields.seller_contactable = listing.seller_contactable;
                if (listing.ship_to_us === undefined && this.$featureAccess.isAllowed('cm-field-edit-will-ship-to-us')) emptyFields.ship_to_us = listing.ship_to_us;
                if (!listing.listing_url && this.$featureAccess.isAllowed('cm-field-edit-listing-url')) emptyFields.listing_url = listing.listing_url;
                if (!listing.listing_title && this.$featureAccess.isAllowed('cm-field-edit-listing-title')) emptyFields.listing_title = listing.listing_title;
                if (!listing.infringement_reason.length && this.$featureAccess.isAllowed('cm-field-edit-infringement-reason')) emptyFields.infringement_reason = listing.infringement_reason;
                if (!listing.keyword && this.$featureAccess.isAllowed('cm-field-edit-keyword')) emptyFields.keyword = listing.keyword;
                if (!listing.item_type && this.$featureAccess.isAllowed('cm-field-edit-item-type')) emptyFields.item_type = listing.item_type;
                if (!listing.claim_type && this.$featureAccess.isAllowed('cm-field-edit-claim-type')) emptyFields.claim_type = listing.claim_type;
                if (!listing.dummy_account && this.$featureAccess.isAllowed('cm-field-edit-dummy-account')) emptyFields.dummy_account = listing.dummy_account;
                if (!listing.shipping_location && this.$featureAccess.isAllowed('cm-field-edit-shipping-location')) emptyFields.shipping_location = listing.shipping_location;
                if (!listing.item_location && this.$featureAccess.isAllowed('cm-field-edit-item-location')) emptyFields.item_location = listing.item_location;
                if (!listing.payment_account && this.$featureAccess.isAllowed('cm-field-edit-payment-accounts')) emptyFields.payment_account = listing.payment_account;
                if (!listing.initial_price && this.$featureAccess.isAllowed('cm-field-edit-initial-price')) emptyFields.initial_price = listing.initial_price;
                if (!listing.currency && this.$featureAccess.isAllowed('cm-field-edit-currency')) emptyFields.currency = listing.currency;
                if (!listing.logo_on_item && this.$featureAccess.isAllowed('cm-field-edit-logo-on-image')) emptyFields.logo_on_item = listing.logo_on_item;
                if (!listing.brand_on_item && this.$featureAccess.isAllowed('cm-field-edit-brand-on-image')) emptyFields.brand_on_item = listing.brand_on_item;
                if (!listing.purchase_type && this.$featureAccess.isAllowed('cm-field-edit-purchase-type')) emptyFields.purchase_type = listing.purchase_type;
                if (!listing.payment_platform && this.$featureAccess.isAllowed('cm-field-edit-payment-platform') && type.includes(listing.purchase_type)) emptyFields.payment_platform = listing.payment_platform;
                if (!listing.transaction_number && this.$featureAccess.isAllowed('cm-field-edit-transaction-number') && type.includes(listing.purchase_type)) emptyFields.transaction_number = listing.transaction_number;
                if (!listing.purchase_price && this.$featureAccess.isAllowed('cm-field-edit-purchase-price') && type.includes(listing.purchase_type)) emptyFields.purchase_price = listing.purchase_price;
                if (!listing.date_captured && this.$featureAccess.isAllowed('cm-field-edit-date-captured')) emptyFields.date_captured = listing.date_captured;
                // if (!listing.is_merged) emptyFields.is_merged = listing.is_merged;
                if (!listing.is_merged && this.$featureAccess.isAllowed('cm-field-edit-full-merge')) emptyFields.is_merged = listing.is_merged;

                if (Object.keys(emptyFields).length > 0) {
                    emptyFields.id = listing.id;
                    this.listingsWithEmptyField.push(emptyFields);
                    this.emptyListingInfo = true
                    console.log(emptyFields)
                }
            })
        },
        checkListingsInfo() {
            let type = ['Purchased, shipping pending', 'Purchased and shipped', 'Delivery delayed', 'Delivery failed/unsuccessful', 'Delivered']
            let listings = this.mappedSelectedListingsValues
            this.emptyListingInfo = false
            this.listingsWithEmptyField = []
            this.isIndividualChecking = false
            this.isMultipleChecking = true
            listings.forEach(listing => {
                const emptyFields = {};
                if (!listing.seller_name && this.$featureAccess.isAllowed('cm-field-edit-seller-name')) emptyFields.seller_name = listing.seller_name;
                if (!listing.seller_url && this.$featureAccess.isAllowed('cm-field-edit-seller-url')) emptyFields.seller_url = listing.seller_url;
                if (!listing.platform && this.$featureAccess.isAllowed('cm-field-edit-seller-platform')) emptyFields.platform = listing.platform;
                if (listing.seller_contactable === undefined && this.$featureAccess.isAllowed('cm-field-edit-seller-contactable')) emptyFields.seller_contactable = listing.seller_contactable;
                if (listing.ship_to_us === undefined && this.$featureAccess.isAllowed('cm-field-edit-will-ship-to-us')) emptyFields.ship_to_us = listing.ship_to_us;
                if (!listing.listing_url && this.$featureAccess.isAllowed('cm-field-edit-listing-url')) emptyFields.listing_url = listing.listing_url;
                if (!listing.listing_title && this.$featureAccess.isAllowed('cm-field-edit-listing-title')) emptyFields.listing_title = listing.listing_title;
                if (!listing.infringement_reason.length && this.$featureAccess.isAllowed('cm-field-edit-infringement-reason')) emptyFields.infringement_reason = listing.infringement_reason;
                if (!listing.keyword && this.$featureAccess.isAllowed('cm-field-edit-keyword')) emptyFields.keyword = listing.keyword;
                if (!listing.item_type && this.$featureAccess.isAllowed('cm-field-edit-item-type')) emptyFields.item_type = listing.item_type;
                if (!listing.claim_type && this.$featureAccess.isAllowed('cm-field-edit-claim-type')) emptyFields.claim_type = listing.claim_type;
                if (!listing.dummy_account && this.$featureAccess.isAllowed('cm-field-edit-dummy-account')) emptyFields.dummy_account = listing.dummy_account;
                if (!listing.shipping_location && this.$featureAccess.isAllowed('cm-field-edit-shipping-location')) emptyFields.shipping_location = listing.shipping_location;
                if (!listing.item_location && this.$featureAccess.isAllowed('cm-field-edit-item-location')) emptyFields.item_location = listing.item_location;
                if (!listing.payment_account && this.$featureAccess.isAllowed('cm-field-edit-payment-accounts')) emptyFields.payment_account = listing.payment_account;
                if (!listing.initial_price && this.$featureAccess.isAllowed('cm-field-edit-initial-price')) emptyFields.initial_price = listing.initial_price;
                if (!listing.currency && this.$featureAccess.isAllowed('cm-field-edit-currency')) emptyFields.currency = listing.currency;
                if (!listing.logo_on_item && this.$featureAccess.isAllowed('cm-field-edit-logo-on-image')) emptyFields.logo_on_item = listing.logo_on_item;
                if (!listing.brand_on_item && this.$featureAccess.isAllowed('cm-field-edit-brand-on-image')) emptyFields.brand_on_item = listing.brand_on_item;
                if (!listing.purchase_type && this.$featureAccess.isAllowed('cm-field-edit-purchase-type')) emptyFields.purchase_type = listing.purchase_type;
                if (!listing.payment_platform && this.$featureAccess.isAllowed('cm-field-edit-payment-platform') && type.includes(listing.purchase_type)) emptyFields.payment_platform = listing.payment_platform;
                if (!listing.transaction_number && this.$featureAccess.isAllowed('cm-field-edit-transaction-number') && type.includes(listing.purchase_type)) emptyFields.transaction_number = listing.transaction_number;
                if (!listing.purchase_price && this.$featureAccess.isAllowed('cm-field-edit-purchase-price') && type.includes(listing.purchase_type)) emptyFields.purchase_price = listing.purchase_price;
                if (!listing.date_captured && this.$featureAccess.isAllowed('cm-field-edit-date-captured')) emptyFields.date_captured = listing.date_captured;
                // if (!listing.is_merged) emptyFields.is_merged = listing.is_merged;
                if (!listing.is_merged && this.$featureAccess.isAllowed('cm-field-edit-full-merge')) emptyFields.is_merged = listing.is_merged;

                if (Object.keys(emptyFields).length > 0) {
                    emptyFields.id = listing.id;
                    this.listingsWithEmptyField.push(emptyFields);
                    this.emptyListingInfo = true
                }
            })
        },
        removeIncompleteListings() {
            const listingIds = new Set(this.listingsWithEmptyField.map(listing => listing.id));
            this.selected_listings = this.selected_listings.filter(id => !listingIds.has(id));
            this.selectedListingsValues?.filter(id => !listingIds.has(id));
        },
        copyToClipboard2(id) {
            const textToCopy = id

            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    this.$parent.showSnackbar(`You copied Listing ID: ${textToCopy}`, false, 'success', 'fas fa-check-circle')
                })
                .catch(err => {
                    this.$parent.showSnackbar(`Unable to copy. ${err}`, false, 'error', 'fas fa-times-circle')
                })
            },
        async multipleStatusChange2(listingIds, allowBypass = false) {
            this.is_loading = true;
            this.status_changing = true;
            const headers = JSON.parse(this.headers)
            headers.headers['Campaign-id'] = this.$route.params.campaign_id
            // const idArray = listingIds.split(',');
            // let counter = 0;
            // let counterTotal = 0;
            // idArray.forEach(id => {
            //     counterTotal++
            // })

            if (allowBypass) headers.headers['Allow-Checking-Bypass'] = 1

            const subStatusMainStatus = this.$parent.getQFlag(this.targetChangeStatus)

            if (this.subStatusesStore?.subStatuses?.total_item_by_substatus?.[subStatusMainStatus]?.filter(s => s.id)?.length && this.subStatusesStore.currentSelectedSubStatus == null) {
                this.status_changing = false
                return this.$parent.showSnackbar('Please select a sub status', false, 'warning', 'fas fa-exclamation-triangle')
            }

                this.$parent.showBackdrop();
                const body = {
                    target_substatus_id: this.subStatusesStore.currentSelectedSubStatus ?? -1,
                    listing_ids: listingIds,
                    comment: !isEmpty(this.$store.state.other_reason_comment) ? this.$store.state.other_reason_comment : '',
                    reasons: this.setPredefinedReasons(),
                    from_lf: true
                }
                await this.$http.patch(`/listings/multiple/statuschange/${this.targetChangeStatus}`, body, headers)
                    .then(response => {
                        let result = response.data
                        console.log(result)
                        // trigger notification to users
                        this.notifyListingUpdate()

                        this.$parent.hideBackdrop();
                        this.$parent.showSnackbar(`Successfully marked listing as ${getQFlag(this.targetChangeStatus)}.`, false, 'success', 'fas fa-check')
                        this.status_changing = false
                        this.batch_comment = ""
                        this.is_select_all = false
                        this.selectAll()
                        this.setRelatedCampaignDuplicateBypass(false)
                        this.platformDisplayCounts = {}
                        $('.modal').modal('hide')
                        setTimeout(async () => {
                            // to call and reinitialize the statuses in the filter drawer - Marvin 01/28/20
                            let filter_drawer = this.siblings_component.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
                            filter_drawer.dem_statuses = []
                            filter_drawer.initializeStatuses()
                            this.selected_reasons = []
                            await this.getListings()
                            await this.getCaseSellerAccounts()
                        })
                    })
                    .catch(error => {
                        this.$parent.hideBackdrop();
                        console.error(error)
                        const duplicate_logs = error?.response?.data?.meta?.duplicate_logs
                        if (duplicate_logs) { 
                            this.status_changing = false
                            this.setRelatedCampaignDuplicateContinueFunction(() => this.multipleStatusChange2(listingIds))
                            this.setRelatedCampaignDuplicates(duplicate_logs)
                            this.$parent.showSnackbar(error.response.data.meta.message, false, "invalid", "fas fa-exclamation-circle")
                            return
                        }
                        this.already_worked_lids = error.response.data.data.map(list => {
                            const worked = list.duplicate[0]
                            return {
                                origin_lid: list.reference.id,
                                seller_name: worked?.name,
                                lid: worked?.id,
                                qflag: worked?.qflag_id
                            }
                        })
                        if (this.selected_listings.length != this.already_worked_lids.length) {
                            // let filter_drawer = this.siblings_component.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0].$children[0]
                            // filter_drawer.dem_statuses = []
                            // filter_drawer.initializeStatuses()
                            this.refreshCM_statuses()
                            this.selected_reasons = []
                            this.getListings(false)
                        }
                        //Show duplicate sellers when trying to change status
                        if (error.response.data.meta.has_duplicate) {
                            this.closeModal()
                            this.duplicate_seller = error.response.data.data.map(({ duplicate, reference }) => ({ ...duplicate, ref: reference.id }))
                            $('#duplicate-seller-modal').modal('show')
                        }
                        this.$parent.showSnackbar(error.response.data.meta.message, false, "invalid", "fas fa-exclamation-circle")
                        this.status_changing = false
                    })
        },
        proceedToAcceptListings({ sellers, allowDuplicateBypass }) {
            this.submitListings(sellers.map(listing => listing.id), allowDuplicateBypass);
        },
        async submitListings(listingIds, allowBypass = false) {
            const headers = JSON.parse(this.headers)

            if (allowBypass) headers.headers['Allow-Checking-Bypass'] = 1

            if (this.subStatusesStore?.subStatuses?.total_item_by_substatus?.["Submitted"]?.filter(s => s.id)?.length && this.subStatusesStore.currentSelectedSubStatus == null) {
                this.status_changing = false
                return this.$parent.showSnackbar('Please select a sub status', false, 'warning', 'fas fa-exclamation-triangle')
            }

            //Accept listings
            this.$http.patch(`/listings/multiple/statuschange/35`, { target_substatus_id: this.subStatusesStore.currentSelectedSubStatus ?? -1, listing_ids: listingIds.join() }, headers)
                .then(() => {             
                    this.setRelatedCampaignDuplicateBypass(false)       
                    this.$parent.showSnackbar('Successfully submitted listings', false, 'success', 'fas fa-check-circle')
                    $('#listingsToBeAcceptedModal').modal('hide');

                    setTimeout(() => location.reload(), 2000)
                })
                .catch(err => {
                    console.error(err)
                    const duplicate_logs = err?.response?.data?.meta?.duplicate_logs
                    if (duplicate_logs) { 
                        this.status_changing = false
                        this.setRelatedCampaignDuplicateContinueFunction(() => this.submitListings())
                        this.setRelatedCampaignDuplicates(duplicate_logs)
                        this.$parent.showSnackbar(err.response.data.meta.message, false, "invalid", "fas fa-exclamation-circle")
                        return
                    }
                    this.$parent.showSnackbar('Something went wrong. Please contact System Admin', false, 'invalid', 'fas fa-times-circle')
                    $('#listingsToBeAcceptedModal').modal('hide');
                });
        },
        caseUploadDragEnter(index) {
            let current = $('.upload-case--box').eq(index)
            $(current).addClass('dragHover')
            $(current).children().filter("div.icon").children().filter("label.d-block").text("Drag 'n drop file here")
        },
        caseUploadDragLeave(index, label) {
            let current = $('.upload-case--box').eq(index)
            $(current).removeClass('dragHover')
            $(current).children().filter("div.icon").children().filter("label.d-block").text(label)
        },
        removeUploadCaseFile(index, label) {
            this.caseUploadDragLeave(index, label)
            this.caseFileUploads[index].file = ""
            this.caseFileUploads[index].blob = ""
            this.caseFileUploads[index].link = ""
            this.caseFileUploads[index].date_granted = ""
        },
        openToBeUploadedCaseFile(index) {
            let current = this.caseFileUploads[index]
            current.link
                ? window.open(current.link, '_blank')
                : typeof current.file.name != 'undefined'
                    ? window.open(current.blob, '_blank') : null
        },
        viewToBeUploadedTooltip(index) {
            let current = this.caseFileUploads[index]
            return current.link ? "Click to download pdf" : typeof current.file.name != 'undefined' ? "Click to view pdf" : null
        },
        resetCaseFileUploads() {
            for (let index = 0; index < this.caseFileUploads.length; index++) {
                this.removeUploadCaseFile(index)
            }
        },
        async getCaseStatuses() {
            let headers = JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers)
            await axios.get(`${process.env.VUE_APP_URL}/case_statuses?page_size=1000`, headers)
                .then(({ data }) => {
                    this.case_status_options = data.data.map(c => ({ label: c.name, value: c.id }))
                    this.caseFileUploads = data.data
                        .filter(c => [4, 5, 6, 7, 8, 9, 10].includes(c.id))
                        .map(c => {
                            const name = c.name == "Case Terminated" ? "Case Closed" : c.name
                            const fileIndex = this.case_files.findIndex(cf => cf.type == name)
                            const date_granted = this.case_files[fileIndex]?.date_granted ?? ""
                            const link = fileIndex !== -1 ? this.getCaseFileLink(this.current_show_file_case_id, name + ".pdf") : ""
                            const file_uploads_id = this.case_files[fileIndex]?.id ?? ""

                            return { id: c.id, label: name, value: c.name, file: "", blob: "", link, date_granted, file_uploads_id }
                        })
                    
                    // make a copy of the caseFileUploads Array to be passed to the DownloadCaseFilesModal
                    this.caseFileUploads_copy = JSON.parse(JSON.stringify(this.caseFileUploads.map(c => ({ ...c, link: '' }))))
                })
            setTimeout(() => {
                $('.caseFileDeleteConfirmationDropdown').click(function (e) {
                    e.stopPropagation();//test comment
                })
            }, 1000);
        },
        initializeRemoveCaseFileButton() {
            $('.cancelCaseFileDelete').click((e) => {
                $(e.target).parent().parent().removeClass('show')
            })
        },
        async getCurrentItemType(id) {
            let response = await axios.get(`${process.env.VUE_APP_URL}/classification_products/searchByListing/${id}`, JSON.parse(localStorage.getItem("headers")));
            response = response.data;
            if (!response) {
                return "Not Set";
            }
            return `[Class ${response.classification_type.classification?.number ?? 'n/a'}] ${response.classification_type.type}`;
        },
        async downloadArchivedSAFiles() {
            let type = this.filter_sa_type
            this.isDownloading = true
            let response = await axios.get(`${process.env.VUE_APP_URL}/files/sa_templates/${this.listing_id_current}/zip/${type}`, JSON.parse(localStorage.getItem("headers")));
            this.isDownloading = false
            window.open(`${process.env.VUE_APP_URL + response.data.data}`);
        },
        getListingInfoValue(field, listing) {
            return listing.info
                && listing.info.find(info => info.field == field)
                && listing.info.find(info => info.field == field).value
        },
        viewUploads(listing_id) {
            this.listing_id_current = listing_id
            this.getAgreements(listing_id)
            console.log(listing_id)
            $('#viewUploadsModal').modal('show')
        },
        hideUploads() {
            $('#viewUploadsModal').modal('hide')
        },
        removeConfirmation() {
            $('#removeFileConfirmation').modal('show')
        },
        hideConfirmation() {
            $('#removeFileConfirmation').modal('hide')
        },
        removeSAConfirmation() {
            $('#removeSAConfirmation').modal('show')
        },
        hideSAConfirmation() {
            $('#removeSAConfirmation').modal('hide')
        },
        openManageGroups(){
            $('#manage-groups').modal('show')
            this.selected_campaign_group = -1
            this.campaign_group_isSelected = false
            this.multipleGroupDelete = []
            this.new_group_mode = false
            this.new_group_name = ''
            this.new_group_description = ''
        },
        closeManageGroups(){
            $('#manage-groups').modal('hide')
        },
        openEditGroups(){
            $('#edit-groups').modal({ backdrop: 'static', keyboard: false });
        },
        closeEditGroups(){
            $('#edit-groups').modal('hide')
        },
        openBulkDeleteGroupConfirm() {
            $('#confirm-bulk-delete-groups').modal('show')
        },
        closeBulkDeleteGroupConfirm() {
            $('#confirm-bulk-delete-groups').modal('hide')
        },
        platformBreakdownMoreFilter() {
            $('#platform-data-breakdown-more-filter').modal('show')
            $('#platform-data-breakdown-modal').modal('hide')
        },
        cancelPlatformMoreFilter() {
            $('#platform-data-breakdown-more-filter').modal('hide')
            $('#platform-data-breakdown-modal').modal('show')
            this.modifyPlatformBreakDown = false
            this.platformDisplayCounts = {}
            this.selected_listings= []
        },
        showPlatformDataBreakdownAgain() {
            $('#platform-data-breakdown-more-filter').modal('hide')
            $('#platform-data-breakdown-modal').modal('show')
            this.selected_listings = []
            this.getCaseSellerAccounts()
        },
        copyListings() {
            this.listingCopy = JSON.parse(JSON.stringify(this.listings))
        },
        savePlatformDisplayCounts() {
            this.modifyPlatformBreakdown = true
            if(this.modifyPlatformBreakdown) {
                const slicedData = {};
                for (let platform in this.platform_data_breakdown) {
                    let count = this.platformDisplayCounts[platform] || this.platform_data_breakdown[platform].length;
                    if (parseInt(count) <= 0) count = 0; // Ensure count is at least 1
                    slicedData[platform] = this.platform_data_breakdown[platform].slice(0, count);
                }
                this.$store.dispatch('getPlatformBreakdown2', slicedData)
                return slicedData;
            } else {
                this.$store.dispatch('getPlatformBreakdown2', this.platform_data_breakdown)
                return this.platform_data_breakdown
            }
        },
        changeFiles(event) {
            this.fileList = [...this.$refs.file.files];
            this.fileNameSelected = `${this.selected_seller_name} - ` + event.target.files[0]?.name
            this.selectedAgreementFile = null
            this.selectedFileType = 'select'
        },
        removeFiles(i) {
            this.fileList.splice(i, 1);
        },
        fileDrag(event) {
            event.preventDefault();
            if (!event.currentTarget.classList.contains('bg-green-300')) {
                event.currentTarget.classList.remove('bg-gray-100');
                event.currentTarget.classList.add('bg-green-300');
            }
        },
        fileLeave(event) {
            event.preventDefault();
            event.currentTarget.classList.add('bg-gray-100');
            event.currentTarget.classList.remove('bg-green-300');
        },
        fileDrop() {
            // event.preventDefault();
            this.fileList = [...this.$refs.file.files];
            this.fileNameSelected = `${this.selected_seller_name} - ` + this.fileList[0]?.name
            this.selectedAgreementFile = null
            this.selectedFileType = 'select'
        },
        uploadedImage() {
            let fileName = this.fileList[0].name.split(".")
            const fileExt = fileName[fileName.length - 1]
            var updatedFileName = fileExt.includes('doc') ? 'DOC'
                : fileExt.includes('xls') ? 'XLS'
                    : ['jpg', 'jpeg'].includes(fileExt) ? 'JPG'
                        : fileExt
            return `/static/svg/agreement-files/${updatedFileName?.toUpperCase()}.svg`
        },
        displayImages(fileName) {
            let file = fileName.file_name.split(".")
            // fileName = this.agreements_computed.file.file_name.split(".")
            const fileExt = file[file.length - 1]
            var updatedFileName = fileExt.includes('doc') ? 'DOC'
                : fileExt.includes('xls') ? 'XLS'
                    : ['jpg', 'jpeg'].includes(fileExt) ? 'JPG'
                        : fileExt
            return `/static/svg/agreement-files/${updatedFileName?.toUpperCase()}.svg`
        },
        downloadAgreementFile() {
            window.open(`${process.env.VUE_APP_URL}/files/sa_templates/${this.agreements_computed[this.selectedAgreementFile]?.id}/download`)
        },
        downloadSAFile() {
            window.open(`${process.env.VUE_APP_URL}/files/sa_templates/${this.selectedAgreementFile}/download`)
        },
        setCurrentGroup(group) {
            if (this.processing) {
                return;
            }
            this.selected_group = group;
        },
        toggleGroupView() {
                this.new_group_mode = !this.new_group_mode;
                this.is_available = null;
                this.new_group_name = null;
                this.new_group_description = null;
        },
        getGroupListings() {
            if (this.selected_group.sellers.length > 0) {
                return;
            }
            let headers = JSON.parse(localStorage.getItem('headers'));
            return axios
                .get(`${process.env.VUE_APP_URL}/listings/get-by-group?page_size=10000&group_id=${this.selected_group.id}`, headers)
                .then(response => {
                    this.selected_group.sellers = response.data.data.map(s => `site-id-${s.site_id}-platform-id-${s.platform_id}`);
                    return true;
                })
                .catch(ex => {
                    console.log(ex);
                    this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-times-circle');
                    return false;
                });
        },
        clearGroupTransfer(){
            // this.newGroupListingTransfer = 'default';
            this.newGroupListingTransfer = '';
            this.listingsToTransfer = [];
            this.confirmDeleteListing = false;
            this.groupNameUpdateConfirm = false;
        },
        cancelGroupTransfer() {
            this.clearGroupTransfer()
            this.selected_group = ''
            this.selected_group_name = ''
            this.selected_group_name_copy = ''
        },
        async getNewGroups(){
            let headers = JSON.parse(this.headers)
            await axios.get(`${process.env.VUE_APP_URL}/api/v2/pre_filing_groups?filter[qflag_id]=${this.qFlagID}&include[0]=listingsCount`, headers).then(
                response => {
                    this.groups_new_copy = response.data.data.map( g => ({
                        label: g.name,
                        value: g.id,
                        campaign_id: g.campaign_id,
                        created_at: g.created_at,
                        deleted_at: g.deleted_at,
                        description: g.description,
                        updated_at: g.updated_at,
                        qflag_id: g.qflag_id,
                        listing_count: g.listings_count
                    }))
                }
            )
        },
        async deleteGroup(){
            this.$parent.showSnackbar('Deleting Group... Please wait.', true)
            let group_id = this.selected_group.value
            let headers = this.$session.get('lawfirm_headers')
            await axios.delete(`${process.env.VUE_APP_URL}/api/v2/pre_filing_groups/${group_id}`, headers)
                .then(response => {
                        this.selected_campaign_group = -1
                        this.campaign_group_isSelected = false
                        this.$parent.showSnackbar(`Successfully deleted group!`, false, 'success', 'fas fa-trash')
                }).catch( ex => {
                    console.log(ex);
                    this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-times-circle')
                    return false;
                })
            this.getListings();
            this.getNewGroups();
            this.selected_group = null;
            this.groups = this.groups.filter( g => { return g.value !== group_id })
            
        },
        async bulkGroupDelete(){
            this.$parent.showSnackbar('Processing... Please wait.', true)
            let group_ids = this.multipleGroupDelete
            let groups = []
            let headers = this.$session.get('lawfirm_headers')
            for (let group of group_ids) {
                groups.push(
                    await axios.delete(`${process.env.VUE_APP_URL}/api/v2/pre_filing_groups/${group}`, headers)
                        .then(response => {
                            this.$parent.showSnackbar('Deleting groups... Please wait.', true)
                        }).catch( ex => {
                            console.log(ex)
                            this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-times-circle')
                            return false;
                        })
                )
            }
            this.$parent.showSnackbar(`Successfully deleted group(s) !`, false, 'success', 'fas fa-trash')
            this.getListings();
            this.getNewGroups();
            this.closeBulkDeleteGroupConfirm();
            this.multipleGroupDelete = [];
        },
        async deleteGroupListing(){
            this.$parent.showSnackbar('Processing... Please wait.', true)
            let listing_id = this.selectedListingToDelete
            let headers = this.$session.get('lawfirm_headers')
            await axios.delete(`${process.env.VUE_APP_URL}/api/v2/pre_filing_group_listings/${listing_id}/removeListingFromTheGroup`, headers)
                .then(response => {
                        this.confirmDeleteListing = false
                        this.selectedListingIndividual = -1
                        this.selectedListingToDelete = 0
                        this.getListings();
                        this.getNewGroups();
                        this.$parent.showSnackbar(`Listing succesfully removed!`, false, 'success', 'fas fa-trash')
                }).catch( ex => {
                    console.log(ex)
                    this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-times-circle')
                    return false;
                })
        },
        async transferGroupListings() {
            this.$parent.showSnackbar('Processing... Please wait.', true)
            let group_id = this.newGroupListingTransfer
            let old_group = this.selected_group?.value
            let listingsToTransfer = this.listingsToTransfer
            let listing_id = []
            let headers = this.$session.get('lawfirm_headers')
            if(group_id == old_group) {
                return this.$parent.showSnackbar('Selected group for transfer is already the current group. Please select a new one.', false, 'invalid', 'fas fa-times-circle')
            }
            else {
                for (let listing of listingsToTransfer) {
                    listing_id.push(
                        await axios.post(`${process.env.VUE_APP_URL}/api/v2/pre_filing_group_listings/${listing}/${group_id}/addListingToTheGroup`, {}, headers)
                            .then( response => {
                                this.$parent.showSnackbar('Transferring to another group... Please wait.', true)
                            }).catch( ex => {
                                console.log(ex)
                                this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-times-circle')
                                return false;
                            })
                    )
                }
            }
            this.$parent.showSnackbar(`Sellers Successfuly Transferred!`, false, 'success', 'fas fa-check-circle')
            this.clearGroupTransfer();
            this.getListings();
            this.getNewGroups();
            this.listingsToTransfer = [];
        },
        copytoClipboard(str = "", prop_name = 'listing id') {
                if (str != "") {
                    let modal = []
                    let container = (modal = document.querySelectorAll('.modal.in, .modal.show')).length ? modal : document
                    const el = document.createElement('textarea')
                    el.value = str
                    el.setAttribute('readonly', '')
                    el.style.position = 'absolute'
                    el.style.left = '-9999px'
                    container[modal.length ? 0 : "body"].appendChild(el)
                    el.select()
                    document.execCommand('copy')
                    container[modal.length ? 0 : "body"].removeChild(el)

                    // call the snackbar when copied
                    this.$parent.showSnackbar(`You copied ${prop_name}: ${str}`, false, 'success', 'fas fa-check')
                }
            },
        async clickClass_Aggregator(index) {
            await sleep(400)

            let hasShowClass = $(`#collapse-item-class-${index}`).hasClass('show')

            if(hasShowClass) {
                this.selected_ItemClass = index
            } else {
                this.selected_ItemClass = null
            }
        },
        async getItemClass_ItemType(refresh = false) {
            // check data first 
            // if already have data return
            // unless refresh is click inside the modal
            if(this.itemClass_withItemTypesData.length > 0 && !refresh)
                return

            // // refresh inside the modal
            if(!this.isRequesting_ItemTypeItemClass) {
                this.cancelRequests();
                this.refreshCancelToken();
            } else {
                return
            }

            let headers = JSON.parse(JSON.parse(localStorage.getItem('lawfirm')).headers)
            headers.cancelToken = this.cancellationTokenSource.token;
            
            this.show_itemClasses = false
            let queries = {
                filter: {
                    campaign_id: this.$route.params.campaign_id,
                    active: 1,
                    has_listings_count: 1,
                },
                include: ["classificationTypes2", "classificationTypes2.qualified_count", "classificationTypes2.has_potential_count", "classificationTypes2.accepted_count", "classificationTypes2.submitted_count", "classificationTypes2.filed_count"],
                fields: {
                    classifications: "id,class,number"
                }
            }
            this.isRequesting_ItemTypeItemClass = true

            await axios.get(`${process.env.VUE_APP_URL}/api/v2/classifications?${qs.stringify(queries, {encodeValuesOnly: true})}`, headers).then(
                response => {
                    if(response.status == 200) {
                        this.itemClass_withItemTypesData = response.data.data.map(d => {
                            return {
                                id: d.id,
                                class: d.class,
                                item_types: d.classification_types2,
                                number: d.number,
                            }
                        })
                        this.show_itemClasses = true
                        // console.log(this.itemClass_withItemTypesData)

                        this.itemClass_withItemTypesData = this.itemClass_withItemTypesData.map(ic => {
                            let totalLID_underThisClass = 0
                            let totalQualified_count = 0
                            let totalHasPotential_count = 0
                            let totalAccepted_count = 0
                            let totalSubmitted_count = 0
                            let totalFiled_count = 0

                            let className = ic.number != '' ? `[Class ${ic.number}] ${ic.class}` : `[Class n/a] ${ic.class}`
                            ic.item_types.forEach(it => {
                                totalLID_underThisClass += it.qualified_count
                                totalLID_underThisClass += it.has_potential_count
                                totalLID_underThisClass += it.accepted_count
                                totalLID_underThisClass += it.submitted_count
                                totalLID_underThisClass += it.filed_count

                                totalQualified_count += it.qualified_count
                                totalHasPotential_count += it.has_potential_count
                                totalAccepted_count += it.accepted_count
                                totalSubmitted_count += it.submitted_count
                                totalFiled_count += it.filed_count

                            })
                            return {
                                ...ic,
                                totalLID_underThisClass: totalLID_underThisClass,
                                formatted_className: className,
                                totalQualified_count: totalQualified_count,
                                totalHasPotential_count: totalHasPotential_count,
                                totalAccepted_count: totalAccepted_count,
                                totalSubmitted_count: totalSubmitted_count,
                                totalFiled_count: totalFiled_count,
                            }
                        })
                        this.isRequesting_ItemTypeItemClass = false
                    }
                }
            ).catch((error) => {
                this.isRequesting_ItemTypeItemClass = false
                if(error.message == 'Network Error')
                    this.$parent.showSnackbar('Encounter an Error while fetching the data. Please refresh to request again.', false, 'invalid', 'fas fa-times-circle');

            })
        },
        async reset_ItemClassModal() {
            let index = this.selected_ItemClass
            await sleep(500)
            let hasShowClass = $(`#collapse-item-class-${index}`).hasClass('show')

            if(hasShowClass) {
                this.selected_ItemClass = null
                $(`#collapse-item-class-${index}`).removeClass('show')
            } else {
                this.selected_ItemClass = null
            }
            this.item_class_order_by = 'asc'
            this.item_type = 0
        },
        async getAdditionalFilters_data() {
            if(!this.expanded)
                return
            if(this.getDataFor_AdditionalFilters)
                return
            
            this.getDataFor_AdditionalFilters = true

            await this.getPaymentTypes()
            await this.getInfringementReasons()
            await this.getItemTypes()
            await this.getItemClasses()
            await this.getClaimTypes()
            await this.getItemLocations()
            await this.getSellerLocations()
            await this.getGroupNames()
            if (['Submitted', 'Accepted'].includes(this.$route.params.status)) await this.getPacks();

            this.getDataFor_AdditionalFilters = false
        },
        isFeatureRestricted(feature) {
            return isFeatureRestricted(feature);
        },
        isDisabled() {
            return this.saUploads_computed.length == 0 || this.isDownloading || this.loadingSAUploads
        },
        doThisOnCaseFileUpdate() {
            this.getListings(false)
            this.refreshCM_statuses()
        },
        refreshCM_statuses() {
            const filter_drawer = this.siblings_component.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0] 
                ?? this.siblings_component.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0].$children[0]
            filter_drawer.dem_statuses = []
            filter_drawer.initializeStatuses()
        }
    },
    watch: {
        'subStatusesStore.showListingsWithSubstatuses'(value) {
            this.getListings();
        },
        async isDateGrantedRestricted(value) {
            if (value && this.isUploadingCaseFiles) {
                $('#case-file-dategranted-modal').modal('hide')
                await sleep(500)
                $('#upload-case-file-modal').modal('show')
            }
        },
        sort_by() {
            //if (this.first_load) this.initializeSearch()
            this.current_page = 1;
        },
        order_by() {
            //if (this.first_load) this.initializeSearch()
            this.current_page = 1;
        },
        async '$route.query'() {
            if (this.$route.query.listing_id) {
                this.search_by = 'listing_id';
                this.search_input = this.$route.query.listing_id;
                this.lid_status = this.$route.params.status;
                this.initializeSearch();
            }
            if (this.$route.query.case_status != null || this.$route.query.substatus_id != null) {
                await this.initializeSortOptions()
                this.getListings();
            }
            this.isFileCaseMode = false
        },
        '$route.params.status': {
            handler: async function (search) {
                this.expanded = false
                this.listings = []
                $('[data-toggle="dropdown"').dropdown('hide')
                await this.initializeSortOptions()
                if (search == 'Cases') await this.resetMoreFilters()
                if (this.$route.params.status != 'Cases') this.getCaseSellerAccounts()
                this.filter_group = '0';
                this.applied_filters_count = this.filter_set_count()
                this.getItemTypes();
                this.getItemClasses();
                if (['Submitted', 'Accepted'].includes(this.$route.params.status)) this.getPacks();
                this.getGroupNames()
                await this.getListings()
                this.checkWhitelist()
                this.getPredefinedReasonsFilter()
                this.is_select_all = false
                this.selectAll()
                this.isFileCaseMode = false
            }
            // ,deep: true
        },
        'filters.selected_lf_platforms': {
            handler: function (val) {
                //if (this.first_load && this.$route.params.status != 'Cases') this.getListings()
                this.current_page = 1;
                if(this.expanded) this.getSellerLocations()
            }
        },
        'filters.selected_payment_methods': {
            handler: async function (val) {
                //await this.doWhenFilterisChanged()
            },
            // deep: true
        },
        'filters.selected_counsels': {
            handler: async function (val) {
                //await this.doWhenFilterisChanged()
            },
            // deep: true
        },
        'filters.selected_seller_flags': {
            handler: async function (val) {
                //await this.doWhenFilterisChanged()
            },
            // deep: true
        },
        '$route.params.case_id': {
            handler: async function (val) {
                $('[data-toggle="dropdown"').dropdown('hide')
                this.case_name = ""
                this.getCaseInfo()
                await this.initializeSortOptions()
                await this.resetMoreFilters()
                await this.getGroupNames()
                // this.getListings()
                this.getImportPlatforms()
                if (val != '0' && val != 0) {
                    this.getDefendantCaseFiles(val)
                    this.getCaseDetails()
                    this.getSellerFlags()
                }
            }
        },
        'selected_payment_method': {
            handler: function (val) {
                // this.getCaseSellerAccounts()
                this.filterAvailablePaymentAccounts()
            }
        },
        selected_platform_data_breakdown() {
            this.filterPlatformDataBreakdown()
        },
        '$route.name'() {
            $('[data-toggle="dropdown"').dropdown('hide')
            this.group_name = ""
            this.first_load = false
            this.isFileCaseMode = false
        },
        filter_upload_type(val) {
            this.filtere_case_files = (val == "0") ? this.case_files : this.case_files.filter(file => file.type == val)
        },
        update_all_frozen_accounts(val) {
            let accounts = this.frozen_accounts
            for (let [index, account] of accounts.entries()) {
                if (account.is_updatable) {
                    let action = val ? 'update' : 'skip'
                    document.getElementById('frozen-issues-' + index).value = action
                    this.frozen_accounts[index]['action'] = action
                }
            }
        },
        is_proceed_to_related_accounts(val) {
            if (val) {
                setTimeout(() => {
                    for (let [index, account] of this.related_frozen_columns.entries()) {
                        document.getElementById('related-frozen-column-' + index).value = '0'
                    }
                }, 500);
            }
        },
        async '$route.params.campaign_id'() {
            console.log('params.campaign_id')
            await this.initializeSortOptions()
            await this.getListings()
            this.checkWhitelist()
            this.getPredefinedReasonsFilter()
            if (this.$route.params.status != 'Cases') {
                this.getPaymentTypes()
                this.getItemTypes()
                this.getItemClasses()
                if (['Submitted', 'Accepted'].includes(this.$route.params.status)) this.getPacks();
            }
            this.isFileCaseMode = false
        },
        'templates.keywords'() {
            this.templates.current = {}
            this.templates.confirmRemove = false
        },
        filter_item_class() {
            if (this.filter_item_class.length > 0) {
                this.filter_item_type = this.item_type_options_display.filter(type => this.filter_item_type.includes(type));
            }
        },
        filter_group() {
            if (this.$route.params.case_id != 0) {
                this.getListings()
                $('#groupFilterDropdown').dropdown('hide')
            }
        },
        search_input(new_val) {
            // console.log(this.globalSearch_input)
            if (new_val == this.globalSearch_input && this.hasUsed_globalSearch) {
                this.$store.dispatch('isGlobalSearch', true)
                this.$store.dispatch('globalSearchItem', this.search_input)
                // console.log(this.globalSearch_input)
                // console.log(this.hasUsed_globalSearch)
            }
        },
        hasUsed_globalSearch(new_val) {
            if (!new_val) {
                this.$store.dispatch('isGlobalSearch', false)
                this.$store.dispatch('globalSearchItem', '')
                this.$store.dispatch('setGlobalSearch', {})
                this.$store.dispatch('setGlobalListings', [])
                this.$parent.showSnackbar('', false, 'info', 'fas fa-info-circle')
                this.clearKeywords()
                this.is_select_all = false
                this.selectAll()
            } else {
                this.getPlatformDataBreakdown()
                this.getCaseSellerAccounts()
                this.platformDisplayCounts = {}
            }
        },
        // 'page_size'(val) {
        //     if(val >= 500) {
        //         this.updatePageSize(val)
        //     }
        // },
        globalSearch_listings() {
            this.getPlatformDataBreakdown()
            this.getCaseSellerAccounts()
        },
        groups(new_val) {
            this.groups_new_copy = new_val
            // this.getListings()
        },
        async addNewGroupListing() {
            await this.getListings()
            this.$store.dispatch('setAddNewGroupListing', false)
        },
        async item_type() {
            let index = this.selected_ItemClass
            await sleep(500)
            let hasShowClass = $(`#collapse-item-class-${index}`).hasClass('show')

            if(hasShowClass) {
                this.selected_ItemClass = null
                $(`#collapse-item-class-${index}`).removeClass('show')
            } else {
                this.selected_ItemClass = null
            }
        },
        async item_class_order_by() {
            let index = this.selected_ItemClass
            await sleep(500)
            let hasShowClass = $(`#collapse-item-class-${index}`).hasClass('show')

            if(hasShowClass) {
                this.selected_ItemClass = null
                $(`#collapse-item-class-${index}`).removeClass('show')
            } else {
                this.selected_ItemClass = null
            }
        },
        restrictedFeatures() {
            this.initializeSortOptions();
        },
        targetChangeStatus(val) {
            if(val == 31) {
                this.selected_status_string = 'Monitoring'
            } else if(val == 3) {
                this.selected_status_string = 'For Review'
            } else if(val == 98) {
                this.selected_status_string = 'Has Potential'
            } else if(val == 37) {
                this.selected_status_string = 'Initial Review Completed'
            } else if(val == 32) {
                this.selected_status_string = 'Accepted'
            } else if(val == 35) {
                this.selected_status_string = 'Submitted'
            } else if(val == 33) {
                this.selected_status_string = 'Rejected'
            } else if(val == 36) {
                this.selected_status_string = 'For Internal Review'
            } else if(val == 34) {
                this.selected_status_string = 'Filed'
            }
        },
        platformDisplayCounts(platform) {
            if(this.platformDisplayCounts[platform] < 0) {
                this.platformDisplayCounts[platform] = 1
            }
        },
        slicedPlatformData(newVal, oldVal) {
            if(this.$route.query.substatus_id) {
                if (!newVal || Object.keys(newVal).length === 0) {
                    this.listings = [...this.listings];
                    return;
                }
    
                const filteredListings = [];
    
                for (const platform in newVal) {
                    if (Object.hasOwnProperty.call(newVal, platform)) {
                        const platformListings = this.listingCopy.filter(listing => {
                            return newVal[platform].some(platformListing => platformListing.listing_id === listing.id);
                        });
    
                        filteredListings.push(...platformListings);
                    }
                }
                this.listings = filteredListings;
            }
        },
        platform_data_breakdown(newVal, oldVal) {
            if(newVal) {
                this.$store.dispatch('getPlatformBreakdown2', this.slicedPlatformData)
            }
        },
        // listings(new_val) {
        //     console.log(new_val)
        //     new_val.forEach( async (l,index) => {
        //         console.log(l, index)
        //         let evidences = await this.returnCurrentEvidences(l.id)
        //         console.log(evidences)
        //         await this.uploadedEvidences(evidences,index)
        //     })
        // }
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>
<style scoped>
.qa-selected strong {
    font-weight: 500;
    position: relative;
}

.qa-selected strong.with-separator:after {
    content: "";
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: #ccc;
    position: absolute;
    right: -71px;
    height: 32px;
    width: 1px;
    top: 50%;
}

.qa-selected .action-item:hover {
    opacity: 0.5;
}

.qa-selected .action-item:active {
    opacity: 0.8;
}

.main-con {
    padding: 208px 343px 150px 77px;
}

.a-header {
    position: relative;
    top: 1px;
    letter-spacing: 0.024em;
    color: #383A3D;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 0;
    height: 40px;
    line-height: 7px;
    vertical-align: middle;
}

.a-header img {
    height: 40px;
    width: 40px;
    margin-right: 17px;
}

.a-header span {
    display: block;
    font-size: 16px;
    font-weight: 400;
    color: #383A3D;
}

.a-header em {
    font-style: initial;
    opacity: 0.8;
}

.a-header strong {
    font-weight: 500 !important;
}

.titlebar-con {
    padding-right: 334px;
    padding-left: 46px;
}

.a-titlebar {
    padding-right: 0;
}

.a-sidebar-filter {
    box-shadow: none;
    height: 100vh;
    z-index: 1;
    top: 57px;
    right: 0;
}

.a-header-con {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.selected-header {
    background: #EAF0FC 0% 0% no-repeat padding-box;
    padding: 15px 25px;
}

.export-btn {
    background: #2D9E5A;
}

.reassign-select-label {
    text-align: left;
}

.a-modal section {
    margin-bottom: 44px;
}

.a-modal hr {
    border: 1px solid #4e4e4e30;
    margin: 32px 0
}

.uploaded-files-con {
    max-height: 300px;
    overflow-y: auto;
}

.empty-listing-con {
    position: relative;
    height: 28vh;
}

.empty-listing-con img {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    width: 38%;
    left: 50%;
    top: 105%;
}

.back-to-case div {
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
    display: block;
    border: 1px solid #ccc;
}

.back-to-case div:hover {
    border-color: #2D599E !important;
}

.case-file-item .case-item-actions img {
    -webkit-transition: opacity .5s ease;
    transition: opacity .5s ease;
    left: initial;
    opacity: 1;
}

.case-file-item .case-item-actions img:hover {
    opacity: 0.7;
}

.case-file-item .case-item-actions {
    display: none;
}

.case-file-item:hover .case-item-actions {
    display: flex;
    justify-content: flex-end;
}

.r-12 {
    right: 12px !important;
}

.search-footer {
    background: #fff;
    position: fixed;
    padding: 10px 350px 0 77px;
    height: 60px;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 3;
}

.additional-filters-con {
    height: 0;
    overflow: hidden;
    -webkit-transition: height 0.2s ease;
    transition: height 0.2s ease;
}

.additional-filters-con.expanded {
    height: calc(100vh - 182px);
}

.additional-filters-con-con {
    height: calc(100vh - 310px);
    padding: 0 20px 20px 41px;
    overflow-x: hidden;
    overflow-y: auto;
}

.fab-con {
    -webkit-transition: transform ease 0.3s;
    transition: transform ease 0.3s;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    position: fixed;
    height: 150px;
    width: 160px;
    bottom: 7px;
    right: 290px;
    z-index: 4;
}

.fab-con .import-btn {
    -webkit-transform: translate(-50%, -50%) rotate(-180deg);
    transform: translate(-50%, -50%) rotate(-180deg);
    -webkit-transition: opacity ease 0.5s;
    transition: opacity ease 0.5s;
    position: absolute;
    left: 50%;
    top: 55%;
    height: 56px;
    z-index: 4;
}

.fab-con .import-btn:hover {
    opacity: 0.8;
}

.fab-con [class^="extra-import-btn"] {
    -webkit-transition: opacity ease .5s, top ease .1s, left ease .1s;
    transition: opacity ease .5s, top ease .1s, left ease .1s;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 36px;
    height: 36px;
    opacity: 0;
    display: block;
    position: absolute;
    left: 45%;
    top: 48%;
    z-index: 0;
}

.fab-con.expanded {
    border-radius: 100%;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

.fab-con.expanded [class^="extra-import-btn"] {
    opacity: 1;
    z-index: 5;
}

.fab-con [class^="extra-import-btn"]:hover {
    opacity: 0.7;
}

.fab-con.expanded .extra-import-btn1 {
    top: 18%;
    left: 61%;
}

.fab-con.expanded .extra-import-btn2 {
    top: 16%;
    left: 35%;
}

.fab-con.expanded .extra-import-btn2.extra {
    top: 19%;
    left: 23%;
}

.fab-con.expanded .extra-import-btn3 {
    left: 15%;
    top: 33%;
}

.fab-con.expanded .extra-import-btn4 {
    top: 62%;
    left: 16%;
}

.fab-con.expanded .extra-import-btn4.extra {
    top: 48%;
    left: 10%;
}

.fab-con.expanded .extra-import-btn5 {
    top: 80%;
    left: 35%;
}

.fab-con.expanded .extra-import-btn5.extra {
    top: 76%;
    left: 23%;
}

.fab-con.expanded .extra-import-btn6 {
    top: 77%;
    left: 61%;
}

#accounting-details-modal h2 {
    color: #3E88DA;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 5px;
}

#accounting-details-modal p {
    color: #53606DB3;
    font-size: 12px;
    letter-spacing: 0.21px;
    margin-bottom: 30px;
}

#accounting-details-modal .header-border {
    border-top: 2px dashed #70707066;
}

#accounting-details-modal h4 {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: #515365;
    margin-bottom: 30px;
}

#accounting-details-modal h5 {
    display: block;
    font-size: 14px;
    margin-bottom: 37px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #383A3D;
}

#accounting-details-modal h5 span {
    display: block;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.21px;
    color: #53606DB3;
    margin-bottom: 4px;
}

div>>>.add-new-con {
    position: relative !important;
    text-align: left;
    padding: 20px 334px 15px 0;
}

div>>>.add-new-con-without-p {
    position: relative;
    text-align: left;
    padding: 20px 0 15px 0;
}

div>>>.add-new-con h5 {
    font-weight: 500 !important;
    color: #515365;
    line-height: 24px;
    font-size: 24px;
    margin-bottom: 17px;
}

div>>>.add-new-con .photo-modal {
    position: absolute;
    right: -18px;
    top: 55%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

/* .table-body.scrollable{
        height    : 300px;
        width     : 100%;
        margin    : 0;
        overflow-y: auto;
    } */
#group-seller-modal table tr {
    font-size: 12px;
}

#group-seller-modal table thead tr {
    background: #F5F6FA;
    border-bottom: 2px solid rgb(214, 214, 216);
    color: #202229;
}

#group-seller-modal table tbody tr {
    border-bottom: 2px solid #F5F6FA;
}

#group-seller-modal table tbody tr:last-child {
    border-bottom: none;
}

#group-seller-modal table tfoot tr {
    font-size: 16px;
    font-weight: 700;
}

.table-scroll {
    width: 100%;
    display: block;
    empty-cells: show;
}

.table-scroll thead,
.table-scroll tfoot {
    position: relative;
    display: block;
    width: 100%;
}

.table-scroll tbody {
    display: block;
    position: relative;
    width: 100%;
    min-height: 150px;
    max-height: 280px;
    overflow-y: auto;
}

.table-scroll tr {
    width: 100%;
    display: inline-block;
}

.table-scroll td,
.table-scroll th {
    display: inline-block;
    word-break: break-all;
    padding: 1rem;
    text-align: left;
}

.group-p {
    text-align: left;
    font: Regular 14px/17px Roboto;
    letter-spacing: 0.24px;
    color: #53606DB3;
    opacity: 1;
}

.gname-at-image {
    text-align: left;
    font: Bold 12px/14px Roboto;
    letter-spacing: 0.4px;
    color: #3E88DA;
    opacity: 1;
}

.overwrite-name {
    text-align: left;
    font: Medium 16px/24px Roboto;
    letter-spacing: 0px;
    color: #2D599E;
    opacity: 1;
    font-weight: 900;
}

.duplicate-account {
    background: #DC1A1A52 0% 0% no-repeat padding-box;
    opacity: 1;
}

.new-entries {
    background: #12B83959 0% 0% no-repeat padding-box;
    opacity: 1;
}

.import-frozen-scrollable {
    height: 500px;
    overflow: hidden auto;
}

.note-import-text {
    text-align: left;
    font-weight: 900;
    letter-spacing: 0px;
    color: #2D599E;
    opacity: 1;
}

.in-group {
    background-color: #cfe2f3;
}

.not-same {
    background-color: #f9cb9c;
}

.release-with-prev-value {
    background-color: #ff00004a;
}

.release-in-group {
    background-color: #ffa5004a;
}

.header-fixed {
    width: 1600px;
}

.header-fixed>thead,
.header-fixed>tbody,
.header-fixed>thead>tr,
.header-fixed>tbody>tr,
.header-fixed>tbody>tr>td {
    display: block;
}

.header-fixed>tbody>tr:after,
.header-fixed>thead>tr:after {
    content: ' ';
    display: block;
    visibility: hidden;
    clear: both;
}

.header-fixed>tbody {
    overflow-y: auto;
    min-height: 150px;
    max-height: 300px;
}

.header-fixed>tbody>tr>td,
.header-fixed>thead>tr>th {
    float: left;
}

.ellipsify {
    white-space: nowrap;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.scrollable-table {
    width: 100%;
    overflow-y: auto;
}

::-webkit-scrollbar {
    width: 10px !important;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

#settlement-agreement-modal .campaign-img,
#vendor-move-modal .campaign-img {
    height: 40px;
    width: 40px;
}

#settlement-agreement-modal h5,
#vendor-move-modal h5 {
    font-size: 16px;
    color: #515365;
    width: 275px;
}

#settlement-agreement-modal h5 span,
#vendor-move-modal h5 span {
    font-size: 12px;
}

#settlement-agreement-modal p,
#vendor-move-modal p {
    font-size: 12px;
    color: #515365;
}

#settlement-agreement-modal .left-con,
#settlement-agreement-modal .right-con,
#vendor-move-modal .left-con,
#vendor-move-modal .right-con {
    max-width: 400px;
    height: 330px;
    overflow-y: auto;
}

#settlement-agreement-modal .right-con.sa-setter {
    height: 355px;
}

#settlement-agreement-modal .left-con::-webkit-scrollbar,
#settlement-agreement-modal .right-con::-webkit-scrollbar,
#vendor-move-modal .left-con::-webkit-scrollbar,
#vendor-move-modal .right-con::-webkit-scrollbar {
    width: 5px !important;
}

#settlement-agreement-modal .settlement-defendants-con,
#vendor-move-modal .settlement-defendants-con {
    text-align: left;
    height: 375px;
}

#settlement-agreement-modal .selected-settlement-defendants,
#vendor-move-modal .selected-settlement-defendants {
    margin-bottom: 28px;
}

#settlement-agreement-modal .selected-settlement-defendants h6,
#vendor-move-modal .selected-settlement-defendants h6 {
    font-weight: 500;
    margin-bottom: 3px;
    font-size: 15px;
    color: #203154;
    letter-spacing: 0.13px;
}

#settlement-agreement-modal .selected-settlement-defendants label,
#vendor-move-modal .selected-settlement-defendants label {
    font-weight: 600;
    font-size: 12px;
    color: #2D599E;
    letter-spacing: 0.4px;
    margin-bottom: 10px;
}

#settlement-agreement-modal .selected-settlement-defendants label span,
#vendor-move-modal .selected-settlement-defendants label span {
    font-weight: 400;
    font-size: 12px;
    color: #3E88DA;
}

#settlement-agreement-modal .table tbody tr td,
#vendor-move-modal .table tbody tr td {
    font-size: 12px;
    font-weight: 600;
    color: #141517;
    letter-spacing: 0.4px;
    padding-top: 5px;
    padding-bottom: 4px;
}

#settlement-agreement-modal .table tbody tr td b,
#vendor-move-modal .table tbody tr td b {
    display: block;
    width: 127px;
}

#settlement-agreement-modal .table tbody tr td.header-label,
#vendor-move-modal .table tbody tr td.header-label {
    font-weight: 400;
    color: #515365;
}

#settlement-agreement-modal .table tbody tr td.header-label img,
#vendor-move-modal .table tbody tr td.header-label img {
    width: 12px;
    height: 12px;
}

.a-modal-seller-info-item {
    -webkit-transition: background 0.5s ease, border-color 0.5s ease;
    transition: background 0.5s ease, border-color 0.5s ease;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid transparent;
    padding: 15px 50px 13px;
    word-break: break-all;
    letter-spacing: 0.25px;
    border-radius: 4px;
    position: relative;
    text-align: left;
    color: #141517;
    font-size: 14px;
    min-height: 68px;
    cursor: pointer;
    opacity: 1;
    margin-bottom: 0;
}

.a-modal-seller-info-item.sa-error {
    background: #F2C6C7 !important;
    border: 1px solid #DC3C40 !important;
}

.a-modal-seller-info-item.generate-details {
    padding: 15px 0 13px 50px;
    cursor: default;
    border: none;
    background: #fff;
}

.a-modal-seller-info-item.generate-details h5 {
    font-weight: 400;
    color: #2D599E !important;
    text-overflow: initial;
    white-space: initial;
    overflow: initial;
}

.a-modal-seller-info-item.generate-details h5 span {
    color: #515365 !important;
}

.a-modal-seller-info-item.generate-details img {
    left: 6px;
}

.a-modal-seller-info-item.current {
    background-color: #dde4ef;
    border-color: #2D599E;
}

.a-modal-seller-info-item:not(.generate-details):hover {
    background-color: #F5F5F5;
}

.a-modal-seller-info-item h5 {
    letter-spacing: 0.24px;
    font-weight: 500;
    color: #141517;
    font-size: 14px;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100% !important;
}

.a-modal-seller-info-item h5 span {
    letter-spacing: 0;
    font-weight: 400;
    margin-top: 5px;
    font-size: 12px;
    display: block;
}

.a-modal-seller-info-item i,
.a-modal-seller-info-item img {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    font-size: 32px;
    left: 15px;
    top: 50%;
}

.a-modal-seller-info-item img {
    width: 24px;
}

.a-modal-seller-info-item .remove-btn img,
.a-modal-seller-info-item .select-btn img {
    -webkit-transition: opacity .5s ease;
    transition: opacity .5s ease;
    opacity: 0;
    left: initial;
    right: 15px;
    width: 27px;
}

.a-modal-seller-info-item .select-btn img {
    right: 48px;
    width: 22px;
}

.a-modal-seller-info-item:hover .remove-btn img,
.a-modal-seller-info-item:hover .select-btn img,
.a-modal-seller-info-item.current .remove-btn img,
.a-modal-seller-info-item.current .select-btn img {
    opacity: 1;
}

.a-modal-seller-info-item .remove-btn:hover img,
.a-modal-seller-info-item .select-btn:hover img {
    opacity: 0.8;
}

.remove-confirm-text {
    margin-bottom: 0;
    font-size: 10px;
}

.remove-confirm-btn {
    -webkit-transition: opacity .5s ease;
    transition: opacity .5s ease;
    display: inline-block;
    width: 24px;
    cursor: pointer;
}

.remove-confirm-btn img {
    width: 100%;
}

.remove-confirm-btn:hover {
    opacity: 0.5;
}

.remove-confirm-con,
.remove-confirm-con .a-modal-seller-info-item {
    background: #eaeef5;
}

.status-icon {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
    right: 20px;
    height: 24px;
    width: 24px;
}

.status-icon img {
    left: initial;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 0;
    border: 3px solid #2D599E;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #2D599E transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.sa-custom-input {
    border-radius: 3px;
    border: 1px solid #666;
    padding: 0 6px;
}

.has-bullet-separator {
    position: relative;
}

.has-bullet-separator::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 2;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 5px;
    width: 5px;
    border-radius: 100%;
}

.already-worked-con {
    max-height: 250px;
    overflow-y: auto;
    padding-right: 10px;
}

.error-doe-con {
    max-height: 250px;
    overflow-y: auto;
    padding-right: 10px;
    width: 100%;
}

.exports-list {
    height: 350px;
    overflow: auto;
    text-align: left;
}

.exports-con {
    text-align: left;
}

.exports-con h5 {
    font-weight: 500;
    font-size: 16px;
}

.exports-con h5 span {
    font-weight: 400;
    font-size: 12px;
}

.exports-campaign-img {
    height: 40px;
    width: 40px;
}

.exports-sub {
    font-size: 12px;
}

.spg-campaign-img {
    height: 40px;
    width: 40px;
}

#submissions-pack-generator-modal .left-con {
    width: 330px;
    height: 391px;
}

#submissions-pack-generator-modal .spg-right-con {
    width: calc(100% - 368px);
    background: #F8F8F8;
    padding: 15px;
}

#submissions-pack-generator-modal .right-con {
    height: 345px;
    overflow-y: auto;
    padding-right: 10px;
}

#submissions-pack-generator-modal .right-con::-webkit-scrollbar,
#submissions-pack-generator-modal .right-con::-webkit-scrollbar-thumb,
.spg-payment-type-drag-con::-webkit-scrollbar,
.spg-payment-type-drag-con::-webkit-scrollbar-thumb {
    width: 6px !important;
}

.spg-left-heading h5,
.spg-right-heading h5 {
    font-weight: 500;
    font-size: 16px;
}

.spg-left-heading h5 span,
.spg-right-heading h5 span {
    font-weight: 400;
    font-size: 12px;
}

.spg-display-tab {
    font-size: 12px;
    font-weight: 500 !important;
    color: #515365;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.spg-display-tab label,
.spg-display-tab i {
    cursor: pointer;
}

.spg-display-tab.active {
    color: #3E88DA;
}

.spg-display-tab.active i {
    background: #ABCBEF;
    border-radius: 100%;
    padding: 2px;
}

#input-pack-info-tab {
    padding: 15px 10px;
    margin: 10px 0 0;
    background: #f2f2f2;
    border-radius: 6px;
}

.spg-payment-type-drag-con {
    height: 267px;
    overflow-y: auto;
    margin-top: 10px;
    padding-right: 8px;
}

.spg-payment-type {
    padding: 10px 20px;
    background: #FFFFFF;
    border-radius: 0 4px 4px 0;
    box-shadow: 2px 1px 5px 1px #eee;
    border-left: 3px solid #2D589D;
    color: #2D599E;
}

.spg-item-types-con {
    padding: 10px 15px 0;
    background: #E9F3FE;
    border-radius: 6px;
    max-height: 155px;
    overflow-y: auto;
}

.spg-item-type {
    color: #515365;
    margin-bottom: 10px;
}

.spg-item-type label {
    font-size: 14px;
    font-weight: 500;
}

.spg-item-type img {
    height: 16px;
    width: 16px;
    position: relative;
    top: -1px;
    margin-right: 10px;
}

.draggable-item {
    margin-bottom: 10px;
    user-select: none;
    position: relative;
    width: 100%;
    cursor: grab;
}

.draggable-item h4 {
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 400;
    letter-spacing: 0.24px;
    cursor: pointer;
}

.draggable-item .action-con {
    cursor: pointer;
}

.draggable-list {
    height: 62px;
    background: #fff;
    border-radius: 4px;
    border-left: 4px solid #2D589D;
    padding: 8px 18px;
    margin-bottom: 14px;
    cursor: grab;
}

.draggable-list .draggable-list-index {
    display: inline-block;
    position: relative;
    padding: 1px 5px;
    font-size: 10px;
    font-weight: 400 !important;
    color: #fff;
    border-radius: 2px;
    background: #2D589D;
    margin-right: 20px;
    margin-bottom: 0;
    top: -2px;
}

.draggable-list h4 {
    font-size: 12px;
    color: #383A3D;
    margin: 0;
    display: inline-block;
}

.draggable-list p {
    font-size: 12px;
    color: #515365;
}

.spg-listing-misc {
    display: inline-block;
    margin-right: 20px;
    position: relative;
    font-size: 12px;
}

.spg-listing-misc::after {
    content: "";
    width: 1px;
    height: 16px;
    background: #707070;
    right: -10px;
    top: 0;
    bottom: 0;
    position: absolute;
}

.spg-listing-misc:last-child {
    margin-right: 0;
}

.spg-listing-misc:last-child::after,
.spg-listing-misc.last::after {
    display: none;
}

.spg-listing-misc label {
    font-weight: 500 !important;
    color: #141517;
}

.spg-listing-misc img {
    height: 12px;
    width: 12px;
    margin-right: 10px;
}

.spg-defendant-filter-btn {
    font-size: 12px;
    font-weight: 500;
}

.spg-defendant-filter-con {
    padding: 13px 6px 5px;
    min-height: 298px;
    width: 243px;
    border-radius: 16px;
}

.spg-defendant-filter-con .dropdown-header {
    font-size: 12px;
    letter-spacing: 2.4px;
    color: #888888;
    margin-bottom: 13px;
}

.edit-seller-account-con {
    max-height: 55vh;
    overflow-y: auto;
    padding-right: 10px;
}

.filter-label {
    color: #383A3D;
    margin-left: 5px;
    font-weight: 500 !important;
    font-size: 13px;
    padding-bottom: calc(0.375rem + 1px);
}

.filter--sub-reasons-con {
    max-height: 0;
    overflow: hidden;
    padding: 0;
    -webkit-transition: .5s ease;
    transition: .5s ease;
}

.filter--sub-reasons-con.toggled {
    max-height: 500px;
    padding-top: 8px;
}

.upload-case--box {
    position: relative;
    display: inline-block;
    width: 250px;
    height: 120px;
    border-radius: 8px;
    border: 2px dashed #262C3952;
    background: #3B59981A;
    margin-left: 0.7%;
    margin-right: 0.7%;
    -webkit-transition: .5s ease;
    transition: .5s ease;
    cursor: pointer;
}

.upload-case--box .remove-upload--file-button {
    position: absolute;
    display: block;
    height: 10px;
    width: 10px;
    top: -6px;
    right: -1px;
    -webkit-transition: .3s ease;
    transition: .3s ease;
    cursor: pointer;
}

.upload-case--box .remove-upload--file-button:hover {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
}

.upload-case--box.toBeUploaded {
    opacity: .7;
}

.upload-case--box:not(.cantUpload):hover,
.upload-case--box:not(.cantUpload).dragHover {
    -webkit-transform: scale(.95);
    transform: scale(.95);
    border-color: #2d599e;
}

.upload-case--box input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
}

.upload-case--box .icon {
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 55%;
    left: 50%;
    font-size: 12px;
    text-align: center;
    color: #3B59988F;
    font-weight: 500;
    z-index: 1;
    cursor: pointer;
}

.upload-case--box .icon img {
    width: 50px;
    height: 60px;
}

.defendants-btn-con {
    position: absolute;
    top: 10px;
    right: 20px;
}

.viewUploadsBtn {
    text-transform: uppercase;
    width: 120px;
    height: 24px;
    background-color: #3E88DA;
    border-radius: 20px;
    border: none;
    outline: none;
    font: normal normal bold 12px/16px Roboto;
    letter-spacing: 1.07px;
    color: #FFFFFF;
    /* z-index: 5; */
}

.viewUploadsBtn:hover {
    opacity: 0.5;
    /* z-index: 999; */
}

.uploadsContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: -60px;
    column-gap: 40px;
}

.fileName {
    height: 38px;
    background: #53606D 0% 0% no-repeat padding-box;
    border: 1px solid #0D1926;
    border-radius: 4px;
    opacity: 0.07;
}

.agreementSelection {
    height: 38px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #0D1926;
    border-radius: 4px;
    opacity: 0.5;
    font: normal normal normal 14px/19px Roboto;
    letter-spacing: 0px;
    color: #000000;
    padding-left: 10px;
}

.agreementSelection:hover {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #2D599E;
    border-radius: 4px;
}

.uploadBtns {
    display: flex;
    margin-top: 30px;
    column-gap: 20px;
}

.uploadBtn {
    background: #3B5998 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px #00000033;
    border: none;
    border-radius: 18px;
    width: 156px;
    height: 36px;
    font: normal normal normal 14px/19px Roboto;
    letter-spacing: 1.25px;
    color: #FFFFFF;
    transition: 0.3s;
}

.uploadBtn:hover {
    background: #c5d3f1 0% 0% no-repeat padding-box;
    transition: 0.3s;
}

.cancelBtn {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #383A3D;
    border-radius: 18px;
    font: normal normal normal 14px/19px Roboto;
    letter-spacing: 1.25px;
    color: #383A3D;
    width: 81px;
    height: 36px;
    transition: 0.3s;
}

.cancelBtn:hover {
    background: #2D599E 0% 0% no-repeat padding-box;
    color: #FFFFFF;
    transition: 0.3s;
    border: none;
}

.removeFile {
    display: flex;
    column-gap: 5px;
    position: absolute;
    bottom: 10px;
    left: 20px;
    text-decoration: underline;
    font: normal normal normal 12px/16px Roboto;
    letter-spacing: 0px;
    color: #F15642;
    cursor: pointer;
}

.removeFile:hover {
    color: #d6331d;
}

.settlementAgreement {
    display: flex;
    flex-direction: column;
}

.settlementHeader {
    font: normal normal bold 22px/32px Roboto;
    letter-spacing: 0px;
    color: #515365;
}

.uploadSpan {
    width: 325px;
    height: 135px;
    background: #3B59981A 0% 0% no-repeat padding-box;
    border: 2px dashed #262C3952;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
    transition: 0.3s;
}

.inputFileAgreement {
    opacity: 1;
    overflow: hidden;
    position: absolute;
    margin-left: -3px;
    left: 20px;
    height: 135px;
    width: 325px;
    z-index: 2;
    color: transparent;
    transition: .3s;
}

.inputFileAgreement::-webkit-file-upload-button {
    display: none;
}

.inputFileAgreement:hover {
    background: #2D599E42 0% 0% no-repeat padding-box;
    border: 2px dashed #2D599E;
    border-radius: 8px;
    opacity: 1;
    transition: .3s;
    cursor: pointer;
}

.uploadSpan:hover {
    background: #2D599E42 0% 0% no-repeat padding-box;
    border: 2px dashed #2D599E;
    border-radius: 8px;
    opacity: 1;
    transition: .3s;
}

.uploadSpan1 {
    background: #2D599E42 0% 0% no-repeat padding-box;
    border: 2px dashed #2D599E;
    border-radius: 8px;
    opacity: 1;
    transition: .3s;
}

.uploadSpanDesc {
    font: normal normal normal 12px/16px Roboto;
    letter-spacing: 0px;
    color: #3B59988F;
}

.uploadSpanDesc:hover {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
    transition: .3s;
}

.fileToUpload {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.removeUploaded {
    position: absolute;
    top: -60px;
    right: 0;
    background: none;
    border: none;
    outline: none;
    height: 10px;
    width: 10px;
    font-weight: bold;
    z-index: 3;
}

.uploadedFile {
    display: flex;
    position: relative;
}

[v-cloak] {
    display: none;
}

.divider {
    width: 3px;
    background-color: #c5c5c5;
}

.uploadedSettlement {
    display: flex;
    flex-direction: column;
}

.agreementHeader {
    font: normal normal bold 16px/24px Roboto;
    letter-spacing: 0px;
    color: #203154;
    text-align: left;
}

.numberOfFiles {
    text-align: left;
    font: normal normal bold 12px/16px Roboto;
    letter-spacing: 0px;
    color: #333333DE;
}

.uploadedSettlementDiv2 {
    display: flex;
    flex-direction: column;
    width: 280px;
    height: 335px;
    overflow-y: scroll;
    overflow-x: hidden;
    row-gap: 5px;
}

.fileImgs {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fileImg {
    width: 28px;
    height: 32px;
}

.uploadedFilesList {
    display: flex;
    width: 265px;
    height: 60px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #383A3D33;
    border-radius: 8px;
    column-gap: 20px;
    padding: 10px;
    position: relative;
}

.uploadedFilesList:hover,
.uploadedFilesList.isSelected {
    background: #0773e01c no-repeat padding-box;
    border: 1px solid #3B599836;
    border-radius: 12px;
    cursor: pointer;
}

.fileDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.uploadedFileName {
    font: normal normal bold 14px/21px Roboto;
    letter-spacing: 0px;
    color: #383A3D;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.uploadedFileStatus {
    font: normal normal normal 12px/16px Roboto;
    letter-spacing: 0px;
    color: #333333DE;
    margin-bottom: 10px;
    height: 10px;
}

.confirmationDetails {
    display: flex;
    flex-direction: column;
    margin-bottom: -50px;
}

.confirmDetails {
    font: normal normal bold 16px/16px Roboto;
}

.confirmationChoices {
    display: flex;
    column-gap: 30px;
    justify-content: center;
}

.choicesBtn {
    height: 30px;
    width: 100px;
    border: none;
    border-radius: 5px;
    outline: none;
    background: #2D599E 0% 0% no-repeat padding-box;
    font: normal normal bold 14px/16px Roboto;
    color: #FFFFFF;
    transition: 0.3s;
}

.choicesBtn:hover {
    border: 1px solid #000000;
    opacity: 0.7;
    transition: 0.3s;
}

.fileDownloadBtn {
    position: absolute;
    right: 10px;
    top: 15px;
    display: flex;
    column-gap: 2px;
    visibility: hidden;
}

.uploadedFilesList:hover .fileDownloadBtn {
    visibility: visible;
}

.uploadDownloadBtn:hover {
    opacity: 0.8;
}

.deleteFileBtn:hover {
    opacity: 0.8;
}

/*
    * CM VARIATIONS
    */
.gui-cm .has-bullet-separator::before {
    background: #2D599E;
}

/*
    * END CM VARIATIONS
    */
/* newly added email */
.email_info_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-size: 12px;
    font-weight: 500;
    margin-top: 10px;
    padding: 10px 10px 10px 45px;
    height: calc(90% - 16px);
    color: #3e88da;
    background: #eef3ff;
    border: 1px solid #3e88da;
    border-radius: 5px;
    position: relative;
}

.email_info_container i {
    top: 0;
    left: 0;
    padding: 10px 0px 0px 15px;
    position: absolute;
}

.email_info_container p {
    margin-bottom: 0;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/* MANAGE GROUPS START */

.manage-groups-con{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.manage-groups-header{
    display: flex;
    column-gap: 5px;
}
.manage-groups-head{
    text-align: left;
    font: normal normal bold 16px/21px Roboto;
    letter-spacing: 0px;
    color: #515365;
}
.manage-groups-desc{
    text-align: left;
    font: normal normal normal 12px/16px Roboto;
    letter-spacing: 0px;
    color: #515365;
}
.group-lists{
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 20px;
    row-gap: 20px;
    height: 70px;
    padding: 5px;
    width: 100vw;
    overflow-y: hidden;
}
.group-list{
    overflow-x: hidden;
}
.group-checkbox-span{
    height: 20px;
    width: 20px;
}
.group-checkbox{
    height: 20px;
    width: 20px;
}
.group-list-desc{
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
}
.group-list-desc{
    font: normal normal bold 14px/19px Roboto;
    letter-spacing: 0.24px;
    color: #515365;
}
.group-list-length{
    font: normal normal normal 10px/13px Roboto;
    letter-spacing: 0.17px;
    color: #515365;
}
.group-list:hover{
    background: #2D59AE26 0% 0% no-repeat padding-box;
    border-radius: 4px;
    cursor: pointer;
}
.group-list:hover .group-list-desc{
    color: #2D59AE;
}
.group-list:hover .group-list-length{
    color: #2D59AE;
}
.group-list:hover .group-list-img{
    background-image: url("/static/svg/group-management/group-blue.svg");
}
.group-buttons{
    column-gap: 5px;
    display: none;
    margin-left: -45px;
}
.group-lists:hover .group-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
}
.group-buttons.deleteGroupClicked{
    display: flex;
    justify-content: center;
    align-items: center;
}
.group-btn{
    height: 13px;
    width: 13px;
}
.button-group{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 24px;
    height: 25px;
    width: 25px;
}
.button-group:hover{
    background: #C4677E17 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 24px;
}
.group-list-img{
    margin-top: 10px;
    background-image: url("/static/svg/Modal_Group.svg");
    background-size: auto;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
}
.group-list-img-span{
    padding-right: 25px;
}
.confirm-group-delete{
    display: flex;
    column-gap: 5px;
    position: absolute;
    top: 40px;
    left: 8%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    padding: 5px;
    border-radius: 10px;
    font: normal normal bold 12px/19px Roboto;
    letter-spacing: 0.24px;
    color: #515365;
}
.confirm-delete-button{
    padding-left: 3px;
    padding-right: 3px;
}
.confirm-delete-button:hover{
    background: #52007817 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
}
.edit-group-main-con{
    display: flex;
    column-gap: 30px;
    height: 565px;
}
.edit-group-left-con{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 40px;
    padding-right: 10px;
    border-right: 2px solid #7070704D;
}
.edit-group-left-header{
    display: flex;
    align-items: flex-end;
    column-gap: 5px;
}
.edit-group-header-desc{
    display: flex;
    flex-direction: column;
    text-align: left;
}
.edit-group-left-groups{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
}
.cancel-update-group-name{
    outline: none;
    border: none;
    width: 300px;
    text-decoration: underline;
    font: normal normal bold 14px/19px Roboto;
    letter-spacing: 1.25px;
    color: #515365;
}
.edit-group-left-back{
    display: flex;
    align-items:flex-end;
    width: 300px;
}
.edit-group-left-back-btn{
    width: 160px;
    height: 36px;
    border: 1px solid #000000;
    color: #000000;
    font: normal normal bold 14px/19px Roboto;
    letter-spacing: 1.25px;
    color: #383A3D;
}
.edit-group-right-con{
    display: flex;
    width: 470px;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
    background: #F8F8F8 0% 0% no-repeat padding-box;
    padding: 10px;
}
.group-listings-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 450px;
    border-bottom: 2px solid #7070704D;
}
.group-listings-head{
    font: normal normal bold 16px/21px Roboto;
    letter-spacing: 0px;
    color: #515365;
}
.group-listings-list{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    height: 500px;
    overflow-y: auto;
    padding-right: 5px;
}
.listings-per-group{
    display: flex;
    column-gap: 10px;
    text-align: left;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #383A3D33;
    border-radius: 8px;
    padding: 10px;
    height: fit-content;
}
.group-list-logo{
    width: 113px;
    border-radius: 8px;
    height: 91px;
}
.group-list-details{
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}
.group-list-detail-name{
    font: normal normal bold 14px/19px Roboto;
    letter-spacing: 0.13px;
    color: #203154;
    width: 230px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.group-list-labels{
    display: flex;
    column-gap: 5px;
}
.group-list-number{
    background: #c1e0ff 0% 0% no-repeat padding-box;
    border-radius: 4px;
    font: normal normal bold 10px/21px Roboto;
    letter-spacing: -0.2px;
    color: #2D599E;
    padding: 3px;
    width: 70px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}
.group-list-number:hover{
    background: #3B5998 0% 0% no-repeat padding-box;
    border-radius: 4px;
    color: #FFFFFF;
}
.group-list-status{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #70707054;
    border-radius: 4px;
    font: normal normal bold 10px/21px Roboto;
    letter-spacing: -0.2px;
    color: #595959;
    padding: 3px;
    width: 70px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}
.group-list-label{
    display: flex;
    justify-content: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #70707054;
    border-radius: 4px;
    font: normal normal bold 10px/21px Roboto;
    letter-spacing: -0.2px;
    color: #595959;
    column-gap: 5px;
    padding: 3px;
    width: 70px;
}
.group-list-label-name{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.group-list-label-logo{
    width: 16px;
    min-height: fit-content;
    border-radius: 4px;
}
.group-list-label-description{
    font: normal normal normal 12px/16px Roboto;
    letter-spacing: 0.4px;
    color: #515365;
    width: 300px;
    height: 35px;
    text-overflow:ellipsis;
    overflow:hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
}
.group-listings-buttons{
    position: absolute;
    display: flex;
    visibility: hidden;
    left: 80%;
    column-gap: 10px;
}
.group-listings-buttons.deleteListingClicked{
    display: flex;
    align-items: center;
}
.remove-listing-span{
    width: 24px;
    height: 24px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.remove-listing-span:hover{
    background: #7c243a17 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
}
.remove-listing-span.hidden{
    visibility: hidden;
}
.edit-listing-span{
    width: 24px;
    height: 24px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.edit-listing-span-checkbox {
    margin-top: 5px;
	font-size: 24px;
	cursor: pointer;
  	caret-color: transparent;
	display: flex;
   	align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    box-shadow: 0px 3px 6px #00000029;
}
.edit-listing-span-checkbox:hover{
    background: #2D59AE30 0% 0% no-repeat padding-box;
}
.img-unchecked .img-hover .img-checked {
    padding-right: 3px;
    padding-top: 3px;
}


/* Hide the browser's default checkbox */
.edit-listing-span-checkbox input {
  display: none;
}

/* Hide the checked checkbox */
.img-checked {
	display: none;
}
.img-hover {
	display: none;
}


.edit-listing-span-checkbox .list-check:checked ~ .img-checked {
	display: inline;
    background: #e4606d 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #FFFFFF;
    border-radius: 24px;
    padding: 6px;
}

.list-check:checked ~ .group-list-detail-name {
    background: #2D59AE47 0% 0% no-repeat padding-box !important;
    border: 1px solid #2D59AE !important;
    border-radius: 8px !important;
    color: red !important;
}

.edit-listing-span-checkbox .list-check:checked ~ .img-unchecked {
	display: none;
}

.edit-listing-span-checkbox:hover .list-check:not(:checked) ~ .img-hover {
	display: inline;
}

.edit-listing-span-checkbox:hover .list-check:not(:checked) ~ .img-checked {
	display: none;
}

.edit-listing-span-checkbox:hover .list-check:not(:checked) ~ .img-unchecked {
	display: none;
}
.listings-per-group:hover{
    background: #ebf4ff 0% 0% no-repeat padding-box;
    border: 1px solid #c2d3e8fc;
    border-radius: 8px;
    cursor: pointer;
}
.listings-per-group:hover .group-listings-buttons{
    visibility: visible;
    display: flex;
    align-items: center;
}
.listings-per-group.listingSelected {
    background: #2D59AE47 0% 0% no-repeat padding-box;
    border: 1px solid #2D59AE;
    border-radius: 8px;
}
.listings-per-group.listingChecked .group-listings-buttons{
    visibility: visible;
    display: flex;
    align-items: center;
}
.confirm-listing-delete{
    display: flex;
    align-items: center;
    column-gap: 5px;
    position: absolute;
    width: max-content;
    height: 30px;
    top: 30px;
    right: 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    padding: 5px;
    border-radius: 10px;
    font: normal normal bold 14px/19px Roboto;
    letter-spacing: 0.24px;
    color: #515365;
}
.confirm-listing-button{
    padding: 3px;
}
.confirm-listing-button:hover{
    background: #52007817 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
}
.selected-listings-length{
    font: normal normal bold 16px/21px Roboto;
    letter-spacing: 0px;
    color: #515365;
    padding: 16.5px;
    margin-left: -15px;
}
.transfer-to{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #70707061;
    border-radius: 4px;
    padding: 3px;
    text-align: left;
    font: normal normal normal 14px/21px Roboto;
    letter-spacing: -0.2px;
    color: #4D4D4D;
    font-weight: 500;
}
.listings-transfer-label{
    color: #383A3D;
    margin-left: 5px;
    font-weight: 500;
    font-size: 13px;
}
.listings-transfer-select{
    display: block;
    width: 100%;
    height: calc(2.5rem + 2px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #FFFFFF;
    background-clip: padding-box;
    border: 1px solid #CED4DA;
    box-shadow: inset 0 0 0 rgb(0 0 0 / 0%);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-radius: 4px;
    margin-top: 7px;
}
.listings-transfer-option:hover{
    background: #3B5998 0% 0% no-repeat padding-box;
    border-radius: 4px;
    font: normal normal normal 20px/16px Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
}
.deleteGroupBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    border-color: #A30000;
    color: #A30000;
}
.deleteGroupBtn:hover{
    border-color: #A30000 !important;
    color: #A30000 !important;
}

/* MANAGE GROUPS END */

.sort-order-label{
    font-size: 14px;
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: bold;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}
.extraction-note{
    font-size: 12px;
    border: 1px solid black;
    padding: 5px;
    margin-bottom: 20px;
}

.order-by-buttons button.active,
.sort-by-buttons button.active {
    background: #C2DEF9;
    border-color: #3F88DA;
    color: #3F88DA;
}

/* Item Classes / Type Aggregator */
#item-class-list-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex- start;
    gap: 12px;
    width: 100%;
    margin-top: 24px;
    border: 1px solid red;

    height: 455px;
    overflow: auto;
    padding: 20px 10px;
    position: relative;
}
#item-class-list-container.hasActive {
    height: calc(100% + 395px);
}
/* .item-class {
    min-height: 50px;
    max-height: 400px;
    height: 50px;
    border: 1px solid #0000001F;
    border-radius: 4px;
    width: 100%;

    -webkit-transition: height .2s ease-in-out;
    transition: height .2s ease-in-out;
} */
.item-class.active {
    background-color: #3F88DA2B;
    border: 1px solid #3F88DA !important;
    border-radius: 4px !important;
}
#item-class-item-types {
    height: 75%;
    overflow: auto;
    padding: 0px 0px 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
#item-class-item-types-container {
    /* display: flex;
    align-items: flex-start;
    justify-content: flex-start; */
    height: auto;
    /* max-height: 170px; */
    min-height:142px;
    padding: 10px 0px 20px 0px;
}
#item-class-item-types-container p{
    font-size: 12px;
    color: #515365;
    margin: 0px;
}
#item-class-item-types-container img{
    width: 12px;
    height: 12px;
}
#item-class-item-types-container.odd {
    border-right: 1px solid #0000001F;
}
#item-class-item-types-container.not-last {
    border-bottom: 1px solid #0000001F;
}
.item-type-status-p {
    color: #141517;
    font-weight: bold;
}
.platform-more-filter {
    transition: 0.2s;
}
.platform-more-filter:hover {
    opacity: 0.7;
    transition: 0.2s;
    cursor: pointer;
}
.list-field-con {
    display: flex;
    column-gap: 80px;
    border: 1px solid #ccc9c9;
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px;
    background-color: #FFFFFF;
    box-shadow: 1px 1px 5px 1px #cac6c6;
}
.copyListingId {
    font-size: 12px;
    font-weight: 400;
    padding: 1px 5px;
    border-radius: 4px;
}
.missing-field-label {
    font: normal normal 350 14px/21px Roboto;
}
.missing-field-con {
    margin-bottom: -3px;
    width: 145px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.accepted-auto-sort {
    height: 100px;
    border: 1px dashed #ff971d;
    border-radius: 10px;
    position: relative;
    padding: 12px 20px 20px 124px;
    background: #fdf7f0;
}
.accepted-auto-sort .notice-img {
    width: 85px;
    height: 63px;
    position: absolute;
    left: 21px;
    top: 50%;
    /* overflow: hidden; */
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 1;
    -webkit-transition: .5s ease;
    transition: .5s ease;
    border: 1px dashed transparent;
}
.accepted-auto-sort .notice-img img {
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 32%;
    left: 50%;
}
.accepted-auto-sort h6 {
    background: #ff971d;
    border-radius: 14px;
    line-height: 25px;
    height: 24px;
    width: 75px;
    text-align: center;
    color: #ffff;
    font-size: 11px;
    margin-bottom: 10px;
}
.accepted-auto-sort p {
    text-align: left;
    font-size: 13px;
    color: #000000CC;
}

.bulk-status-options-con {
    max-height: 200px;
    overflow-y: auto;
}

.case-timeline-menu {
    min-height: 48px;
    background: #DADFEB;
}

.case-timeline-menu h4 {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    color: #8293B1;
}

.defendants-case-statuses .info-status {
    display: inline-block;
    height: 25px;
    border-radius: 25px;
    font-size: 12px;
    line-height: 24px;
    color: #ffffff;
    text-align: center;
    position: relative;
    top: -4px;
    padding-left: 20px;
    padding-right: 20px;
}

.defendants-case-statuses .case-status {
    font-size: 10px;
    height: auto;
    line-height: 100%;
    border: 1px solid #fff;
    background: #7005ae;
    border-radius: 25px;
    padding: 8px 25px 6px 25px;
    top: initial;
    min-width: 75px;
    border-right: 2px solid #fff;
}

.defendants-case-statuses a:first-child .case-status {
    margin-left: initial;
    padding-left: 5px;
    border-radius: 4px 25px 25px 4px;
}

.defendants-case-statuses a:last-child .case-status {
    padding-right: 5px;
}

.defendants-case-statuses .case-status.case-file {
    background: #DADFEB;
    color: #8293B1;
    position: relative;
    min-height: 29px;
}

.defendants-case-statuses .case-status.case-file img {
    display: inline-block;
    width: 12px;
    height: 12px;
    position: relative;
    top: -1px;
}

.defendants-case-statuses .case-status.case-file.available {
    background: #8293B1;
    -webkit-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    color: #fff;
}

.defendants-case-statuses .case-status.case-file.available.current {
    background: #3B5998;
}

.defendants-case-statuses .case-status.download-btn.available {
    background: #28a745;
}

.defendants-case-statuses .case-status.case-file.available:hover {
    opacity: .8;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>
